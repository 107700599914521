<template>
  <f7-page
    class="swimlane-page"
    :style="backgroundImage"
  >
    <!-- Navigation bar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Commercial</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <board-toolbar></board-toolbar>

    <dashboard-menu @onToNewProject="toNewProject"></dashboard-menu>

    <project-swimlane-board
      style="background: transparent"
      class="lazy custom-margin-top"
      @onOpenPopup="openCardDetailsPopup"
    ></project-swimlane-board>

    <!-- Popup -->
    <project-details-popup
      ref="projectDetailsPopup"
      :cardNumber="$f7route.params.cardNumber"
      :actionId="$f7route.params.actionId"
      @navigateToBoard="navigateToBoard"
      @showSetting="openSettingPopup"
      @reOpenPopup="handleSettingChanged"
    ></project-details-popup>

    <project-setting-popup
      ref="projectSettingPopup"
      @settingChanged="handleSettingChanged"
    ></project-setting-popup>
  </f7-page>
</template>

<script>
import ProjectSwimlaneBoard from '../board/ProjectSwimlaneBoard.vue';
import ProjectDetailsPopup from '../../components/popup/ProjectDetailsPopup.vue';
import ProjectSettingPopup from '../../components/popup/ProjectSettingPopup.vue';
import BoardToolbar from '../../components/toolbar/BoardToolbar.vue';
import DashboardMenu from '../../components/menu/DashboardMenu.vue';

import { ACTION_CODE_CONSTRUCTION } from '@/utility/const';

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Menu from '../../../../components/menu/Menu.vue';

export default {
  components: {
    ProjectSwimlaneBoard,
    ProjectDetailsPopup,
    ProjectSettingPopup,
    BoardToolbar,
    DashboardMenu,
    Menu,
  },

  data() {
    const dark = localStorage.getItem('themeDark') === 'true';
    const imgUrl = dark
      ? 'https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-dark.jpg?alt=media&token=2f771661-0968-41e6-bbdc-47f70a3ba912'
      : 'https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-light.jpg?alt=media&token=af0e5a92-6824-400e-8ae0-5b25e9c67cc2';
    return {
      backgroundImage: `background-image: url(${imgUrl}); background-size: cover;`,
    };
  },

  computed: {
    ...mapGetters('dashboard/project', ['boardCode', 'users', 'cardList']),
  },

  async created() {
    this.$f7.preloader.show();
    if (this.cardList?.length !== 0) {
      await this.unbindCardList();
    }

    const businessCode =
      this.$f7route.route.meta.businessCode ||
      this.$f7route.params.businessCode;
    const boardCode = this.$f7route.route.meta.boardCode || '';

    await this.bindCardList({
      businessCode: businessCode,
      boardCode: boardCode,
    });

    const promises = [];
    promises.push(this.getConstantTypeList());
    promises.push(this.setCurrentBusinessCode(businessCode));
    promises.push(this.setCurrentBoard(boardCode));

    if (_.isEmpty(this.users)) {
      promises.push(this.bindUserData());
    }

    Promise.all(promises)
      // For case get details of project
      .then(() => {
        if (
          this.$f7route.params.cardNumber &&
          this.$f7route.params.actionCode
        ) {
          const card = this.cardList.find(
            r => r.cardNumber === this.$f7route.params.cardNumber
          );
          this.getActionBys([
            {
              prop: 'code',
              val: Number(this.$f7route.params.actionCode),
              op: '==',
            },
          ]).then(action => {
            if (
              _.isEmpty(action) ||
              (card && !(card.actions || []).includes(action[0].id))
            ) {
              this.$f7.notification
                .create({
                  title: 'Not found action',
                  closeTimeout: 15000,
                  closeButton: true,
                  text: `Not found action code <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
                  closeOnClick: true,
                })
                .open();
              return;
            }
            this.setCurrentAction(action[0].id);
            this.setCurrentResponse(action[0].id);
            this.setCurrentCard(this.$f7route.params.cardNumber);
            this.$refs.projectDetailsPopup.initCardData(
              this.$f7route.params.cardNumber,
              action[0].id
            );
            this.$refs.projectDetailsPopup.openPopup(false);
          });
        }

        if (this.$f7route.route.meta.isNew) {
          this.$refs.projectDetailsPopup.openPopup(true);
        }
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  methods: {
    ...mapActions('dashboard/project', [
      'setCurrentBusinessCode',
      'setCurrentBoard',
      'bindCardList',
      'setCurrentCard',
      'setCurrentAction',
      'setCurrentResponse',
      'bindUserData',
      'getActionBys',
      'getAction',
      'unbindCardList',
    ]),
    // ...mapActions("dashboard/client", ["bindClientData"]),
    ...mapActions('dashboard/app-constant', ['getConstantTypeList']),

    toNewProject() {
      // this.$f7router.navigate("/dashboard/swimlane/commercial/sales/new", {
      //   pushState: true,
      //   reloadAll: true
      // });
      window.history.pushState(
        '',
        '',
        `/dashboard/swimlane/commercial/sales/new`
      );
      this.$refs.projectDetailsPopup.openPopup(true);
    },

    navigateToBoard() {
      // this.$f7router.navigate(
      //   `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${this.boardCode}`,
      //   {
      //     pushState: true
      //   }
      // );
      //clear query param
      window.history.pushState(
        '',
        '',
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${this.boardCode}`
      );
    },

    async openCardDetailsPopup({ actionId, cardNumber }) {
      if (_.isEmpty(cardNumber) || _.isEmpty(actionId)) {
        return;
      }

      // get action by id
      const action = await this.getAction(actionId);
      this.setCurrentCard(cardNumber);
      this.$f7router.updateCurrentUrl(
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${this.boardCode}/project/${cardNumber}/action/${action.code}`
      );

      this.$refs.projectDetailsPopup.initCardData(cardNumber, actionId);
      this.$refs.projectDetailsPopup.openPopup(false);
    },

    openSettingPopup() {
      this.$refs.projectSettingPopup.openPopup();
    },

    handleSettingChanged({ code, data }) {
      const self = this;
      if (code === 're-open-popup') {
        if (data.actionCode === ACTION_CODE_CONSTRUCTION) {
          this.$f7router.navigate(
            `/dashboard/${self.$f7route.route.meta.boardType}/commercial/construction/project/${data.cardNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );
        }
        // else if (true) {
        // }
        else {
          // this.$refs.projectDetailsPopup.closePopup(() => {
          // self.openCardDetailsPopup({
          //   actionId: data.actionId,
          //   cardNumber: data.cardNumber
          // });
          self.$f7router.navigate(
            `/dashboard/${self.$f7route.route.meta.boardType}/${self.$f7route.route.meta.businessCode}/${data.boardName}/project/${data.cardNumber}/action/${data.actionCode}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );
          // });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swimlane-page {
  // background-image: var(--sales-board-bg-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.swimlane-page {
  .custom-margin-top {
    margin-top: calc(28px + var(--swimlane-dashboard-mode-margin-top) * 2);
  }
}
</style>
