<template>
  <div
    :class="`data-table data-table-init card${
      dataTableCollapsible ? ' data-table-collapsible' : ''
    }`"
  >
    <!-- Custom header -->
    <slot name="card-header"></slot>
    <!-- Table -->
    <div
      class="card-content"
      :class="dataTable.length === 0 ? 'table-no-data' : ''"
      :style="`width: ${lengthWidth || '100%'}`"
    >
      <table>
        <!-- Header Table -->
        <thead v-if="$scopedSlots['header']">
          <th
            v-for="(column, index) in headers"
            :key="index"
          >
            <slot
              name="header"
              :column="column"
            ></slot>
          </th>
        </thead>
        <f7-pin-header-table
          v-if="!$scopedSlots['header']"
          :headers="headers"
          :checkbox="checkbox"
          :indeterminate="indeterminate"
          :checked="checked"
          @sortColumn="sortColumn"
          @pinArray="receivePinData"
          :pinWidth="pinWidth"
          @selectCheckbox="selectCheckbox($event, dataTable)"
        />

        <!-- Body Table -->

        <!-- Custom Only row -->
        <tbody
          v-if="
            $scopedSlots[`body.${nameSlotItem($scopedSlots)}`] &&
            dataTable.length > 0
          "
        >
          <tr
            v-for="(row, index) in dataTable.slice(0, pagination.pageSize)"
            :key="index"
          >
            <td v-if="checkbox">
              <f7-checkbox
                :checked="row.checked"
                @change="
                  selectCheckbox(
                    { type: 'header', checked: $event.target.checked },
                    row
                  )
                "
              ></f7-checkbox>
            </td>
            <template v-for="header in headers">
              <td
                :key="header.value"
                v-if="!$scopedSlots['body.' + header.value]"
              >
                {{ row[header.value] }}
              </td>
              <td
                v-else
                :key="header.value + ''"
              >
                <slot
                  :name="`body.${header.value}`"
                  :item="row"
                />
              </td>
            </template>
          </tr>
        </tbody>

        <!-- Custom multiple row -->
        <tbody v-if="$scopedSlots['body'] && dataTable.length > 0">
          <tr
            v-for="(row, index) in dataTable.slice(
              0,
              pageSize || pagination.pageSize
            )"
            :key="index"
            class="cursor-pointer"
            @click="handleRowClick($event, row)"
          >
            <td v-if="checkbox">
              <f7-checkbox
                :checked="row.checked"
                @click.native.stop
                @change.native.stop="
                  selectCheckbox(
                    { type: 'body', checked: $event.target.checked },
                    row
                  )
                "
              ></f7-checkbox>
            </td>
            <slot
              name="body"
              :item="row"
              :index="index"
            ></slot>
          </tr>
        </tbody>

        <!-- Default table -->
        <f7-pin-body-table
          v-else-if="
            !$scopedSlots['body'] &&
            !$scopedSlots[`body.${nameSlotItem($scopedSlots)}`] &&
            dataTable.length > 0
          "
          :headers="headers"
          :checkbox="checkbox"
          :items="dataTable.slice(0, pagination.pageSize)"
          :pinArrayData="pinArrayData"
          @selectCheckbox="selectCheckbox"
        />
        <!-- No Data -->
        <tbody v-if="dataTable.length === 0">
          <tr>
            <td
              v-if="checkbox"
              :colspan="headers.length + 1"
              class="no-data"
              style="text-align: center"
            >
              No Data!
            </td>
            <td
              v-else
              :colspan="headers.length"
              class="no-data"
              style="text-align: center"
            >
              No Data!
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Footer Table -->
      <slot
        name="footer"
        v-if="$scopedSlots['footer']"
      ></slot>
      <f7-footer-table
        v-else-if="!$scopedSlots['footer']"
        :footerProps="footerProps"
        :totals="totals"
        :pagination="pagination"
        @changeRowPerPage="changeRowPerPage"
        @paginationNextPrev="paginationNextPrev"
      />
    </div>
    <slot name="paging-footer"></slot>
  </div>
</template>
<script>
import F7PinHeaderTable from './F7PinHeaderTable.vue';
import F7PinBodyTable from './F7PinBodyTable.vue';
import F7FooterTable from './F7FooterTable.vue';

export default {
  components: {
    F7PinHeaderTable,
    F7PinBodyTable,
    F7FooterTable,
  },
  props: {
    headers: Array,
    items: Array,
    checkbox: Boolean,
    iconSort: String,
    footerProps: Object,
    totals: Number,
    pageSize: Number,
    pinWidth: Array,
    lengthWidth: [String, Number],
    color: String,
    textColor: String,
    dataTableCollapsible: Boolean,
  },
  data() {
    return {
      checked: false,
      indeterminate: false,
      pagination: {
        descending: false,
        page: 1,
        pageSize: 10,
        sortBy: '',
        totalItems: 0,
      },
      pinArrayData: [null, true],
      dataTable: [],
      selected: [],
    };
  },
  methods: {
    // Pagination Common
    changePagination(options) {
      this.$emit('pagination', options);
    },

    // Sort
    sortColumn(props) {
      if (props.sortable) {
        this.pagination = {
          ...this.pagination,
          sortBy: props.value,
          descending: !this.pagination.descending,
        };
        this.changePagination(this.pagination);
      }
    },
    handleRowClick(event, row) {
      const columnIndex = event.target.cellIndex;

      if (
        columnIndex === 16 ||
        columnIndex === 17 ||
        event.target.tagName.toLowerCase() === 'input' ||
        event.target.tagName.toLowerCase() === 'select'
      ) {
        return;
      }

      if (this.checkbox) {
        this.selectCheckbox({ type: 'body', checked: !row.checked }, row);
      } else {
        this.$emit('onEdit', row);
      }
    },
    receivePinData(data) {
      this.pinArrayData = data;
      this.$emit('pinArray', data);
    },
    // Checkbox
    selectCheckbox(obj, values) {
      if (obj.type === 'header') {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => ({
          ...x,
          checked: obj.checked,
        }));
        this.indeterminate = null;
        this.checked = obj.checked;
        // The value is taken to emit outside
        if (obj.checked === true) {
          this.selected = values;
        } else {
          this.selected = [];
        }
        this.dataTable.forEach(x => {
          this.$emit('selected:change', {
            id: x.id,
            checked: obj.checked,
          });
        });
      } else if (obj.type === 'body') {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => {
          if (x.id === values.id) {
            return { ...x, checked: obj.checked };
          }
          return x;
        });
        var everyCheckbox = this.dataTable.map(x => x.checked);
        if (
          everyCheckbox.filter(x => x === true).length === this.dataTable.length
        ) {
          this.checked = true;
          this.indeterminate = false;
        } else if (everyCheckbox.filter(x => x === true).length > 0) {
          this.checked = false;
          this.indeterminate = true;
        } else if (
          everyCheckbox.filter(x => x === false).length ===
          this.dataTable.length
        ) {
          this.checked = false;
          this.indeterminate = null;
        }
        // The value is taken to emit outside
        var checkboxExist = this.selected.some(item => item.id === values.id);
        this.$emit('selected:change', {
          id: values.id,
          checked: !checkboxExist,
        });
        if (checkboxExist) {
          this.selected = this.selected.filter(item => item.id !== values.id);
        } else {
          this.selected = [...this.selected, values];
        }
      }
      this.$emit('selected:checkbox', this.selected);
    },

    // Next or Prev Pagination
    paginationNextPrev(type) {
      this.pagination = {
        ...this.pagination,
        totalItems: this.items.length,
        page:
          type === 'prevPage' ? --this.pagination.page : ++this.pagination.page,
      };
      this.changePagination(this.pagination);
    },

    // Change Row Per Page
    changeRowPerPage(event) {
      this.changePagination(event);
      this.pagination = event;
    },

    nameSlotItem(scopedSlots) {
      return (this.headers || [])
        .map(header => header.value)
        .find(x => scopedSlots[`body.${x}`]);
    },

    // reset checked
    resetChecked() {
      this.dataTable = this.items.map(x => ({ ...x, checked: false }));
      this.selected = [];
      this.indeterminate = null;
      this.checked = false;
      this.$emit('selected:checkbox', this.selected);
    },
  },
  updated() {
    this.changePagination(this.pagination);
  },
  created() {
    this.dataTable =
      this.items.map(x => ({
        ...x,
        checked: x && x.checked ? x.checked : false,
      })) || [];
    this.dataTable
      .filter(r => r.checked)
      .forEach(v => {
        this.selectCheckbox(
          {
            type: 'body',
            checked: true,
          },
          { ...v }
        );
      });
  },
  watch: {
    footerProps(val) {
      this.pagination = {
        ...this.pagination,
        pageSize:
          val && val.pageSizeOption && !this.pageSize
            ? val.pageSizeOption[0]
            : this.pageSize,
        totalItems: this.items.length,
      };
    },
    items(val) {
      this.dataTable = (val || []).map(x => ({
        ...x,
        checked: x && x.checked ? x.checked : false,
      }));
      this.resetChecked();
      val
        .filter(r => r && r.checked)
        .forEach(v => {
          this.selectCheckbox(
            {
              type: 'body',
              checked: true,
            },
            { ...v }
          );
        });
    },
  },
};
</script>
<style scoped>
.no-data {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  padding: 10px;
  font-size: 14px;
  color: grey;
}
.not-data {
  font-size: 14px;
  color: grey;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section-name {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}
</style>
