<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Vendors</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search vendor"
      :clear-button="true"
      class="text-color-white search-list"
      @input="
        handleSetSearchText($event.target.value);
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:clear="
        handleSetSearchText('');
        onSearch();
      "
      @searchbar:disable="
        handleSetSearchText('');
        onSearch();
      "
      @blur="
        if (!searchText.trim()) {
          handleSetSearchText('');
        }
      "
      :value="searchText"
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list
        media-list
        class="no-margin"
      >
        <f7-list-item
          v-for="item in hits"
          :key="item.id"
          :title="item.companyName"
          :reload-detail="true"
          :link="`/vendor/${item.id}`"
          :text-color="item.id === (vendor || {}).id ? 'primary' : 'default'"
        >
          <div
            slot="text"
            class="font-text"
          >
            Address: {{ item.addressValues[0] || '' }}
          </div>
          <div
            slot="footer"
            class="font-text"
          >
            Phone number: {{ item.phoneValues[0] || '' }}
          </div>
        </f7-list-item>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Menu from '../../../components/menu/Menu.vue';
import _ from 'lodash';

export default {
  components: {
    Menu,
  },

  data() {
    return {
      popupAddNewProduct: false,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
    };
  },
  methods: {
    ...mapActions({
      resetSearchVendor: 'product/product-item-page/vendor/resetSearch',
      resetSearchProduct: 'product/product-item-page/product-item/resetSearch',
    }),
    ...mapActions('product/product-item-page/vendor', [
      'searchVendor',
      'loadMoreVendor',
      'resetSearch',
      'unbindVendor',
      'setSearchText',
    ]),
    ...mapActions('product/product-item-page/common', ['setDestroyFlag']),
    ...mapActions('product/product-item-page/category', [
      'getCategories',
      'resetCategory',
    ]),
    ...mapActions('product/product-item-page/sub-category', [
      'getSubCategories',
      'resetSubCategory',
    ]),
    ...mapActions('product/roof-type', ['getRoofTypeList', 'resetRoofType']),
    ...mapActions('product/product-item-page/app-constant', [
      'getConstantTypeList',
      'resetConstant',
    ]),

    handleSetSearchText(value) {
      this.setSearchText(value);
    },

    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function () {
      const self = this;
      return self
        .searchVendor({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreVendor({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
  },

  computed: {
    ...mapGetters('product/product-item-page/common', ['destroyFlag']),

    ...mapGetters('product/product-item-page/vendor', [
      'hits',
      'nbPages',
      'page',
      'vendor',
      'searchText',
    ]),
  },

  created() {
    this.unbindVendor();
    this.setDestroyFlag(false);

    const refs = [];
    refs.push(this.onSearch());

    refs.push(this.getRoofTypeList());
    refs.push(this.getCategories());
    refs.push(this.getSubCategories());
    refs.push(this.getConstantTypeList());

    if (!this.hasMoreItems) {
      this.$f7.preloader.show();
    }
    Promise.all(refs).finally(() => {
      this.setDestroyFlag(true);
      if (!this.hasMoreItems) {
        this.$f7.preloader.hide();
      }
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list.searchbar input').focus();
      }
    });
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.resetSearchVendor();
      this.resetRoofType();
      this.resetCategory();
      this.resetSubCategory();
      this.resetSearchProduct();
      this.resetConstant();
      this.unbindVendor();
    }
  },
};
</script>
