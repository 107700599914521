<template>
  <div>
    <div class="data-table data-table-init card data-table-collapsible">
      <!-- Custom header -->
      <div
        v-if="isShowApproveAll"
        class="card-header"
      >
        <div
          class="data-table-header"
          text-color="primary"
        >
          <!-- <div class="data-table-title">
            <b>Selected Items: {{ selectedItems.length }}</b>
          </div> -->

          <div class="data-table-actions">
            <f7-button
              @click.native="approveAll(waitingTimelogList)"
              outline
              >Approve All</f7-button
            >
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <!-- <th v-if="isShowCheckboxAll" class="" style="width: 50px">
                <f7-checkbox
                  :checked="isSelectAll"
                  @change="checkBoxAllChange($event.target.checked)"
                ></f7-checkbox>
              </th> -->
              <th
                v-for="(props, index) in headers"
                :key="index"
                :class="`${textAlign(props.align)} ${props.cssClass || ''}`"
                :width="props.width"
              >
                {{ props.text }}
              </th>
            </tr>
          </thead>
          <tbody v-if="detailTimeLogByDate.length > 0">
            <tr
              v-for="(item, index) in detailTimeLogByDate"
              :key="index"
              :class="{ 'parent-row': item.parentRow }"
            >
              <template v-if="item.parentRow">
                <!-- <td v-if="isShowCheckboxAll" class="checkbox-cell">
                  <f7-checkbox
                    v-if="item.isShowCheckbox"
                    :checked="isSelectGroup(item.date)"
                    @change="
                      checkBoxGroupChange($event.target.checked, item.date)
                    "
                  ></f7-checkbox>
                </td> -->
                <td :data-collapsible-title="headers[0].text">
                  {{ item.date }}
                </td>

                <td
                  :data-collapsible-title="headers[1].text"
                  class="parent-row"
                  :colspan="5"
                >
                  {{
                    moment(item.date, 'MM/DD/YYYY')
                      .format('dddd')
                      .substring(0, 3)
                  }}
                </td>
                <td
                  :data-collapsible-title="headers[6].text"
                  class="parent-row"
                  :colspan="5"
                >
                  {{ formatDuration(item.totalHoursByDate) }}
                </td>
                <td
                  :data-collapsible-title="headers[7].text"
                  class="parent-row"
                  :colspan="5"
                >
                  {{ item.overtime ? formatDuration(item.overtime || 0) : '' }}
                </td>
                <!-- <td
                  :data-collapsible-title="headers[2].text"
                  class="parent-row numeric-cell"
                >
                  {{ formatDuration(item.totalHoursByDate) }}
                </td>

                <td
                  :data-collapsirble-title="headers[3].text"
                  class="parent-row numeric-cell"
                >
                  {{ item.overtime ? formatDuration(item.overtime) : '' }}
                </td>

                <td
                  :data-collapsirble-title="headers[4].text"
                  class="parent-row numeric-cell"
                  :colspan="headers.length - 6"
                ></td> -->
              </template>

              <template v-else-if="!item.footerRow">
                <!-- <td v-if="isShowCheckboxAll" class="checkbox-cell">
                  <f7-checkbox
                    v-if="item.isShowCheckbox"
                    :checked="item.checked"
                    @change="checkBoxChange($event.target.checked, item.id)"
                  ></f7-checkbox>
                </td> -->
                <td :data-collapsible-title="headers[0].text">
                  {{ item.date && item.date.toDate() | MMDDYYYY }}
                </td>

                <td :data-collapsible-title="headers[1].text">
                  {{
                    moment(item.date.toDate(), 'MM/DD/YYYY')
                      .format('dddd')
                      .substring(0, 3)
                  }}
                </td>

                <td :data-collapsible-title="headers[2].text">
                  {{
                    item.startTime
                      ? moment(item.startTime, 'HH:mm').format('h:mm a')
                      : '-'
                  }}
                </td>
                <td :data-collapsible-title="headers[3].text">
                  {{
                    item.endTime
                      ? moment(item.endTime, 'HH:mm').format('h:mm a')
                      : '-'
                  }}
                </td>

                <td
                  :data-collapsible-title="headers[4].text"
                  :class="`${item.override ? 'checkOveright' : ''} numeric-cell`"
                >
                  {{
                    formatDuration(
                      item.override ? item.overrideHour : item.loggedHour
                    )
                  }}
                </td>

                <td
                  :data-collapsible-title="headers[5].text"
                  class="numeric-cell"
                >
                  {{
                    item.hasLunchBreak
                      ? formatDuration(item.lunchTimeInfo.hour || 0)
                      : ''
                  }}
                </td>

                <td
                  :data-collapsible-title="headers[6].text"
                  :class="`numeric-cell ${
                    item.status !== STATUS_TIME_TRACKING_WFA ? 'checkAwait' : ''
                  }`"
                >
                  {{
                    formatDuration(
                      (item.override ? item.overrideHour : item.loggedHour) +
                        (item.hasLunchBreak ? item.lunchTimeInfo.hour || 0 : 0)
                    )
                  }}
                </td>

                <td
                  class="numeric-cell"
                  :data-collapsible-title="headers[7].text"
                >
                  <span>{{
                    !!item.hasOvertime ? formatDuration(item.overtime || 0) : ''
                  }}</span>
                </td>
                <td :data-collapsible-title="headers[8].text">
                  <f7-toggle
                    :disabled="true"
                    :checked="item.billable"
                  ></f7-toggle>
                </td>
                <!-- <td :data-collapsible-title="headers[4].text">
                  <f7-icon
                    v-if="item.override"
                    f7="checkmark"
                    color="red"
                  ></f7-icon>
                </td> -->
                <!-- <td
                  :data-collapsible-title="headers[5].text"
                  :class="
                    item.status !== STATUS_TIME_TRACKING_WFA ? 'checkAwait' : ''
                  "
                >
                  {{ item.approvedHour | floatFixed }}
                </td> -->
                <td
                  :data-collapsible-title="headers[9].text"
                  :class="
                    item.status !== STATUS_TIME_TRACKING_WFA ? 'checkAwait' : ''
                  "
                >
                  {{ item.approvedBy }}
                </td>
                <td :data-collapsible-title="headers[10].text">
                  <f7-chip
                    :text="(getStatus(item.status) || {}).displayName"
                    :color="(getStatus(item.status) || {}).color"
                  ></f7-chip>
                </td>
                <td :data-collapsible-title="headers[11].text">
                  <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
                    {{ item.timeOffTypeValue }}
                  </span>
                  <span v-else-if="item.timeLogType === TIME_LOG_TYPE_OTHERS">{{
                    item.title
                  }}</span>

                  <span v-else>{{ (item.project || {}).title }}</span>
                </td>
                <td :data-collapsible-title="headers[12].text">
                  {{ item.qbClassRef && item.qbClassRef.name }}
                </td>
                <td :data-collapsible-title="headers[13].text">
                  {{ item.qbServiceItemRef && item.qbServiceItemRef.name }}
                </td>
                <td :data-collapsible-title="headers[14].text">
                  <start-and-end-component
                    v-if="
                      !item.override &&
                      (item.project.propertyAddress ||
                        item.project.projectAddress)
                    "
                    :distance="item.distance"
                    @auditClick="clickOnAudit(item)"
                  ></start-and-end-component>
                </td>
                <td :data-collapsible-title="headers[15].text">
                  <f7-chip
                    v-if="item.hasLunchBreak"
                    :text="item.lunchTimeInfo.title"
                    color="yellow"
                  ></f7-chip>
                  &nbsp;
                  <long-content-block
                    :seedId="`note-${item.id}`"
                    :content="item.note"
                  ></long-content-block>
                </td>
                <td :data-collapsible-title="headers[16].text">
                  <long-content-block
                    :seedId="`rr-${item.id}`"
                    :content="item.reject_reason"
                  ></long-content-block>
                </td>
                <td :data-collapsible-title="headers[17].text">
                  <f7-row class="actions-container justify-content-flex-start">
                    <f7-button
                      v-if="
                        !(
                          item.status === STATUS_TIME_TRACKING_APPROVED ||
                          item.status === STATUS_TIME_TRACKING_PAID ||
                          item.status === STATUS_TIME_TRACKING_REJECTED
                        )
                      "
                      @click="showDetailPopup(item)"
                      large
                      small
                      outline
                      class="margin-right-half"
                      >Edit</f7-button
                    >
                    <f7-button
                      v-if="item.status === STATUS_TIME_TRACKING_WFA"
                      class="margin-right-half"
                      @click="approved(item)"
                      fill
                      small
                      color="green"
                      >Approve</f7-button
                    >
                    <f7-button
                      v-if="item.status === STATUS_TIME_TRACKING_WFA"
                      @click="reject(item)"
                      fill
                      small
                      color="red"
                      >Reject</f7-button
                    >
                  </f7-row>
                </td>
              </template>

              <template v-else-if="item.footerRow">
                <f7-block-header
                  v-if="!$device.desktop"
                  class="padding-top text-color-white"
                  style="font-weight: bold"
                  >{{
                    `${$t('timeTracking.detail.table.weekTotal')}`
                  }}</f7-block-header
                >
                <td
                  v-if="$device.desktop"
                  :data-collapsible-title="headers[0].text"
                  class="footer-row numeric-cell"
                  :colspan="6"
                >
                  {{ `${$t('timeTracking.detail.table.weekTotal')}` }}
                </td>
                <td
                  :data-collapsible-title="headers[6].text"
                  class="footer-row numeric-cell"
                >
                  {{ formatDuration(item.totalHours) }}
                </td>

                <td
                  :data-collapsible-title="headers[7].text"
                  class="footer-row numeric-cell"
                >
                  {{ item.overtime ? formatDuration(item.overtime) : '' }}
                </td>
                <td
                  v-if="$device.desktop"
                  :data-collapsible-title="headers[3].text"
                  class="footer-row"
                  :colspan="headers.length - 7"
                ></td>
              </template>
            </tr>
          </tbody>
        </table>
        <div
          class="no-data"
          v-if="detailTimeLogByDate.length === 0"
        >
          No Data!
        </div>
      </div>
    </div>

    <edit-time-tracking
      :popupEditTimeTracking="isShowEditTimeTracking"
      :item="detailTimeTracking"
      @close="isShowEditTimeTracking = false"
      :redirect="false"
    />
    <RejectTimeTracking
      :popupRejectTimeTracking="isShowRejectTimeTracking"
      @close="isShowRejectTimeTracking = false"
      @rejectTimeTracking="rejectTimeTracking($event, itemReject)"
    />
    <AuditDetailComponent
      :startedLocation="auditStartedLocation"
      :endedLocation="auditEndedLocation"
      :distance="auditDistance"
      :isShow="!!auditView"
      :username="timeTrackingUser"
      :propertyAddress="auditPropertyAddress"
      @close="auditView = null"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import EditTimeTracking from '../popups/EditTimeTracking';
import RejectTimeTracking from '../popups/RejectTimeTracking';
import StartAndEndComponent from '../location/StartAndEndComponent.vue';
import AuditDetailComponent from '../location/AuditDetailComponent.vue';
import LongContentBlock from '../blocks/LongContentBlock.vue';
import { groupBy } from '@/utility/filter-tools';
import { sortEarliest, sortBy } from '@/utility/date-time-tool';
import { formatDuration } from '@/utility/datetime';
import { getFullAddress } from '@/utility/address';
import { overtimeMixin } from '../../mixin/overtime-mixin';
import { actionMixin } from '../../mixin/action-mixin';
import { roundNumber } from '@/utility/number-tool';

import moment from 'moment';
import {
  STATUS_TIME_TRACKING_WFA,
  STATUS_TIME_TRACKING_PAID,
  STATUS_TIME_TRACKING_REJECTED,
  STATUS_TIME_TRACKING_APPROVED,
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_LOG_TYPE_LUNCHTIME,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
} from '../../../../utility/const';
import Vue from 'vue';

export default {
  data() {
    return {
      isShowEditTimeTracking: false,
      isShowRejectTimeTracking: false,
      detailTimeTracking: {},
      itemReject: {},
      auditView: null,
      selectedItems: [],

      STATUS_TIME_TRACKING_WFA,
      STATUS_TIME_TRACKING_APPROVED,
      STATUS_TIME_TRACKING_REJECTED,
      STATUS_TIME_TRACKING_PAID,
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_LOG_TYPE_LUNCHTIME,
      moment,
      formatDuration,
    };
  },

  components: {
    EditTimeTracking,
    RejectTimeTracking,
    StartAndEndComponent,
    AuditDetailComponent,
    LongContentBlock,
  },

  mixins: [overtimeMixin, actionMixin],

  methods: {
    ...mapActions({
      deleteTimeTracking: 'time-tracking/time-tracking/delete',
      updateTimeTracking: 'time-tracking/time-tracking/update',
      getTimeTrackingBys: 'time-tracking/time-tracking/getTimeTrackingBys',
      getTimeTrackingList: 'time-tracking/time-tracking/getTimeTrackingList',
    }),

    ...mapMutations({
      setTimeTrackingViewport: 'time-tracking/time-tracking/setViewport',
    }),

    textAlign(align) {
      return align === 'left'
        ? 'text-align-left'
        : align === 'center'
          ? 'text-align-center'
          : align === 'right'
            ? 'text-align-right'
            : 'text-align-left';
    },

    getStatus(status) {
      return this.timeTrackingStatusList.find(x => x.value === status) || {};
    },

    showDetailPopup(item) {
      this.detailTimeTracking = {
        ...item,
        overrideHour:
          item.timeLogType === TIME_LOG_TYPE_LUNCHTIME
            ? item.overrideHour * -1
            : item.overrideHour,
      };
      this.isShowEditTimeTracking = true;
    },

    checkBoxAllChange(checked) {
      (this.detailTimeLogByDate || []).map(item => {
        if (!item.parentRow && item.isShowCheckbox) {
          Vue.set(item, 'checked', checked);
        }
      });
      this.setSelectedItems();
    },

    checkBoxGroupChange(checked, date) {
      (this.detailTimeLogByDate || []).map(item => {
        if (
          !item.parentRow &&
          moment(item.date.toDate()).format('MM/DD/YYYY') === date
        ) {
          Vue.set(item, 'checked', checked);
        }
      });
      this.setSelectedItems();
    },

    checkBoxChange(checked, id) {
      let item = (this.detailTimeLogByDate || []).find(item => item.id === id);
      Vue.set(item, 'checked', checked);
      this.setSelectedItems();
    },

    setSelectedItems() {
      this.selectedItems = this.detailTimeLogByDate.filter(
        item => item.checked === true && item.isShowCheckbox === true
      );
    },

    removeItem(id) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Delete item',
        content: 'Are you sure you want to delete the item?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.deleteTimeTracking(id);
            _sefl.app.dialog.close();
          }
        },
      });
    },

    reject(item) {
      this.itemReject = item;
      this.isShowRejectTimeTracking = true;
    },

    clickOnAudit(item) {
      this.auditView = item;
    },
  },

  computed: {
    ...mapGetters('setting/app/system', ['setting']),

    ...mapGetters({
      time_trackingList: 'time-tracking/time-tracking/objectList',
      currentWeek: 'time-tracking/time-tracking/currentWeek',
      detailTimeLog: 'time-tracking/time-tracking/detail',
      userById: 'time-tracking/user/userById',
    }),

    ...mapGetters('time-tracking/app-constant', [
      'timeTrackingStatusList',
      'timeOffTypeList',
    ]),

    ...mapGetters('common/app-constant', ['tenantId']),

    headers() {
      return [
        {
          text: 'Date',
          align: 'left',
          sortable: false,
          value: 'date',
        },
        {
          text: 'Day',
          align: 'left',
          sortable: false,
          value: 'day',
        },
        // {
        //   text: "Manually Enter",
        //   value: "override",
        //   sortable: false,
        //   align: "left"
        // },
        {
          text: 'In',
          align: 'left',
          sortable: false,
          value: 'startTime',
        },
        {
          text: 'Out',
          align: 'left',
          sortable: false,
          value: 'endTime',
        },
        {
          text: 'Duration',
          value: 'duration',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Lunch Break',
          align: 'right',
          sortable: false,
          value: 'lunchBreak',
        },
        {
          text: 'Hours',
          value: 'workHours',
          sortable: false,
          align: 'right',
        },

        {
          text: 'Overtime',
          value: 'overtime',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Billable',
          value: 'billable',
          sortable: false,
          align: 'center',
        },
        // {
        //   text: "Approved Hours",
        //   value: "approvedHour",
        //   sortable: false,
        //   align: "left"
        // },
        {
          text: 'Approved By',
          value: 'approvedBy',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Status',
          value: 'propertyAdress',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Time Log Info',
          value: 'timeLogInfo',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Class',
          value: 'classItem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Service Item',
          value: 'serviceItem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Audit',
          value: 'audit',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Notes',
          value: 'note',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Reject reason',
          value: 'reject_reason',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ];
    },

    detailTimeLogComputed() {
      let results = this.detailTimeLog
        .filter(item => item.timeLogType != TIME_LOG_TYPE_LUNCHTIME)
        .map(r => ({
          ...r,
          timeOffTypeValue: r.timeOffType
            ? (
                this.timeOffTypeList.find(
                  timeOffType => timeOffType.value === r.timeOffType
                ) || {}
              ).displayName
            : '',
          startTime: !r.override && r.startTime ? r.startTime : '',
          endTime: !r.override && r.endTime ? r.endTime : '',
        }))
        .slice();

      // return results
      //   .slice()
      //   .sort((x, y) => y.date.toMillis() - x.date.toMillis());

      return sortBy(results, 'date', false);
    },

    waitingTimelogList() {
      return this.detailTimeLogComputed.filter(
        item => item.status === STATUS_TIME_TRACKING_WFA
      );
    },

    detailTimeLogByDate() {
      // group by date
      const groupByDate = groupBy(this.detailTimeLogComputed, i =>
        i.date ? moment(i.date.toDate()).format('MM/DD/YYYY') : ''
      ).map(item => ({
        date: item.key,
        data: sortEarliest(item.data),
        totalHoursByDate: item.data
          .filter(item => item.status !== 'tt-rejected')
          .map(
            item =>
              (item.approvedHour
                ? parseFloat(item.approvedHour)
                : item.override
                  ? parseFloat(item.overrideHour)
                  : item.loggedHour) +
              (item.hasLunchBreak ? item.lunchTimeInfo.hour || 0 : 0)
          )
          // .map(r => Math.round(r * 100) / 100)
          .map(r => roundNumber(r, 3))
          .reduce((a, b) => a + b, 0),
        // .toFixed(2),
        overtime: item.data
          .filter(item => item.status !== 'tt-rejected')
          .map(item => (item.hasOvertime ? item.overtime || 0 : 0))
          // .map(r => Math.round(r * 100) / 100)
          .map(r => roundNumber(r, 3))
          .reduce((a, b) => a + b, 0),
        // .toFixed(2)
      }));

      // create new list
      let listItems = [];
      for (const group of groupByDate) {
        listItems = [
          ...listItems,
          ...group.data.map(r => ({
            ...r,
            isShowCheckbox: r.status === STATUS_TIME_TRACKING_WFA,
            checked: r.status === STATUS_TIME_TRACKING_WFA ? false : true,
          })),
        ];

        listItems.push({
          parentRow: true,
          date: group.date,
          totalHoursByDate: group.totalHoursByDate,
          isShowCheckbox: group.data.some(r => {
            return r.status === STATUS_TIME_TRACKING_WFA;
          }),
          overtime: parseFloat(group.overtime) ? group.overtime : '',
        });
      }

      const totalOvertime = listItems
        .filter(item => item.parentRow === true)
        .map(item => parseFloat(item.overtime) || 0)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);

      if (listItems.length > 0) {
        listItems.push({
          footerRow: true,
          date: 'firstDate',
          totalHours: this.detailTimeLogComputed
            .filter(item => item.status !== 'tt-rejected')
            .map(
              item =>
                (item.override
                  ? parseFloat(item.overrideHour)
                  : item.loggedHour) +
                +(item.hasLunchBreak ? item.lunchTimeInfo.hour : 0)
            )
            // .map(r => Math.round(r * 100) / 100)
            .map(r => roundNumber(r, 3))
            .reduce((a, b) => a + b, 0),
          // .toFixed(2),
          overtime: totalOvertime,
        });
      }

      return listItems;
    },

    isShowCheckboxAll() {
      return (this.detailTimeLogByDate || []).some(r => r.isShowCheckbox);
    },

    isShowApproveAll() {
      return (this.detailTimeLogByDate || []).some(
        r => r.status === STATUS_TIME_TRACKING_WFA
      );
    },

    isSelectAll() {
      const activeList =
        (this.detailTimeLogByDate || []).filter(
          r => !r.parentRow && r.isShowCheckbox
        ) || [];
      return this.selectedItems.length === activeList.length;
    },

    isSelectGroup() {
      return date => {
        const activeList =
          (this.detailTimeLogByDate || []).filter(
            r =>
              r.isShowCheckbox &&
              !r.parentRow &&
              moment(r.date.toDate()).format('MM/DD/YYYY') === date
          ) || [];
        const selected =
          (this.selectedItems || []).filter(
            r =>
              r.isShowCheckbox &&
              !r.parentRow &&
              moment(r.date.toDate()).format('MM/DD/YYYY') === date
          ) || [];
        return activeList.length === selected.length;
      };
    },

    auditStartedLocation() {
      const location = this.auditView ? this.auditView.startLocation : null;
      return location || {};
    },

    auditEndedLocation() {
      const location = this.auditView ? this.auditView.endLocation : null;
      return location || {};
    },

    auditDistance() {
      return this.auditView ? this.auditView.distance : 0;
    },

    auditPropertyAddress() {
      if (!this.auditView) return null;
      if (
        this.auditView.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
        (this.auditView.project.businessCode === BUSINESS_CODE_SERVICE &&
          this.auditView.project.customerType === 'commercial')
      ) {
        return getFullAddress(this.auditView.project.propertyAddress);
      } else if (
        this.auditView.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
        (this.auditView.project.businessCode === BUSINESS_CODE_SERVICE &&
          this.auditView.project.customerType === 'residential')
      ) {
        return getFullAddress(this.auditView.project.projectAddress);
      }
      return '';
    },

    timeTrackingUser() {
      const user = this.userById(this.$f7route.params['id']);
      return (user || {}).displayName;
    },
  },

  watch: {
    detailTimeLog: {
      handler() {
        this.selectedItems = [];
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped>
.parent-row {
  background-color: var(--f7-color-icon-neutral);
  font-weight: bold;
}
.footer-row {
  background-color: var(--f7-color-img-neutral);
  font-weight: bold;
}

.deleteButton {
  margin-left: 10px;
}
.checkOveright {
  color: red;
  font-size: bold;
}
.checkAwait {
  color: darkorange;
}
.button-action-employees {
  width: 240px;
}
.actions-container {
  flex-wrap: nowrap;
}
@media (max-width: 480px) and (orientation: portrait) {
  div /deep/ .data-table.data-table-collapsible .item-content.item-checkbox {
    padding-left: 0px !important;
  }
}
.no-data {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: grey;
}
</style>
