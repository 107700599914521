<template>
  <div>
    <f7-block-header class="display-flex justify-content-space-between">
      Contact Information
      <div v-if="contactId">
        <f7-button
          small
          outline
          @click="openEditContact"
          >Edit Contact</f7-button
        >
      </div>
    </f7-block-header>
    <f7-list media-list>
      <f7-list-item
        link
        @click.native="navigateToSelectCompany"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Insurance Company
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          <span v-if="companyName">({{ companyName }})</span>
          <span
            v-else
            class="list-item-title_placeholder"
            >Select company</span
          >
        </div>
        <input-icon
          slot="media"
          icon="globe"
        ></input-icon>
      </f7-list-item>
      <f7-list-item>
        <div slot="media"></div>
        <f7-button
          slot="title"
          small
          @click="openAddNewCompany"
          class="btn-fill-gray display-flex justify-content-center align-items-center"
        >
          <f7-icon
            size="18"
            f7="plus"
            class="margin-right-half"
          ></f7-icon>
          Add a new company
        </f7-button>
      </f7-list-item>
      <f7-list-item
        link
        @click.native="navigateToSelectContact"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Contact Name<required-asterisk></required-asterisk>
        </div>
        <div class="item-error-message">
          {{ requireErrorMessage('contactId') }}
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          <span v-if="contactName">({{ contactName }})</span>
          <span
            v-else
            class="list-item-title_placeholder"
            >Select contact</span
          >
        </div>
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
      </f7-list-item>
      <f7-list-item>
        <div slot="media"></div>
        <f7-button
          slot="title"
          @click="openAddNewContactPopup"
          small
          class="btn-fill-gray display-flex justify-content-center align-items-center"
        >
          <f7-icon
            size="18"
            f7="plus"
            class="margin-right-half"
          ></f7-icon>
          Add new contact
        </f7-button>
      </f7-list-item>

      <!-- Phone -->
      <f7-list-item
        v-for="(phone, index) in (contact || {}).phones"
        :key="`phone-${index}`"
      >
        <div slot="header">{{ phone.code }}</div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ phone.value }}
        </div>
        <input-icon
          slot="media"
          icon="phone"
        ></input-icon>
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'tel:' + phone.value"
            icon-f7="phone_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>

      <!-- Email -->
      <f7-list-item
        v-for="(email, index) in (contact || {}).emails"
        :key="`email-${index}`"
      >
        <div slot="header">{{ email.code }}</div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ email.value }}
        </div>
        <input-icon
          slot="media"
          icon="envelope"
        ></input-icon>
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'mailto:' + email.value"
            icon-f7="envelope_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <address-input-residential
      ref="addressInput"
      :address="projectAddress"
      :addressesSuggestion="(contact || {}).addresses || []"
      @input="onInputAddress($event)"
      @select="onSelectAddress($event)"
    ></address-input-residential>

    <f7-list media-list>
      <f7-list-item
        link
        @click.native="navigateToSelectInspector"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Inspector Name
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          <span v-if="inspectorName">{{ inspectorName }}</span>
          <span
            v-else
            class="list-item-title_placeholder"
            >Select inspector</span
          >
        </div>
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
      </f7-list-item>
      <f7-list-input
        label="Inspect Date"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
        }"
        :value="inspectDate || []"
        @calendar:change="
          onUpdateDate('inspectDate', $event);
          $f7.calendar.close();
        "
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="calendar"
        ></input-icon>
      </f7-list-input>

      <f7-list-input
        :class="
          ('inspect-time-web',
          {
            'inspect-time-ios': $device.ios,
            'inspect-time-android': $device.android,
            'inspect-time-desktop': $device.desktop || $device.ipad,
          })
        "
        :style="inputDarkClass"
        label="Inspect Time"
        type="time"
        :value="inspectTime"
        @input="onUpdateCard('inspectTime', $event.target.value)"
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="clock"
        ></input-icon>
      </f7-list-input>
      <f7-list-input
        label="Lead Source"
        type="select"
        placeholder="Please choose..."
        :value="leadSource"
        @input="selectLeadSource($event.target.value)"
        validate
        validate-on-blur
      >
        <input-icon
          slot="media"
          icon="selection_pin_in_out"
        ></input-icon>
        <option
          v-for="(type, index) in leadSourceResidentialList"
          :key="index"
          :value="type.value"
        >
          {{ type.displayName }}
        </option>
      </f7-list-input>
      <f7-list-input
        v-if="leadSource === 'referral'"
        label="Referral Name"
        type="text"
        placeholder="Enter Referral Name..."
        :value="displayCard.referralName"
        @blur="onUpdateCard('referralName', $event.target.value)"
      >
        <input-icon
          slot="media"
          icon="ellipses_bubble"
        ></input-icon>
      </f7-list-input>
      <f7-list-item
        link
        @click.native="navigateToSelectSalesRep"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Sales Rep
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          {{ salesRep }}
        </div>
        <input-icon
          slot="media"
          icon="person"
        ></input-icon>
      </f7-list-item>
      <f7-list-input
        label="Date of Loss"
        placeholder="Enter Date of Loss..."
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
        }"
        :value="dateOfLoss || []"
        @calendar:change="
          if ($event && $event !== dateOfLoss) {
            onUpdateDate('dateOfLoss', $event);
            $f7.calendar.close();
          }
        "
        clear-button
        @input:clear="clearDate"
      >
        <input-icon
          slot="media"
          icon="calendar"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>
    <photo-section-residential
      ref="photoSectionResidential"
      :actionId="action.id"
    ></photo-section-residential>
    <agreement-section
      :contactName="contactName"
      ref="agreementSection"
    ></agreement-section>
    <letter-section
      :contactName="contactName"
      :companyName="companyName"
      ref="letterSection"
    ></letter-section>
    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <!-- <note-input
      :value="displayCard.commentAndNote"
      @change="saveNote($event)"
    ></note-input> -->
    <note-input
      :value="card ? card.commentAndNote || [] : []"
      :card="card"
      @change="saveNote($event)"
    ></note-input>

    <!-- archive job -->
    <note-popup
      ref="archiveNotePopup"
      title="Archive Note"
      label="Note"
      v-model="archiveNote"
      @done="archiveProject"
      @cancelPopup="archiveNote = ''"
      validate
    ></note-popup>

    <contact-list-popup
      ref="selectContact"
      :isHiddenAddNew="true"
      @onSelected="
        $event => {
          getContact($event).then(contact => {
            changeCardValue(contact);
          });
        }
      "
      @onCreated="
        $event => {
          getContact($event).then(() => {
            changeCardValue(contact);
          });
        }
      "
    ></contact-list-popup>
    <company-list-popup
      ref="selectCompany"
      :isHiddenAddNew="true"
      :defaultTypesSelected="[COMPANY_TYPE_INSURANCE]"
      :queryFilters="`companyTypes:${COMPANY_TYPE_INSURANCE}`"
      @onSelected="
        $event => {
          getInsuranceCompany($event).then(() => {
            setCurrentCardValues({ insuranceCompanyId: $event });
            onUpdateCard('insuranceCompanyId', $event, true);
          });
        }
      "
      @onCreated="
        $event => {
          getInsuranceCompany($event).then(() => {
            setCurrentCardValues({ insuranceCompanyId: $event });
            onUpdateCard('insuranceCompanyId', $event, true);
          });
        }
      "
    ></company-list-popup>
    <edit-contact-popup ref="editContactPopup"></edit-contact-popup>
    <user-popup
      ref="userPopup"
      @selectSalesRep="selectSalesRep"
    >
    </user-popup>
    <inspector-popup
      ref="inspectorPopup"
      @selectInspector="selectInspector"
    ></inspector-popup>
  </div>
</template>
<script>
import AddressInputResidential from '@/components/inputs/AddressInputResidential.vue';
import NotePopup from '../popup/NotePopup.vue';
// import NoteInput from '../input/NoteInput.vue';
import methodsMixins from '../../mixin/methods';
import residentialMixins from '../../mixin/residential';
import AttachmentInput from '../input/AttachmentInput.vue';
import UserInput from '../input/UserInput.vue';
import InputIcon from '../icon/InputIcon.vue';
import PhotoSectionResidential from '../photo/PhotoSectionResidential.vue';
import ContactListPopup from '../../../../components/popups/ContactListPopup.vue';
import EditContactPopup from '../popup/EditContactPopup.vue';
import AgreementSection from '../sections/AgreementSection.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { firebase, auth } from '../../../../services/firebase.service';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import UserPopup from '../popup/UserPopup.vue';
import {
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  LEAD_SOURCE_KNOCK_DOOR,
  COLLECTION_OPRATION_CARD,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import { toDateCalendar } from '../../../../utility/datetime';
import { toDateFirebase } from '../../../../utility/datetime';
import { getFullAddress } from '../../../../utility/address';
import LetterSection from '../sections/LetterSection.vue';
import { COMPANY_TYPE_INSURANCE } from '../../../../utility/const';
import CompanyListPopup from '../../../../components/popups/CompanyListPopup.vue';
import InspectorPopup from '../popup/InspectorPopup.vue';
import NoteInput from '@/plugins/swimlane/components/inputs/NoteInput.vue';

export default {
  components: {
    NotePopup,
    AttachmentInput,
    UserInput,
    NoteInput,
    InputIcon,
    PhotoSectionResidential,
    AddressInputResidential,
    ContactListPopup,
    EditContactPopup,
    AgreementSection,
    UserPopup,
    LetterSection,
    CompanyListPopup,
    InspectorPopup,
  },
  mixins: [methodsMixins, residentialMixins],
  data: () => {
    return {
      archiveNote: '',
      currentResponse: {},
      contactId: '',
      projectAddress: {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      leadSource: LEAD_SOURCE_KNOCK_DOOR,
      inspectDate: [new Date()],
      inspectTime: moment().format('HH:mm'),
      displayCard: {},
      salesRep: '',
      dateOfLoss: [],
      inspectorName: '',
    };
  },
  computed: {
    ...mapGetters('dashboard/project', ['card', 'action']),
    ...mapGetters('dashboard/app-constant', ['leadSourceResidentialList']),
    ...mapGetters('dashboard/client', ['contact', 'insuranceCompany']),
    ...mapGetters('setting/app/profile', ['user']),

    contactName() {
      return !_.isEmpty(this.contact)
        ? (
            (this.contact.firstName || '') +
            ' ' +
            (this.contact.lastName || '')
          ).trim()
        : '';
    },

    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },
    COMPANY_TYPE_INSURANCE() {
      return COMPANY_TYPE_INSURANCE;
    },
    companyName() {
      return this.insuranceCompany
        ? this.insuranceCompany.companyName || ''
        : '';
    },
    inputDarkClass() {
      let theme =
        localStorage.getItem('themeDark') === 'true' ? 'dark' : 'light';
      return `color-scheme: ${theme};`;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions('dashboard/project', [
      'updateCard',
      'updateProject',
      'getCardInColumn',
      'setCurrentCardValues',
    ]),
    ...mapActions('dashboard/swimlane', [
      'getDefaultServiceBoard',
      'getFirstColumn',
      'getSwimlaneBoard',
    ]),
    ...mapActions('dashboard/client', ['getContact', 'getInsuranceCompany']),
    ...mapActions('common/notification', ['createNotificationByType']),
    initData() {
      if (
        !_.isEmpty(this.$refs.photoSectionResidential) &&
        !_.isEmpty(this.$refs.agreementSection) &&
        !_.isEmpty(this.$refs.letterSection)
      ) {
        this.$refs.photoSectionResidential.initData(this.displayCard.id);
        this.$refs.agreementSection.initData(this.displayCard.id);
        this.$refs.letterSection.initData(this.displayCard.id);
      }
    },

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        this.initData();
        if (this.displayCard.contactId) {
          this.contactId = this.displayCard.contactId;
          this.getContact(this.contactId);
        }
        if (this.displayCard.insuranceCompanyId) {
          this.getInsuranceCompany(this.displayCard.insuranceCompanyId);
        }
        if (this.displayCard.projectAddress) {
          this.projectAddress = this.displayCard.projectAddress;
        }
        this.leadSource = this.displayCard.leadSource || LEAD_SOURCE_KNOCK_DOOR;
        if (!this.displayCard.inspectDate || _.isEmpty(this.inspectDate)) {
          this.inspectDate = [new Date()];
        } else
          !moment(this.inspectDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.inspectDate)[0]),
            'day'
          );
        {
          this.inspectDate = toDateCalendar(this.displayCard.inspectDate);
        }
        this.inspectTime =
          this.displayCard.inspectTime || moment().format('HH:mm');
        if (this.card.salesRep) {
          this.salesRep = this.displayCard.salesRep;
        }
        if (this.card.inspectorName) {
          this.inspectorName = this.card.inspectorName;
        }
        if (
          this.displayCard.dateOfLoss &&
          (_.isEmpty(this.dateOfLoss) ||
            (!_.isEmpty(this.dateOfLoss) &&
              !moment(this.dateOfLoss[0]).isSame(
                moment(toDateCalendar(this.displayCard.dateOfLoss)[0]),
                'day'
              )))
        ) {
          this.dateOfLoss = toDateCalendar(this.displayCard.dateOfLoss);
        }
      }
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },
    openAddNewContactPopup() {
      this.$refs.selectContact.open(true);
    },
    openEditContact() {
      this.$refs.editContactPopup.open(this.contactId);
    },
    onInputAddress({ prop, value }) {
      this.projectAddress[prop] = value;
      this.onUpdateAddress(true);
    },
    onSelectAddress(address) {
      this.projectAddress = address;
      this.onUpdateAddress(true);
    },
    onUpdateAddress(isShowLoading = false) {
      this.$refs.addressInput.v$.$touch();
      if (this.$refs.addressInput.v$.$invalid) return;
      this.onUpdateCard('projectAddress', this.projectAddress, isShowLoading);
      let title = '';
      if (this.displayCard.contactId) {
        title = `${this.getContactName(this.contact)} - `;
      }
      title += getFullAddress(this.projectAddress);

      this.onUpdateCard('title', title, isShowLoading);
    },

    onUpdateDate(field, value) {
      if (field === 'inspectDate') {
        if (!_.isEmpty(value)) {
          if (
            !_.isEmpty(this.inspectDate) &&
            moment(value[0]).isSame(moment(this.inspectDate[0]), 'day')
          )
            return;
          this.onUpdateCard('inspectDate', toDateFirebase(value));
          this.onUpdateCard('dueDate', toDateFirebase(value));
        }
      } else {
        if (!_.isEmpty(value)) {
          if (
            !_.isEmpty(this.dateOfLoss) &&
            moment(value[0]).isSame(moment(this.dateOfLoss[0]), 'day')
          )
            return;
          this.dateOfLoss = value;
          this.onUpdateCard('dateOfLoss', toDateFirebase(value));
        }
      }
    },
    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value,
        },
      })
        .then(() => {
          if (
            [
              'referralName',
              'contactId',
              'projectAddress',
              'dueDate',
              'dateOfLoss',
              'salesRep',
              'insuranceCompanyId',
              'inspectorName',
            ].includes(field)
          ) {
            this.$emit('onChangeHashtag');
          }
        })
        .finally(() => {
          isShowLoading && this.$f7.preloader.hide();
        });
    },

    inValid() {
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) {
        return true;
      }
      return false;
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'move-to-services-board':
          this.updateServiceCard();
          break;

        case 'move-to-insurance-inspection':
          if (this.inValid()) return;
          this.currentResponse = response;
          this.displayCard.dueDate = '';
          this.resolve();
          break;
        case 'archive-job':
          this.currentResponse = response;
          this.displayCard.dueDate = this.inspectDate;
          this.$refs.archiveNotePopup.open();
          break;
      }
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);

      if (this.archiveNote) {
        commentAndNote.push({
          code: 'note',
          title: 'Archive Note',
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
        doc.archiveDate = firebase.firestore.Timestamp.now();
      }
      doc.dueDate = toDateFirebase(this.displayCard.dueDate);
      doc.commentAndNote = commentAndNote;
      await this.updateCard({
        id: this.displayCard.id,
        doc,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote = event.map(item => ({
          ...item,
          title: 'Leads Note',
        }));

        this.onUpdateCard('commentAndNote', commentAndNote);
      }
    },

    // update service job
    async updateServiceCard() {
      this.$f7.preloader.show();
      const self = this;
      const project = {};
      const defaultServiceBoard = await this.getDefaultServiceBoard(
        BUSINESS_CODE_SERVICE
      );
      if (!defaultServiceBoard) {
        return;
      }
      const firstColumn = await this.getFirstColumn(defaultServiceBoard.id);

      const cardsInColumn = await this.getCardInColumn(firstColumn.id);
      const minPriorityInColumn =
        Math.min(...[...cardsInColumn.map(r => r.priority), 0]) || 0;

      const actionsHistory = [
        ...this.displayCard.actionsHistory,
        {
          nextAction: 'Job is Moved From Residential',
          previousAction: '',
          resolvedAt: firebase.firestore.Timestamp.now(),
          resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
          resolvedById: auth.currentUser.uid,
        },
      ];

      project.priority = minPriorityInColumn - 1000;
      project.columnId = firstColumn.id;
      project.boardId = defaultServiceBoard.id;
      project.actionsHistory = actionsHistory;
      project.businessCode = BUSINESS_CODE_SERVICE;
      project.customerType = BUSINESS_CODE_RESIDENTIAL;

      const { docNumber } = await this.updateProject({
        tenantId: this.user.tenantId,
        id: this.displayCard.id,
        project,
      });
      this.createNotificationByType({
        data: {
          assignees: this.displayCard.assigneeIDs,
          project: {
            title: this.displayCard.title,
            id: this.displayCard.id,
            to: 'service',
            type: 'project',
            entityName: COLLECTION_OPRATION_CARD,
          },
        },
        type: 'move-project',
      });
      this.$f7.preloader.hide();
      this.$emit('doClosePopup', () => {});
      this.$ri.dialog
        .openSuccessDialog({
          title: 'The job is moved successfully.',
          content: 'Do you want to navigate to your job details?',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              self.navigateToServiceBoard({
                boardNumber: defaultServiceBoard.boardNumber,
                cardNumber: docNumber,
              });
            }
          },
        })
        .open();
    },
    navigateToServiceBoard({ boardNumber, cardNumber }) {
      this.$f7router.navigate(
        `/dashboard/swimlane/service/${boardNumber}/card/${cardNumber}`,
        {
          reloadAll: true,
          pushState: true,
        }
      );
    },
    navigateToSelectSalesRep() {
      this.$refs.userPopup.open();
    },
    selectSalesRep(userName) {
      this.salesRep = userName;
      this.onUpdateCard('salesRep', userName);
    },
    clearDate() {
      this.dateOfLoss = [];
      this.updateCard({
        id: this.displayCard.id,
        doc: { dateOfLoss: '' },
      });
    },
    clearData() {
      if (!_.isEmpty(this.$refs.agreementSection)) {
        this.$refs.agreementSection.clearData();
      }
      if (!_.isEmpty(this.$refs.letterSection)) {
        this.$refs.letterSection.clearData();
      }
    },
    navigateToSelectCompany() {
      this.$refs.selectCompany.open();
    },
    openAddNewCompany() {
      this.$refs.selectCompany.open(true);
    },
    navigateToSelectInspector() {
      this.$refs.inspectorPopup.open();
    },
    selectInspector(userName) {
      this.inspectorName = userName;
      this.onUpdateCard('inspectorName', userName);
    },
    selectLeadSource(value) {
      this.onUpdateCard('leadSource', value);
      if (value !== 'referral') {
        this.onUpdateCard('referralName', '');
      }
    },
    getContactName(contact) {
      return !_.isEmpty(contact)
        ? ((contact.firstName || '') + ' ' + (contact.lastName || '')).trim()
        : '';
    },
    changeCardValue(contact) {
      if (!_.isEmpty(contact)) {
        this.setCurrentCardValues({ contactId: contact.id });
        this.onUpdateCard('contactId', contact.id, true);
        const title = `${this.getContactName(contact)} - ${getFullAddress(
          this.projectAddress
        )}`;
        this.onUpdateCard('title', title, true);
      }
    },

    archiveProject() {
      this.resolve(() => {});
      this.archiveNote = '';
      this.$f7router.updateCurrentUrl('/dashboard/swimlane/residential');
    },
  },
  watch: {
    card: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!_.isEmpty(val) && val.status !== 'draft') {
          this.initCardDisplayValues();
        }
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      contactId: {
        required,
      },
    };
  },
};
</script>
<style scoped lang="scss">
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
  &_placeholder {
    color: var(--f7-color-placeholder);
  }
}

.inspect-time-desktop ::v-deep input[type='time'] {
  width: 100px;
}

.inspect-time-android ::v-deep input[type='time'] {
  width: 100%;
}

.inspect-time-ios ::v-deep input[type='time']::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}

.inspect-time-web
  ::v-deep
  input[type='time']::-webkit-calendar-picker-indicator {
  filter: var(--f7-color-text-neutral);
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
