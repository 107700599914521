<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            popup-close
            @click.native="handleBack"
            >Close</f7-link
          >
        </f7-nav-left>
        <f7-nav-title
          >{{ isEdit ? 'Edit' : 'Create New' }} Invoice From
          Estimate</f7-nav-title
        >
        <f7-nav-right>
          <f7-link @click="save()">Save </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list :inset="$device.desktop">
        <div class="row">
          <f7-block-title :class="{ 'no-margin-top': !$device.desktop }"
            >Estimate Infomation</f7-block-title
          >
          <f7-list
            no-hairlines-md
            class="col-100"
            media-list
          >
            <!-- Estimate -->
            <f7-list-item @click.native="openSelectEstimate">
              <div slot="title">Estimate<required-asterisk /></div>
              <div slot="subtitle">
                <span>
                  {{
                    newInvoice.estimateId
                      ? getDisplayName(newInvoice.estimateId)
                      : 'Select Estimate'
                  }}
                </span>
              </div>
              <div
                slot="text"
                style="color: red"
              >
                {{ estimateErrorMessage }}
              </div>
            </f7-list-item>
            <!-- Building -->
            <f7-list-item
              v-show="isServiceType"
              link
              @click.native="openSelectBuildings"
            >
              <div
                class="list-item-inner-start"
                slot="inner-start"
              >
                Buildings
              </div>
              <div
                class="list-item-title"
                slot="title"
              >
                <span>
                  {{
                    newInvoice.buildingIDs
                      ? buildingByIds(newInvoice.buildingIDs)
                      : 'Select buildings'
                  }}
                </span>
              </div>
              <div
                slot="text"
                style="color: red"
              >
                {{ requiredSelectEstimateMessage }}
              </div>
            </f7-list-item>
            <dynamic-muti-select
              ref="selectBuildings"
              search-placeholder="Search in buildings"
              title="Select buildings"
              :value="newInvoice.buildingIDs"
              :options="buildingListOptions"
              @changed="changeBuildings"
            >
            </dynamic-muti-select>
          </f7-list>
        </div>
        <!-- company info -->
        <div class="row">
          <div class="col-100 medium-50">
            <f7-block-title :class="{ 'no-margin-top': !$device.desktop }"
              >From</f7-block-title
            >
            <f7-list
              :inline-labels="$device.desktop"
              :class="{ 'no-margin': !$device.desktop }"
              no-hairlines-md
            >
              <f7-list-input
                label="Roofing Company Name"
                placeholder="Enter Roofing Company Name"
                :value="newInvoice.roofingCompanyName"
                @change="
                  onChangeInvoiceProp('roofingCompanyName', $event.target.value)
                "
                type="text"
                error-message-force
                :error-message="requireErrorMessage('roofingCompanyName')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input
                label="Address"
                placeholder="Enter Address"
                :value="newInvoice.roofingCompanyAddress"
                @change="
                  onChangeInvoiceProp(
                    'roofingCompanyAddress',
                    $event.target.value
                  )
                "
                type="text"
              >
              </f7-list-input>
              <f7-list-input
                label="Phone"
                placeholder="Enter Phone"
                :value="newInvoice.roofingCompanyPhone"
                @change="
                  onChangeInvoiceProp(
                    'roofingCompanyPhone',
                    $event.target.value
                  )
                "
                type="text"
              >
              </f7-list-input>
            </f7-list>
          </div>

          <div class="col-100 medium-50">
            <f7-block-title style="height: 21px"></f7-block-title>
            <f7-list
              :inline-labels="$device.desktop"
              :class="{ 'no-margin-top': !$device.desktop }"
              no-hairlines-md
            >
              <f7-list-input
                :disabled="true"
                label="Project/Job Name"
                placeholder="Select Job"
                :value="newInvoice.jobName || 'Select a Project/Job'"
                type="text"
                readonly
                @click.native="selectProject"
                error-message-force
                :error-message="requireErrorMessage('jobName')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input
                label="Property Name"
                placeholder="Type or select a Project/Job first"
                :value="newInvoice.propertyName"
                type="text"
                @change="
                  onChangeInvoiceProp('propertyName', $event.target.value)
                "
              >
              </f7-list-input>
              <f7-list-input
                label="Property Address"
                placeholder="Type or select a Project/Job first"
                :value="newInvoice.propertyAddress"
                type="text"
                @change="
                  onChangeInvoiceProp('propertyAddress', $event.target.value)
                "
              >
              </f7-list-input>
            </f7-list>
          </div>

          <div class="col-100 medium-50">
            <f7-block-title>To</f7-block-title>
            <f7-list
              :inline-labels="$device.desktop"
              :class="{ 'no-margin': !$device.desktop }"
              no-hairlines-md
            >
              <f7-list-input
                label="Client Name"
                placeholder="Enter Client Name"
                :value="newInvoice.clientName"
                @change="onChangeInvoiceProp('clientName', $event.target.value)"
                type="text"
                error-message-force
                :error-message="requireErrorMessage('clientName')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input
                label="Address"
                placeholder="Enter Address"
                :value="newInvoice.clientAddress"
                @change="
                  onChangeInvoiceProp('clientAddress', $event.target.value)
                "
                type="text"
              >
              </f7-list-input>
              <f7-list-input
                label="Phone"
                placeholder="Enter Phone"
                :value="newInvoice.clientPhoneNumber"
                @input="
                  onChangeInvoiceProp(
                    'clientPhoneNumber',
                    $event.target.value.trim()
                  )
                "
                @blur="v$.newInvoice.clientPhoneNumber.$touch()"
                v-mask="'(+1) ###-###-####'"
                error-message-force
                :error-message="clientPhoneNumberErrorMessage()"
              >
              </f7-list-input>
              <f7-list-input
                label="Email"
                placeholder="Enter Email"
                :value="newInvoice.clientEmail"
                @change="
                  onChangeInvoiceProp('clientEmail', $event.target.value)
                "
                type="text"
                error-message-force
                :error-message="clientEmailErrorMessage()"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
            </f7-list>
          </div>
          <div class="col-100 medium-50">
            <f7-block-title style="height: 21px"></f7-block-title>
            <f7-list
              :inline-labels="$device.desktop"
              no-hairlines-md
            >
              <f7-list-input
                label="Invoice Date"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                :calendar-params="{
                  backdrop: true,
                  openIn: 'customModal',
                  header: true,
                  footer: false,
                  dateFormat: 'mm/dd/yyyy',
                  disabled: {
                    to: new Date(new Date().getTime() - 86400000), // yesterdat
                  },
                }"
                :value="newInvoice.invoiceDate || [new Date()]"
                @calendar:change="
                  onChangeInvoiceProp('invoiceDate', $event);
                  $f7.calendar.close();
                "
                error-message-force
                :error-message="requireErrorMessage('invoiceDate')"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input
                label="Due Date"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                :calendar-params="{
                  backdrop: true,
                  openIn: 'customModal',
                  header: true,
                  footer: false,
                  dateFormat: 'mm/dd/yyyy',
                  disabled: {
                    to: new Date(new Date().getTime() - 86400000), // yesterdat
                  },
                }"
                :value="newInvoice.dueDate || []"
                @calendar:change="
                  onChangeInvoiceProp('dueDate', $event);
                  $f7.calendar.close();
                "
                error-message-force
                :error-message="dueDateErrorMessage"
              >
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input
                v-if="project && project.businessCode === 'service'"
                label="Table Content Type"
                type="select"
                :value="newInvoice.tableContentType"
                @change="changeTableContentType($event.target.value)"
              >
                <option
                  v-for="c in TABLE_CONTENT_TYPES"
                  :key="c.value"
                  :value="c.value"
                >
                  {{ c.name }}
                </option>
                <required-asterisk slot="label" />
              </f7-list-input>
            </f7-list>
          </div>
        </div>
      </f7-list>
      <!-- item detail -->
      <!-- show error when table no data -->
      <div
        class="error-message padding-horizontal"
        v-show="tableErrorMessage"
      >
        <f7-icon
          f7="exclamationmark_octagon"
          class="margin-right-half"
        ></f7-icon>
        {{ tableErrorMessage }}
      </div>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <component
          :is="
            isServiceType
              ? newInvoice.tableContentType !== TABLE_CONTENT_TYPE_ITEMIZE
                ? 'summary-table'
                : 'product-items-table-with-profit'
              : 'product-items-table'
          "
          :invoiceDetail="newInvoice"
          @onChangeItems="onChangeInvoiceProp('itemDetails', $event)"
          ref="productItemTable"
        >
          <summary-footer
            slot="summary"
            isFromEstimate
            :invoice="newInvoice"
            @onChangeSummaryFooter="onChangeSummaryFooter"
          >
          </summary-footer>
        </component>
      </f7-block>
      <!-- Note -->
      <f7-block-title>Notes</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <f7-text-editor
          class="no-margin-horizontal"
          :value="newInvoice.notes"
          @texteditor:change="val => onChangeInvoiceProp('notes', val)"
          placeholder="Enter Notes"
          :buttons="[
            ['bold', 'italic', 'underline', 'strikeThrough'],
            ['orderedList', 'unorderedList'],
          ]"
          resizable
        ></f7-text-editor>
      </f7-block>
      <!-- Term and Condition -->
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <text-editor
          :data="newInvoice.termsAndConditions"
          @change="onChangeInvoiceProp('termsAndConditions', $event)"
        ></text-editor>
      </f7-block>
      <!-- Photo sections -->
      <f7-block-title>Before</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <div class="row flex-start">
          <image-item
            v-for="item in newInvoice.beforeImages"
            :key="item.id"
            :photo="item"
            :projectId="newInvoice.projectId || ''"
            :selectedPhotos="newInvoice.beforeImages"
            @onDelete="photo => onDeletePhoto(photo, 'beforeImages')"
            @onChangePhotoProp="
              (prop, value) =>
                onChangePhotoProp(prop, value, item.id, 'beforeImages')
            "
          ></image-item>
          <image-add-item
            :projectId="newInvoice.projectId || ''"
            :selectedPhotos="newInvoice.beforeImages"
            @onSelectPhotos="photos => onSelectPhotos(photos, 'beforeImages')"
          ></image-add-item>
        </div>
      </f7-block>
      <f7-block-title>After</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <div class="row flex-start">
          <image-item
            v-for="item in newInvoice.afterImages"
            :key="item.id"
            :selectedPhotos="newInvoice.afterImages"
            :photo="item"
            :projectId="newInvoice.projectId || ''"
            @onDelete="photo => onDeletePhoto(photo, 'afterImages')"
            @onChangePhotoProp="
              (prop, value) =>
                onChangePhotoProp(prop, value, item.id, 'afterImages')
            "
          ></image-item>
          <image-add-item
            :projectId="newInvoice.projectId || ''"
            :selectedPhotos="newInvoice.afterImages"
            @onSelectPhotos="photos => onSelectPhotos(photos, 'afterImages')"
          ></image-add-item>
        </div>
      </f7-block>
    </f7-page>

    <project-list-popup
      ref="selectProject"
      @onSelected="onSelectedProject($event)"
    ></project-list-popup>

    <select-estimate-popup
      ref="selectEstimate"
      @onSelected="onSelectedEstimate($event)"
    >
    </select-estimate-popup>
  </f7-popup>
</template>

<script>
import ProductItemsTableWithProfit from '../tables/ProductItemsTableWithProfit.vue';
import ProductItemsTable from '../tables/ProductItemsTable.vue';
import SummaryTable from '../tables/SummaryTable.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import CompanyListPopup from './CompanyListPopup.vue';
import ContactListPopup from './ContactListPopup.vue';
import TextEditor from '../inputs/TextEditor.vue';
import ImageItem from '../inputs/ImageItem.vue';
import ImageAddItem from '../inputs/ImageAddItem.vue';
import DynamicMutiSelect from '../inputs/DynamicMultiSelect.vue';
import SelectEstimatePopup from './SelectEstimatePopup.vue';
import SummaryFooter from '../footer/SummaryFooter.vue';

import { getFullAddress } from '@/utility/address';
import { mapActions, mapGetters } from 'vuex';
import invoiceMixins from '../../mixins/invoice-mixin';
import commonMixins from '../../mixins/common-mixin';
import Vue from 'vue';
import { DEFAULT_STATUS_INVOICE, VALIDATION_MESSAGE } from '@/utility/const';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { auth } from '@/services/firebase.service';
import {
  TABLE_CONTENT_TYPES,
  TABLE_CONTENT_TYPE_ITEMIZE,
} from '@/utility/const';
import moment from 'moment';
import { mask } from 'vue-the-mask';

export default {
  components: {
    ProductItemsTableWithProfit,
    ProductItemsTable,
    SummaryTable,
    ProjectListPopup,
    CompanyListPopup,
    ContactListPopup,
    TextEditor,
    ImageItem,
    ImageAddItem,
    DynamicMutiSelect,
    SelectEstimatePopup,
    SummaryFooter,
  },
  directives: { mask },
  mixins: [commonMixins, invoiceMixins],
  props: {
    isEdit: Boolean,
  },
  data: () => {
    return {
      TABLE_CONTENT_TYPES,
      TABLE_CONTENT_TYPE_ITEMIZE,
      popupOpened: false,
      requiredSelectEstimateMessage: '',
      tableErrorMessage: '',
      originalInvoice: {},
      newInvoice: {
        discount: {
          type: 'percent',
          value: 0,
        },
        tax: {
          type: 'percent',
          value: 0,
        },
        shippingCharge: {
          type: 'cash',
          value: 0,
        },
        itemDetails: [],
        termsAndConditions: '',
        estimateId: '',
        buildingIDs: [],
        beforeImages: [],
        afterImages: [],
        tableContentType: null,
        crossProfitMarginRatio: 0,
        miscPercent: 0,
      },
      redundantPhotos: [],
      oldInvoiceNumber: '',
    };
  },

  async created() {
    if (
      this.$f7route.params.projectId &&
      this.$f7route.params.actionType === 'newInvoice'
    ) {
      const projectId = this.$f7route.params.projectId;
      await this.open();
      this.onSelectedProject(projectId);
    }
  },
  computed: {
    ...mapGetters('invoices/invoices', ['invoice']),
    ...mapGetters('invoices/project', ['project']),
    ...mapGetters('invoices/company', ['companyById']),
    ...mapGetters('invoices/invoice-template', ['invoiceDefaultTemplate']),
    ...mapGetters({
      setting: 'setting/app/system/setting',
      estimateList: 'invoices/estimate/estimateList',
      estimateById: 'invoices/estimate/estimateById',
    }),
    ...mapGetters('setting/app/profile', ['user']),

    ...mapGetters('common/app-constant', ['tenantId']),

    estimateErrorMessage() {
      if (!this.v$.newInvoice.$error) return '';
      if (this.v$.newInvoice.estimateId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    dueDateErrorMessage() {
      if (!this.v$.newInvoice.dueDate.$error) return null;
      if (this.v$.newInvoice.dueDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newInvoice.dueDate.timeRules.$invalid)
        return VALIDATION_MESSAGE.DUE_DATE_GREATER_THAN_INVOICE_DATE;
      return null;
    },
    isServiceType() {
      return (
        (this.estimateList.find(r => r.id == this.newInvoice.estimateId) || {})
          .businessCode === 'service'
      );
    },
    estimateListOptions() {
      let list = _.cloneDeep(this.estimateList);
      if (this.$f7route.params.projectId) {
        list = list.filter(r => r.projectId === this.$f7route.params.projectId);
      }
      return list.map(r => ({
        id: r.id,
        value: `${r.estimateNumber}-${r.estimateName}`,
      }));
    },
    buildingListOptions() {
      let estimate = this.estimateById(this.newInvoice.estimateId);
      return ((estimate || {}).buildings || []).map(r => ({
        id: r.buildingId,
        value: r.buildingName,
      }));
    },
  },

  methods: {
    ...mapActions('invoices/invoices', [
      'createNewInvoice',
      'updateInvoice',
      'bindInvoice',
    ]),
    ...mapActions('invoices/project', ['bindProject']),
    ...mapActions('invoices/property', ['getPropertyById']),
    ...mapActions({
      bindSetting: 'setting/app/system/bindSetting',
      bindEstimateListBys: 'invoices/estimate/bindEstimateListBys',
      getBuilding: 'invoices/estimate/getBuilding',
      getCompanyById: 'invoices/company/getCompanyById',
      getContactById: 'invoices/contact/getContactById',
    }),
    ...mapActions({
      bindInvoiceTemplateList:
        'invoices/invoice-template/bindInvoiceTemplateList',
      copyPhoto: 'invoices/photo/copyPhoto',
      removePhoto: 'invoices/photo/removePhoto',
    }),
    getItemDetailFromBuilding(buidingIds) {
      if (_.isEmpty(buidingIds)) {
        let itemManualAdded = this.newInvoice.itemDetails.filter(
          r => !r.isAutoAdded
        );
        this.onChangeInvoiceProp('itemDetails', itemManualAdded);
        return;
      }
      let products = [];
      let promises = [];
      buidingIds.forEach(buildingId => {
        promises.push(
          this.getBuilding({
            estimateId: this.newInvoice.estimateId,
            buildingId: buildingId,
          })
        );
      });
      Promise.all(promises).then(data => {
        if (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE) {
          (data || []).forEach(building => {
            if (!_.isEmpty(building)) {
              let productList = this.getProductList(building);
              products = products.concat(productList);
            }
          });
          // keep items manual added
          let itemManualAdded = this.newInvoice.itemDetails.filter(
            r => !r.isAutoAdded
          );
          products = products.concat(itemManualAdded || []);
          this.onChangeInvoiceProp('itemDetails', products);
        } else {
          // get estimate material cost
          const estimate = this.estimateById(this.newInvoice.estimateId) || {};
          const summary = this.summaryEstimate(this.project, data, estimate);
          this.onChangeInvoiceProp('itemDetails', summary);
        }
      });
    },
    getDisplayName(estimateId) {
      let estimate = this.estimateList.find(r => r.id === estimateId) || {};
      return !_.isEmpty(estimate)
        ? `${estimate.estimateNumber} - ${estimate.estimateName}`
        : '';
    },
    getProductList(building) {
      const estimate = this.estimateById(this.newInvoice.estimateId) || {};
      const doesApplyTaxForMaterial = this.doesApplyTaxForMaterial(
        this.project,
        building,
        estimate
      );
      const doesApplyTaxForLabor = this.doesApplyTaxForLabor(
        this.project,
        building,
        estimate
      );

      let products = [];
      (building.productData || []).forEach(element => {
        let sectionProduct = (element.productList || [])
          .filter(r => !!r.actualQty || !!r.orderQty)
          .map(r => ({
            productName: r.productItem,
            quantity: r.orderQty,
            price: r.price || 0,
            unit: r.uom || '',
            category: element.category,
            discount: false,
            tax:
              element.category === 'Labor'
                ? doesApplyTaxForLabor
                : doesApplyTaxForMaterial,
            isAutoAdded: true,
          }));
        products = products.concat(sectionProduct);
      });
      return products;
    },
    buildingByIds(ids) {
      if (!ids) return '';
      return (
        this.buildingListOptions.filter(r => (ids || []).includes(r.id)) || []
      )
        .map(r => r.value)
        .join(', ');
    },
    changeTableContentType(value) {
      this.onChangeInvoiceProp('itemDetails', []);
      this.onChangeInvoiceProp('tableContentType', value);
      this.getItemDetailFromBuilding(this.newInvoice.buildingIDs);
    },
    openSelectEstimate() {
      this.$refs.selectEstimate.open(this.$f7route.params.projectId);
    },
    openSelectBuildings() {
      if (this.newInvoice.estimateId) {
        this.$refs.selectBuildings.openSelectPopup();
      } else {
        this.requiredSelectEstimateMessage = 'Please select estimate first';
      }
    },
    changeBuildings(values) {
      this.onChangeInvoiceProp('buildingIDs', values);
      this.getItemDetailFromBuilding(values);
    },
    async onSelectedEstimate(value) {
      this.onChangeInvoiceProp('estimateId', value);
      this.onChangeInvoiceProp('buildingIDs', []);
      // keep items manual added
      let itemManualAdded = this.newInvoice.itemDetails.filter(
        r => !r.isAutoAdded
      );
      this.onChangeInvoiceProp('itemDetails', itemManualAdded || []);

      this.requiredSelectEstimateMessage = '';

      let estimate = this.estimateById(value);
      this.onChangeInvoiceProp('estimateNumber', estimate.estimateNumber);
      this.onChangeInvoiceProp('estimateName', estimate.estimateName);
      await this.onSelectedProject(estimate.projectId);
      this.onChangeInvoiceProp('tax', {
        value: estimate.saleTax || 0,
        type: 'percent',
      });
      this.onChangeInvoiceProp(
        'crossProfitMarginRatio',
        estimate.crossProfitMarginRatio || 0
      );
      this.onChangeInvoiceProp('miscPercent', estimate.miscPercent || 0);
      //only service type we will get product items from building.
      if (estimate.businessCode === 'service') {
        if (!this.newInvoice.tableContentType) {
          this.onChangeInvoiceProp(
            'tableContentType',
            TABLE_CONTENT_TYPE_ITEMIZE
          );
        }
        this.setDefaultBuilding();
      } else {
        this.onChangeInvoiceProp('tableContentType', null);
      }
    },
    setDefaultBuilding() {
      let buildingIDs = this.buildingListOptions.map(r => r.id);
      this.changeBuildings(buildingIDs);
    },

    handleBack() {
      if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
        this.navigateToBoard();
      } else {
        // remove before photos
        let promises = [];
        const originalBeforeImages = this.originalInvoice.beforeImages || [];
        const newBeforeImages = this.newInvoice.beforeImages || [];
        const beforeImagesRemove = _.differenceWith(
          newBeforeImages,
          originalBeforeImages,
          _.isEqual
        );
        // remove after photos
        const originalAfterImages = this.originalInvoice.afterImages || [];
        const newAfterImages = this.newInvoice.afterImages || [];
        const afterImagesRemove = _.differenceWith(
          newAfterImages,
          originalAfterImages,
          _.isEqual
        );
        // remove redundant images
        const redundantImagesRemove = _.differenceWith(
          this.redundantPhotos,
          originalBeforeImages.concat(originalAfterImages),
          _.isEqual
        );
        const itemsRemove = beforeImagesRemove.concat(
          afterImagesRemove,
          redundantImagesRemove
        );
        for (const photo of itemsRemove) {
          promises.push(this.removePhoto(photo.fullPath));
        }

        Promise.all(promises);
        this.cancel();
      }
    },

    onSelectPhotos(photos, propName) {
      this.$f7.preloader.show();
      let items = this.newInvoice[propName] || [];
      const beforeIds = items.map(r => r.id);
      const afterIds = photos.map(r => r.id);

      const idsRemove = _.differenceWith(beforeIds, afterIds, _.isEqual);

      const idsAdd = _.differenceWith(afterIds, beforeIds, _.isEqual);

      let promises = [];

      // delete photo in storage
      for (const id of idsRemove) {
        const photo = items.find(r => r.id === id);
        this.redundantPhotos.push(photo);
        items = _.remove(items, item => item.id != id);
      }

      // add photo
      for (const id of idsAdd) {
        const photo = photos.find(r => r.id === id);
        promises.push(
          this.copyPhoto(photo).then(({ fullPath, url }) => {
            items.push({
              id: photo.id,
              photoUrl: url,
              fullPath: fullPath,
              notes: photo.notes,
            });
          })
        );
      }

      Promise.all(promises).then(() => {
        this.onChangeInvoiceProp(propName, items);
        this.$f7.preloader.hide();
      });
    },
    onDeletePhoto(photo, propName) {
      //remote photo from storage
      this.redundantPhotos.push(photo);
      let items = this.newInvoice[propName] || [];
      items = _.remove(items, item => item.id != photo.id);
      this.onChangeInvoiceProp(propName, items);
    },
    onChangePhotoProp(prop, value, id, invoiceProp) {
      let items = this.newInvoice[invoiceProp] || [];
      let index = items.findIndex(r => r.id == id);
      let photo = { ...items[index] };
      if (!photo) return;
      photo[prop] = value;
      Vue.set(items, index, photo);
      this.onChangeInvoiceProp(invoiceProp, items);
    },
    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: true,
          closeTimeout: 5000,
        })
        .open();
    },
    async open(id, isNeedBindDetail = false) {
      this.popupOpened = true;
      this.$f7.preloader.show();
      if (id) {
        if (isNeedBindDetail) {
          await this.bindInvoice(id);
        }
        this.newInvoice = {
          ...this.invoice,
          invoiceDate: this.invoice.invoiceDate.toDate
            ? [new Date(this.invoice.invoiceDate.toDate())]
            : [new Date()],
          dueDate: this.invoice.dueDate.toDate
            ? [new Date(this.invoice.dueDate.toDate())]
            : [],
        };
        this.originalInvoice = _.cloneDeep(this.newInvoice);
        if (this.invoice.projectId) {
          await this.bindProject(this.invoice.projectId);
        }
      } else {
        const refs = [];
        if (_.isEmpty(this.setting)) {
          refs.push(this.bindSetting(this.tenantId));
        }
        if (_.isEmpty(this.invoiceDefaultTemplate)) {
          refs.push(this.bindInvoiceTemplateList());
        }
        await Promise.all(refs);

        //populate data
        this.onChangeInvoiceProp(
          'roofingCompanyName',
          this.setting ? this.setting.companyName : ''
        );
        this.onChangeInvoiceProp(
          'roofingCompanyAddress',
          this.setting ? this.setting.address : ''
        );
        this.onChangeInvoiceProp(
          'roofingCompanyPhone',
          this.setting ? this.setting.phoneNumber : ''
        );
        //from templte
        this.onChangeInvoiceProp('logo', this.invoiceDefaultTemplate.logo);
        let userEmail = auth.currentUser.email || 'example@email.com';
        let userName = auth.currentUser.displayName || '{ User Name }';
        let termsAndConditions =
          this.invoiceDefaultTemplate.termsAndConditions.replace(
            '{user email}',
            userEmail
          );
        termsAndConditions = termsAndConditions.replace(
          '{user name}',
          userName
        );
        this.onChangeInvoiceProp('termsAndConditions', termsAndConditions);
      }
      const promises = [];
      if (this.$f7route.params.projectId) {
        promises.push(this.bindProject(this.$f7route.params.projectId));
      }
      if (_.isEmpty(this.estimateList)) {
        promises.push(
          this.bindEstimateListBys([
            {
              prop: 'status',
              val: 'active',
              op: '==',
            },
            {
              prop: 'isProjectArchived',
              val: false,
              op: '==',
            },
          ])
        );
      }
      await Promise.all(promises);
      this.$f7.preloader.hide();
    },

    cancel() {
      this.popupOpened = false;
      this.tableErrorMessage = '';
      this.newInvoice = {
        discount: {
          type: 'percent',
          value: 0,
        },
        tax: {
          type: 'percent',
          value: 0,
        },
        shippingCharge: {
          type: 'cash',
          value: 0,
        },
        itemDetails: [],
        tableContentType: null,
        crossProfitMarginRatio: 0,
        miscPercent: 0,
      };
      this.newInvoice.notes = '';
      this.v$.$reset();
    },

    selectProject() {
      this.$refs.selectProject.open();
    },

    async onSelectedProject(value) {
      // get info
      await this.bindProject(value);
      // get company info
      let companyId = this.project.companyId || this.project.companyId;
      let projectAddress = this.project.projectAddress;
      let company = companyId ? await this.getCompanyById(companyId) : {};
      let companyMap = {};
      if (company) {
        let { addresses, phones, others } = company;
        companyMap = {
          companyName: company ? company.companyName : '',
          address: getFullAddress((addresses || [])[0]),
          phoneNumber: ((phones || [])[0] || {}).value || '',
          email: (others || []).find(r => r.code == 'email')
            ? (others || []).find(r => r.code == 'email').value
            : '',
        };
      }
      // get contact info
      let contactId = this.project.contactId || this.project.contactId;
      let contact = contactId ? await this.getContactById(contactId) : null;
      let contactMap = {};
      if (contact) {
        let { addresses, phones, emails } = contact;
        contactMap = {
          contactName: contact
            ? `${contact.firstName} ${contact.lastName}`
            : '',
          address: getFullAddress((addresses || [])[0]),
          phoneNumber: ((phones || [])[0] || {}).value || '',
          email: (emails || []).find(r => r.code == 'main')
            ? (emails || []).find(r => r.code == 'main').value
            : '',
        };
      }

      // get property info
      let propertyId = this.project.propertyId || this.project.propertyId;
      let property = propertyId ? await this.getPropertyById(propertyId) : null;
      let propertyMap = {};
      if (property) {
        let { addresses } = property;
        propertyMap = {
          propertyName: property ? property.propertyName : '',
          address: getFullAddress((addresses || [])[0]),
        };
      }

      // if client is company get from company first otherwise get from contact
      this.newInvoice = {
        ...this.newInvoice,
        projectId: value,
        projectNumber: this.project.cardNumber,
        jobName: this.project.title || '',
        propertyName:
          propertyMap.propertyName || getFullAddress(projectAddress) || '',
        propertyAddress:
          propertyMap.address || getFullAddress(projectAddress) || '',

        clientCompanyId: company && company.id ? company.id : '',
        clientContactId: contact && contact.id ? contact.id : '',
        clientName: companyMap.companyName || contactMap.contactName || '',
        clientAddress: companyMap.address || contactMap.address || '',
        clientPhoneNumber:
          companyMap.phoneNumber || contactMap.phoneNumber || '',
        clientEmail: companyMap.email || contactMap.email || '',
      };
    },
    onChangeInvoiceProp(prop, value) {
      Vue.set(this.newInvoice, prop, value);
    },
    onChangeSummaryFooter({ prop, subprop, value }) {
      let fieldDetail = this.newInvoice[prop];
      fieldDetail = {
        ...fieldDetail,
        [subprop]: value,
      };
      Vue.set(this.newInvoice, prop, fieldDetail);
    },
    validate() {
      let isProductItemsValid = this.$refs.productItemTable.validate();
      this.v$.$touch();
      if (this.v$.$invalid || this.tableInValid() || !isProductItemsValid) {
        return false;
      }
      return true;
    },
    tableInValid() {
      if ((this.newInvoice.itemDetails || []).length < 1) {
        this.tableErrorMessage = 'Please add items';
        return true;
      }
      return false;
    },
    save() {
      if (!this.validate()) return;
      if (this.newInvoice.status === 'in-overdue') {
        const currentDate = moment(new Date(), 'MM/DD/YYYY');
        let dueDate = this.newInvoice.dueDate[0];
        const days = currentDate.diff(dueDate, 'day');
        if (days < 0) {
          Vue.set(this.newInvoice, 'status', DEFAULT_STATUS_INVOICE);
        }
      }
      let data = {
        ...this.newInvoice,
        status: this.newInvoice.id
          ? this.newInvoice.status
          : DEFAULT_STATUS_INVOICE, //default status
        dueDate: this.newInvoice.dueDate[0] || '',
        invoiceDate: this.newInvoice.invoiceDate[0],
      };
      this.$f7.preloader.show();
      this.createOrUpdate(data).then(() => {
        this.$f7.preloader.hide();

        // remove redundant photos
        for (const photo of this.redundantPhotos) {
          this.removePhoto(photo.fullPath);
        }
        this.redundantPhotos = [];
        this.cancel();
        if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
          this.navigateToBoard();
        }
      });
    },
    createOrUpdate(data) {
      if (data.id) {
        return this.updateInvoice({
          id: data.id,
          doc: data,
        });
      } else {
        return this.createNewInvoice({
          invoice: data,
          isInvoiceGrid: this.isInvoiceGrid,
        });
      }
    },
    requireErrorMessage(prop) {
      if (!this.v$.newInvoice[prop].$error) return null;
      if (this.v$.newInvoice[prop].required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    clientEmailErrorMessage() {
      if (!this.v$.newInvoice.clientEmail.$error) return null;
      if (this.v$.newInvoice.clientEmail.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newInvoice.clientEmail.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    clientPhoneNumberErrorMessage() {
      if (!this.v$.newInvoice.clientPhoneNumber.$error) return null;
      if (this.v$.newInvoice.clientPhoneNumber.minLength.$invalid)
        return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
      return null;
    },
  },
  watch: {
    'newInvoice.itemDetails': {
      handler(val) {
        if ((val || []).length) {
          this.tableErrorMessage = '';
        }
      },
      deep: true,
      immediate: true,
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const timeRules = () => {
      if (!this.newInvoice.invoiceDate || !this.newInvoice.dueDate) return true;
      const fromDate = moment(this.newInvoice.invoiceDate[0]).format(
        'YYYY-MM-DD'
      );
      const toDate = moment(this.newInvoice.dueDate[0]).format('YYYY-MM-DD');
      return !moment(toDate).isBefore(fromDate, 'day');
    };
    return {
      newInvoice: {
        roofingCompanyName: {
          required,
        },
        jobName: {
          required,
        },
        clientName: {
          required,
        },
        clientEmail: {
          required,
          commonEmailRule: email,
        },
        invoiceDate: {
          required,
        },
        dueDate: {
          required,
          timeRules,
        },
        estimateId: {
          required,
        },
        clientPhoneNumber: {
          minLength: minLength(17),
        },
        // paymentStatus: {
        //   required
        // }
      },
    };
  },
};
</script>
<style land="scss" scoped>
.flex-start {
  justify-content: flex-start;
}

.error-message {
  font-size: var(--f7-list-item-text-font-size);
  font-weight: var(--f7-list-item-text-font-weight);
  color: red;
  display: flex;
}
</style>
