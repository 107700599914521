<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          isEdit ? 'Edit Expenses' : 'Add New Expenses'
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="isEdit ? edit() : add()">
            {{ isEdit ? 'Save' : 'Done' }}
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- report Type -->
      <f7-list>
        <f7-list media-list>
          <f7-list-input
            label="Expenses Name"
            placeholder="Enter Expenses Name"
            type="text"
            :value="object.expensesName"
            @input="object.expensesName = $event.target.value"
            error-message-force
            :error-message="expensesNameErrorMessage"
            ><required-asterisk slot="label"></required-asterisk>
          </f7-list-input>
        </f7-list>
        <f7-list-input
          label="Category"
          type="select"
          placeholder="Select category"
          :value="reportType"
          @change="reportType = $event.target.value"
          error-message-force
          validate
          validate-on-blur
          :error-message="reportTypeErrorMessage"
        >
          <option
            value=""
            disabled
            hidden
          >
            Select category
          </option>
          <option
            v-for="type in expenseTypeList"
            :key="type.id"
            :value="type.value"
          >
            {{ type.displayName }}
          </option>
        </f7-list-input>
      </f7-list>

      <!-- if report type is select -->
      <div v-if="reportType">
        <f7-list media-list>
          <f7-list-item
            link
            @click.native="selectProject"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Project Name <required-asterisk></required-asterisk>
            </div>
            <div
              class="list-item-title"
              slot="title"
            >
              <span v-if="projectDetail.title">{{ projectDetail.title }}</span>
              <span
                v-else
                class="list-item-title_placeholder"
                >Select project</span
              >
            </div>
            <div
              slot="text"
              style="color: red"
            >
              {{ projectErrorMessage }}
            </div>
          </f7-list-item>
        </f7-list>
        <f7-list media-list>
          <f7-list-input
            label="Building/Section#"
            placeholder="Enter Building/Section#"
            type="text"
            :value="object.buildingOrSection"
            @input="object.buildingOrSection = $event.target.value"
          ></f7-list-input>
        </f7-list>

        <f7-list
          v-if="!isMyExpense"
          media-list
        >
          <f7-list-item
            link
            @click.native="isShowUserListPopup = true"
            :disabled="isEdit"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Employee Name
            </div>
            <div
              class="list-item-title"
              slot="title"
            >
              <span v-if="userByIds(object.payeeIds)">{{
                userByIds(object.payeeIds)
              }}</span>
              <span
                v-else
                class="list-item-title_placeholder"
                >Select employee</span
              >
            </div>
            <div
              slot="text"
              style="color: red"
            >
              {{ userErrorMessage }}
            </div>
          </f7-list-item>
        </f7-list>

        <!-- if milleage is select -->
        <div v-if="reportType === 'mileage'">
          <f7-list style="z-index: 102">
            <address-auto-complete
              label="From Location"
              style="z-index: 104"
              placeholder="Enter From Location"
              v-model="mileageOptionObject.from"
              @change="calculateDistanceAddress"
              :errorMessage="fromErrorMessage"
            >
            </address-auto-complete>
            <address-auto-complete
              style="z-index: 103"
              label="To Location"
              placeholder="Enter To Location"
              v-model="mileageOptionObject.to"
              @change="calculateDistanceAddress"
              :errorMessage="toErrorMessage"
            >
            </address-auto-complete>
          </f7-list>

          <f7-list>
            <f7-list-input
              label="Enter Mileage"
              :value="mileageOptionObject.mileage"
              type="number"
              @focus="
                mileageOptionObject.mileage == 0
                  ? (mileageOptionObject.mileage = null)
                  : mileageOptionObject.mileage
              "
              @blur="
                mileageOptionObject.mileage = Number(
                  mileageOptionObject.mileage
                )
              "
              @input="mileageOptionObject.mileage = $event.target.value.trim()"
              error-message-force
              :error-message="mileageErrorMessage"
              ><required-asterisk slot="label"></required-asterisk>
            </f7-list-input>
          </f7-list>
        </div>

        <!-- if all the remaining options -->
        <div v-if="reportType !== 'mileage'">
          <f7-list media-list>
            <f7-list-item>
              <div
                class="list-item-title"
                slot="title"
              >
                Amount<required-asterisk></required-asterisk>
              </div>
              <InputMount
                :price="Number(remainingOptionObject.amount)"
                @input="changeAmountEdit($event)"
                :error-message="amountErrorMessage"
              />
            </f7-list-item>
          </f7-list>

          <f7-list media-list>
            <f7-list-item
              class="cursor-pointer"
              @click.native="openFileInput"
            >
              <div
                class="list-item-title"
                slot="title"
              >
                Attachment
              </div>
              <div
                v-if="remainingOptionObject.attachments.length < 1"
                slot="text"
              >
                Upload file...
              </div>
              <div v-else>
                <div
                  v-for="(file, index) in remainingOptionObject.attachments"
                  :key="index"
                  slot="subtitle"
                  style="color: #2196f3"
                >
                  {{ file.name }}
                  <f7-link
                    icon-f7="multiply_circle"
                    color="red"
                    @click.stop="handleFileDelete(index)"
                  ></f7-link>
                </div>
              </div>

              <f7-link
                slot="after"
                class="margin-left"
                icon-f7="paperclip"
              ></f7-link>
            </f7-list-item>
          </f7-list>
        </div>

        <f7-list>
          <f7-list-input
            label="Notes"
            type="textarea"
            placeholder="Enter Notes..."
            :value="object.note"
            @input="object.note = $event.target.value"
          ></f7-list-input>
        </f7-list>
      </div>
    </f7-page>

    <project-list-popup
      ref="selectProject"
      @onSelected="onSelectedProject"
    ></project-list-popup>
    <user-list-popup
      :isShow="isShowUserListPopup"
      @close="isShowUserListPopup = false"
      @onSelected="object.payeeIds = $event"
      :currentSelectedId="object.payeeIds[0]"
    ></user-list-popup>
    <input
      type="file"
      @change="handleFileUpload"
      class="display-none"
      ref="fileInput"
      multiple
    />
  </f7-popup>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import UserListPopup from './UserListPopup.vue';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { auth } from '../../../../services/firebase.service';
import InputMount from '@/plugins/expenses/components/inputs/InputMount.vue';
import AddressAutoComplete from '../inputs/AddressAutoCompleteInput/AutoAddressInput.vue';
import { googlePlaceApiMixin } from '@/services/place.google.service';
import { meterToMile } from '@/utility/distance-tool';
import {
  EXPENSE_TYPE_MILEAGE,
  DEFAULT_STATUS_EXPENSE,
  VALIDATION_MESSAGE,
} from '@/utility/const';
import { getFullAddress } from '@/utility/address';

const getDefaultData = () => {
  return {
    isShowUserListPopup: false,
    isShowProjectListPopup: false,
    reportType: EXPENSE_TYPE_MILEAGE,
    object: {
      expensesName: '',
      projectId: null,
      // userId: null,
      note: '',
      // reimbursement: false,
      payeeIds: [],
      buildingOrSection: '',
      // expenseType: ""
    },
    mileageOptionObject: {
      mileage: 0,
      from: '',
      to: '',
    },
    remainingOptionObject: {
      amount: 0,
      attachments: [],
    },
    projectDetail: {},
  };
};
export default {
  components: {
    ProjectListPopup,
    UserListPopup,
    InputMount,
    AddressAutoComplete,
  },
  mixins: [googlePlaceApiMixin],
  props: {
    isShow: Boolean,
    isEdit: Boolean,
    isMyExpense: Boolean,
  },
  data: getDefaultData,
  methods: {
    ...mapActions('common/user', ['getUserList']),
    ...mapActions('common/project', ['getProjectById']),
    ...mapActions('common/expense', [
      'createExpense',
      'uploadAttachment',
      'updateExpense',
      'removeAttachment',
      'getExpenseListBys',
    ]),
    cancel() {
      Object.assign(this.$data, getDefaultData());
      this.v$.$reset();
      this.$emit('close');
    },
    selectProject() {
      this.$refs.selectProject.open();
    },
    async onSelectedProject(projectId) {
      this.projectDetail = await this.getProjectById(projectId);
      this.object.projectId = projectId;
      this.mileageOptionObject.to = getFullAddress(
        this.projectDetail.projectAddress
      );
    },
    changeAmountEdit(amount) {
      this.remainingOptionObject.amount = amount;
    },
    // changeReimbursement(value) {
    //   this.object.reimbursement = value;
    //   this.object.payeeName = value ? auth.currentUser.displayName : "";
    // },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload() {
      let data = [];
      const files = this.$refs.fileInput.files;
      let attachments = _.cloneDeep(this.remainingOptionObject.attachments);
      for (let i = 0; i < files.length; i += 1) {
        if (attachments.some(r => r.name === files[i].name)) {
          this.$ri.dialog.openWarningDialog({
            title: 'Please choose another one',
            content: 'That file name has already existed!',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            },
          });
          data = [];
          break;
        }
        data.push(files[i]);
      }
      this.remainingOptionObject.attachments = attachments.concat(data);
      this.$refs.fileInput.value = '';
    },
    handleFileDelete(index) {
      this.remainingOptionObject.attachments.splice(index, 1);
      this.$refs.fileInput.value = '';
    },
    handleUploadFile(attachments) {
      let promises = [];
      let attachs = [];

      for (const file of attachments) {
        const attachment = {
          name: file.name,
          fullPath: `expense/attachments/${+new Date() + file.name}`,
          fileUrl: '',
        };
        promises.push(
          this.uploadAttachment({ fullPath: attachment.fullPath, file }).then(
            url => {
              attachment.fileUrl = url;
              attachs.push(attachment);
            }
          )
        );
      }

      return Promise.all(promises).then(() => {
        return attachs;
      });
    },
    async existsExpensesName() {
      let expenseList = await this.getExpenseListBys([
        {
          prop: 'expensesName',
          val: this.object.expensesName,
          op: '==',
        },
      ]);
      if (this.isEdit) {
        expenseList = expenseList.filter(i => i.id !== this.expense.id);
      }

      if (!_.isEmpty(expenseList)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That expenses name has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(expenseList);
    },
    async add() {
      this.v$.reportType.$touch();
      if (this.v$.reportType.$invalid) {
        return;
      } else {
        this.v$.$touch();
        if (this.v$.object.$invalid) {
          return;
        }
        let data = {
          reportType: this.reportType,
          ...this.object,
          status: DEFAULT_STATUS_EXPENSE, // is Waiting for Approval
        };
        if (this.reportType === EXPENSE_TYPE_MILEAGE) {
          if (this.v$.mileageOptionObject.$invalid) {
            return;
          } else {
            this.$f7.preloader.show();
            data = {
              ...data,
              ...this.mileageOptionObject,
              mileage: parseFloat(this.mileageOptionObject.mileage),
            };
            const promises = data.payeeIds.map(payeeId => {
              const baseData = { ...data, payeeId };
              delete baseData.payeeIds;
              return this.createExpense(baseData);
            });
            Promise.all(promises)
              .then(() => {
                this.$f7.preloader.hide();
                this.cancel();
              })
              .catch(error => {
                this.$f7.preloader.hide();
                console.error('Error creating expenses:', error);
              });
          }
        } else {
          if (this.v$.remainingOptionObject.$invalid) {
            return;
          } else {
            this.$f7.preloader.show();
            this.handleUploadFile(this.remainingOptionObject.attachments).then(
              res => {
                data = {
                  ...data,
                  ...this.remainingOptionObject,
                  attachments: res,
                };
                const promises = data.payeeIds.map(payeeId => {
                  const baseData = { ...data, payeeId };
                  delete baseData.payeeIds;
                  return this.createExpense(baseData);
                });

                Promise.all(promises)
                  .then(() => {
                    this.$f7.preloader.hide();
                    this.cancel();
                  })
                  .catch(error => {
                    this.$f7.preloader.hide();
                    console.error('Error creating expenses:', error);
                  });
              }
            );
          }
        }
      }
    },

    async edit() {
      this.v$.reportType.$touch();
      if (this.v$.reportType.$invalid) {
        return;
      } else {
        this.v$.$touch();
        if (this.v$.object.$invalid || (await this.existsExpensesName())) {
          return;
        }
        let doc = {
          reportType: this.reportType,
          status: DEFAULT_STATUS_EXPENSE,
          ...this.object,
        };
        if (this.reportType === EXPENSE_TYPE_MILEAGE) {
          if (this.v$.mileageOptionObject.$invalid) {
            return;
          } else {
            this.$f7.preloader.show();
            doc = {
              ...doc,
              ...getDefaultData().remainingOptionObject,
              ...this.mileageOptionObject,
              mileage: parseFloat(this.mileageOptionObject.mileage),
            };
            this.updateExpense({
              id: this.expense.id,
              doc,
            }).then(() => {
              this.$f7.preloader.hide();
              this.cancel();
            });
          }
        } else {
          if (this.v$.remainingOptionObject.$invalid) {
            return;
          } else {
            this.$f7.preloader.show();
            const { attachments } = this.remainingOptionObject;
            let attachsBefore = _.cloneDeep(this.expense.attachments);
            let attachsAfter = _.cloneDeep(attachments);
            let attachsRemove = _.differenceWith(
              attachsBefore,
              attachsAfter,
              _.isEqual
            );
            let attachsAdd = _.differenceWith(
              attachsAfter,
              attachsBefore,
              _.isEqual
            );
            let attachsRemain = _.differenceWith(
              attachsBefore,
              attachsRemove,
              _.isEqual
            );
            //delete file in storage
            attachsRemove.forEach(attach => {
              this.removeAttachment(attach.fullPath);
            });
            //upload db
            this.handleUploadFile(attachsAdd).then(res => {
              doc = {
                ...doc,
                ...this.remainingOptionObject,
                ...getDefaultData().mileageOptionObject,
                attachments: _.union(attachsRemain || [], res),
              };
              this.updateExpense({
                id: this.expense.id,
                doc,
              }).then(() => {
                this.$f7.preloader.hide();
                this.cancel();
              });
            });
          }
        }
      }
    },

    async calculateDistanceAddress() {
      if (this.mileageOptionObject.from && this.mileageOptionObject.to) {
        let result = await this.calculateDistance(
          this.mileageOptionObject.from,
          [this.mileageOptionObject.to]
        );
        this.mileageOptionObject.mileage = result
          ? meterToMile(result.distance).toFixed(2)
          : 0;
      }
    },
  },

  computed: {
    ...mapGetters('common/user', ['userByIds']),
    ...mapGetters('common/expense', ['expense']),
    ...mapGetters('common/app-constant', ['expenseTypeList']),

    reportTypeErrorMessage() {
      if (!this.v$.reportType.$error) return null;
      if (this.v$.reportType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    expensesNameErrorMessage() {
      if (!this.v$.object.expensesName.$error) return null;
      if (this.v$.object.expensesName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    userErrorMessage() {
      if (!this.v$.object.payeeIds.$error) return '';
      if (this.v$.object.payeeIds.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    projectErrorMessage() {
      if (!this.v$.object.projectId.$error) return '';
      if (this.v$.object.projectId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    fromErrorMessage() {
      if (!this.v$.mileageOptionObject.$error) return '';
      if (this.v$.mileageOptionObject.from.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    toErrorMessage() {
      if (!this.v$.mileageOptionObject.$error) return '';
      if (this.v$.mileageOptionObject.to.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    mileageErrorMessage() {
      if (!this.v$.mileageOptionObject.mileage.$error) return null;
      if (this.v$.mileageOptionObject.mileage.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.mileageOptionObject.mileage.mileageRules.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
    amountErrorMessage() {
      if (!this.v$.remainingOptionObject.amount.$error) return '';
      if (this.v$.remainingOptionObject.amount.amountRules.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return '';
    },
  },

  watch: {
    isShow(val) {
      if (val && !this.isEdit) {
        this.object.payeeIds.push(auth.currentUser.uid);
      } else if (val && this.isEdit) {
        const expense = _.cloneDeep(this.expense) || {};
        this.onSelectedProject(expense.projectId);
        this.reportType = expense.reportType;
        this.object = {
          expensesName: expense.expensesName,
          projectId: expense.projectId,
          // userId: expense.userId,
          note: expense.note,
          // reimbursement: expense.reimbursement || false,
          payeeIds: [expense.payeeId],
          buildingOrSection: expense.buildingOrSection || '',
          // expenseType: expense.expenseType || ""
        };
        this.mileageOptionObject = {
          mileage: expense.mileage || 0,
          from: expense.from,
          to: expense.to,
        };
        this.remainingOptionObject = {
          amount: expense.amount || 0,
          attachments: expense.attachments || [],
        };
      }
    },
  },

  created() {
    this.getUserList();
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const mileageRules = () => {
      return !(this.mileageOptionObject.mileage <= 0);
    };
    const amountRules = () => {
      return !(this.remainingOptionObject.amount <= 0);
    };
    return {
      reportType: {
        required,
      },
      object: {
        expensesName: {
          required,
        },
        projectId: {
          required,
        },
        payeeIds: {
          required,
        },
        // payeeName: {
        //   required
        // }
        // expenseType: {
        //   required
        // }
      },
      mileageOptionObject: {
        mileage: {
          required,
          mileageRules,
        },
        from: {
          required,
        },
        to: {
          required,
        },
      },
      remainingOptionObject: {
        amount: {
          amountRules,
        },
      },
    };
  },
};
</script>
<style scoped lang="scss">
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  &_placeholder {
    color: var(--f7-color-placeholder);
  }
}
</style>
