<template>
  <div>
    <!-- layers -->
    <f7-list
      media-list
      class="insulation-layer"
    >
      <!-- Insulation Type -->
      <f7-list-input
        type="select"
        label="Insulation Type"
        :value="layer.insulationType || ''"
        @change="setLayerValue('insulationType', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="layerInsulationTypeErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="thermometer_sun"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select insulation type
        </option>
        <option
          v-for="item in insulationTypeList"
          :key="item.id"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>

      <!-- thickness -->
      <f7-list-item
        v-if="!layer.insulationType"
        @click.native="selectThickness"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Thickness (inches)<required-asterisk />
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          Select thickness (inches)
        </div>
        <input-icon
          slot="media"
          icon="expand"
        ></input-icon>
        <div
          slot="text"
          style="color: red"
        >
          {{ selectThicknessMessage || layerThicknessErrorMessage }}
        </div>
      </f7-list-item>
      <f7-list-input
        v-else
        type="select"
        label="Thickness (inches)"
        :value="layer.thickness || ''"
        @change="setLayerValue('thickness', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="layerThicknessErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="expand"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select thickness (inches)
        </option>
        <option
          v-for="item in insulationThicknessListByType(layer.insulationType)"
          :key="item.id"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>

      <!-- attached -->
      <f7-list-item
        v-if="!layer.insulationType"
        @click.native="selectAttached"
      >
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Attached<required-asterisk />
        </div>
        <div
          class="list-item-title"
          slot="title"
        >
          Select attached
        </div>
        <input-icon
          slot="media"
          icon="square_stack_3d_up"
        ></input-icon>
        <div
          slot="text"
          style="color: red"
        >
          {{ selectAttachedMessage || layerAttachedErrorMessage }}
        </div>
      </f7-list-item>
      <f7-list-input
        v-else
        type="select"
        label="Attached"
        :value="layer.attached"
        @change="setLayerValue('attached', $event.target.value)"
        error-message-force
        validate
        validate-on-blur
        :error-message="layerAttachedErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="square_stack_3d_up"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select attached
        </option>
        <option
          v-for="item in insulationAttachmentTypeListByType(
            layer.insulationType
          )"
          :key="item.id"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>

      <!-- Coverage Rate -->
      <f7-list-input
        v-show="(layer.attached || '').includes('fully-adhered')"
        type="select"
        label="Field Coverage Rate"
        :value="layer.coverageRate || ''"
        @change="setLayerValue('coverageRate', parseFloat($event.target.value))"
        error-message-force
        validate
        validate-on-blur
        :error-message="layerCoverageRateErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="map"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select coverage rate
        </option>
        <option
          v-for="item in [4, 6, 12]"
          :key="item"
          :value="item"
        >
          {{ item }}
        </option>
      </f7-list-input>

      <f7-list-input
        v-show="
          (layer.attached || '').includes('fully-adhered') &&
          subStrateType === 'tpo-structural-concrete'
        "
        type="select"
        label="Perimeter Coverage Rate"
        :value="layer.perimeterCoverageRate || ''"
        @change="
          setLayerValue(
            'perimeterCoverageRate',
            parseFloat($event.target.value)
          )
        "
        error-message-force
        validate
        validate-on-blur
        :error-message="layerPerimeterCoverageRateErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="map"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select perimeter coverage rate
        </option>
        <option
          v-for="item in [4, 6, 12]"
          :key="item"
          :value="item"
        >
          {{ item }}
        </option>
      </f7-list-input>

      <f7-list-input
        v-show="
          (layer.attached || '').includes('fully-adhered') &&
          subStrateType === 'tpo-structural-concrete'
        "
        type="select"
        label="Corner Coverage Rate"
        :value="layer.cornerCoverageRate || ''"
        @change="
          setLayerValue('cornerCoverageRate', parseFloat($event.target.value))
        "
        error-message-force
        validate
        validate-on-blur
        :error-message="layerCornerCoverageRateErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="map"
        ></input-icon>
        <option
          value=""
          hidden
        >
          Select corner coverage rate
        </option>
        <option
          v-for="item in [4, 6, 12]"
          :key="item"
          :value="item"
        >
          {{ item }}
        </option>
      </f7-list-input>

      <f7-list-item
        v-show="(layer.attached || '').includes('mechanically-attached')"
      >
        <div
          slot="inner"
          class="input-label"
        >
          Screws Per Board <required-asterisk></required-asterisk>
        </div>
        <input-icon
          slot="media"
          icon="square_grid_4x3_fill"
        ></input-icon>
        <fasteners-auto-complete-input
          :value="(layer.screwsPerBoard || '').toString()"
          :data="screwsPerBoard"
          @selected="
            setLayerValue('screwsPerBoard', parseFloat($event.target.value))
          "
          placeholder="Select screws per board"
        ></fasteners-auto-complete-input>
        <div
          slot="footer"
          class="item-footer"
        >
          {{ layerScrewsPerBoardErrorMessage }}
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import FastenersAutoCompleteInput from '../inputs/FastenersAutoCompleteInput.vue';

import { mapGetters } from 'vuex';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import InputIcon from '@/components/icons/InputIcon.vue';
import mixins from '../../utility/mixins';
import { screwsPerBoard } from '../../utility/screwsPerBoard';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    layer: Object,
    subStrateType: String,
  },
  components: {
    InputIcon,
    FastenersAutoCompleteInput,
  },

  mixins: [mixins],

  data: () => {
    return {
      selectThicknessMessage: '',
      selectAttachedMessage: '',
      screwsPerBoard,
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    layer: {
      insulationType: {
        required,
      },
      thickness: {
        required,
      },
      attached: {
        required,
      },
      coverageRate: {
        required(val) {
          return (
            (val && (this.layer.attached || '').includes('fully-adhered')) ||
            !(this.layer.attached || '').includes('fully-adhered')
          );
        },
      },
      perimeterCoverageRate: {
        required(val) {
          if (
            (this.layer.attached || '').includes('fully-adhered') &&
            this.subStrateType === 'tpo-structural-concrete'
          ) {
            return !!val;
          }
          return true;
        },
      },
      cornerCoverageRate: {
        required(val) {
          if (
            (this.layer.attached || '').includes('fully-adhered') &&
            this.subStrateType === 'tpo-structural-concrete'
          ) {
            return !!val;
          }
          return true;
        },
      },
      screwsPerBoard: {
        required(val) {
          return (
            (val &&
              (this.layer.attached || '').includes('mechanically-attached')) ||
            !(this.layer.attached || '').includes('mechanically-attached')
          );
        },
      },
    },
  },

  computed: {
    ...mapGetters('common/app-constant', [
      'insulationTypeList',
      'insulationThicknessListByType',
      'insulationAttachmentTypeListByType',
    ]),
    ...mapGetters('estimate/estimate-page/estimate', ['currentEstimate']),
    layerInsulationTypeErrorMessage() {
      if (!this.v$.layer.insulationType.$error) return null;
      if (this.v$.layer.insulationType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerThicknessErrorMessage() {
      if (!this.v$.layer.thickness.$error) return null;
      if (this.v$.layer.thickness.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerAttachedErrorMessage() {
      if (!this.v$.layer.attached.$error) return null;
      if (this.v$.layer.attached.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerCoverageRateErrorMessage() {
      if (!this.v$.layer.coverageRate.$error) return null;
      if (this.v$.layer.coverageRate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerPerimeterCoverageRateErrorMessage() {
      if (!this.v$.layer.perimeterCoverageRate.$error) return null;
      if (this.v$.layer.perimeterCoverageRate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerCornerCoverageRateErrorMessage() {
      if (!this.v$.layer.cornerCoverageRate.$error) return null;
      if (this.v$.layer.cornerCoverageRate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    layerScrewsPerBoardErrorMessage() {
      if (!this.v$.layer.screwsPerBoard.$error) return null;
      if (this.v$.layer.screwsPerBoard.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    setLayerValue(field, value) {
      this.$emit('setLayerValue', { field, value });
    },

    selectAttached() {
      if (!this.layer.insulationType) {
        this.selectAttachedMessage = 'Please select insulation type first';
        return;
      }
    },

    selectThickness() {
      if (!this.layer.insulationType) {
        this.selectThicknessMessage = 'Please select insulation type first';
        return;
      }
    },
  },

  watch: {
    layer: {
      handler(val) {
        if (!_.isEmpty(val) && val.insulationType) {
          this.selectAttachedMessage = '';
          this.selectThicknessMessage = '';
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-label {
  font-size: var(--f7-label-font-size);
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
  &_placeholder {
    color: var(--f7-color-placeholder);
  }
}

.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}

.item-footer {
  color: var(--f7-input-error-text-color);
}

.insulation-layer {
  margin-top: 0px;
  z-index: unset !important;
}
</style>
