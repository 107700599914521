<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-f7="chevron_left"
          @click.native="handleBackBtn"
        >
          Back
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ album.name }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="selectedPhotoIds.length > 0"
          @click="cancelSelect"
        >
          Cancel
        </f7-link></f7-nav-right
      >
    </f7-navbar>
    <photos
      :projectPhotoListGroup="projectPhotoListGroup"
      :selectionMode="selectionMode"
      :selectedPhotoIds="selectedPhotoIds"
      :loading="loadingPhoto"
      :isClientView="true"
      :photoFilter="photoFilter"
      :endDateFilterErrorMessage="endDateFilterErrorMessage"
      :hoveredPhotoIds="hoveredPhotoIds"
      @clickPhoto="clickPhoto"
      @handleSelected="handleSelected"
      @handleSelectAllDay="handleSelectAllDay"
      @cancelSelected="cancelSelect"
      @onFilterDate="onFilterDate"
      @handleMouseEnter="createTooltips"
    ></photos>
    <template v-if="selectedPhotoIds.length > 0">
      <f7-fab
        :class="`tab-btn tab-btn_1 download-tooltip-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleDownload"
      >
        <f7-icon f7="cloud_download"></f7-icon>
      </f7-fab>
    </template>
    <photo-browser
      :photos="photoList"
      :isClientView="true"
      @clickDownload="clickDownload"
      theme="dark"
      ref="pageDark"
    ></photo-browser>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Photos from '@/plugins/photos/components/list/Photos.vue';
import PhotoBrowser from '../components/popups/PhotoBrowser.vue';
import moment from 'moment';
import { groupBy } from '@/utility/filter-tools';
import { sortLatest } from '@/utility/date-time-tool';
import _ from 'lodash';
import { uuid } from 'vue-uuid';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    Photos,
    PhotoBrowser,
  },
  data: () => {
    return {
      loadingPhoto: null,
      selectionMode: false,
      selectedPhotoIds: [],
      projectPhotoList: [],
      hoveredPhotoIds: [],
      endDateFilterErrorMessage: '',
      album: {},
      uuid: uuid.v4(),
    };
  },
  props: {
    albumId: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('photo/photo', [
      'getAlbumPhotoByTenant',
      'setPhotoFilterField',
      'downloadPhoto',
    ]),
    ...mapActions('photo/album', ['getAlbumByTenant']),

    clickPhoto(id, isShowPhoto = false) {
      this.destroyTooltips();
      if (isShowPhoto) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.download-tooltip-${this.uuid}`,
          text: 'Download photo/video',
        },
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button',
          text: tooltip.text,
        });
        document
          .querySelector(tooltip.targetEl)
          ?.addEventListener('mouseenter', () => {
            this.$f7.tooltip.get(tooltip.targetEl)?.show();
          });
      });
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedPhotoIds = [];
      this.$nextTick(() => {
        this.createTooltips();
      });
    },
    onSelect(id) {
      if (this.selectedPhotoIds.includes(id)) {
        var index = this.selectedPhotoIds.indexOf(id);
        if (index > -1) {
          this.selectedPhotoIds.splice(index, 1);
          this.hoveredPhotoIds.splice(index, 1);
        }
      } else {
        this.selectedPhotoIds.push(id);
      }
    },
    onFilterDate({ field, value }) {
      if (
        (_.isEmpty(value) && _.isEmpty(this.photoFilter[field])) ||
        (!_.isEmpty(value) &&
          !_.isEmpty(this.photoFilter[field]) &&
          moment(value[0]).isSame(moment(this.photoFilter[field][0]), 'day')) ||
        (this.$f7 && !this.$f7.views.main.router.currentRoute.params.albumId)
      )
        return;

      this.setPhotoFilterField({ field, value });
      const { startDate, endDate } = this.photoFilter;
      let fromDate = '';
      let toDate = '';
      if (!_.isEmpty(value) && !_.isEmpty(startDate) && !_.isEmpty(endDate)) {
        if (field === 'endDate') {
          fromDate = moment(startDate[0]).format('YYYY-MM-DD');
          toDate = moment(value[0]).format('YYYY-MM-DD');
        } else {
          fromDate = moment(value[0]).format('YYYY-MM-DD');
          toDate = moment(endDate[0]).format('YYYY-MM-DD');
        }
      }
      if (fromDate && toDate && moment(toDate).isBefore(fromDate, 'day')) {
        this.endDateFilterErrorMessage =
          VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
        return;
      } else {
        this.endDateFilterErrorMessage = '';
      }
      this.loadPhotos();
    },
    async loadPhotos() {
      this.$f7.preloader.show();
      const albumId = this.albumIdComputed;
      const tenant = this.tenantIdComputed;
      let photos = await this.getAlbumPhotoByTenant({
        id: albumId,
        tenant: tenant,
      });
      this.projectPhotoList = photos;
      this.$f7.preloader.hide();
    },
    clickDownload(id) {
      const existedPhotoId = this.selectedPhotoIds.find(i => i === id);
      if (!existedPhotoId) {
        this.selectedPhotoIds.push(id);
      }
      this.handleDownload();
    },
    handleSelectAllDay(data) {
      this.createTooltips();
      if (data.event) {
        const photosIds = (data.ids || []).filter(
          i => !this.selectedPhotoIds.includes(i)
        );
        this.selectedPhotoIds = this.selectedPhotoIds.concat(photosIds);
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photosIds);
        this.selectionMode = true;
      } else {
        this.selectedPhotoIds = this.selectedPhotoIds.filter(
          i => !data.ids.includes(i)
        );
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !data.ids.includes(i)
        );
      }
    },
    handleBackBtn() {
      this.destroyTooltips();
      this.$f7router.navigate(
        `/share-photo/${this.$f7route.params.tenantId}/${this.$f7route.params.projectId}?currentTab=album`,
        {
          pushState: true,
          reloadAll: true,
        }
      );
    },
    destroyTooltips() {
      const tooltips = [
        { targetEl: '.download-tooltip', text: 'Download photo' },
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
      });
    },
    handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading files...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const allPhotos = this.projectPhotoListGroup
        .map(group => group.data)
        .flat();
      const selectedPhotos = allPhotos.filter(photo =>
        this.selectedPhotoIds.includes(photo.id)
      );

      selectedPhotos.forEach(i =>
        this.downloadPhoto({
          fullPath: i.photoFullPath,
          name: i.photoName,
          photoUrl: i.photoUrl,
        })
      );
      this.cancelSelect();
      this.createTooltips();
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.selectedPhotoIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.selectedPhotoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    async initDataAlbum() {
      const albumId = this.albumIdComputed;
      const tenant = this.tenantIdComputed;

      this.loadingPhoto = true;

      Promise.all([
        this.getAlbumPhotoByTenant({
          id: albumId,
          tenant: tenant,
        }),
        this.getAlbumByTenant({
          id: albumId,
          tenant: tenant,
        }),
      ])
        .then(([photos, album]) => {
          this.projectPhotoList = photos;
          this.album = album;
        })
        .finally(() => {
          this.loadingPhoto = false;
        });
    },
  },
  computed: {
    ...mapGetters('photo/photo', ['photoFilter']),
    photoList() {
      return this.projectPhotoList.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
      }));
    },
    projectPhotoListGroup() {
      return groupBy(sortLatest(this.projectPhotoList), i =>
        i.createdAt
          ? moment(i.createdAt.toDate()).format('dddd, MMMM Do YYYY')
          : ''
      );
    },
    albumIdComputed() {
      return !this.$f7route.params.albumId
        ? this.albumId
        : this.$f7route.params.albumId;
    },
    tenantIdComputed() {
      return !this.$f7route.params.albumId
        ? this.tenantId
        : atob(this.$f7route.params.tenantId);
    },
    ...mapGetters('common/app-constant', ['tenantId']),
  },
  watch: {
    albumId: {
      handler(val) {
        if (val) {
          this.initDataAlbum();
        }
      },
      deep: true,
    },
  },
  async created() {
    if (!this.$f7route.params.albumId || !this.$f7route.params.tenantId) return;
    this.initDataAlbum();
  },
};
</script>
<style lang="scss" scoped>
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) + 16px
    );
  }
}
</style>
