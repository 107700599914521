<template>
  <f7-popup
    :opened="popupOpened"
    :tablet-fullscreen="true"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="close">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Photos</f7-nav-title>
      </f7-navbar>
      <div class="photo-browser-container">
        <div class="image-viewer">
          <div
            v-show="isLoading"
            class="loader"
          >
            <f7-preloader
              :size="38"
              color="var(--f7-list-item-title-text-color);"
            ></f7-preloader>
          </div>
          <div class="image-container">
            <f7-swiper ref="imageSwiper">
              <f7-swiper-slide
                v-for="(photo, idx) in photos"
                :key="idx"
              >
                <video
                  ref="videoPlayer"
                  v-show="photo.url && isVideo(photo.url)"
                  :id="idx"
                  class="modal-content"
                  @load="onImgLoad"
                  controls
                  height="100%"
                  width="100%"
                  :src="photo.url"
                  :key="idx"
                ></video>
                <img
                  v-show="photo.url && isImage(photo.url)"
                  :id="idx"
                  class="modal-content"
                  ref="zoomImage"
                  :src="photo.url"
                  @load="onImgLoad"
                />
              </f7-swiper-slide>
            </f7-swiper>
          </div>
          <f7-segmented
            v-if="photo.url && isImage(photo.url)"
            tag="p"
            class="btn-zoom"
            :style="!$device.desktop && 'top: 0'"
          >
            <f7-button
              outline
              @click="zoomIn"
              >+</f7-button
            >
            <f7-button
              outline
              @click="zoomOut"
              >-</f7-button
            >
          </f7-segmented>
          <div class="navigator-bar">
            <div class="previous">
              <span :class="{ 'gray-text': index == 0 }">
                <i
                  class="icon f7-icons"
                  @click="previous"
                  >arrowtriangle_left</i
                >
              </span>
            </div>
            <div class="count-photo">
              <span>{{ `${index + 1} of ${photos.length}` }} </span>
            </div>
            <div class="next">
              <span :class="{ 'gray-text': index == photos.length - 1 }">
                <i
                  class="icon f7-icons"
                  @click="next"
                  >arrowtriangle_right</i
                >
              </span>
            </div>
          </div>
        </div>
        <f7-col
          v-show="
            !isClientView ||
            ((photo || {}).comments && (photo || {}).comments.length > 0)
          "
          class="comment-section"
        >
          <comment
            ref="commentPopupDesktop"
            :photoId="(photo || {}).id"
            :comments="(photo || {}).comments"
            :isClientView="isClientView"
          ></comment>
        </f7-col>
      </div>
      <!-- mobile element -->
      <div
        v-show="
          !isClientView ||
          ((photo || {}).comments && (photo || {}).comments.length > 0)
        "
        class="comment-button"
        @click="openCommentPopup"
      >
        <i
          class="icon f7-icons"
          style="font-size: 22px"
          >bubble_left_bubble_right</i
        >
      </div>
      <f7-popup
        class="demo-popup"
        :opened="popupCommentOpened"
        @popup:closed="closedCommentPopup"
      >
        <f7-page>
          <f7-navbar title="Comments">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <comment
              ref="commentPopupMobile"
              :photoId="(photo || {}).id"
              :comments="(photo || {}).comments"
              :isClientView="isClientView"
            ></comment>
          </f7-block>
        </f7-page>
      </f7-popup>
      <f7-fab
        v-if="photo.photoFullPath && isImage(photo.photoFullPath)"
        :class="`tab-btn tab-btn_3 edit-photo-tooltip-${uuid}`"
        :style="
          $device.desktop
            ? 'left: calc(100% - 29%) !important;'
            : 'left: calc(100% - 18%) !important;'
        "
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="$emit('clickEdit', (photo || {}).id)"
      >
        <f7-icon f7="pencil_outline"></f7-icon>
      </f7-fab>
      <f7-fab
        :class="`tab-btn tab-btn_2 download-photo-tooltip-${uuid}`"
        :style="
          $device.desktop
            ? 'left: calc(100% - 29%) !important;'
            : 'left: calc(100% - 18%) !important;'
        "
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="$emit('clickDownload', (photo || {}).id)"
      >
        <f7-icon f7="cloud_download"></f7-icon>
      </f7-fab>
      <f7-fab
        :class="`tab-btn tab-btn_1 delete-photo-tooltip-${uuid}`"
        :style="
          $device.desktop
            ? 'left: calc(100% - 29%) !important;'
            : 'left: calc(100% - 18%) !important;'
        "
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="onClick((photo || {}).id)"
      >
        <f7-icon f7="trash"></f7-icon>
      </f7-fab>
    </f7-page>
  </f7-popup>
</template>

<script>
import Comment from '../comment/Comment.vue';
import { mapActions, mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import {
  isVideo,
  isImage,
  initZoomImage,
  zoomIn,
  zoomOut,
} from '@/utility/common';
export default {
  props: {
    photos: { type: Array, default: () => [] },
    isClientView: { type: Boolean, default: false },
  },
  components: {
    Comment,
  },
  mounted() {
    this.createTooltips();
    this.$refs.imageSwiper.swiper.on('activeIndexChange', () => {
      this.onSlideChange();
    });
  },
  data() {
    return {
      popupOpened: false,
      popupCommentOpened: false,
      index: 0,
      isLoading: false,
      imageHeight: 0,
      selectedPhotoIds: [],
      uuid: uuid.v4(),
    };
  },
  computed: {
    ...mapGetters('swimlane/photo', ['projectPhotoSelected']),
    photo() {
      return (
        this.photos &&
        this.photos.length > this.index &&
        this.photos[this.index]
      );
    },
  },
  methods: {
    ...mapActions('swimlane/photo', ['deleteProjectPhoto', 'removePhoto']),

    isVideo,

    isImage,
    initZoomImage,
    zoomIn,
    zoomOut,
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        { targetEl: `.edit-photo-tooltip-${this.uuid}`, text: 'Edit photo' },
        {
          targetEl: `.delete-photo-tooltip-${this.uuid}`,
          text: 'Delete photo/video',
        },
        {
          targetEl: `.download-photo-tooltip-${this.uuid}`,
          text: 'Download photo/video',
        },
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button-photo',
          text: tooltip.text,
        });
        document
          .querySelector(tooltip.targetEl)
          ?.addEventListener('mouseenter', () => {
            this.$f7.tooltip.get(tooltip.targetEl)?.show();
          });
      });
    },
    onImgLoad() {
      this.isLoading = false;
    },
    open(index) {
      this.index = index;
      this.$refs.imageSwiper.swiper.slideTo(index);
      this.popupOpened = true;
      this.$nextTick(() => {
        this.createTooltips();
        const imgElement = this.$refs.zoomImage[index];
        this.initZoomImage(imgElement);
      });
    },
    next() {
      this.$refs.imageSwiper.swiper.slideNext();
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.forEach(video => {
          video.pause();
        });
      }
      this.$refs.commentPopupDesktop.clearComment();
      this.index = this.$refs.imageSwiper.swiper.activeIndex;
      this.createTooltips();
    },
    previous() {
      this.$refs.imageSwiper.swiper.slidePrev();
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.forEach(video => {
          video.pause();
        });
      }
      this.$refs.commentPopupDesktop.clearComment();
      this.index = this.$refs.imageSwiper.swiper.activeIndex;
      this.createTooltips();
    },
    close() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.forEach(video => {
          video.pause();
        });
      }
      this.$refs.commentPopupDesktop.clearComment();
      this.popupOpened = false;
    },
    closedCommentPopup() {
      this.$refs.commentPopupMobile.clearComment();
      this.popupCommentOpened = false;
    },
    openCommentPopup() {
      this.popupCommentOpened = true;
    },
    onSlideChange() {
      this.index = this.$refs.imageSwiper.swiper.activeIndex;
      const imgElement = this.$refs.zoomImage[this.index];
      this.initZoomImage(imgElement);
    },
    onClick(photoId) {
      this.selectedPhotoIds.push(photoId);
      this.handleDetele();
    },
    handleDetele() {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Delete photo/video',
        content: 'Are you sure you want to delete ?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const photos = app.projectPhotoSelected(app.selectedPhotoIds);
            photos.forEach(i => {
              if (i.thumbnailFullPath) app.removePhoto(i.thumbnailFullPath);
              if (i.photoFullPath) app.removePhoto(i.photoFullPath);
            });
            app.selectedPhotoIds.forEach(i => {
              app.deleteProjectPhoto(i);
            });
            _sefl.app.dialog.close();
            app.close();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-browser-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.image-viewer {
  flex: 7.5;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.comment-section {
  height: 100%;
  overflow: scroll;
  flex: 2.5;
}
.image-container {
  width: 100%;
  height: 90%;
  display: flex;
}
.swiper-container .swiper-wrapper .swiper-slide {
  display: flex !important;
}
.swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active {
  width: 100% !important;
}
.modal-content {
  margin: auto;
  display: block;
  max-width: 1000px;
  max-height: 700px;
}
.modal-content {
  animation-name: zoom;
  animation-duration: 0.6s;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.445);
}

.navigator-bar {
  display: flex;
  height: 62px;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 18px;
  background: rgba(27, 27, 27, 0.8);
  color: white;
}
.navigator-bar div {
  flex: 1;
}
.previous {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
}
.count-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.next {
  user-select: none;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.gray-text {
  color: gray;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) + 16px
    );
  }
  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 2
    );
  }
  &_3 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 3
    );
  }
}
.btn-zoom {
  position: absolute;
  width: 80px;
  top: 10px;
  right: 20px;
  z-index: 1000;
  .button {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
