export default {
  proposalTemplateList: state => state.proposalTemplateList || [],

  proposalTemplate: state => state.proposalTemplate || {},

  searchText: state => {
    return state.searchText;
  },

  saveFunc: state => state.saveFunc,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
};
