<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{ cancelPopup }}</f7-link>
        </f7-nav-left>
        <f7-nav-title>Send Invoice Email</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="send()">Send </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list
        media-list
        style="z-index: 102; width: calc(100% - 6px)"
      >
        <!-- from -->
        <!-- <email-auto-complete-input
          :options="emailList"
          :value="composer.from"
          @change="
            value => {
              composer.from = value;
              v$.$touch();
            }
          "
          title="From"
          placeholder="Enter email"
        >
          <f7-icon slot="icon" f7="envelope"></f7-icon>
          <span slot="error-message">{{ toEmailErrorMessage }}</span>
        </email-auto-complete-input> -->
        <!-- to -->
        <email-auto-complete-input
          :options="emailList"
          :value="composer.to"
          @change="
            value => {
              composer.to = value;
              v$.composer.to.$touch();
            }
          "
          title="To"
          placeholder="Enter email"
          isRequired
        >
          <f7-icon
            slot="icon"
            f7="envelope"
          ></f7-icon>
          <span slot="error-message">{{ toEmailErrorMessage }}</span>
        </email-auto-complete-input>
        <!-- cc -->
        <email-auto-complete-input
          :options="emailList"
          :value="composer.cc"
          @change="
            value => {
              composer.cc = value;
              v$.composer.cc.$touch();
            }
          "
          title="Cc"
          placeholder="Enter email"
        >
          <f7-icon
            slot="icon"
            f7="square_on_square"
          ></f7-icon>
          <span slot="error-message">{{ ccEmailErrorMessage }}</span>
        </email-auto-complete-input>
        <!-- bcc -->
        <!-- <email-auto-complete-input
          :options="emailList"
          :value="composer.bcc"
          @change="
            value => {
              composer.bcc = value;
              v$.$touch();
            }
          "
          title="Bcc"
          placeholder="Enter email"
        >
          <f7-icon slot="icon" f7="square_list"></f7-icon>
          <span slot="error-message">{{ bccEmailErrorMessage }}</span>
        </email-auto-complete-input> -->
        <!-- subject -->
        <div class="email-input subject">
          <div class="email-input-icon">
            <f7-icon f7="square_favorites_alt"></f7-icon>
          </div>
          <div class="email-input-content">
            <div class="email-input-lable">Subject<required-asterisk /></div>
            <div class="email-input-wrap">
              <f7-input
                type="textarea"
                placeholder="Enter subject"
                resizable
                clear-button
                @blur="v$.composer.subject.$touch()"
                @input="composer.subject = $event.target.value"
                :value="composer.subject"
              />
            </div>
            <div class="email-error-message">{{ subjectErrorMessage }}</div>
          </div>
        </div>
      </f7-list>
      <!-- Text area -->
      <f7-block-header class="display-flex justify-content-space-between">
        <div>Message<required-asterisk /></div>
        <div class="error-message">{{ messageErrorMessage }}</div>
      </f7-block-header>
      <f7-text-editor
        style="height: 500px; z-index: 10"
        ref="emailContent"
        :value="composer.message"
        placeholder="Enter message..."
        @texteditor:change="onChangeMessage"
        @texteditor:blur="v$.composer.message.$touch()"
        :buttons="buttons"
        :custom-buttons="customButtons"
      ></f7-text-editor>
      <attachment-input
        :projectId="invoiceDetail.projectId"
        attachment-type="email-attachment"
        add-button-title="Add an Attachment"
        :value="newAttachmentFiles"
        @input="newAttachmentFiles = $event"
      ></attachment-input>
    </f7-page>
    <select-color-popup ref="selectColor" />
  </f7-popup>
</template>

<script>
import EmailAutoCompleteInput from '@/components/inputs/EmailAutoCompleteInput';
import invoiceMixins from '../../mixins/invoice-mixin';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { currencyUSD } from '@/utility/config';
import axiosService from '@/services/axios.service';
import { firebase } from '@/services/firebase.service';
import AttachmentInput from '@/components/inputs/AttachmentInput.vue';
import moment from 'moment';
import _ from 'lodash';
import {
  buttonsForTextFormatBar,
  SENDER_CONFIG_EMAIL,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import SelectColorPopup from '@/plugins/proposal/components/popup/SelectColorPopup.vue';
import { customButtonForTextFormatBar } from '@/utility/common';

export default {
  name: 'SendEmailPopup',

  components: {
    EmailAutoCompleteInput,
    AttachmentInput,
    SelectColorPopup,
  },
  mixins: [invoiceMixins],
  props: {
    cancelPopup: {
      type: String,
      default: 'Close',
    },
  },
  data: () => {
    return {
      popupOpened: false,
      invoiceDetail: {},
      composer: {
        attachments: [],
        message: '',
        subject: '',
        from: '',
        to: '',
        cc: '',
        bcc: '',
        threadId: false,
      },
      newAttachmentFiles: [],
      currentRange: null,
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar,
    };
  },
  computed: {
    ...mapGetters('common/common', ['emailList']),
    ...mapGetters('invoices/invoice-template', ['invoiceDefaultTemplate']),
    ...mapGetters('setting/app/profile', ['user']),
    toEmailErrorMessage() {
      if (!this.v$.composer.to.$error) return null;
      if (this.v$.composer.to.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.composer.to.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    ccEmailErrorMessage() {
      if (!this.v$.composer.cc.$error) return null;
      if (this.v$.composer.cc.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    bccEmailErrorMessage() {
      if (!this.v$.composer.bcc.$error) return null;
      if (this.v$.composer.bcc.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    subjectErrorMessage() {
      if (!this.v$.composer.subject.$error) return null;
      if (this.v$.composer.subject.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    messageErrorMessage() {
      if (!this.v$.composer.message.$error) return null;
      if (this.v$.composer.message.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  methods: {
    ...mapActions('common/common', ['getEmailList']),
    ...mapActions('invoices/company', ['getCompanyById']),
    ...mapActions('invoices/invoice-template', [
      'getInvoiceEmailTemplate',
      'bindInvoiceTemplateList',
      'createEmailNotification',
    ]),
    ...mapActions('invoices/invoices', [
      'updateInvoice',
      'getInvoiceById',
      'getInvoiceByGroupId',
    ]),
    onChangeMessage(value) {
      if (value === '<br>') {
        this.composer.message = '';
        this.$refs.emailContent.f7TextEditor.setValue('');
      } else {
        this.composer.message = value;
      }
    },
    send() {
      if (!this.validate()) return;
      let email = {
        to: this.composer.to,
        from: this.composer.from,
        cc: this.composer.cc,
        bcc: this.composer.bcc,
        subject: this.composer.subject,
        template: 'invoice',
        htmlContent: this.composer.message,
        attachments: this.getAttachment().map(r => ({
          fileName: r.fileName,
          path: r.url,
        })),
        scheduleSendingTime: firebase.firestore.Timestamp.now(),
        isSent: false,
        numberOfAttempt: 0,
      };
      this.sendMail(email);
      //send email
    },
    getAttachment() {
      return this.composer.attachments.concat(this.newAttachmentFiles);
    },
    async sendMail(email) {
      try {
        this.$f7.preloader.show();
        const data = await this.createEmailNotification(email);
        if (data) {
          await axiosService.post('/notification/trigger-email-service', {
            sender: this.composer.from,
          });
          this.showToastMessage('Email Sent!');
          await this.updateInvoice({
            id: this.invoiceDetail.id,
            doc: {
              status: 'in-sent',
            },
          });
          await this.getInvoiceByGroupId(this.invoiceDetail.invoiceGroupId);
          this.cancel();
          this.$emit('closePopup');
          this.$f7.preloader.hide();
        }
      } catch (error) {
        console.error('[Send Email]', error);
        this.$f7.preloader.hide();
        throw new Error(error.message);
      }
    },
    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: 'Cancel',
              color: 'gray',
            },
            {
              text: 'OK',
              cssClass: 'primary',
            },
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.emailContent;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById('insert-link');
                const inputValue = inputElement.value;
                document.execCommand('createLink', true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          },
        })
        .open();
    },
    onFileChange() {
      this.uploadFiles = this.$refs.fileInput.files;
    },

    convertDate(date) {
      if (!date) return '';

      if (date.seconds || date._seconds) {
        const convertDate = moment.unix(date.seconds || date._seconds);
        return convertDate.format('MM/DD/YYYY');
      }
      if (typeof date === 'string') {
        return date;
      }

      if (date instanceof Date) {
        return moment(date).format('MM/DD/YYYY');
      }
      return '';
    },

    async open(invoiceInfo) {
      this.$f7.preloader.show();
      this.popupOpened = true;
      this.invoiceDetail = _.cloneDeep(invoiceInfo);

      //get invoice by id in the case branch new invoice
      if (_.isEmpty(invoiceInfo.invoiceNumber)) {
        this.invoiceDetail = await this.getInvoiceById(invoiceInfo.id);
      }
      let promises = [];

      if (!this.emailList || this.emailList.length == 0) {
        promises.push(this.getEmailList());
      }
      if (!this.invoiceDefaultTemplate) {
        promises.push(this.bindInvoiceTemplateList());
      }

      this.composer.to = this.invoiceDetail.clientEmail || '';
      this.composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      // popuplate info of user
      this.composer.cc = this.user.email || '';
      //parse email template info

      const res = await Promise.all([
        this.getInvoiceEmailTemplate(),
        ...promises,
      ]);
      const templateEmail = res[0] || {};

      //tenantid to base64
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'invoice',
        entityId: this.invoiceDetail.id,
      });
      const getInvoiceDate = this.convertDate(this.invoiceDetail.invoiceDate);

      const getDueDate = this.convertDate(this.invoiceDetail.dueDate);
      let valueObject = {
        logo_url: this.invoiceDefaultTemplate.logo || '',
        invoice_amount:
          currencyUSD(this.totalInvoice(this.invoiceDetail)) || '',
        client_company_name: this.invoiceDetail.clientName || '',
        roofing_company_name: this.invoiceDetail.roofingCompanyName || '',
        roofing_company_address: this.invoiceDetail.roofingCompanyAddress || '',
        roofing_company_phone: this.invoiceDetail.roofingCompanyPhone || '',
        invoice_number: this.invoiceDetail.invoiceNumber || '',
        invoice_date: getInvoiceDate,
        due_date: getDueDate,
        detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/invoices-view/?token=${response.data}&id=${this.invoiceDetail.id}`,
        project_name: this.invoiceDetail.jobName || '',
      };

      this.composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );

      this.composer.subject = this.parseEmailTemplate(
        templateEmail.subject,
        valueObject
      );
      this.$f7.preloader.hide();

      //Attach PDF invoice file.
      // let base64 = await this.downloadPDF(invoice, "base64");
      // //attachment pdf invoice
      // this.composer.attachments = [
      //   {
      //     filename: `${invoice.invoiceNumber}.pdf`,
      //     content: base64,
      //     encoding: "base64"
      //   }
      // ];
    },
    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, 'g');
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },
    cancel() {
      this.popupOpened = false;
      this.newAttachmentFiles = [];
      this.v$.$reset();
    },
    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return true;
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      composer: {
        subject: {
          required,
        },
        message: {
          required,
        },
        to: {
          required,
          commonEmailRule: email,
        },
        cc: {
          commonEmailRule: email,
        },
        bcc: {
          commonEmailRule: email,
        },
      },
    };
  },
};
</script>
<style land="scss" scoped>
::v-deep .text-editor-toolbar {
  z-index: inherit;
}
.error-message {
  font-size: 12px;
  color: var(--f7-theme-color);
}
</style>
