<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>General Settings</f7-nav-title>
      <f7-nav-right
        ><f7-link
          v-if="isOwner || isAdminGroup"
          @click.stop="openEditPopup"
          >Edit</f7-link
        >
      </f7-nav-right>
    </f7-navbar>
    <f7-list class="justify-content-center align-items-center display-flex">
      <img
        class="image-logo"
        :src="setting.logoUrl"
        alt=""
      />
    </f7-list>
    <!-- link="/settings/general/company-name" -->
    <f7-block-title>Company Information</f7-block-title>

    <f7-list>
      <f7-list-item
        :title="setting ? setting.companyName || '' : ''"
        header="Company Name"
      >
        <f7-icon
          color="primary"
          f7="briefcase"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? setting.address || '' : ''"
        header="Address"
      >
        <f7-icon
          color="primary"
          f7="house"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? setting.phoneNumber || '' : ''"
        header="Phone Number"
      >
        <f7-icon
          color="primary"
          f7="phone"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? setting.companyEmail || '' : ''"
        header="Email"
      >
        <f7-icon
          color="primary"
          f7="envelope"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? setting.companyTaxCode || '' : ''"
        header="Tax Code"
      >
        <f7-icon
          color="primary"
          f7="number_square"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? setting.companyWebsite || '' : ''"
        header="Company Website"
      >
        <f7-icon
          color="primary"
          f7="globe"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <f7-list-item
        :title="setting ? displayTimeZone : ''"
        header="Time Zone"
      >
        <f7-icon
          color="primary"
          f7="clock"
          slot="media"
        ></f7-icon>
      </f7-list-item>
      <!-- <f7-list-item title="(+1) 324-345-2324" header="Phone Number">
        <f7-icon color="primary" f7="phone_circle_fill" slot="media"></f7-icon>
      </f7-list-item>
      <f7-list-item title="info@swadley.com" header="Email"
        ><f7-icon
          color="primary"
          f7="envelope_circle_fill"
          slot="media"
        ></f7-icon
      ></f7-list-item>
      <f7-list-item
        title="Dallas Statler Hilton, 1919 Jackson St, Dallas, TX 75201"
        header="Address"
        ><f7-icon color="primary" f7="placemark_fill" slot="media"></f7-icon
      ></f7-list-item> -->
    </f7-list>
    <!-- link="/settings/general/company-name" -->
    <f7-block-title>Third-party integration</f7-block-title>

    <f7-list
      media
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
    >
      <!-- Quickbooks -->
      <f7-list-item
        :title="userInfoQB"
        footer=""
        header="Quickbooks"
      >
        <f7-icon slot="media" >
          <img
            :src="qbIcon"
            alt=""
          />
        </f7-icon>
        <div
          slot="after"
          class="display-flex"
          style="gap: 8px"
        >
          <f7-button
            v-if="isQBLoggedIn"
            fill
            @click="qbSyncData()"
            >Sync Data</f7-button
          >
          <f7-button
            outline
            @click="qbLoginOrLogout()"
            >{{ !isQBLoggedIn ? 'Login' : 'Logout' }}</f7-button
          >
        </div>
      </f7-list-item>
      <!-- Stack CT -->
      <f7-list-item
        :title="userInfoStackCT"
        footer=""
        header="Stack CT"
      >
        <f7-button
          slot="after"
          outline
          @click="stackLoginOrLogout()"
          >{{ !isStackLoggedIn ? 'Login' : 'Logout' }}</f7-button
        >
        <f7-icon
          color="primary"
          f7="arrow_2_squarepath"
          slot="media"
        ></f7-icon>
      </f7-list-item>

      <!-- Gmail -->
      <f7-list-item
        :footer="senderGmailInfo ? `${senderGmailInfo.displayName || ''} <${senderGmailInfo.email || ''}>` : 'Authorize Gmail for sending emails on your behalf.'"
        header="Gmail"
      >
        <f7-icon slot="media">
          <img
            :src="gmailIcon"
            alt=""
            class="gmail-icon"
          />
        </f7-icon>
        <div
          slot="after"
          class="display-flex"
          style="gap: 8px"
        >
          <f7-button
            v-show="isSenderGmailConnected === false"
            outline
            @click="connectGmailSender()"
            >Authorize</f7-button
          >
          <f7-button
            v-show="isSenderGmailConnected === true"
            fill
            @click="revokeGmailSender()"
            >Revoke</f7-button
          >
        </div>
      </f7-list-item>
    </f7-list>
    <f7-block>
      <f7-block-header>RooferIntel app version: {{ APP_VERSION }}</f7-block-header>
    </f7-block>

    

    <GeneralEditPopup ref="editPopup"></GeneralEditPopup>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import quickbooksService from '@/services/quickbooks.service';
import stackService from '@/services/stackct.service';
import emailSenderService from '@/services/notification.service'
import _ from 'lodash';
import Menu from '../../../../components/menu/Menu.vue';
import GeneralEditPopup from '../../components/popup/GeneralEditPopup.vue';
import qbIcon from '@/assets/img/qb-icon.svg';
import gmailIcon from '@/assets/img/gmail-icon-2020.png';

export default {
  components: {
    Menu,
    GeneralEditPopup,
  },

  data() {
    return {
      qbIcon,
      gmailIcon,
      isStackLoggedIn: false,
      isQBLoggedIn: false,
      isSenderGmailConnected: false,
      userInfoQB: '',
      userInfoStackCT: '',
      senderGmailInfo: null,
      APP_VERSION: __APP_VERSION__
    };
  },

  created() {
    this.$f7.preloader.show();
    const promises = [];
    
    promises.push(emailSenderService.checkRefreshToken({tenantId: this.tenantId}).then(({data}) => {
      if (data.data) {
        const {isConnected, userInfo} = data.data
        this.isSenderGmailConnected = isConnected
        this.senderGmailInfo = userInfo
      }
    }))

    if (_.isEmpty(this.timeZoneList)) {
      promises.push(this.bindConstantTypeList());
    }
    if (_.isEmpty(this.setting)) {
      promises.push(this.bindSetting(this.tenantId));
    }

    promises.push(
      stackService.checkAuth().then(result => {
        this.isStackLoggedIn = result.isAuthorized;
        if (this.isStackLoggedIn) {
          return stackService.getUserInfo().then(res => {
            if (res.success) {
              const emailInfo = res.data.collection.items[0].data.find(
                r => r.name === 'Email'
              );
              this.userInfoStackCT = emailInfo.value;
            }
          });
        }
      })
    );
    promises.push(
      quickbooksService.checkAuth().then(result => {
        this.isQBLoggedIn = result.success;
        if (this.isQBLoggedIn) {
          return quickbooksService.getUserInfo().then(res => {
            this.userInfoQB = res.email;
          });
        }
      })
    );
    Promise.all(promises).finally(() => {
      this.$f7.preloader.hide();
    });
  },

  computed: {
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('setting/profile-page/app-constant', ['timeZoneList']),
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),
    ...mapGetters('common/app-constant', ['tenantId']),

    displayTimeZone() {
      return (
        this.timeZoneList.find(r => r.value === this.setting.timezone) || {}
      ).displayName;
    },

    isOwner() {
      if (
        !_.isEmpty(this.currentUser) &&
        !_.isEmpty(this.currentUser.customClaims)
      ) {
        return this.currentUser.customClaims.role === 'owner';
      }

      return false;
    },
  },

  methods: {
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('setting/profile-page/app-constant', [
      'bindConstantTypeList',
    ]),

    ...mapActions('setting/app/group', ['checkAdminGroup']),

    qbLoginOrLogout() {
      if (this.isQBLoggedIn) {
        this.logoutQB();
      } else {
        this.loginQB();
      }
    },

    loginQB() {
      quickbooksService
        .getAuthUri('settings/general')
        .then(uri => {
          window.location.href = uri;
        })
        .catch(error => {
          this.showToast(error);
        });
    },

    logoutQB() {
      let app = this;
      this.$ri.dialog.openInfoDialog({
        title: 'Logout Quickbooks Account',
        content: 'Are you sure you want to logout Quickbooks account?',
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            quickbooksService
              .logout()
              .then(result => {
                if (result.success) {
                  app.isQBLoggedIn = false;
                  app.userInfoQB = '';
                  app.showToast('Logout Quickbooks successful!');
                }
              })
              .catch(() => {
                app.showToast('Logout failed');
              });
          }
        },
      });
    },

    stackLoginOrLogout() {
      if (this.isStackLoggedIn) {
        this.logoutStack();
      } else {
        this.loginStack();
      }
    },

    loginStack() {
      stackService
        .getAuthUri('/settings/general')
        .then(uri => {
          window.location.href = uri;
        })
        .catch(error => {
          this.showToast(error);
        });
    },

    logoutStack() {
      let app = this;
      app.$ri.dialog.openInfoDialog({
        title: 'Logout StackCT Account',
        content: 'Are you sure you want to logout StackCT account?',
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            stackService
              .logout()
              .then(result => {
                if (result.success) {
                  app.isStackLoggedIn = false;
                  app.userInfoStackCT = '';
                  app.showToast('Logout StackCT successful');
                }
              })
              .catch(() => {
                app.showToast('Logout failed');
              });
          }
        },
      });
    },

    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
    },

    openEditPopup() {
      this.$refs.editPopup.open(this.setting);
    },

    qbSyncData() {
      this.$f7.preloader.show();
      quickbooksService
        .checkAuth()
        .then(result => {
          this.isQBLoggedIn = result.success;
          if (this.isQBLoggedIn) {
            return quickbooksService.syncData().then(res => {
              if (res) {
                this.showToast('Data sync successful!');
              }
            });
          }
        })
        .finally(() => this.$f7.preloader.hide());
    },

    connectGmailSender() {
      this.$f7.preloader.show();
      emailSenderService
        .getAuthUri({tenantId: this.tenantId, redirectPath: '/settings/general'})
        .then(result => {
          const uri = result.authUri || '';
          window.location.href = uri;
        })
        .catch(error => {
          this.showToast(error);
        })
        .finally(()=>{
          this.$f7.preloader.hide()
        });
    },

    revokeGmailSender() {
      this.$f7.preloader.show();
      emailSenderService
        .revokeRefreshToken({tenantId: this.tenantId})
        .then(({data}) => {
          if (data.success) {
            this.isSenderGmailConnected = false
            this.senderGmailInfo = null
            this.showToast('Gmail sender disconnected!')
          }
          
        })
        .catch(error => {
          this.showToast(error);
        })
        .finally(()=>{
          this.$f7.preloader.hide()
        });;
    }
  },

  watch: {
    currentUser: {
      deep: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          this.checkAdminGroup(val.uid);
        }
      },
    },
  },
};
</script>

<style scoped>
.title-logo-image {
  margin-top: 20px;
}
.image-logo {
  margin-left: 10px;
  /* border-radius: 50%; */
}

.gmail-icon {
  height: 12px;
}
</style>
