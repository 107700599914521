<template>
  <f7-page class="employee-time-logs-page">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Employee Time Logs</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click="isShowAddPopup = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block
      strong
      class="display-flex align-items-center justify-content-space-between"
      style="flex-wrap: wrap; gap: 8px"
    >
      <div
        class="display-flex align-items-center"
        style="flex-wrap: wrap; gap: 8px"
      >
        <div class="border-shadow btn-container">
          <f7-button
            color="gray"
            icon-f7="arrowtriangle_left_fill"
            class="flex-center btn-container_prev"
            @click="prevWeek"
          ></f7-button>
          <f7-link
            color="black"
            class="btn-container_select"
            @click.native="selectCalendar"
          >
            {{ showWeekRange }}
          </f7-link>
          <f7-button
            color="gray"
            icon-f7="arrowtriangle_right_fill"
            class="flex-center btn-container_next"
            @click="nextWeek"
          ></f7-button>
        </div>

        <div class="border-shadow select-container no-padding">
          <f7-list
            inset
            class="no-margin"
            style="max-width: 250px"
          >
            <f7-list-item
              title="Filter by teams"
              smart-select
              :smart-select-params="{
                openIn: 'popup',
                routableModals: false,
                searchbar: true,
                searchbarPlaceholder: 'Search team',
                on: {
                  opened: handleFilterPopupOpened,
                },
              }"
              ref="teamFilterSmartSelect"
            >
              <select
                multiple
                @change="changeTeamFilter"
              >
                <option
                  v-for="team in teamOptions"
                  :key="team.id"
                  :value="team.id"
                >
                  {{ team.teamName }}
                </option>
              </select>
            </f7-list-item>
          </f7-list>
        </div>

        <div class="border-shadow select-container no-padding">
          <f7-list
            inset
            class="no-margin"
            style="max-width: 250px"
          >
            <f7-list-item
              title="Filter by statuses"
              smart-select
              :smart-select-params="{
                openIn: 'popover',
                routableModals: false,
              }"
              ref="statusFilterSmartSelect"
            >
              <select
                multiple
                @change="changeStatusFilter"
              >
                <option
                  v-for="status in timeTrackingStatusList"
                  :key="status.id"
                  :value="status.value"
                >
                  {{ status.displayName }}
                </option>
              </select>
            </f7-list-item>
          </f7-list>
        </div>

        <div class="border-shadow select-container no-padding">
          <f7-list
            inset
            class="no-margin"
            style="max-width: 250px"
          >
            <f7-list-item
              title="Filter by time log types"
              smart-select
              :smart-select-params="{
                openIn: 'popover',
                routableModals: false,
              }"
              ref="timeLogTypeFilterSmartSelect"
            >
              <select
                multiple
                @change="changeTimeLogTypeFilter"
              >
                <option
                  v-for="tlt in timeLogTypeList"
                  :key="tlt.id"
                  :value="tlt.value"
                  :selected="timeLogTypeFilter.includes(tlt.value)"
                >
                  {{ tlt.displayName }}
                </option>
              </select>
            </f7-list-item>
          </f7-list>
        </div>

        <div
          class="border-shadow select-container display-flex justify-content-center align-items-center"
        >
          <input-search
            placeholder="Filter by project"
            :value="projectFilter"
            @input="setProjectFilter($event)"
          ></input-search>
        </div>
      </div>
      <div
        class="display-flex align-items-center"
        style="flex-wrap: wrap; gap: 16px"
      >
        <div
          v-if="allTimeLogsWaiting.length > 0"
          class="display-flex align-items-center"
          style="gap: 6px"
        >
          <f7-checkbox
            :checked="
              allTimeLogsWaiting.every(r =>
                selectedTimeLogWFAIds.includes(r.id)
              )
            "
            @change="onSelectAllTimeLogsWaiting($event.target.checked)"
          ></f7-checkbox>
          All timelogs waiting
        </div>
        <f7-button
          fill
          popover-close
          color="red"
          @click.native="isShowRejectAllTimeTracking = true"
          v-if="selectedTimeLogWFAIds.length > 0"
          >Reject</f7-button
        >
        <f7-button
          v-if="selectedTimeLogWFAIds.length > 0"
          fill
          color="green"
          @click.native="
            approveAll(selectedTimeLogWFAList, () => {
              selectedTimeLogWFAIds = [];
            })
          "
        >
          Approve
        </f7-button>
      </div>
    </f7-block>

    <div class="tableFixHead">
      <table class="table">
        <thead>
          <tr>
            <th width="200px">
              <input-search
                placeholder="Search Employee"
                :value="searchText"
                @input="onChangeSearchText($event)"
              ></input-search>
            </th>
            <th
              class="label-cell"
              v-for="(d, index) in currentWeek"
              :key="index"
            >
              <span class="table-head-label">{{ showDateHeader(d) }}</span>
            </th>
          </tr>
        </thead>

        <tbody v-if="dataTimeTrackings.length > 0">
          <tr
            v-for="u in dataTimeTrackings"
            :key="u.uid"
            class="no-border-top"
          >
            <td
              class="label-cell"
              width="200px"
            >
              <div
                class="display-flex flex-direction-column"
                style="gap: 12px"
              >
                <div
                  class="display-flex align-items-center cursor-pointer"
                  @click="openDetailPopup(u.uid)"
                >
                  <user-avatar
                    :avatarSize="30"
                    :name="u.displayName"
                    :imageUrl="u.photoURL"
                  />
                  <div class="margin-left-half">{{ u.displayName }}</div>
                </div>
                <div
                  v-if="allTimeLogsWaitingByUser(u.uid).length > 0"
                  class="display-flex align-items-center"
                  style="gap: 6px; font-weight: 400"
                >
                  <f7-checkbox
                    :checked="
                      allTimeLogsWaitingByUser(u.uid).every(r =>
                        selectedTimeLogWFAIds.includes(r.id)
                      )
                    "
                    @change="
                      onSelectAllTimeLogsWaitingByUser(
                        $event.target.checked,
                        u.uid
                      )
                    "
                  ></f7-checkbox>
                  Timelogs waiting
                </div>
                <div class="text-align-right">
                  <f7-chip
                    :text="`${formatDuration(u.totalHours)}`"
                    color="gray"
                  ></f7-chip>
                </div>
              </div>
            </td>
            <td
              class="label-cell cursor-pointer"
              v-for="(day, index) in u.timeTrackings"
              :key="index"
            >
              <div class="card-container">
                <!-- line > 3 to show more -->
                <div v-if="day.details.length > 3">
                  <time-log-card
                    :item="day.details[0]"
                    :selectedTimeLogWFAIds="selectedTimeLogWFAIds"
                    @onSelectTimeLogId="onSelectTimeLogId"
                    @clickOnAudit="clickOnAudit"
                    @openEditPopup="showDetailPopup"
                    @openRejectPopup="reject"
                    @onApprove="
                      approved($event, () =>
                        filterSelectedTimeLogWFAIds($event)
                      )
                    "
                    @onReOpen="onReOpen"
                  ></time-log-card>
                  <time-log-card
                    :item="day.details[1]"
                    :selectedTimeLogWFAIds="selectedTimeLogWFAIds"
                    @onSelectTimeLogId="onSelectTimeLogId"
                    @clickOnAudit="clickOnAudit"
                    @openEditPopup="showDetailPopup"
                    @openRejectPopup="reject"
                    @onApprove="
                      approved($event, () =>
                        filterSelectedTimeLogWFAIds($event)
                      )
                    "
                    @onReOpen="onReOpen"
                  ></time-log-card>
                  <time-log-card
                    :item="day.details[2]"
                    :selectedTimeLogWFAIds="selectedTimeLogWFAIds"
                    @onSelectTimeLogId="onSelectTimeLogId"
                    @clickOnAudit="clickOnAudit"
                    @openEditPopup="showDetailPopup"
                    @openRejectPopup="reject"
                    @onApprove="
                      approved($event, () =>
                        filterSelectedTimeLogWFAIds($event)
                      )
                    "
                    @onReOpen="onReOpen"
                  ></time-log-card>

                  <!-- show more -->
                  <f7-link
                    class="show-more"
                    :popover-open="`.show-popover_${u.uid}_${index}`"
                    >Show more</f7-link
                  >

                  <f7-popover
                    style="width: 350px"
                    :backdrop="false"
                    :class="`show-popover_${u.uid}_${index}`"
                  >
                    <f7-card>
                      <f7-card-header>
                        {{ showDateHeader(day.date) }} - {{ u.displayName }}
                      </f7-card-header>
                      <f7-card-content class="show-more-content">
                        <time-log-card
                          v-for="(detail, index) in day.details"
                          :key="index"
                          :item="detail"
                          :selectedTimeLogWFAIds="selectedTimeLogWFAIds"
                          @onSelectTimeLogId="onSelectTimeLogId"
                          @clickOnAudit="clickOnAudit"
                          @openEditPopup="showDetailPopup"
                          @openRejectPopup="reject"
                          @onApprove="
                            approved($event, () =>
                              filterSelectedTimeLogWFAIds($event)
                            )
                          "
                          @onReOpen="onReOpen"
                        ></time-log-card>
                      </f7-card-content>
                    </f7-card>
                  </f7-popover>
                </div>
                <div v-else>
                  <time-log-card
                    v-for="(detail, index) in day.details"
                    :key="index"
                    :item="detail"
                    :selectedTimeLogWFAIds="selectedTimeLogWFAIds"
                    @onSelectTimeLogId="onSelectTimeLogId"
                    @clickOnAudit="clickOnAudit"
                    @openEditPopup="showDetailPopup"
                    @openRejectPopup="reject"
                    @onApprove="
                      approved($event, () =>
                        filterSelectedTimeLogWFAIds($event)
                      )
                    "
                    @onReOpen="onReOpen"
                  ></time-log-card>
                </div>
              </div>
              <div class="add-button">
                <circle-button
                  icon="plus"
                  @onClick="openAddPopup({ userId: u.uid, date: day.date })"
                ></circle-button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="text-align-center"
              colspan="8"
            >
              No data!
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <add-new-employee-time-tracking
      :isShow="isShowAddPopup"
      :item="selectedItem"
      :type="EMPLOYEES_TIME_LOG"
      @close="closePopup"
    ></add-new-employee-time-tracking>

    <!-- calendar input hidden -->
    <input
      class="display-none"
      type="text"
      readonly="readonly"
      id="calendar-input"
    />

    <edit-time-tracking
      :popupEditTimeTracking="isShowEditTimeTracking"
      :item="detailTimeTracking"
      @close="isShowEditTimeTracking = false"
      :redirect="false"
    />
    <!-- reject a item -->
    <reject-time-tracking
      :popupRejectTimeTracking="isShowRejectTimeTracking"
      @close="isShowRejectTimeTracking = false"
      @rejectTimeTracking="
        rejectTimeTracking($event, itemReject, () =>
          filterSelectedTimeLogWFAIds(itemReject)
        )
      "
    />
    <!-- reject all -->
    <reject-time-tracking
      :popupRejectTimeTracking="isShowRejectAllTimeTracking"
      @close="isShowRejectAllTimeTracking = false"
      @rejectTimeTracking="rejectAll($event)"
    />
    <audit-detail-component
      :startedLocation="auditStartedLocation"
      :endedLocation="auditEndedLocation"
      :distance="auditDistance"
      :isShow="!!auditView"
      :username="auditTimeTrackingUser"
      :propertyAddress="auditPropertyAddress"
      @close="auditView = null"
    />
    <employee-time-log-detail-popup ref="employeeTimeLogDetailPopup" />
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { applyFilter } from '@/utility/filter-tools';
import { formatDuration } from '@/utility/datetime';
import { overtimeMixin } from '../mixin/overtime-mixin';
import { actionMixin } from '../mixin/action-mixin';
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import AddNewEmployeeTimeTracking from '../components/popups/AddNewEmployeeTimeTracking.vue';
import InputSearch from '../components/inputs/InputSearch.vue';
import TimeLogCard from '../components/cards/TimeLogCard.vue';
import EditTimeTracking from '../components/popups/EditTimeTracking';
import RejectTimeTracking from '../components/popups/RejectTimeTracking';
import EmployeeTimeLogDetailPopup from '../components/popups/EmployeeTimeLogDetailPopup';
import CircleButton from '../components/buttons/CircleButton.vue';
import AuditDetailComponent from '../components/location/AuditDetailComponent.vue';
import moment from 'moment';
import _ from 'lodash';

import Menu from '../../../components/menu/Menu.vue';
import { getFullAddress } from '@/utility/address';
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  STATUS_TIME_TRACKING_WFA,
  TIME_LOG_TYPE_LUNCHTIME,
} from '@/utility/const';
import { SESSION_KEY, EMPLOYEES_TIME_LOG } from '../utility/const';

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

function getCurrentWeek() {
  const startTime = sessionStorage.getItem(SESSION_KEY);

  const weekStart = startTime
    ? moment(startTime).startOf('isoWeek')
    : moment().startOf('isoWeek');

  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
  }

  return days;
}

export default {
  components: {
    UserAvatar,
    AddNewEmployeeTimeTracking,
    InputSearch,
    TimeLogCard,
    EditTimeTracking,
    RejectTimeTracking,
    EmployeeTimeLogDetailPopup,
    AuditDetailComponent,
    CircleButton,
    Menu,
  },

  mixins: [overtimeMixin, actionMixin],

  data() {
    return {
      searchText: '',
      isShowAddPopup: false,
      currentDate: [new Date()],
      fromDate: [new Date(moment(new Date(), 'MM/DD/YYYY').subtract(6, 'd'))],
      toDate: [new Date()],
      formatDuration,
      auditView: null,
      isShowEditTimeTracking: false,
      isShowRejectTimeTracking: false,
      detailTimeTracking: {},
      selectedItem: {},
      itemReject: {},
      selectedTimeLogWFAIds: [],
      itemsReject: [],
      isShowRejectAllTimeTracking: false,
      EMPLOYEES_TIME_LOG: EMPLOYEES_TIME_LOG,
    };
  },

  methods: {
    ...mapActions('common/team-management', ['getTeamList']),
    ...mapActions('time-tracking/user', ['getUserList']),
    ...mapActions({
      setCurrentWeek: 'time-tracking/time-tracking/setCurrentWeek',
      getTimeTrackingList: 'time-tracking/time-tracking/getTimeTrackingList',
    }),
    ...mapMutations({
      setTeamFilter: 'time-tracking/time-tracking/setTeamFilter',
      setStatusFilter: 'time-tracking/time-tracking/setStatusFilter',
      setTimeLogTypeFilter: 'time-tracking/time-tracking/setTimeLogTypeFilter',
      setProjectFilter: 'time-tracking/time-tracking/setProjectFilter',
      setIsMyTimeLog: 'time-tracking/time-tracking/setIsMyTimeLog',
    }),
    ...mapActions('time-tracking/app-constant', ['getConstantTypeList']),
    ...mapActions('time-tracking/quickbooks', [
      'getClassQBList',
      'getServiceItemQBList',
    ]),
    ...mapActions('time-tracking/service-item-setting', [
      'getServiceItemSettingList',
    ]),
    ...mapActions('time-tracking/pay-rate', ['getCurrentPayRateOfUser']),

    handleFilterPopupOpened() {
      if (this.$device.desktop) {
        this.$refs.teamFilterSmartSelect.f7SmartSelect.searchbar.$el[0]
          .querySelector('.searchbar input')
          .focus();
      }
    },

    openDetailPopup(uid) {
      this.$refs.employeeTimeLogDetailPopup.open(uid);
    },

    showDateHeader(date) {
      return moment(date, 'MM/DD/YYYY').format('ddd Do');
    },

    onChangeSearchText(value) {
      this.searchText = value;
    },

    changeTeamFilter() {
      const value = this.$refs.teamFilterSmartSelect.f7SmartSelect.getValue();
      this.setTeamFilter(value);
    },

    changeStatusFilter() {
      const value = this.$refs.statusFilterSmartSelect.f7SmartSelect.getValue();
      this.setStatusFilter(value);
    },
    changeTimeLogTypeFilter() {
      const value =
        this.$refs.timeLogTypeFilterSmartSelect.f7SmartSelect.getValue();
      this.setTimeLogTypeFilter(value);
    },
    bindDataByDate(currentWeek) {
      let from = new Date(currentWeek[0]);
      let to = new Date(
        moment(new Date(currentWeek[6]), 'MM/DD/YYYY').add(1, 'days')
      );
      return this.getTimeTrackingList([
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(from),
          op: '>=',
        },
        {
          prop: 'date',
          val: this.$google.firebase.firestore.Timestamp.fromDate(to),
          op: '<',
        },
      ]);
    },
    selectCalendar() {
      const style = document.createElement('style');
      style.innerHTML = `
    .calendar-day.calendar-day-selected .calendar-day-number {
      background-color: transparent !important;
    }
  `;
      document.head.appendChild(style);
      this.currentDate = null;

      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-hover',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0]),
                to: new Date(this.currentWeek[6]),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              // if (
              //   !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              // ) {
              this.currentDate = value;

              sessionStorage.setItem(SESSION_KEY, value[0]);

              const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                'isoWeek'
              );
              this.getCurrentWeekTimeLogs(weekStart);
              this.selectedTimeLogWFAIds = [];
              calendar.close();
              // }
            },
          },
        })
        .open();
    },
    getCurrentWeekTimeLogs(weekStart) {
      this.$f7.preloader.show();
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format('MM/DD/YYYY'));
      }

      this.setCurrentWeek(days);
      this.bindDataByDate(this.currentWeek).then(() =>
        this.$f7.preloader.hide()
      );
    },
    prevWeek() {
      const weekStart = moment(this.currentWeek[0], 'MM/DD/YYYY')
        .subtract(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.getCurrentWeekTimeLogs(weekStart);
      sessionStorage.setItem(SESSION_KEY, weekStart);
      this.selectedTimeLogWFAIds = [];
    },
    nextWeek() {
      const weekStart = moment(this.currentWeek[0], 'MM/DD/YYYY')
        .add(1, 'weeks')
        .startOf('isoWeek');
      this.currentDate = [new Date(weekStart)];
      this.getCurrentWeekTimeLogs(weekStart);
      sessionStorage.setItem(SESSION_KEY, weekStart);
      this.selectedTimeLogWFAIds = [];
    },
    clickOnAudit(item) {
      this.auditView = item;
    },
    showDetailPopup(item) {
      this.detailTimeTracking = {
        ...item,
        overrideHour:
          item.timeLogType === TIME_LOG_TYPE_LUNCHTIME
            ? item.overrideHour * -1
            : item.overrideHour,
      };
      this.isShowEditTimeTracking = true;
    },
    reject(item) {
      this.itemReject = item;
      this.isShowRejectTimeTracking = true;
    },
    rejectAll(event) {
      this.rejectAllTimeTracking(
        event,
        this.selectedTimeLogWFAList,
        () => (this.selectedTimeLogWFAIds = [])
      );
    },
    onSelectTimeLogId(id) {
      if (this.selectedTimeLogWFAIds.includes(id)) {
        var index = this.selectedTimeLogWFAIds.indexOf(id);
        if (index > -1) {
          this.selectedTimeLogWFAIds.splice(index, 1);
        }
      } else {
        this.selectedTimeLogWFAIds.push(id);
      }
    },
    openAddPopup(item) {
      this.selectedItem = item;
      this.isShowAddPopup = true;
    },
    closePopup() {
      this.isShowAddPopup = false;
      this.selectedItem = {};
    },
    onSelectAllTimeLogsWaitingByUser(checked, userId) {
      if (checked) {
        this.selectedTimeLogWFAIds.push(
          ...this.allTimeLogsWaitingByUser(userId).map(t => t.id)
        );
      } else {
        this.selectedTimeLogWFAIds = this.selectedTimeLogWFAIds.filter(
          id =>
            !this.allTimeLogsWaitingByUser(userId)
              .map(t => t.id)
              .includes(id)
        );
      }
      this.selectedTimeLogWFAIds = _.uniq(this.selectedTimeLogWFAIds);
    },
    onSelectAllTimeLogsWaiting(checked) {
      if (checked) {
        this.selectedTimeLogWFAIds.push(
          ...this.allTimeLogsWaiting.map(t => t.id)
        );
      } else {
        this.selectedTimeLogWFAIds = this.selectedTimeLogWFAIds.filter(
          id => !this.allTimeLogsWaiting.map(t => t.id).includes(id)
        );
      }
      this.selectedTimeLogWFAIds = _.uniq(this.selectedTimeLogWFAIds);
    },
    filterSelectedTimeLogWFAIds(item) {
      this.selectedTimeLogWFAIds = this.selectedTimeLogWFAIds.filter(
        id => id !== item.id
      );
    },
    resetWeekOnReload() {
      sessionStorage.removeItem(SESSION_KEY);
    },
  },

  computed: {
    ...mapGetters('common/team-management', ['teamList']),
    ...mapGetters('time-tracking/user', ['userListByHours', 'userById']),
    ...mapGetters({
      listUserTrackings: 'time-tracking/time-tracking/listUserTrackings',
      timeTrackingList: 'time-tracking/time-tracking/objectList',
      currentWeek: 'time-tracking/time-tracking/currentWeek',
      detailUid: 'time-tracking/time-tracking/detailUid',
      statusFilter: 'time-tracking/time-tracking/statusFilter',
      timeLogTypeFilter: 'time-tracking/time-tracking/timeLogTypeFilter',
      projectFilter: 'time-tracking/time-tracking/projectFilter',
    }),

    ...mapGetters('time-tracking/app-constant', [
      'timeTrackingStatusList',
      'timeLogTypeList',
    ]),
    ...mapGetters('time-tracking/pay-rate', ['payRateList']),

    ...mapGetters('setting/app/group', ['isAdminGroup']),

    teamOptions() {
      return (_.cloneDeep(this.teamList) || []).sort((a, b) =>
        sortBy(a.teamName, b.teamName)
      );
    },

    showWeekRange() {
      if (this.currentWeek.length === 0) return 'Filter by week';
      const from = moment(this.currentWeek[0], 'MM/DD/YYYY').format('MMM Do');
      const to = moment(this.currentWeek[6], 'MM/DD/YYYY').format('MMM Do');
      return `${from} - ${to}`;
    },

    filteredUsers() {
      const list =
        applyFilter(this.listUserTrackings, this.searchText, ['displayName']) ||
        [];
      return list.sort((a, b) => sortBy(a.displayName, b.displayName));
    },
    allTimeLogsWaiting() {
      return this.timeTrackingList.filter(
        t =>
          t.status === STATUS_TIME_TRACKING_WFA &&
          this.filteredUsers.some(u => u.uid === t.user_id)
      );
    },
    allTimeLogsWaitingByUser() {
      return userId => {
        return (
          this.timeTrackingList.filter(
            t => t.user_id === userId && t.status === STATUS_TIME_TRACKING_WFA
          ) || []
        );
      };
    },
    selectedTimeLogWFAList() {
      return this.timeTrackingList.filter(
        t =>
          this.selectedTimeLogWFAIds.includes(t.id) &&
          t.status === STATUS_TIME_TRACKING_WFA
      );
    },

    dataTimeTrackings() {
      const list = this.filteredUsers.map(r => ({
        ...r,
        timeTrackings: this.currentWeek.map(d => ({
          date: d,
          details: (
            this.timeTrackingList.filter(
              t =>
                t.user_id === (r && r.uid) &&
                moment(t.date.toDate()).format('MM/DD/YYYY') === d
            ) || []
          ).sort((a, b) => sortBy(a.startTime, b.startTime)),
        })),
      }));
      return list;
    },
    auditStartedLocation() {
      const location = this.auditView ? this.auditView.startLocation : null;
      return location || {};
    },

    auditEndedLocation() {
      const location = this.auditView ? this.auditView.endLocation : null;
      return location || {};
    },

    auditDistance() {
      return this.auditView ? this.auditView.distance : 0;
    },

    auditPropertyAddress() {
      if (!this.auditView) return null;
      if (
        this.auditView.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
        (this.auditView.project.businessCode === BUSINESS_CODE_SERVICE &&
          this.auditView.project.customerType === 'commercial')
      ) {
        return getFullAddress(this.auditView.project.propertyAddress);
      } else if (
        this.auditView.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
        (this.auditView.project.businessCode === BUSINESS_CODE_SERVICE &&
          this.auditView.project.customerType === 'residential')
      ) {
        return getFullAddress(this.auditView.project.projectAddress);
      }
      return '';
    },
    auditTimeTrackingUser() {
      const user = this.auditView && this.userById(this.auditView.user_id);
      return (user || {}).displayName;
    },
  },

  created() {
    const promises = [];
    const self = this;
    this.$f7.preloader.show();
    window.addEventListener('beforeunload', this.resetWeekOnReload);

    return this.setCurrentWeek(getCurrentWeek()).then(() => {
      promises.push(self.bindDataByDate(self.currentWeek));
      promises.push(self.getUserList());
      promises.push(self.getTeamList());
      promises.push(self.setTeamFilter([]));
      promises.push(self.setStatusFilter([]));
      promises.push(self.setTimeLogTypeFilter([]));
      promises.push(self.setProjectFilter(''));
      promises.push(self.setIsMyTimeLog(false));
      promises.push(self.getConstantTypeList());
      promises.push(self.getClassQBList());
      promises.push(self.getServiceItemQBList());
      promises.push(self.getServiceItemSettingList());
      return Promise.all(promises).finally(() => {
        self.$f7.preloader.hide();
      });
    });
  },
  beforeDestroy() {
    // Xóa event listener khi component bị hủy
    window.removeEventListener('beforeunload', this.resetWeekOnReload);
  },
};
</script>
<style lang="scss" scoped>
@import '../style/common.scss';
.calendar-hover {
  .calendar-months {
    .calendar-row {
      &:hover {
        background-color: #000 !important;
      }
    }
  }
}
.current-week {
  background-color: #000 !important;
}
.add-button {
  display: none;
}
.paste-button {
  display: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.tableFixHead {
  overflow-y: auto;
  height: calc(
    100% - var(--f7-navbar-height) - var(--f7-block-margin-vertical) - 50px
  );
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.tableFixHead,
.tableFixHead td,
.tableFixHead th {
  box-shadow: inset 1px -1px var(--f7-color-border-neutral);
  box-sizing: border-box;
}
table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--f7-color-bg-4-neutral);
  table-layout: fixed;

  tr th:first-child > div {
    margin: 0 10px;
  }
  tr td:first-child {
    font-weight: 500;
    background: var(--f7-color-bg-3-neutral);
    word-break: break-word;

    & > div {
      margin: 0 10px;
    }
  }
  th {
    background: var(--f7-color-bg-3-neutral);
    height: 3rem;
    font-weight: 500;
    position: relative;
  }
  td {
    vertical-align: top;
    position: relative;
    padding: 6px 4px;
    height: 240px;
  }
  td:not(:first-child):hover {
    .add-button {
      display: inline;
      position: absolute;
      bottom: 2px;
      right: 2px;
    }
    .paste-button {
      display: inline;
      position: absolute;
      bottom: 3px;
      right: 34px;
    }
  }
}
.card-container {
  height: inherit;
}
.popover-schedule-detail {
  width: 400px;
}
.show-more {
  font-size: 12px;
  font-weight: 600;
  margin-left: 9px;
  position: absolute;
  bottom: 9px;
  left: 2px;
}
.show-more-content {
  max-height: 500px;
  overflow-y: auto;
}

.employee-time-logs-page ::v-deep .page-content {
  overflow: unset;
}
</style>
