<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ editorTitle }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- sku -->
        <f7-list-input
          class="first-input"
          label="SKU"
          placeholder="Product SKU"
          clear-button
          :value="currentProduct.sku"
          @input="onChangeProduct('sku', $event.target.value.trim())"
        ></f7-list-input>

        <!-- Manufacturer -->
        <f7-list-input
          label="Manufacturer"
          placeholder="Enter Manufacturer"
          clear-button
          :value="currentProduct.manufacturer"
          @input="currentProduct.manufacturer = $event.target.value.trim()"
        ></f7-list-input>

        <f7-list v-if="!isProductList">
          <!-- vendor -->
          <f7-list-item
            header="Vendor"
            link
            @click.native="selectVendor"
          >
            <div
              class="list-item-title"
              slot="title"
            >
              {{
                vendorById(currentProduct.vendorId)
                  ? vendorById(currentProduct.vendorId).companyName
                  : 'Select vendor'
              }}
            </div>
            <required-asterisk slot="header" />
            <div
              slot="footer"
              class="text-color-red"
            >
              {{ vendorErrorMessage }}
            </div>
          </f7-list-item>
        </f7-list>

        <!-- Product Name -->
        <f7-list-input
          label="Product Name"
          placeholder="Product Name"
          :value="currentProduct.productItem"
          @input="onChangeProduct('productItem', $event.target.value.trim())"
          error-message-force
          clear-button
          :error-message="productItemErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
      </f7-list>

      <!-- Roof types -->
      <multi-select
        v-model="currentProduct.roofTypes"
        :error-message="roofTypesErrorMessage"
        placeholder="Select roof types"
        :selectionSource="
          roofTypeList.map(item => ({
            key: item.value,
            value: item.displayName,
          }))
        "
        header="Roof Types"
        required
      >
      </multi-select>

      <f7-list>
        <!-- Product Category -->
        <f7-list-input
          label="Product Category"
          type="select"
          :value="currentProduct.categoryId"
          @input="currentProduct.categoryId = $event.target.value.trim()"
          error-message-force
          :error-message="categoryIdErrorMessage"
        >
          <option
            v-for="c in [
              { id: '', name: 'Select category' },
              ...filteredCategories,
            ]"
            :key="c.id"
            :value="c.id"
          >
            {{ c.name }}
          </option>
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Sub category -->
        <f7-list-input
          class="black-text-option"
          label="Sub category"
          type="select"
          :value="currentProduct.subCategoryId"
          @input="onChangeProduct('subCategoryId', $event.target.value.trim())"
          error-message-force
          :error-message="subCategoryIdErrorMessage"
        >
          <option
            v-for="sc in [
              { id: '', name: 'Select sub category' },
              ...filteredSubCategories,
            ]"
            :key="sc.id"
            :value="sc.id"
          >
            {{ sc.name }}
          </option>
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Product Status -->
        <f7-list-input
          label="Product Status"
          type="select"
          :value="currentProduct.status || 'inactive'"
          @input="onChangeProduct('status', $event.target.value.trim())"
          error-message-force
          :error-message="productStatusErrorMessage"
        >
          <option
            v-for="status in productItemStatusList"
            :key="status.id"
            :value="status.value"
          >
            {{ status.displayName }}
          </option>
        </f7-list-input>
      </f7-list>

      <f7-list>
        <!-- Technical Data -->
        <attachment-input-not-save
          title="Technical Data Attachment"
          :value="currentProduct.technicalData"
          @input="currentProduct.technicalData = $event"
        ></attachment-input-not-save>
      </f7-list>

      <f7-list>
        <!-- photo -->
        <UploadProductColorPhoto
          :value="currentProduct.colorPhotos"
          @input="currentProduct.colorPhotos = $event"
        >
        </UploadProductColorPhoto>
      </f7-list>

      <f7-list>
        <f7-list-item header="Vendor Price">
          <required-asterisk slot="header" />
          <input-price
            slot="title"
            ref="vendorPriceInput"
            :allowZero="false"
            :price="Number(currentProduct.price)"
            :isResetData="!isShow"
            @input="onChangeProduct('price', $event)"
          />
        </f7-list-item>
        <f7-list-item header="Markup">
          <required-asterisk slot="header" />
          <div slot="title">
            <input-percent-or-cash
              ref="markupInputPercentOrCash"
              inputClass="bottom-line"
              :value="markup.value"
              :type="markup.type"
              :isResetData="!isShow"
              @inputValue="changeMarkup('value', $event)"
              @changeType="changeMarkup('type', $event)"
            ></input-percent-or-cash>
          </div>
        </f7-list-item>
        <f7-list-item
          header="Price"
          :title="priceProductPLValue | currencyUSD"
        ></f7-list-item>
        <!-- Waste Factor -->
        <f7-list-input
          v-show="!isLabor"
          label="Waste Factor"
          placeholder="Waste Factor"
          :value="currentProduct.wasterFactor"
          type="number"
          min="0"
          @input="
            onChangeProduct(
              'wasterFactor',
              $event.target.value.trim() && parseFloat($event.target.value.trim())
            )
          "
          error-message-force
          :error-message="wasterFactorErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
        <!-- Packaging -->
        <f7-list-input
          v-show="!isLabor"
          label="Packaging"
          placeholder="Packaging"
          :value="currentProduct.packaging"
          @input="onChangeProduct('packaging', $event.target.value.trim())"
          error-message-force
          :error-message="packagingErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Unit Pack -->
        <f7-list-input
          v-show="!isLabor"
          label="Unit Packs"
          placeholder="Unit Packs"
          :value="currentProduct.unitPack"
          type="number"
          min="0"
          @input="
            onChangeProduct('unitPack', $event.target.value.trim() && parseFloat($event.target.value.trim()))
          "
          error-message-force
          :error-message="unitPackErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- Unit Size -->
        <f7-list-input
          v-show="!isLabor"
          label="Unit Size"
          placeholder="Unit Size"
          :value="currentProduct.unitSize"
          type="number"
          min="0"
          @input="
            onChangeProduct('unitSize', $event.target.value.trim() &&parseFloat($event.target.value.trim()))
          "
          error-message-force
          :error-message="unitSizeErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- UoM -->
        <f7-list-input
          label="UoM"
          placeholder="UoM"
          :value="currentProduct.uom"
          @input="onChangeProduct('uom', $event.target.value.trim())"
          error-message-force
          :error-message="uomErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
        <!-- internal note -->
        <f7-list-input
          label="Internal Note"
          type="textarea"
          placeholder="Enter internal note..."
          :value="internalNote"
          @input="internalNote = $event.target.value.trim()"
        >
        </f7-list-input>
        <!-- proposal note -->
        <f7-list-input
          label="Proposal Note"
          type="textarea"
          placeholder="Enter proposal note..."
          :value="proposalNote"
          @input="proposalNote = $event.target.value"
        >
        </f7-list-input>
      </f7-list>
    </f7-page>
    <company-list-popup
      ref="companyListPopup"
      title="Vendor"
      :isHiddenAddNew="false"
      :companyTypes="[COMPANY_TYPE_VENDOR]"
      :queryFilters="`companyTypes:${COMPANY_TYPE_VENDOR}`"
      @onSelected="currentProduct.vendorId = $event"
      @onCreated="handleCompanySelected"
    ></company-list-popup>
  </f7-popup>
</template>

<script>
import { mapActions } from 'vuex';
import MultiSelect from '@/components/inputs/MultiSelect.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import {
  DEFAULT_STATUS_PRODUCT_ITEM,
  VALIDATION_MESSAGE,
} from '@/utility/const';
import UploadProductColorPhoto from '@/components/inputs/UploadProductColorPhoto.vue';
import InputPrice from '@/components/inputs/InputPrice.vue';
import InputPercentOrCash from '@/components/inputs/InputPercentOrCash.vue';
import AttachmentInputNotSave from '@/components/inputs/AttachmentInputNotSave.vue';
import CompanyListPopup from '@/components/popups/CompanyListPopup.vue';
import { uploadFile, removeFile } from '@/services/firebase.service';
import _ from 'lodash';
import Vue from 'vue';
import { COMPANY_TYPE_VENDOR } from '../../../../utility/const';
import { handleColorPhoto } from '@/utility/common';

export default {
  finishCallback: null,
  components: {
    MultiSelect,
    AttachmentInputNotSave,
    CompanyListPopup,
    UploadProductColorPhoto,
    InputPrice,
    InputPercentOrCash,
  },
  props: {
    roofTypeList: Array,
    categories: Array,
    subCategories: Array,
    productItemStatusList: Array,
    vendorById: Function,
    isProductList: { type: Boolean, default: false },
  },
  data: () => ({
    oldTechnicalData: [],
    oldPhotos: [],
    currentProduct: {
      sku: '',
      manufacturer: '',
      productItem: '',
      roofTypes: [],
      size: '',
      price: 0,
      uom: '',
      note: '',
      wasterFactor: '',
      subCategoryId: '',
      categoryId: '',
      color: '',
      status: DEFAULT_STATUS_PRODUCT_ITEM,
      technicalData: [],
      photos: [],
      colorPhotos: [],
    },
    editorTitle: '',
    isShow: false,
    internalNote: '',
    isCopied: false,
    proposalNote: '',
    markup: {
      value: 0,
      type: 'percent',
    },
    COMPANY_TYPE_VENDOR,
  }),
  methods: {
    ...mapActions('product/product-item-page/product-item', [
      'uploadPhoto',
      'copyPhoto',
    ]),
    ...mapActions('price-list/price-list-page/vendor', ['getVendors']),
    cancel(ignoreCallback) {
      this.oldTechnicalData = [];
      this.oldPhotos = [];
      this.currentProduct = {
        sku: '',
        manufacturer: '',
        productItem: '',
        subCategoryId: '',
        categoryId: '',
        uom: '',
        note: '',
        roofTypes: [],
        price: 0,
        wasterFactor: '',
        packaging: '',
        unitPack: '',
        unitSize: '',
        color: '',
        status: DEFAULT_STATUS_PRODUCT_ITEM,

        technicalData: [],
        photos: [],
        colorPhotos: [],
      };
      (this.isCopied = false), this.v$.$reset();
      this.isShow = false;
      this.internalNote = '';
      this.proposalNote = '';
      this.markup = {
        value: 0,
        type: 'percent',
      };
      !ignoreCallback && this.finishCallback(null);
    },
    changeMarkup(property, value) {
      Vue.set(
        this.markup,
        property,
        property === 'value' ? parseFloat(value) : value
      );
      this.v$.markup[property] && this.v$.markup[property].$touch();
    },
    async handleCompanySelected(companyId) {
      await this.getVendors();
      this.currentProduct.vendorId = companyId;
    },
    onChangeProduct(property, value) {
      Vue.set(this.currentProduct, property, value);
      this.v$.currentProduct[property] &&
        this.v$.currentProduct[property].$touch();
    },
    selectVendor() {
      this.$refs.companyListPopup.open();
    },
    async handleUploadFile(attachments) {
      let attachs = [];

      for (const file of attachments) {
        let attachment = {
          name: file.name,
          fullPath: `product/technical-data/${+new Date() + file.name}`,
          url: '',
          description: file.description || '',
        };
        let url = await uploadFile(attachment.fullPath, file);

        attachment.url = url;
        attachs.push(attachment);
      }

      return attachs;
    },

    handleDataBeforeUpload(oldData, currentData) {
      let attachsBefore = _.cloneDeep(oldData);
      let attachsAfter = _.cloneDeep(currentData);

      let attachsRemove = _.differenceWith(
        attachsBefore,
        attachsAfter,
        _.isEqual
      );
      let attachsAdd = _.differenceWith(attachsAfter, attachsBefore, _.isEqual);
      let attachsRemain = _.differenceWith(
        attachsBefore,
        attachsRemove,
        _.isEqual
      );
      //delete file in storage
      if (!this.isCopied) {
        attachsRemove.forEach(attach => {
          removeFile(attach.fullPath);
        });
      }
      return { attachsAdd, attachsRemain };
    },

    async handleColorPhotoFiles(oldData, currentData) {
      const props = {
        oldData,
        currentData,
        uploadPhoto: this.uploadPhoto,
        copyPhoto: this.copyPhoto,
        isCopied: this.isCopied,
      };
      const result = await handleColorPhoto(props);

      const { updatedData, isCopied: updatedIsCopied } = result;
      this.isCopied = updatedIsCopied;
      return updatedData;
    },
    add() {
      this.v$.$touch();
      this.$refs.vendorPriceInput?.validate();
      this.$refs.markupInputPercentOrCash?.validate();
      if (
        this.v$.$invalid ||
        this.$refs.markupInputPercentOrCash?.validate() ||
        this.$refs.vendorPriceInput?.validate()
      ) {
        return;
      }
      this.$f7.preloader.show();
      let data = { ...this.currentProduct };
      const { technicalData, colorPhotos } = this.currentProduct;

      const technicalDataBeforeUpload = this.handleDataBeforeUpload(
        this.oldTechnicalData,
        technicalData
      );

      //upload db
      this.handleColorPhotoFiles(this.oldColorPhotos, colorPhotos)
        .then(colorPhotos => {
          data.colorPhotos = colorPhotos;
          return this.handleUploadFile(technicalDataBeforeUpload.attachsAdd);
        })
        .then(attachs => {
          data.technicalData = _.union(
            technicalDataBeforeUpload.attachsRemain || [],
            attachs
          );
          // labor will dont have some data
          if (this.isLabor) {
            data.wasterFactor = '';
            data.packaging = '';
            data.unitPack = '';
            data.unitSize = '';
          }
          this.finishCallback({
            productData: data,
            internalNote: this.internalNote,
            proposalNote: this.proposalNote,
            markup: this.markup,
            price: this.priceProductPLValue,
          });
          this.cancel(true);
          this.$f7.preloader.hide();
        });
    },
    startEditor(title, obj) {
      this.editorTitle = title;
      this.isShow = true;
      if (title === 'Copy Product') {
        this.isCopied = true;
      }
      this.oldTechnicalData = _.cloneDeep(obj.technicalData || []);
      this.oldColorPhotos = _.cloneDeep(obj.colorPhotos || []);
      if (obj.vendorId === '?') obj.vendorId = '';
      if (obj.subCategoryId === '?') obj.subCategoryId = '';
      this.currentProduct = {
        ...this.currentProduct,
        ...obj,
        price: obj.vendorPrice || 0,
      };
      delete this.currentProduct.internalNote;
      delete this.currentProduct.proposalNote;
      delete this.currentProduct.markup;
      delete this.currentProduct.vendorPrice;
      this.internalNote = obj.internalNote || '';
      this.proposalNote = obj.proposalNote || '';
      this.markup = _.cloneDeep(obj.markup) || { value: 0, type: 'percent' };
      return new Promise(resolve => {
        this.finishCallback = resolve;
      });
    },

    handlePopupOpened() {
      if (this.editorTitle.includes('Add') && this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },
  },
  computed: {
    priceProductPLValue() {
      let price = 0;
      if (this.markup.type === 'percent') {
        price =
          (this.currentProduct.price || 0) *
          (1 + (this.markup.value || 0) / 100);
      } else {
        price = (this.currentProduct.price || 0) + (this.markup.value || 0);
      }
      return parseFloat(price.toFixed(2));
    },
    filteredSubCategories() {
      if (!this.currentProduct.categoryId) return this.subCategories;

      return this.subCategories.filter(
        r => r.categoryId == this.currentProduct.categoryId
      );
    },

    filteredCategories() {
      if (
        !this.currentProduct.roofTypes ||
        !this.currentProduct.roofTypes.length
      )
        return this.categories;
      return this.categories.filter(r =>
        (r.roofTypes || []).some(x => this.currentProduct.roofTypes.includes(x))
      );
    },
    isLabor() {
      let category = this.categories.find(
        r => r.id == this.currentProduct.categoryId
      );
      return category && category.name == 'Labor';
    },
    productItemErrorMessage() {
      if (!this.v$.currentProduct.productItem.$error) return null;
      if (this.v$.currentProduct.productItem.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    vendorErrorMessage() {
      if (!this.v$.currentProduct.vendorId.$error) return null;
      if (this.v$.currentProduct.vendorId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    subCategoryIdErrorMessage() {
      if (!this.v$.currentProduct.subCategoryId.$error) return null;
      if (this.v$.currentProduct.subCategoryId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    productStatusErrorMessage() {
      if (!this.v$.currentProduct.status.$error) return null;
      if (this.v$.currentProduct.status.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    categoryIdErrorMessage() {
      if (!this.v$.currentProduct.categoryId.$error) return null;
      if (this.v$.currentProduct.categoryId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    roofTypesErrorMessage() {
      if (!this.v$.currentProduct.roofTypes.$error) return null;
      if (this.v$.currentProduct.roofTypes.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    packagingErrorMessage() {
      if (!this.v$.currentProduct.packaging.$error) return null;
      if (this.v$.currentProduct.packaging.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    unitPackErrorMessage() {
      if (!this.v$.currentProduct.unitPack.$error) return null;
      if (this.v$.currentProduct.unitPack.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.unitPack.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },
    unitSizeErrorMessage() {
      if (!this.v$.currentProduct.unitSize.$error) return null;
      if (this.v$.currentProduct.unitSize.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.unitSize.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },

    wasterFactorErrorMessage() {
      if (!this.v$.currentProduct.wasterFactor.$error) return null;
      if (this.v$.currentProduct.wasterFactor.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentProduct.wasterFactor.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },

    uomErrorMessage() {
      if (!this.v$.currentProduct.uom.$error) return null;
      if (this.v$.currentProduct.uom.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    categoryDisplay() {
      const category = this.categories.find(
        r => r.id === this.currentProduct.categoryId
      );
      return (category || {}).name || 'Select category';
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      currentProduct: {
        vendorId: {
          required,
        },
        productItem: {
          required,
        },
        roofTypes: {
          required(val) {
            return val && val.length > 0;
          },
        },
        packaging: {
          required: !this.isLabor ? required : true,
        },
        unitPack: {
          required: !this.isLabor ? required : true,
          minValue(val) {
            return val > 0;
          },
        },
        unitSize: {
          required: !this.isLabor ? required : true,
          minValue(val) {
            return val > 0;
          },
        },
        wasterFactor: {
          required: !this.isLabor ? required : true,
          minValue(val) {
            return val > 0;
          },
        },
        uom: {
          required,
        },
        price: {
          required,
          minValue(val) {
            return val > 0;
          },
        },
        subCategoryId: {
          required,
        },
        categoryId: {
          required,
        },
        status: {
          required,
        },
      },
      markup: {
        value: { required },
        type: { required },
      },
    };
  },

  watch: {
    'currentProduct.subCategoryId': function (v) {
      if (!v) return;
      if (this.currentProduct.categoryId) return;
      const subCategory = this.subCategories.find(r => r.id === v);
      if (subCategory) {
        this.currentProduct.categoryId = subCategory.categoryId;
      }
    },
    'currentProduct.categoryId': function (v) {
      if (!v) return;
      if (
        this.currentProduct.roofTypes &&
        this.currentProduct.roofTypes.length > 0
      ) {
        return;
      }
      const category = this.categories.find(r => r.id === v);
      if (category) {
        this.currentProduct.roofTypes = category.roofTypes;
      }
    },
  },
};
</script>
<style scoped>
.black-text-option ::v-deep select {
  color: var(--f7-black);
}
</style>
