<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title>Employees Certificates</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <div>
      <pin-data-table
        :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
        :headers="headers"
        :items="hits"
        @pinArray="receivePinData"
        :pageSize="(hits || []).length"
        :pinWidth="[getRowLeft(0)]"
      >
        <!-- Custom Card Header  -->
        <template slot="card-header">
          <f7-card-content>
            <f7-list>
              <f7-searchbar
                placeholder="Search in Product items"
                class="search-list-popup"
                :clear-button="true"
                disable-button-text
                :value="searchText"
                @input="
                  setMyCertificateSearchText($event.target.value);
                  onSearch();
                "
                @searchbar:clear="
                  setMyCertificateSearchText('');
                  onSearch();
                "
                @searchbar:disable="
                  setMyCertificateSearchText('');
                  onSearch();
                "
                @blur="
                  if (!searchText.trim()) {
                    setMyCertificateSearchText('');
                  }
                "
              >
              </f7-searchbar>
            </f7-list>
          </f7-card-content>
        </template>

        <template #body="{ item }">
          <td
            :style="{
              left: pinArrayData && pinArrayData[0] ? getRowLeft(0) : 'auto',
              boxShadow: pinArrayData
                ? getLastTrue(pinArrayData, 0)
                  ? '7px 0px 5px #000'
                  : ''
                : '',
            }"
            id="column-pin-0"
            :class="`${
              pinArrayData && pinArrayData[0] && !$device.desktop
                ? 'pinned-row'
                : ''
            }`"
          >
            {{ item.certificateName }}
          </td>
          <!-- <td>{{ item.certificateName }}</td> -->
          <td>{{ item.certificateAuthority }}</td>
          <td>{{ item.certificateNumber }}</td>
          <td>
            {{
              item.completionDate
                ? toDisplayDateString(item.completionDate)
                : ''
            }}
          </td>
          <td>
            {{ item.expiredDate ? toDisplayDateString(item.expiredDate) : '' }}
          </td>
          <td>{{ item.note }}</td>
          <td>
            <ul class="technical-data">
              <li
                v-for="(attach, index) in item.attachmentFiles"
                :key="index"
                :id="`${item.id}-${attach.fileName
                  .split('.')[0]
                  .replace(/[^a-zA-Z0-9]/g, '')}`"
              >
                <a
                  class="display-block"
                  @click.stop="openLink(item.attachmentFiles, attach)"
                >
                  {{ attach.fileName }}
                </a>
              </li>
            </ul>
          </td>
          <td>
            <f7-chip
              :text="getStatus(item.expiredDate).text"
              :color="getStatus(item.expiredDate).color"
            ></f7-chip>
          </td>
          <td class="text-align-center">
            <div
              class="display-flex justify-content-center"
              style="width: 70px; gap: 6px"
            >
              <a
                class="display-flex align-items-center"
                href="#"
              >
                <i
                  class="f7-icons text-color-blue"
                  style="margin: 2px; font-size: 20px"
                  @click="openNewMyCertificate(item)"
                  >pencil</i
                >
              </a>
              <a
                class="display-flex align-items-center"
                href="#"
              >
                <i
                  @click="deleteCertificate(item.id)"
                  class="f7-icons"
                  style="color: red; font-size: 20px"
                  >trash</i
                >
              </a>
            </div>
          </td>
        </template>

        <template slot="paging-footer">
          <div
            v-if="hits.length > 0"
            class="data-table-footer"
            :class="$device.desktop ? '' : 'mobile-container'"
          >
            <div class="data-table-rows-select">
              Per page:
              <div class="input input-dropdown">
                <select
                  @input="onChangeLimit"
                  :value="hitsPerPage"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                </select>
              </div>
            </div>
            <div
              class="data-table-pagination"
              :style="$device.desktop ? '' : 'margin-left:-20px'"
            >
              <span
                v-if="page === 0"
                class="display-flex align-items-center margin-left color-gray"
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</span
              >
              <a
                v-else
                @click="onGoToPage('first')"
                class="display-flex align-items-center margin-left"
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</a
              >

              <span
                v-if="page === 0"
                class="margin-left color-gray"
                >Previous</span
              >
              <a
                v-else
                @click="onGoToPage('prev')"
                class="margin-left"
                >Previous</a
              >

              <span class="data-table-pagination-label margin-left">{{
                pagingMessage
              }}</span>

              <a
                v-if="page < nbPages - 1"
                @click="onGoToPage('next')"
                class="margin-left"
                >Next</a
              >
              <span
                v-else
                class="margin-left color-gray"
                >Next</span
              >

              <a
                v-if="page < nbPages - 1"
                @click="onGoToPage('last')"
                class="display-flex align-items-center margin-left"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></a>
              <span
                v-else
                class="display-flex align-items-center margin-left color-gray"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></span>
            </div>
          </div>
        </template>
      </pin-data-table>
      <AddNewMyCertificatePopup
        @closed="onSearch"
        :isEdit="isEdit"
        ref="addNewMyCertificate"
      ></AddNewMyCertificatePopup>
      <f7-photo-browser
        :photos="attachments"
        theme="dark"
        :ref="`detail_pageDark`"
        type="popup"
      ></f7-photo-browser>
    </div>
  </f7-page>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  onMounted,
  ref,
  defineEmits,
} from 'vue';
import { useMyCertificatesStore } from '../../../stores/my-certificates/actions';
import Menu from '../../../components/menu/Menu.vue';
import SafetyList from '../components/list/SafetyList.vue';
import _ from 'lodash';
import LongContentBlock from '@/components/blocks/LongContentBlock.vue';
import AddNewMyCertificatePopup from '../components/popups/AddNewMyCertificatePopup.vue';
import { toDisplayDateString as toDisplayDateStringUtility } from '../../../utility/datetime';
import { createMediaArray, openAttackLink } from '@/utility/common';
import PinDataTable from '../../../components/pindatatables/index.vue';
import moment from 'moment';
export default defineComponent({
  components: {
    Menu,
    SafetyList,
    AddNewMyCertificatePopup,
    LongContentBlock,
    PinDataTable,
  },
  setup() {
    const instance = getCurrentInstance() as any;

    const store = useMyCertificatesStore();
    const router = instance.proxy.$f7router;
    const searchText = computed(() => store.searchText);
    const addNewMyCertificate = ref<any>(null);
    const detailPageDark = ref<any>(null);
    const hits = computed(() => store.hits);
    const hitsPerPage = computed(() => store.hitsPerPage);
    const page = computed(() => store.page);
    const nbPages = computed(() => store.nbPages);
    const nbHits = computed(() => store.nbHits);
    const pinArrayData = ref<any>([null, true]);
    const attachments = ref<any>([]);
    const isEdit = ref(false);
    let allowInfinite = ref(true);
    let hasMoreItems = ref(true);
    let hasData = ref(true);
    const pagingMessage = computed(() => {
      const fromRow = page.value * hitsPerPage.value + 1;
      const toRow = page.value * hitsPerPage.value + hits.value.length;
      return `${fromRow} - ${toRow} of ${nbHits.value}`;
    });
    const headers = computed(() => [
      {
        text: 'Certificate Name',
        value: 'certificateName',
        sortable: false,
        isPin: true,
        align: 'left',
      },
      {
        text: 'Certificate Authority',
        value: 'certificateAuthority',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Certificate Number',
        value: 'certificateNumber',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Completion Date',
        value: 'completionDate',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Expired Date',
        value: 'expiredDate',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Note',
        value: 'note',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Attachments',
        value: 'attachments',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        isPin: false,
        align: 'left',
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        isPin: false,
        align: 'left',
      },
    ]);

    const toDisplayDateString = (val: any) => {
      return toDisplayDateStringUtility(val);
    };
    const openNewMyCertificate = (item: any) => {
      if (!addNewMyCertificate.value) return;
      const userId = instance.proxy.$f7route.params.employeeId;
      isEdit.value = !_.isEmpty(item);
      addNewMyCertificate.value.open(item, userId);
    };

    const setMyCertificateSearchText = (value: string) => {
      store.setSearchText(value);
    };
    const onSearch = _.debounce(() => {
      hasMoreItems.value = true;
      allowInfinite.value = true;
      hasData.value = true;
      const filters = `userId:${instance.proxy.$f7route.params.employeeId}`;

      return store
        .searchMyCertificates([], filters)
        .then(() => {
          if (page.value + 1 === nbPages.value) {
            hasMoreItems.value = false;
            allowInfinite.value = false;
          }
          if (hits.value.length === 0 && nbPages.value === 0) {
            hasData.value = false;
            hasMoreItems.value = false;
            allowInfinite.value = false;
          }
        })
        .finally(() => {
          hasMoreItems.value = false;
        });
    }, 500);
    const openLink = (attachmentFiles: [], attach: object) => {
      attachments.value = createMediaArray(attachmentFiles);
      openAttackLink(attach, attachments.value, detailPageDark.value);
    };
    const deleteCertificate = (id: string) => {
      const filters = `userId:${instance.proxy.$f7route.params.employeeId}`;
      instance.proxy.$ri.dialog.openWarningDialog({
        title: 'Delete Document',
        content: 'Are you sure you want to to delete the document?',
        textButton: 'Delete',
        onClick: async (_sefl: any, index: number) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            instance.proxy.$f7.preloader.show();
            await store.deleteMyCertificate(id, filters);
            instance.proxy.$f7.preloader.hide();
          }
        },
      });
    };
    const onChangeLimit = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const value = target.value;
      const numericValue = parseInt(value, 10);
      store.setNumberOfRows(numericValue);
      onSearch();
    };
    const onGoToPage = (pageName: string) => {
      instance.proxy.$f7.preloader.show();
      store.goToPage(pageName).finally(() => {
        instance.proxy.$f7.preloader.hide();
      });
    };

    const getRowLeft = (index: number) => {
      const tdElement = document.getElementById('column-pin-0');
      const width =
        tdElement && tdElement.offsetWidth ? tdElement.offsetWidth : 0;
      const truePinnedHeaders = pinArrayData.value.filter(Boolean);
      if (truePinnedHeaders.length < 1) {
        return '0px';
      }
      const pinnedRows = index === 1 ? width : 0;
      return pinnedRows + 'px';
    };
    const getLastTrue = (Array: [], index: number) => {
      let last = -1;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i] === true) {
          last = i;
        }
      }
      if (last === index) {
        return true;
      }
      return false;
    };
    const receivePinData = (data: []) => {
      pinArrayData.value = data;
    };
    const dateToValue = (a: any) => {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    };
    const getStatus = (expiredDate: string) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const expired = new Date(dateToValue(expiredDate));
      expired.setHours(0, 0, 0, 0);

      if (!expired || isNaN(expired.getTime())) {
        return { text: 'Active', color: 'green' };
      }

      const now = new Date();
      if (expired.getTime() === today.getTime()) {
        const endOfToday = new Date(today);
        endOfToday.setHours(23, 59, 59, 999);
        if (now.getTime() <= endOfToday.getTime()) {
          return { text: 'Expiring Soon', color: 'yellow' };
        } else {
          return { text: 'Expired', color: 'red' };
        }
      } else if (expired.getTime() > today.getTime()) {
        const diffTime = expired.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 30) {
          return { text: 'Active', color: 'green' };
        } else {
          return { text: 'Expiring Soon', color: 'yellow' };
        }
      } else {
        return { text: 'Expired', color: 'red' };
      }
    };
    onMounted(async () => {
      instance.proxy.$f7.preloader.show();
      await onSearch();
      instance.proxy.$nextTick(() => {
        addNewMyCertificate.value = instance.proxy.$refs.addNewMyCertificate;
        detailPageDark.value = instance.proxy.$refs.detail_pageDark;
      });
      instance.proxy.$f7.preloader.hide();
    });
    // onBeforeUnmount: Được gọi trước khi component bị hủy
    onBeforeUnmount(() => {
      // destroyStore();
    });

    // onUnmounted: Được gọi sau khi component đã bị hủy
    onUnmounted(() => {});

    return {
      openNewMyCertificate,
      setMyCertificateSearchText,
      onSearch,
      searchText,
      headers,
      hits,
      toDisplayDateString,
      openLink,
      attachments,
      deleteCertificate,
      isEdit,
      hitsPerPage,
      onChangeLimit,
      onGoToPage,
      page,
      nbPages,
      pagingMessage,
      pinArrayData,
      getRowLeft,
      getLastTrue,
      receivePinData,
      getStatus,
    };
  },
});
</script>

<style scoped lang="scss">
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tab-button {
  flex-direction: row;
  width: auto;
  font-weight: 500;
  background: var(--f7-color-bg-2-neutral);
  color: var(--f7-color-text-neutral);
  padding: 0 15px;
  font-size: 16px;
  gap: 8px;
}

.custom-link {
  width: fit-content !important;
}

.custom-link ::v-deep .toolbar-inner {
  justify-content: flex-start;
  gap: 16px;
  padding-left: 0px;
  width: fit-content;
}

.custom-link ::v-deep .tab-link:not(.tab-link-active) {
  background-color: white;
}

.tab-link-active {
  color: white;
  background: #f05034;
}

.pinned-row {
  position: sticky;
  z-index: 2;
  background-color: var(--f7-color-bg-4-neutral);
}

.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}

.mobile-container > * {
  padding: 10px 0;
}

.pinned-row::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 100%;
  box-shadow: 7px 0 5px rgba(10, 10, 10, 0.9);
  top: 0;
  right: 0;
}

.roof-type-box ::v-deep {
  .list {
    margin: 0;
    width: 100%;
  }

  ul {
    padding-left: 0;

    .item-content {
      padding-left: 0;
    }
  }
}

.row-info {
  margin: 0 10px;
}

.col-info {
  border: none;

  &::v-deep {
    .list ul {
      border-radius: 4px;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.time-update-text {
  color: var(--f7-color-text-5-neutral);
  font-size: 13px;
  line-height: 26px;
}
.full-screen {
  width: calc(100vw);
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: scroll;
}
</style>
