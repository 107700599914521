<template>
  <div class="comment">
    <f7-block-title v-show="!isClientView">Add a comment</f7-block-title>
    <div
      v-show="!isClientView"
      class="text-editor-container"
    >
      <f7-text-editor
        v-show="!isClientView"
        :value="displayContent"
        placeholder="Enter text..."
        mode="popover"
        resizable
        style="padding-right: 30px"
        @texteditor:change="onTextEditorChange"
      />
      <div
        v-if="isRecognizing"
        class="loader"
      >
        <span
          v-for="i in number"
          :key="i"
          :class="isRecognizing ? 'stroke' : ''"
        ></span>
      </div>
      <f7-icon
        v-if="!isRecognizing"
        f7="mic_fill"
        color="primary"
        class="text-editor-icon"
        @click.native="startRecognition"
      ></f7-icon>
      <f7-icon
        v-else
        f7="stop_circle"
        color="primary"
        class="text-editor-icon icon-animation"
        style="top: 10px"
        @click.native="stopRecognition"
      ></f7-icon>
    </div>
    <f7-block
      v-show="!isClientView"
      class="save-comment-block"
    >
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
        "
      >
        <div style="display: flex; align-items: center; gap: 5px">
          <select
            v-model="languageRecord"
            class="option-selected"
          >
            <option
              v-for="language in langs"
              :key="language.value"
              :value="language.value"
            >
              {{ language.displayName }}
            </option>
          </select>
          <div
            v-if="isTranslating"
            class="loader-translate"
          >
            <f7-preloader
              :size="15"
              color="var(--f7-list-item-title-text-color);"
            ></f7-preloader>
          </div>
        </div>
        <f7-button
          v-if="commentContent !== '' && commentContent !== '<br>'"
          style="padding: 0 25px"
          raised
          @click.native="saveComment"
          >Save</f7-button
        >
      </div>
    </f7-block>
    <div
      v-for="(item, index) in sortedComments"
      :key="index"
    >
      <f7-block
        strong
        inset
        style="display: flex; align-items: flex-start"
        :style="isRecognizing ? 'top: 15px' : ''"
      >
        <user-avatar
          :avatar-size="30"
          :image-url="item.imageUrl"
        ></user-avatar>
        <div
          style="
            margin-left: 15px;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
          "
        >
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <strong>{{ item.createdBy }}</strong>
            <div style="color: var(--f7-block-footer-text-color)">
              {{ toLocaleString(item.createdAt) }}
            </div>
          </div>
          <span
            style="word-break: break-word; display: block"
            v-html="
              item.translatedText ? item.translatedText : item.htmlContent
            "
          ></span>
          <div
            v-if="item.isShowOrigin && isShowTranslate"
            style="display: flex; flex-direction: row; gap: 10px"
          >
            <f7-icon
              f7="arrow_turn_down_right"
              color="#ababab6b"
            ></f7-icon>
            <span v-html="item.htmlContent"></span>
          </div>
          <span
            v-if="
              item.translatedText &&
              item.translatedText !== item.htmlContent &&
              isShowTranslate
            "
            class="text-translated"
            :class="item.isShowOrigin && 'text-translated-active'"
            @click="() => handleShowOrigin(item)"
            >{{ item.isShowOrigin ? 'Hide original' : 'Show original' }}</span
          >
        </div>
      </f7-block>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { firebase, auth } from '@/services/firebase.service';
import _ from 'lodash';
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import {
  convertToGoogleTranslateCode,
  languages,
} from '../../../../lang/languages.js';
import { v4 as uuid4 } from 'uuid';
import {
  clearSelectionHightLight,
  fetchTranslatedComments,
} from '@/utility/common.js';
import {
  getActiveLanguage,
  handleDetectLanguage,
  handleTranslateText,
} from '@/lang/translation.js';

export default {
  components: {
    UserAvatar,
  },
  props: {
    photoId: String,
    comments: Array,
    isClientView: { type: Boolean, default: false },
    isShowTranslate: { type: Boolean, default: false },
  },
  data() {
    return {
      commentContent: '',
      recognition: null,
      isRecognizing: false,
      finalTranscript: '',
      interimTranscript: '',
      number: 25,
      intervalId: null,
      langs: languages,
      languageRecord: 'es-MX',
      timer: '00:00',
      countdown: null,
      isClear: false,
      isTranslating: false,
      commentList: [],
      translatedComments: [],
    };
  },
  computed: {
    sortedComments() {
      if (_.isEmpty(this.translatedComments) || _.isEmpty(this.comments))
        return [];

      return (this.translatedComments || [])
        .slice()
        .sort((a, b) => b.createdAt - a.createdAt);
    },
    displayContent() {
      return this.isRecognizing ? `${this.timer}` : this.commentContent;
    },
  },
  mounted() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert(
        'Your browser does not support the Web Speech API. Please use a supported browser like Google Chrome or Safari.'
      );
      return;
    }
    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = this.languageRecord;

    this.recognition.onstart = () => {
      this.isRecognizing = true;
      this.finalTranscript = '';
      this.interimTranscript = '';
    };

    this.recognition.onresult = event => {
      let interimTranscript = '';
      let finalTranscript = '';
      if (this.$device.android) {
        let resultsLength = event.results.length - 1;
        let ArrayLength = event.results[resultsLength].length - 1;
        const transcript = event.results[resultsLength][ArrayLength].transcript;
        if (event.results[resultsLength].isFinal) {
          finalTranscript += transcript + ' ';
        } else {
          interimTranscript += transcript;
        }
      } else {
        for (let i = 0; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript + ' ';
          } else {
            interimTranscript += transcript;
          }
        }
      }
      this.interimTranscript = interimTranscript;
      this.finalTranscript = finalTranscript;
      this.commentContent = this.finalTranscript;

      //set countdown for stop recognition after 5s
      if (this.interimTranscript !== '') {
        this.resetCountdown();
      } else {
        this.startCountdown();
      }
    };

    this.recognition.onerror = event => {
      this.$ri.dialog.openWarningDialog({
        title: `${event.type}`,
        content: `Please try again, your mic is ${event.error}`,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    };

    this.recognition.onend = () => {
      this.isRecognizing = false;
      this.stopTimer();
      if (this.isClear) {
        this.commentContent = '';
        this.finalTranscript = '';
        this.interimTranscript = '';
        this.isClear = false;
      }
    };
  },
  methods: {
    ...mapActions('photo/photo', ['updateProjectPhoto']),
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },
    handleShowOrigin(item) {
      const newDataComments = this.translatedComments.map(comment => {
        if (comment.id === item.id) {
          return {
            ...comment,
            isShowOrigin: !comment.isShowOrigin,
          };
        }
        return comment;
      });

      this.translatedComments = newDataComments;
    },
    async saveComment() {
      clearSelectionHightLight();
      this.isTranslating = true;
      let comments = _.cloneDeep(this.comments) || [];
      const { message } = await handleDetectLanguage(this.commentContent);

      comments.push({
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        htmlContent: this.commentContent,
        imageUrl: auth.currentUser.photoURL,
        language: message,
      });

      this.updateProjectPhoto({
        id: this.photoId,
        doc: {
          comments: comments,
        },
      }).then(() => {
        this.stopRecognition();
        this.finalTranscript = '';
        this.interimTranscript = '';
        this.commentContent = '';
        this.isTranslating = false;
      });
    },
    startRecognition() {
      if (this.recognition) {
        this.commentContent = '';
        this.recognition.start();
        this.startTimer();
      }
    },
    stopRecognition() {
      if (this.recognition) {
        this.recognition.stop();
        this.isRecognizing = false;
        this.stopTimer();
        clearTimeout(this.countdown);
      }
    },
    startTimer() {
      this.timer = '00:00';
      let seconds = 0;
      this.intervalId = setInterval(() => {
        seconds++;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.timer = `${String(minutes).padStart(2, '0')}:${String(
          remainingSeconds
        ).padStart(2, '0')}`;
      }, 1000);
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    startCountdown() {
      clearTimeout(this.countdown);
      this.countdown = setTimeout(() => {
        if (!this.interimTranscript && this.isRecognizing) {
          this.stopRecognition();
        }
      }, 5000);
    },
    resetCountdown() {
      clearTimeout(this.countdown);
      this.startCountdown();
    },
    onTextEditorChange(val) {
      if (this.isRecognizing) {
        this.commentContent = this.finalTranscript;
      } else {
        this.commentContent = val;
      }
    },
    clearComment() {
      if (this.isRecognizing) {
        this.isClear = true;
      } else {
        this.commentContent = '';
        this.finalTranscript = '';
        this.interimTranscript = '';
      }
      this.stopRecognition();
    },
  },
  watch: {
    comments: {
      handler(newVal) {
        this.translatedComments = [];

        if (newVal) {
          this.commentList = (this.comments || []).map(item => {
            return { ...item, id: uuid4(), translatedText: '' };
          });
        }
      },
      deep: true,
      immediate: true,
    },
    languageRecord: {
      handler(newVal) {
        if (newVal && this.recognition) {
          this.recognition.lang = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    commentList: {
      immediate: true,
      deep: true,
      handler(newValue) {
        fetchTranslatedComments(newValue || []).then(data => {
          this.translatedComments = data;
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.option-selected {
  width: 120px;
  border: 1px solid #cccccc91;
  border-radius: 3px;
  padding: 2px 10px;
  cursor: pointer;
}
.text-translated {
  cursor: pointer;
  color: var(--f7-theme-color);
}
.text-translated-active {
  font-weight: bold;
}
.input-dropdown {
  border: 1px solid gray;
  border-radius: 2px;
  padding: 2px;
  margin-top: 3px;
}
.timer {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.text-editor-container {
  position: relative;
}
.text-editor-icon {
  position: absolute;
  right: 25px;
  bottom: 10px;
  z-index: 9999;
  cursor: pointer;
}
.reverse {
  color: #ababab6b;
}
.reverse:hover {
  color: var(--f7-theme-color);
  cursor: pointer;
}
.loader {
  height: 10px;
  width: max-content;
  display: flex;
  align-items: center;
  margin-top: -35px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .stroke {
  display: block;
  position: relative;
  background: #f05034;
  height: 120%;
  width: 2px;
  border-radius: 50px;
  margin: 0 3px;
  animation: animate 0.8s linear infinite;
}

@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
@for $i from 1 through 25 {
  .stroke:nth-child(#{$i}) {
    animation-delay: #{0.2 * (($i - 1) % 4)}s;
  }
}
.icon-animation {
  animation: wave 0.8s infinite;
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}

@media (max-width: 1400px) {
  .option-selected {
    width: 90px;
  }
}
</style>
