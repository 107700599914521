<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <form @submit.prevent="add">
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Add new category</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="add()">Next</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>Category name <required-asterisk /></f7-block-title>
        <f7-list>
          <f7-list-input
            class="first-input"
            placeholder="Input category name"
            :value="object.name"
            @input="object.name = $event.target.value.trim()"
            error-message-force
            clear-button
            :error-message="nameErrorMessage"
          ></f7-list-input>
        </f7-list>

        <f7-block-title>Roof types <required-asterisk /></f7-block-title>
        <f7-list>
          <f7-list-item
            ref="roofTypesPopup"
            smart-select
            :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Search',
              on: {
                closed: v => (object.roofTypes = v.getValue()),
                opened: handleSmartSelectPopupOpened,
              },
              appendSearchbarNotFound: 'No Data',
              cssClass: 'multi-select-popup',
            }"
            title="Select roof types"
          >
            <div
              slot="footer"
              class="text-color-red"
            >
              {{ roofTypesErrorMessage }}
            </div>
            <select multiple>
              <option
                v-for="item in roofTypeList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.displayName }}
              </option>
            </select>
          </f7-list-item>
        </f7-list>
      </form>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {},
  props: {
    isShow: Boolean,
  },
  data: () => ({
    product: [],
    object: {
      name: '',
      roofTypes: [],
    },
  }),
  methods: {
    ...mapActions({
      create: 'product/category-page/category/create',
    }),
    cancel() {
      this.object = {
        name: '',
        roofTypes: [],
      };
      this.$refs[`roofTypesPopup`].f7SmartSelect.unsetValue();
      this.v$.$reset();
      this.$emit('close');
    },

    async add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      var { name, roofTypes } = this.object;
      this.cancel();

      var data = {
        name,
        roofTypes,
      };
      const id = await this.create(data);
      this.$f7router.navigate(`/category/${id}`);
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },

    handleSmartSelectPopupOpened() {
      if (this.$device.desktop) {
        this.$refs.roofTypesPopup.f7SmartSelect.searchbar.$el[0]
          .querySelector('.searchbar input')
          .focus();
      }
    },
  },

  computed: {
    ...mapGetters({
      roofTypeList: 'product/roof-type/roofTypeList',
    }),
    nameErrorMessage() {
      if (!this.v$.object.name.$error) return null;
      if (this.v$.object.name.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    roofTypesErrorMessage() {
      if (!this.v$.object.roofTypes.$error) return null;
      if (this.v$.object.roofTypes.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      object: {
        name: {
          required,
        },
        roofTypes: {
          required(val) {
            return val && val.length > 0;
          },
        },
      },
    };
  },
};
</script>
<style>
.multi-select-popup .searchbar-not-found {
  padding: 8px;
  text-align: center;
}
</style>