<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Metal Templates</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search metal template"
      :clear-button="true"
      :value="searchValue"
      @input="
        setSearchValue($event.target.value);
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:disable="
        setSearchValue('');
        onSearch();
      "
      @searchbar:clear="
        setSearchValue('');
        onSearch();
      "
      @blur="
        if (!searchValue.trim()) {
          setSearchValue('');
        }
      "
      class="search-list"
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list
        media-list
        class="no-margin"
      >
        <f7-list-group media-list>
          <f7-list-item
            v-for="(item, index) in hits"
            :key="index"
            :title="item.templateNumber"
            :reload-detail="true"
            :link="`/assembly-template/${item.templateNumber}`"
            :text-color="
              item.id === (assemblyTemplate || {}).id ? 'primary' : 'default'
            "
          >
            <f7-row slot="text">
              <f7-col>
                <div>
                  <div
                    class="font-text"
                    :style="{
                      color:
                        item.id === (assemblyTemplate || {}).id
                          ? '#f05034'
                          : '',
                    }"
                  >
                    {{ item.templateName }}
                  </div>
                  <div class="font-text">
                    {{ displayRooftype(item.roofTypes) }}
                  </div>
                </div>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Menu from '../../../components/menu/Menu.vue';

export default {
  components: {
    Menu,
  },

  data: () => {
    return {
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list.searchbar input').focus();
      }
    });
  },
  created() {
    this.$f7.preloader.show();
    if (this.searchValue) {
      this.setSearchValue(this.searchValue);
    } else {
      this.setSearchValue('');
    }
    this.onSearch();
    this.$f7.preloader.hide();
  },

  computed: {
    ...mapGetters('common/app-constant', ['roofTypeBy']),
    ...mapGetters('common/assembly-template', [
      'searchValue',
      'assemblyTemplate',
      'hits',
      'nbPages',
      'page',
    ]),
    displayRooftype() {
      return roofTypes => {
        return roofTypes
          .map(item => this.roofTypeBy(item).displayName || '')
          .join(', ');
      };
    },
  },

  methods: {
    ...mapActions('common/assembly-template', [
      'searchAssemblyTemplate',
      'setSearchValue',
      'loadMoreAssemblyTemplate',
      'resetSearch',
    ]),
    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function () {
      const self = this;
      return self
        .searchAssemblyTemplate({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreAssemblyTemplate({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
  },
  beforeDestroy() {
    this.resetSearch();
  },
};
</script>

<style scoped>
.font-text {
  font-size: 11px;
}
</style>
