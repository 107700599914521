<template>
  <div
    v-if="
      checkShowContentScreenSingleInEstimate(
        showContentType,
        CONTENT_TYPE_EST.INFO
      )
    "
    :class="typeSwitchScreen !== 'step' && 'container-info'"
  >
    <f7-list>
      <div
        :class="
          typeSwitchScreen === 'step' && $device.desktop
            ? 'container-info-step-row'
            : 'container-info-step-column'
        "
      >
        <div
          style="flex: 1"
          :class="typeSwitchScreen !== 'step' && 'container-info-content'"
        >
          <f7-list
            media-list
            :inset="$device.desktop"
          >
            <!-- Building/Section# -->
            <f7-list-input
              label="System #"
              placeholder="Enter System #"
              type="text"
              :value="buildingName"
              error-message-force
              validate
              validate-on-blur
              :error-message="buildingNameErrorMessage"
              @input="
                buildingName = $event.target.value.trim();
                v$.buildingName.$touch();
                if (v$.buildingName.$invalid) return;
                saveBuildingValue('buildingName', buildingName);
                updateEstimateBuildingName({
                  buildingId: currentBuilding.id,
                  newBuildingName: buildingName,
                });
              "
            >
              <required-asterisk slot="label"></required-asterisk>
              <input-icon
                slot="media"
                icon="building_2"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Number of Building/Sections"
              :value="numberOfCopy"
              class="number-building-input"
              placeholder="Enter number of buidings/sections"
              type="number"
              error-message-force
              validate
              validate-on-blur
              :error-message="numberOfBuildingsErrorMessage"
              @input="onChangeNumberOfCopy($event.target.value.trim())"
            >
              <!-- <div class="cursor-pointer" slot="inner-start" @click="() => {}">
                {{ "Number of Building/Sections" }}
              </div> -->
              <input-icon
                slot="media"
                icon="building_2"
              ></input-icon>
            </f7-list-input>
          </f7-list>

          <!-- StackCT -->
          <f7-list
            v-if="project.businessCode != 'service'"
            :inset="$device.desktop"
          >
            <f7-list-item title="Enable StackCT Integration">
              <f7-toggle
                slot="after"
                color="red"
                :checked="currentBuilding.stackctIntegration || false"
                @toggle:change="onChangeStackIntergration($event)"
              ></f7-toggle>
            </f7-list-item>
          </f7-list>

          <f7-list
            v-if="currentBuilding.stackctIntegration && stackctProjects.length"
            :inset="$device.desktop"
            media-list
          >
            <f7-list-input
              v-show="currentBuilding.stackctIntegration"
              label="StackCT Project"
              type="select"
              :value="currentBuilding.stackTakeOffId"
              @input="onChangeStackCTProject($event)"
            >
              <input-icon
                slot="media"
                icon="arrow_2_squarepath"
              ></input-icon>
              <option
                value=""
                hidden
              >
                Select Project
              </option>
              <option
                v-for="project in stackctProjects"
                :key="project.TakeOffId"
                :value="project.TakeOffId"
              >
                {{ project.Name }}
              </option>
            </f7-list-input>

            <f7-list-item
              v-show="
                currentBuilding.stackctIntegration &&
                currentBuilding.stackTakeOffId
              "
              link
              @click.native="openSelectPages"
            >
              <div
                slot="inner-start"
                class="list-item-inner-start"
              >
                Drawings of {{ currentBuilding.buildingName }}
              </div>
              <input-icon
                slot="media"
                icon="skew"
              ></input-icon>
              <div
                slot="title"
                class="list-item-title"
              >
                <span v-if="(currentBuilding.stackPageIds || []).length">
                  {{ pageByIds(currentBuilding.stackPageIds) }}
                </span>
                <span
                  v-else
                  class="list-item-title_placeholder"
                  >Select drawings</span
                >
              </div>
            </f7-list-item>
          </f7-list>
          <f7-block
            v-if="
              currentBuilding.stackctIntegration &&
              isLoadStackctProject === false &&
              !stackctProjects.length
            "
            class="theme-color"
            >Not found StackCT projects. Please check again!</f7-block
          >

          <!-- Description -->
          <f7-list
            media-list
            :inset="$device.desktop"
          >
            <f7-list-input
              label="Description"
              type="textarea"
              placeholder="Enter description..."
              :value="currentBuilding.description"
              @change="
                saveBuildingValue('description', $event.target.value.trim())
              "
              ><input-icon
                slot="media"
                class="desc-icon"
                icon="doc"
              ></input-icon
            ></f7-list-input>
          </f7-list>
        </div>

        <div
          :style="
            typeSwitchScreen === 'step' && $device.desktop
              ? ' flex: 1; max-width: 50%;'
              : 'flex: 1;'
          "
        >
          <roof-info-form
            ref="roofInfoForm"
            :slopeTypeList="slopeTypeList"
            :roofTypeList="roofTypes"
            :workTypeList="workTypes"
            @onSelectedPriceList="onSelectedPriceList"
            @onSelectedVendor="onSelectedVendor"
          ></roof-info-form>
        </div>
      </div>
    </f7-list>

    <!-- popups -->
    <div>
      <dynamic-muti-select
        ref="selectPages"
        search-placeholder="Search in drawings"
        :title="`Select Drawings of ${currentBuilding.buildingName}`"
        :value="currentBuilding.stackPageIds || []"
        :options="pageListOptions"
        @changed="changePages"
      >
      </dynamic-muti-select>
    </div>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import DynamicMutiSelect from '../inputs/DynamicMultiSelect.vue';

import RoofInfoForm from '../forms/RoofInfoForm.vue';

import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxValue } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';
import stackctServices from '@/services/stackct.service';
import {
  SLOPE_TYPE_LOW,
  TAX_APPLY_TYPE,
  ROOF_TYPE_SHINGLE,
  WORK_TYPE_RECOVER,
  CONTENT_TYPE_EST,
  VALIDATION_MESSAGE,
} from '@/utility/const';
import { checkShowContentScreenSingleInEstimate } from '@/utility/common';

export default {
  components: {
    InputIcon,
    DynamicMutiSelect,
    RoofInfoForm,
  },

  mixins: [mixins, buildingMixins],

  props: {
    showContentType: {
      type: String,
      default: 'all',
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  data() {
    return {
      buildingName: '',
      numberOfCopy: 0,
      isGoingToDetails: false,
      // estimateNumber: "",
      selectPriceListMessage: '',
      selectRoofTypeMessage: '',
      isLoadStackctProject: null,
      isEndDate: false,
      SLOPE_TYPE_LOW,
      TAX_APPLY_TYPE,
      CONTENT_TYPE_EST,
    };
  },

  validations: {
    buildingName: { required },
    numberOfCopy: { required, minValue: minValue(1), maxValue: maxValue(200) },
  },
  async created() {
    if (
      _.isEmpty(this.priceListDefault) &&
      checkShowContentScreenSingleInEstimate(
        this.showContentType,
        CONTENT_TYPE_EST.INFO
      )
    ) {
      await this.getPriceListDefault();
    }

    if (_.isEmpty(this.vendors)) {
      await this.getVendors();
    }
  },
  computed: {
    ...mapGetters('common/app-constant', [
      'roofTypeList',
      'roofTypeBy',
      'slopeType',
      'workType',
      'workTypeList',
      'slopeTypeList',
      'tpoWarrantyOption',
    ]),
    ...mapGetters('estimate/estimate-page/project', ['project']),

    ...mapGetters('estimate/estimate-page/price-list', [
      'priceList',
      'priceListDefault',
    ]),

    ...mapGetters('estimate/estimate-page/estimate', [
      'estimate',
      'typeSwitchScreen',
    ]),

    ...mapGetters({
      // estimateById: "estimate/estimate-page/estimate/estimateById",
      categories: 'estimate/estimate-page/estimate/category/objectList',
      subCategories: 'estimate/estimate-page/estimate/sub-category/objectList',
      currentBuilding: 'estimate/estimate-page/estimate/currentBuilding',
      vendors: 'estimate/estimate-page/vendor/objectList',
    }),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('estimate/estimate-page/stackct', [
      'stackctProjects',
      'stackctTakeoffs',
      'stackctPages',
    ]),

    workTypes() {
      if (this.currentBuilding.roofType === ROOF_TYPE_SHINGLE) {
        return this.workTypeList.filter(r => r.value !== WORK_TYPE_RECOVER);
      }
      return this.workTypeList;
    },

    roofTypes() {
      return this.roofTypeList
        .filter(type => {
          return (
            type.workSteps &&
            type.workSteps.commercial &&
            type.slopeType === this.currentBuilding.slopeType
          );
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });
    },

    pageListOptions() {
      return (this.stackctPages || []).map(r => ({
        id: r.PageId,
        value: r.PageName,
      }));
    },

    buildingNameErrorMessage() {
      if (!this.v$.buildingName.$error) return '';
      if (this.v$.buildingName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },

    numberOfBuildingsErrorMessage() {
      if (!this.v$.numberOfCopy.$error) return '';
      if (this.v$.numberOfCopy.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfCopy.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      if (this.v$.numberOfCopy.maxValue.$invalid) {
        return VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('estimate/estimate-page/estimate', [
      'updateEstimate',
      'getProductDataTemplate',
      'updateNumberOfCopyEstimateManually',
    ]),

    ...mapActions('estimate/estimate-page/product-item', [
      'getActiveProductItemsByPriceListId',
    ]),

    ...mapActions('estimate/estimate-page/price-list', [
      'bindPriceListItem',
      'unbindPriceListItem',
      'getPriceListDefault',
    ]),
    ...mapActions('estimate/estimate-page/stackct', [
      'setStackctProjects',
      'setStackctTakeoffs',
      'setStackctPages',
    ]),
    ...mapActions('estimate/estimate-page/assembly-drawing', [
      'getAssemblyDrawingByPriceList',
    ]),
    ...mapActions({
      getVendors: 'estimate/estimate-page/vendor/getVendors',
    }),

    checkShowContentScreenSingleInEstimate,

    async onChangeNumberOfCopy(value) {
      this.numberOfCopy = parseInt(value);
      this.v$.numberOfCopy.$touch();
      if (this.v$.numberOfCopy.$invalid) return;
      this.updateNumberOfCopyEstimateManually({
        estId: this.estimate.id,
        buildingId: this.currentBuilding.id,
        value,
      });
      await this.saveBuildingValue('numberOfCopy', this.numberOfCopy);
      await this.updateEstimateBuildingCopy({
        buildingId: this.currentBuilding.id,
        newNumberOfCopy: this.numberOfCopy,
      });
    },

    onSelectedVendor() {
      this.$f7.preloader.show();
      this.getActiveProductItemsByPriceListId(this.currentBuilding.priceListId)

        .then(() => {
          return this.getProductDataTemplate({
            roofType: this.currentBuilding.roofType,
          });
        })
        .then(productData => {
          return this.saveBuildingValue('productData', productData);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    onSelectedPriceList(priceListId) {
      if (_.isEmpty(priceListId)) {
        return;
      }
      // bind product by price list id
      this.bindPriceListItem(priceListId)
        .then(() => {
          return this.getActiveProductItemsByPriceListId(priceListId);
        })
        .then(() => {
          return this.saveBuildingValue('assemblyList', []);
        })
        .then(() => {
          return this.getProductDataTemplate({
            roofType: this.currentBuilding.roofType,
          });
        })
        .then(productData => {
          return this.saveBuildingValue('productData', productData);
        });

      this.getAssemblyDrawingByPriceList(priceListId);
    },

    validate() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    init() {},
    initStackData() {
      this.$f7.preloader.show();
      this.stackCTCheckAuthMiddleware(() => {
        this.isLoadStackctProject = true;
        this.setStackctProjects().finally(() => {
          this.isLoadStackctProject = false;
          this.$f7.preloader.hide();
        });
      });
    },
    stackCTCheckAuthMiddleware(callback, openLogin = 1) {
      return stackctServices.checkAuth().then(data => {
        if (data.isAuthorized) {
          return callback();
        } else {
          this.$f7.preloader.hide();
          openLogin && this.openLoginPopup();
        }
      });
    },
    openLoginPopup() {
      let app = this;
      this.$ri.dialog.openInfoDialog({
        title: 'Connect to StackCT.',
        content:
          'You need to set up a connection between RooferIntel and StackCT. It will take about 5 minutes. Do you want to proceed now?',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            stackctServices.getAuthUri(app.$f7router.url).then(uri => {
              window.location.href = uri;
            });
          }
        },
      });
    },
    async onChangeStackCTProject($event) {
      await this.saveBuildingValue('stackTakeOffId', $event.target.value);
      await this.saveBuildingValue('stackPageIds', []);
    },
    async onChangeStackIntergration(value) {
      if (!value) {
        // reset old value
        await this.saveBuildingValue('stackTakeOffId', '');
        await this.saveBuildingValue('stackPageIds', []);
        this.isLoadStackctProject = null;
      } else {
        await this.initStackData();
      }
      await this.saveBuildingValue('stackctIntegration', value);
    },
    pageByIds(ids) {
      if (!ids) return '';
      return (
        this.pageListOptions.filter(r => (ids || []).includes(r.id)) || []
      )
        .map(r => r.value)
        .join(', ');
    },
    openSelectPages() {
      if (this.currentBuilding.stackTakeOffId) {
        this.$refs.selectPages.openSelectPopup();
      }
    },
    changePages(values) {
      this.saveBuildingValue('stackPageIds', values);
    },
    toDetails(estimateNumber) {
      this.isGoingToDetails = true;
      this.estimateNumber = estimateNumber;
    },

    updateEstimateBuildingName({ buildingId, newBuildingName }) {
      const buildings = _.cloneDeep(this.estimate.buildings);
      if (_.isEmpty(buildings)) {
        return;
      }
      const building = buildings.find(item => item.buildingId === buildingId);
      if (_.isEmpty(building)) {
        return;
      }

      building.buildingName = newBuildingName;
      this.updateEstimate({
        id: this.estimate.id,
        doc: {
          buildings: buildings,
        },
      });
    },

    updateEstimateBuildingCopy({ buildingId, newNumberOfCopy }) {
      const buildings = _.cloneDeep(this.estimate.buildings);
      if (_.isEmpty(buildings)) {
        return;
      }
      const building = buildings.find(item => item.buildingId === buildingId);
      if (_.isEmpty(building)) {
        return;
      }

      building.numberOfCopy = newNumberOfCopy;
      this.updateEstimate({
        id: this.estimate.id,
        doc: {
          buildings: buildings,
        },
      });
    },
  },
  watch: {
    currentBuilding: {
      handler(val) {
        if (!_.isEmpty(val)) {
          if (val.slopeType) {
            this.selectRoofTypeMessage = '';
          }
          if (val.roofType) {
            this.selectPriceListMessage = '';
          }

          if (val.buildingName) {
            this.buildingName = val.buildingName;
          }

          if (val.numberOfCopy) {
            this.numberOfCopy = val.numberOfCopy;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    'currentBuilding.stackTakeOffId': {
      async handler(value) {
        if (value) {
          await this.setStackctPages(value);
          await this.setStackctTakeoffs(value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
  &_placeholder {
    color: var(--f7-color-placeholder);
  }
}

.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  margin-top: 10px;
  gap: 10px;
}

.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 1px 0 16px 0;
}

.container-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
