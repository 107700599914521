<template>
  <f7-popup
    class="demo-popup"
    :opened="showTimerPopup"
    @popup:opened="handlePopupOpened"
    @popup:closed="closedPopup"
  >
    <f7-page class="time-tracking-form">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{ $t('timeTracking.add.cancel') }}</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ $t('timeTracking.add.title') }}</f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>

      <div style="margin: 20px">
        <f7-card :style="$device.desktop ? 'width: 50%; margin: auto' : ''">
          <f7-card-header>
            {{ $t('timeTracking.add.override') }}
            <f7-toggle
              :disabled="
                timeElapsed24Hours ||
                [TIME_LOG_TYPE_TIME_OFF, TIME_LOG_TYPE_LUNCHTIME].includes(
                  currenttimeTracking.timeLogType
                ) ||
                !isShowStartTime
              "
              :checked="currenttimeTracking.override"
              @toggle:change="currenttimeTracking.override = $event"
            ></f7-toggle>
          </f7-card-header>
          <f7-card-content
            class="display-flex justify-content-center align-items-center"
            style="padding: 20px 20px 40px 20px"
          >
            <!-- enter time -->
            <div v-if="currenttimeTracking.override">
              <div class="list-item-inner-start">
                {{
                  ![TIME_LOG_TYPE_TIME_OFF, TIME_LOG_TYPE_LUNCHTIME].includes(
                    currenttimeTracking.timeLogType
                  )
                    ? $t('timeTracking.add.time')
                    : $t('timeTracking.add.numberOfHours')
                }}<required-asterisk />
              </div>
              <template
                v-if="
                  currenttimeTracking.timeLogType !== TIME_LOG_TYPE_LUNCHTIME
                "
              >
                <input-spent-time
                  style="font-size: 40px"
                  :spentTime="spentTime"
                  :maxValue="maxTimeValue"
                  @changeSpentTime="changeSpentTime"
                ></input-spent-time>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ spentTimeErrorMessage }}
                </div>
              </template>
              <template v-else>
                <f7-input
                  outline
                  style="font-size: 40px"
                  type="number"
                  min="0"
                  placeholder="Hours"
                  :value="currenttimeTracking.overrideHour"
                  @input="
                    currenttimeTracking.overrideHour = $event.target.value
                  "
                ></f7-input>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ overrideHourErrorMessage }}
                </div>
              </template>
            </div>

            <!-- start time -->
            <div
              v-if="
                !currenttimeTracking.override &&
                isShowStartTime &&
                currenttimeTracking.timeLogType != TIME_LOG_TYPE_TIME_OFF &&
                currenttimeTracking.timeLogType != TIME_LOG_TYPE_LUNCHTIME
              "
            >
              <div class="start-time">
                <h1
                  style="font-size: 40px"
                  class="no-margin"
                >
                  {{ totalTimeInTimer | timerFormat }}
                </h1>
                <div class="display-flex">
                  <f7-button
                    fill
                    :color="totalTimeInTimer ? 'theme-color' : 'gray'"
                    class="circle-btn"
                    @click="restartTimer"
                  >
                    <f7-icon f7="arrow_counterclockwise"></f7-icon>
                  </f7-button>
                  <f7-button
                    v-if="!isPendingTracking"
                    fill
                    class="circle-btn"
                    @click="startTimer"
                  >
                    <f7-icon f7="play_fill"></f7-icon>
                  </f7-button>
                  <div
                    v-else
                    class="display-flex"
                  >
                    <f7-button
                      v-if="isLastTimerRunning"
                      fill
                      class="circle-btn"
                      @click="pauseTimer"
                    >
                      <f7-icon f7="pause"></f7-icon>
                    </f7-button>
                    <f7-button
                      v-else
                      fill
                      class="circle-btn"
                      @click="startTimer"
                    >
                      <f7-icon f7="play_fill"></f7-icon>
                    </f7-button>
                  </div>
                </div>
              </div>
              <div
                style="color: red; text-align: center"
                class="margin-top-half"
              >
                {{ totalTimeInTimerErrorMessage }}
              </div>
            </div>
          </f7-card-content>
        </f7-card>
      </div>

      <f7-list accordion-list>
        <f7-list-item accordion-item>
          <div
            slot="title"
            style="font-weight: bold"
          >
            Information Details
          </div>
          <div
            v-if="$device.desktop"
            slot="after"
            class="theme-color"
          >
            {{ infomationDetailErrorMessage }}
          </div>
          <div
            v-else
            slot="footer"
            class="theme-color"
          >
            {{ infomationDetailErrorMessage }}
          </div>

          <f7-accordion-content>
            <f7-list>
              <!-- time log type -->
              <f7-list-item>
                <div slot="header">
                  {{ $t('timeTracking.add.timeLogType') }}<required-asterisk />
                </div>
                <f7-input
                  slot="title"
                  type="select"
                  :value="currenttimeTracking.timeLogType"
                  @change="changeTimeLogType"
                >
                  <option
                    v-for="item in filteredTimeLogTypeList"
                    :key="item.id"
                    :value="item.value"
                  >
                    {{ $t('timeTracking.timeLogType.' + item.value) }}
                  </option>
                </f7-input>
                <input-icon
                  slot="media"
                  icon="square_on_circle"
                ></input-icon>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ timeLogTypeErrorMessage }}
                </div>
              </f7-list-item>

              <!-- project/timeoff/title -->
              <f7-list-item
                v-show="
                  currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT
                "
                link
                @click.native="selectProject"
              >
                <div slot="header">
                  {{ $t('timeTracking.add.project') }}<required-asterisk />
                </div>
                <div slot="title">
                  {{
                    currenttimeTracking.project
                      ? currenttimeTracking.project.title
                      : $t('timeTracking.add.selectProject')
                  }}
                </div>
                <input-icon
                  slot="media"
                  icon="cube_box"
                ></input-icon>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ projectErrorMessage }}
                </div>
              </f7-list-item>

              <f7-list-input
                v-show="
                  currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT
                "
                :label="$t('timeTracking.add.buildingOrSection')"
                :placeholder="$t('timeTracking.add.enterBuildingOrSection')"
                type="text"
                :value="currenttimeTracking.buildingOrSection"
                @input="
                  currenttimeTracking.buildingOrSection = $event.target.value
                "
              >
                <input-icon
                  slot="media"
                  icon="building_2"
                ></input-icon>
              </f7-list-input>

              <f7-list-item
                v-show="
                  currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF
                "
              >
                <div slot="header">
                  {{ $t('timeTracking.add.time-off') }}<required-asterisk />
                </div>
                <f7-input
                  slot="title"
                  type="select"
                  :value="currenttimeTracking.timeOffType"
                  @change="
                    currenttimeTracking.timeOffType = $event.target.value
                  "
                >
                  <option
                    v-for="item in timeOffTypeList"
                    :key="item.id"
                    :value="item.value"
                  >
                    {{ item.displayName }}
                  </option>
                </f7-input>
                <input-icon
                  slot="media"
                  icon="cube_box"
                ></input-icon>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ timeOffTypeErrorMessage }}
                </div>
              </f7-list-item>

              <f7-list-item
                v-show="
                  currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS
                "
              >
                <div slot="header">
                  {{ $t('timeTracking.add.otherTitle') }}<required-asterisk />
                </div>
                <f7-input
                  slot="title"
                  type="text"
                  placeholder="Enter Title"
                  :value="currenttimeTracking.title"
                  @input="changeTitle($event.target.value.trim())"
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="pencil_ellipsis_rectangle"
                ></input-icon>
                <div
                  slot="footer"
                  style="color: red"
                >
                  {{ titleErrorMessage }}
                </div>
              </f7-list-item>

              <!-- date timer -->
              <f7-list-input
                v-show="!currenttimeTracking.override"
                :label="$t('timeTracking.add.date')"
                placeholder="MM/DD/YYYY"
                error-message-force
                validate
                validate-on-blur
                :value="currentDate"
                :error-message="dateErrorMessage"
                :readonly="true"
              >
                <input-icon
                  slot="media"
                  icon="calendar"
                ></input-icon>
                <required-asterisk slot="label" />
              </f7-list-input>
              <!-- v-show="!([TIME_LOG_TYPE_OTHERS, TIME_LOG_TYPE_LUNCHTIME].includes(
                    currenttimeTracking.timeLogType
                  ) && currenttimeTracking.override)" -->
              <!-- date manual -->
              <f7-list-input
                v-show="
                  currenttimeTracking.override &&
                  ![
                    TIME_LOG_TYPE_PROJECT,
                    TIME_LOG_TYPE_OTHERS,
                    TIME_LOG_TYPE_LUNCHTIME,
                  ].includes(currenttimeTracking.timeLogType)
                "
                :label="$t('timeTracking.add.date')"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                error-message-force
                validate
                validate-on-blur
                :value="currenttimeTracking.date"
                :error-message="dateErrorMessage"
                :calendar-params="{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  dateFormat: 'mm/dd/yyyy',
                }"
                @calendar:change="
                  currenttimeTracking.date = $event;
                  $f7.calendar.close();
                "
              >
                <input-icon
                  slot="media"
                  icon="calendar"
                ></input-icon>
                <required-asterisk slot="label" />
              </f7-list-input>

              <!-- from date -->
              <f7-list-input
                v-show="
                  ![TIME_LOG_TYPE_LUNCHTIME].includes(
                    currenttimeTracking.timeLogType
                  ) && currenttimeTracking.override
                "
                :label="$t('timeTracking.add.startDate')"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                error-message-force
                validate
                validate-on-blur
                :value="currenttimeTracking.fromDate"
                :error-message="dateErrorMessage"
                :calendar-params="{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  dateFormat: 'mm/dd/yyyy',
                }"
                @calendar:change="handleCalendarChange"
              >
                <input-icon
                  slot="media"
                  icon="calendar"
                ></input-icon>
                <required-asterisk slot="label" />
              </f7-list-input>

              <!-- to date -->
              <f7-list-input
                v-show="
                  ![TIME_LOG_TYPE_LUNCHTIME].includes(
                    currenttimeTracking.timeLogType
                  ) && currenttimeTracking.override
                "
                :label="$t('timeTracking.add.endDate')"
                type="datepicker"
                placeholder="MM/DD/YYYY"
                error-message-force
                validate
                validate-on-blur
                :value="currenttimeTracking.toDate"
                :error-message="endDateErrorMessage"
                :calendar-params="{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  dateFormat: 'mm/dd/yyyy',
                }"
                @calendar:change="
                  currenttimeTracking.toDate = $event;
                  $f7.calendar.close();
                "
              >
                <input-icon
                  slot="media"
                  icon="calendar"
                ></input-icon>
                <required-asterisk slot="label" />
              </f7-list-input>

              <!-- billable -->
              <f7-list-item :title="$t('timeTracking.add.billable')">
                <f7-toggle
                  slot="after"
                  :checked="currenttimeTracking.billable"
                  @toggle:change="currenttimeTracking.billable = $event"
                ></f7-toggle>
                <input-icon
                  slot="media"
                  icon="money_dollar_circle"
                ></input-icon>
              </f7-list-item>

              <template>
                <!-- class -->
                <f7-list-item>
                  <div slot="header">
                    {{ $t('timeTracking.add.class') }}
                  </div>
                  <f7-input
                    slot="title"
                    type="select"
                    :value="currenttimeTracking.classItem"
                    @change="
                      changeClassServiceItem('classItem', $event.target.value)
                    "
                  >
                    <option
                      value=""
                      hidden
                    >
                      {{ $t('timeTracking.add.selectClass') }}
                    </option>
                    <option
                      v-for="item in classQBList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </f7-input>
                  <input-icon
                    slot="media"
                    icon="rectangle_grid_2x2"
                  ></input-icon>
                </f7-list-item>

                <!-- service item -->
                <f7-list-item>
                  <div slot="header">
                    {{ $t('timeTracking.add.serviceItem') }}
                  </div>
                  <f7-input
                    slot="title"
                    type="select"
                    :value="currenttimeTracking.serviceItem"
                    @change="
                      changeClassServiceItem('serviceItem', $event.target.value)
                    "
                  >
                    <option
                      value=""
                      hidden
                    >
                      {{ $t('timeTracking.add.selectServiceItem') }}
                    </option>
                    <option
                      v-for="item in serviceItemOptions"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </f7-input>
                  <input-icon
                    slot="media"
                    icon="list_bullet_below_rectangle"
                  ></input-icon>
                </f7-list-item>
              </template>

              <!-- note -->
              <f7-list-input
                :label="$t('timeTracking.add.note')"
                type="textarea"
                resizable
                :placeholder="$t('timeTracking.add.notePlaceholder')"
                :value="currenttimeTracking.note"
                @input="currenttimeTracking.note = $event.target.value"
              >
                <input-icon
                  slot="media"
                  icon="square_pencil"
                ></input-icon>
              </f7-list-input>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-page>

    <!-- button cancel/submit -->
    <div class="button-container">
      <f7-button
        fill
        @click="add"
        class="button-action"
      >
        {{ $t('timeTracking.add.submit') }}
      </f7-button>
    </div>

    <project-list-popup
      ref="selectProject"
      @onSelected="onSelectedProject"
    ></project-list-popup>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import InputSpentTime from '../../components/inputs/InputSpentTime.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import _ from 'lodash';

import { googlePlaceApiMixin } from '../../../../services/place.google.service';

import { numberRoundUp } from '../../../../utility/number-tool';
import { overtimeMixin } from '../../mixin/overtime-mixin';

import {
  DEFAULT_STATUS_TIME_TRACKING,
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_OFF_TYPE_PTO,
  TIME_LOG_TYPE_LUNCHTIME,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  VALIDATION_MESSAGE,
  COLLECTION_EMPLOYEE_TIME_LOG,
} from '../../../../utility/const';
import { getFullAddress } from '../../../../utility/address';
import { toDisplayDateString } from '../../../../utility/datetime';
export default {
  runTimer: null,
  components: {
    InputIcon,
    ProjectListPopup,
    InputSpentTime,
  },

  mixins: [googlePlaceApiMixin, overtimeMixin],

  data() {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_OFF_TYPE_PTO,
      TIME_LOG_TYPE_LUNCHTIME,

      currenttimeTracking: {
        date: [new Date()],
        fromDate: [new Date()],
        toDate: [new Date()],
        projectId: null,
        project: null,
        override: false,
        note: '',
        reject_reason: '',
        approvedHour: 0,
        status: DEFAULT_STATUS_TIME_TRACKING,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        classItem: null,
        serviceItem: null,
        overrideHour: 1,
        billable: true,
      },
      spentTime: {
        hours: '-1',
        minutes: '-1',
      },
      guid: 0,
      isSaveInfo: true,
      isAfterMidnight: false,
      timeElapsed24Hours: false,
      isClear: true,
    };
  },

  filters: {
    secondToHour(seconds) {
      const hour = seconds / 3600;
      return numberRoundUp(hour);
    },
  },

  methods: {
    ...mapActions({
      createTimeTracking: 'time-tracking/time-tracking/create',
      updateTimeTracking: 'time-tracking/time-tracking/update',
      setTimeLogInfoAvailable:
        'time-tracking/time-tracking/setTimeLogInfoAvailable',
      createTimeTrackingDraf: 'time-tracking/time-tracking-draf/create',
      updateTimeTrackingDraf: 'time-tracking/time-tracking-draf/update',
      bindTimeTrackingDraf: 'time-tracking/time-tracking-draf/bindTimerListBys',
      deleteTimeTrackingDraf: 'time-tracking/time-tracking-draf/delete',
      getProject: 'time-tracking/project/getProject',

      getWeeklyTimeLogs: 'time-tracking/time-tracking/getWeeklyTimeLogs',
    }),

    ...mapMutations({
      closeTimerPopup: 'time-tracking/time-tracking/closeTimerPopup',
    }),

    ...mapActions('time-tracking/app-constant', ['getConstantTypeList']),
    ...mapActions('time-tracking/quickbooks', [
      'bindClassQBList',
      'bindServiceItemQBList',
    ]),
    ...mapActions('common/notification', ['createNotificationByType']),
    ...mapActions('common/user', ['checkGroupUsers']),
    ...mapActions('time-tracking/service-item-setting', [
      'bindServiceItemSettingList',
    ]),

    changeOverride(currentDate) {
      if (moment(moment(currentDate[0])).isSame(moment(new Date()), 'day')) {
        this.currenttimeTracking.override = false;
      } else {
        this.currenttimeTracking.override = true;
      }
    },
    changeTimeLogType($event) {
      if ($event.target.value === TIME_LOG_TYPE_TIME_OFF) {
        this.currenttimeTracking.override = true;
        if (
          (this.spentTime.hours <= 0 && this.spentTime.minutes <= 0) ||
          this.spentTime.hours >= 8
        ) {
          this.spentTime.hours = 8;
          this.spentTime.minutes = 0;
        }
      }
      if ($event.target.value === TIME_LOG_TYPE_LUNCHTIME) {
        this.currenttimeTracking.override = true;
      } else if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
        this.isShowStartTime
      ) {
        this.currenttimeTracking.override = false;
      }
      this.currenttimeTracking.timeLogType = $event.target.value;
      this.saveUnsubmittedData();
    },
    changeSpentTime(field, val) {
      this.spentTime[field] = Number(val);
    },
    calcOverrideHour() {
      const { hours, minutes } = this.spentTime;
      return this.currenttimeTracking.override
        ? Number(hours) + Number(minutes) / 60
        : 0;
    },
    selectProject() {
      this.$refs.selectProject.open();
    },

    openDialogWarning() {
      this.$ri.dialog.openWarningDialog({
        title: 'Manual enter timelog',
        content: `<div style="text-align: center;">Your duration has exceeded the specified time. Please manually enter the timelog.</div>`,
        textButton: 'ok',
        hideCancelButton: true,
        onClick: (_sefl, indexx) => {
          if (indexx === 1) {
            this.currenttimeTracking.override = true;
            this.timeElapsed24Hours = false;
          }
        },
      });
    },

    clearData() {
      this.currenttimeTracking = {
        date: [new Date()],
        fromDate: [new Date()],
        toDate: [new Date()],
        projectId: null,
        project: null,
        note: '',
        override: false,
        reject_reason: '',
        approvedHour: 0,
        status: DEFAULT_STATUS_TIME_TRACKING,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        classItem: null,
        serviceItem: null,
        overrideHour: 1,
        billable: true,
      };
      this.isClear = true;
      this.spentTime = {
        hours: '-1',
        minutes: '-1',
      };
      this.timeElapsed24Hours = false;
    },

    cancel() {
      if (!this.isLastDrafTimeTrackingPendingForSubmit) {
        this.clearData();
        this.v$.$reset();
        this.closeTimerPopup();
        return;
      }
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: this.$t('timeTracking.cancelYourTime.title'),
        content: this.$t('timeTracking.cancelYourTime.content'),
        textButton: this.$t('timeTracking.button.yes'),
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.clearData();
            app.discardLastTimeTracking();
            app.v$.$reset();
            app.closeTimerPopup();
          }
        },
      });
    },
    /**
     * onSelectedProject
     */

    async onSelectedProject(projectId = '') {
      this.currenttimeTracking.projectId = projectId;
      this.currenttimeTracking.project = await this.getProject(projectId);

      const businessCode = this.currenttimeTracking.project.businessCode;
      const keysToGet = [
        'classItem',
        'serviceItem',
        'projectId',
        'timeLogType',
      ];
      const suffix = `${businessCode
        .charAt(0)
        .toUpperCase()}${businessCode.slice(1)}TimeLogInfoRemember`;
      const timeLogInfo = this.getValuesFromLocalStorage(keysToGet, suffix);
      if (timeLogInfo) {
        const { timeLogType, classItem, serviceItem } = timeLogInfo;

        const classItemValue =
          timeLogType === TIME_LOG_TYPE_PROJECT
            ? (this.classQBList.find(r => r.value === classItem) || {}).value ||
              null
            : null;

        const serviceItemValue =
          timeLogType === TIME_LOG_TYPE_PROJECT
            ? (this.serviceItemQBList.find(r => r.value === serviceItem) || {})
                .value || null
            : null;

        this.currenttimeTracking = {
          ...this.currenttimeTracking,
          classItem: classItemValue,
          serviceItem: serviceItemValue,
        };
      } else {
        this.currenttimeTracking = {
          ...this.currenttimeTracking,
          classItem: null,
          serviceItem: null,
        };
      }
      this.saveUnsubmittedData();
    },

    popupRequiresLocation() {
      this.$ri.dialog.openInfoDialog({
        title: this.$t('timeTracking.requiresLocation.title'),
        content: this.$t('timeTracking.requiresLocation.content'),
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            const isEdge = navigator.userAgent.indexOf('Edg') > -1;

            this.handleOpenPopupLocationPermissionDenied(isEdge);
          }
        },
      });
    },

    handleOpenPopupLocationPermissionDenied(isEdge) {
      let dialogOptions = {
        title: 'Turn on your location',
        content: '',
        hideCancelButton: true,
        onClick: (_self, index) => {
          if (index === 0) {
            _self.app.dialog.close();
          }
        },
      };

      if (this.$device.desktop) {
        dialogOptions.content =
          "Click the control icon inside the address box > Turn on the toggle switch for 'Location'.";
      }

      if (this.$device.android) {
        if (!isEdge) {
          dialogOptions.content =
            "1. Swipe down from the top and tap the 'Location' icon. If it's not there, go to Settings > Location and turn it on.<br>2. Click the control icon inside the address box > Select 'Permissions' from the menu that appears > Turn on the toggle switch for 'Location'.<br>3. Tap on the three-dots icon in the address bar > Select 'Site Settings' > Tap on 'Location' and toggle it on";
        } else {
          dialogOptions.content =
            "1. Swipe down from the top and tap the 'Location' icon. If it's not there, go to Settings > Location and turn it on.<br>2. Click the control icon inside the address box > Select 'Permissions' from the menu that appears > Turn on the toggle switch for 'Location'.<br>3. Click on the three-line icon in the right corner of the screen > Select 'Settings' > Select 'Primary and security' > Select 'Site settings' > Click on 'Location' and turn it on";
        }
      }

      if (this.$device.ios) {
        dialogOptions.content =
          'Open Setting and go to Privacy & Security > Location Service and Turn it on.';
      }

      this.$ri.dialog.openInfoDialog(dialogOptions);
    },

    async askAddLunchtime({ fromDate, toDate }) {
      const lunchTimeByDateAndUser = await this.getWeeklyTimeLogs({
        fromDate,
        toDate,
        userId: this.currentUser.uid,
      });
      // total days between fromDate-toDate
      const startDate = moment(fromDate, 'YYYY-MM-DD').startOf('day');
      const endDate = moment(toDate, 'YYYY-MM-DD').endOf('day');

      const days = endDate.diff(startDate, 'days');
      if (
        (lunchTimeByDateAndUser.filter(item => item.hasLunchBreak) || [])
          .length <= days
      ) {
        this.$ri.dialog.openInfoDialog({
          title: this.$t('timeTracking.askAddLunchTime.title'),
          content: this.$t('timeTracking.askAddLunchTime.content'),
          textButton: this.$t('timeTracking.button.yes'),
          cancelButtonCaption: this.$t('timeTracking.button.no'),
          onClick: (_sefl, index) => {
            if (index === 0) {
              this.handleAdd();
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.handleAdd(true);
              _sefl.app.dialog.close();
            }
          },
        });
      } else {
        this.handleAdd();
      }
    },

    async add() {
      if (this.timeElapsed24Hours) {
        return this.openDialogWarning();
      }

      if (!this.isShowStartTime) {
        this.currenttimeTracking.override = true;
      }
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const { timeLogType, date } = this.currenttimeTracking;
      if (timeLogType === TIME_LOG_TYPE_LUNCHTIME) {
        const lunchTimeByDateAndUser = await this.getWeeklyTimeLogs({
          fromDate: date[0],
          toDate: date[0],
          userId: this.currentUser.uid,
        });
        if (
          (lunchTimeByDateAndUser.filter(item => item.hasLunchBreak) | [])
            .length
        ) {
          this.$ri.dialog
            .openErrorDialog({
              title: '',
              content:
                'The lunchtime has already been logged in the time log. Each employee is limited to one lunchtime per day.',
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            })
            .open();
          return;
        }
      }

      if (
        timeLogType === TIME_LOG_TYPE_PROJECT ||
        timeLogType === TIME_LOG_TYPE_OTHERS
      ) {
        this.askAddLunchtime({
          fromDate: this.currenttimeTracking.fromDate[0],
          toDate: this.currenttimeTracking.toDate[0],
        });
      } else {
        this.handleAdd();
      }
    },

    async handleAdd(isAddLunchTime = false) {
      localStorage.setItem('startTimer', null);

      let startLocation = null;
      let endLocation = null;
      if (this.isLastDrafTimeTrackingPendingForSubmit) {
        startLocation = this.lastDrafTimeTracking.startLocation;
        endLocation = this.lastDrafTimeTracking.endLocation;
      } else {
        if (this.currenttimeTracking.override) {
          startLocation = null;
          endLocation = null;
        } else {
          try {
            const location = await this.getCurrentPosition();
            const {
              latitude,
              longitude,
              altitude,
              accuracy,
              altitudeAccuracy,
              heading,
              speed,
            } = location.coords;
            startLocation = {
              latitude,
              longitude,
              altitude,
              accuracy,
              altitudeAccuracy,
              heading,
              speed,
            };
            endLocation = {
              latitude,
              longitude,
              altitude,
              accuracy,
              altitudeAccuracy,
              heading,
              speed,
            };
          } catch {
            this.popupRequiresLocation();
            return;
          }
        }
      }
      this.closeTimerPopup(); // close first update data later
      this.isClear = false;
      await this.submitCurrentTimeTracking(
        { startLocation, endLocation },
        isAddLunchTime
      ).then(async () => {
        //after submit => set data unsubmit = ""
        let item = {
          id: this.lastDrafTimeTracking.id,
          doc: {
            ...this.lastDrafTimeTracking,
            timeLogTypeUnsubmittedService: '',
            projectIdUnsubmittedService: '',
            classItemUnsubmittedService: '',
            serviceItemUnsubmittedService: '',
            titleUnsubmittedService: '',
          },
        };
        this.updateTimeTrackingDraf(item);
      });
    },

    async submitCurrentTimeTracking(
      startAndEndLocationInformation,
      isAddLunchTime
    ) {
      const {
        projectId,
        project,
        note,
        override,
        reject_reason,
        approvedHour,
        status,
        date,
        timeLogType,
        timeOffType,
        title,
        buildingOrSection,
        classItem,
        serviceItem,
        overrideHour,
        billable,
        fromDate,
        toDate,
      } = this.currenttimeTracking;

      const self = this;
      // Calculate locations
      const { startLocation, endLocation } = startAndEndLocationInformation;
      if (startLocation && endLocation) {
        try {
          const address = await this.lookupAddress(
            startLocation.latitude,
            startLocation.longitude
          );
          startLocation.formattedAddress = address.formattedAddress;
          startLocation.placeId = address.placeId;
        } catch (e) {
          console.warn(e);
        }
        try {
          const address = await this.lookupAddress(
            endLocation.latitude,
            endLocation.longitude
          );
          endLocation.formattedAddress = address.formattedAddress;
          endLocation.placeId = address.placeId;
        } catch (e) {
          console.warn(e);
        }
      }
      let distance = 0;
      let gResult = null;
      // get project address
      if (
        timeLogType === TIME_LOG_TYPE_PROJECT &&
        !_.isEmpty(project) &&
        startLocation &&
        endLocation
      ) {
        let propertyAddress = {};
        if (
          project.businessCode === BUSINESS_CODE_COMMERCIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === 'commercial')
        ) {
          propertyAddress = project.propertyAddress;
        } else if (
          project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === 'residential')
        ) {
          propertyAddress = project.projectAddress;
        }
        if (!_.isEmpty(propertyAddress)) {
          try {
            const distanceResult = await this.calculateDistanceFromThreePoints(
              getFullAddress(propertyAddress),

              {
                lat: startLocation.latitude,
                lng: startLocation.longitude,
              },
              {
                lat: endLocation.latitude,
                lng: endLocation.longitude,
              }
            );
            distance = distanceResult.distance;
            gResult = distanceResult.gResult;
          } catch {
            gResult = [];
            distance = 0;
          }
        }
      }
      const calcOverrideHour =
        timeLogType === TIME_LOG_TYPE_LUNCHTIME
          ? parseFloat((-1 * parseFloat(overrideHour)).toFixed(2))
          : this.calcOverrideHour();
      let data = {
        date: this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(moment(date[0]).format('MM/DD/YYYY'))
        ),
        overrideHour: calcOverrideHour,
        override,
        note,
        projectId: timeLogType === TIME_LOG_TYPE_PROJECT ? projectId : null,
        project: timeLogType === TIME_LOG_TYPE_PROJECT ? project : {},
        buildingOrSection:
          timeLogType === TIME_LOG_TYPE_PROJECT ? buildingOrSection : '',
        reject_reason,
        status,
        approvedHour,
        timeLogType,
        timeOffType:
          timeLogType === TIME_LOG_TYPE_TIME_OFF ? timeOffType : null,
        title:
          timeLogType === TIME_LOG_TYPE_OTHERS
            ? title
            : timeLogType === TIME_LOG_TYPE_LUNCHTIME
              ? 'Lunchtime'
              : '',
        loggedHour: numberRoundUp(this.totalTimeInTimer / 3600),
        user_id: this.currentUser.uid,
        startLocation,
        endLocation,
        drafTimeTrackingId: (this.lastDrafTimeTracking || {}).id || null,
        isDeleted: false,
        distance,
        gResult: JSON.parse(JSON.stringify(gResult)),
        qbClassRef:
          timeLogType === TIME_LOG_TYPE_PROJECT ||
          timeLogType === TIME_LOG_TYPE_OTHERS
            ? this.classQBList.find(r => r.value === classItem) || {}
            : {},
        qbServiceItemRef:
          timeLogType === TIME_LOG_TYPE_PROJECT ||
          timeLogType === TIME_LOG_TYPE_OTHERS
            ? this.serviceItemQBList.find(r => r.value === serviceItem) || {}
            : {},
        billable,
      };
      // save info to localstorage
      if (
        timeLogType === TIME_LOG_TYPE_PROJECT &&
        this.isSaveInfo &&
        timeLogType !== TIME_LOG_TYPE_LUNCHTIME
      ) {
        const dataSave = {
          timeLogType: timeLogType || '',
          projectId: data.projectId || '',
          classItem: classItem || '',
          serviceItem: serviceItem || '',
        };
        let keySuffix = '';
        if (project.businessCode === 'residential') {
          keySuffix += 'ResidentialTimeLogInfoRemember';
        } else if (project.businessCode === 'commercial') {
          keySuffix += 'CommercialTimeLogInfoRemember';
        } else if (project.businessCode === 'service') {
          keySuffix += 'ServiceTimeLogInfoRemember';
        }
        for (const [key, value] of Object.entries(dataSave)) {
          localStorage.setItem(`${key}${keySuffix}`, value);
        }
        localStorage.setItem('jobType', project.businessCode);
      }

      let itemDraf = {
        id: (this.lastDrafTimeTracking || {}).id || null,
        doc: { ...this.lastDrafTimeTracking },
      };

      if (this.isPendingTracking) {
        itemDraf = await this.handleDataBeforeStop();
      }

      itemDraf = {
        id: itemDraf.id,
        doc: {
          ...itemDraf.doc,
          submitted: true,
        },
      };
      let promises = [];

      //add start time/endtime if not overide
      if (!override) {
        data.startTime = this.getStartTime(itemDraf.doc.times);
        data.endTime = this.getEndTime(itemDraf.doc.times);
      }
      if (timeLogType === TIME_LOG_TYPE_PROJECT) {
        if (isAddLunchTime) {
          data.lunchTimeInfo = {
            hour: -1,
            title: 'Lunch Break Included',
          };
          data.hasLunchBreak = isAddLunchTime;
        }
        let startDate = moment(fromDate[0]);
        const endDate = moment(toDate[0]);
        let dates = [];
        dates.push(startDate.format('MM/DD/YYYY'));
        while (!startDate.isSame(endDate, 'day')) {
          startDate = startDate.add(1, 'days');
          dates.push(startDate.format('MM/DD/YYYY'));
        }
        for (const r of dates || []) {
          data.date = this.$google.firebase.firestore.Timestamp.fromDate(
            new Date(r)
          );
          promises.push(this.createTimeTracking(data));
        }
      }
      // else if (timeLogType === TIME_LOG_TYPE_LUNCHTIME) {
      //   promises.push(this.createTimeTracking(data));
      // }
      else {
        let startDate = moment(this.currenttimeTracking.fromDate[0]);
        const endDate = moment(this.currenttimeTracking.toDate[0]);
        let dates = [];
        dates.push(startDate.format('MM/DD/YYYY'));
        while (!startDate.isSame(endDate, 'day')) {
          startDate = startDate.add(1, 'days');
          dates.push(startDate.format('MM/DD/YYYY'));
        }

        for (const r of dates || []) {
          data.date = this.$google.firebase.firestore.Timestamp.fromDate(
            new Date(r)
          );

          const lunchTimeByDateAndUser = await this.getWeeklyTimeLogs({
            fromDate: r,
            toDate: r,
            userId: this.currentUser.uid,
          });

          if (
            !(lunchTimeByDateAndUser.filter(item => item.hasLunchBreak) || [])
              .length &&
            isAddLunchTime
          ) {
            data.lunchTimeInfo = {
              hour: -1,
              title: 'Lunch Break Included',
            };
            data.hasLunchBreak = isAddLunchTime;
          }

          promises.push(this.createTimeTracking(data));
        }
      }

      if (itemDraf.id) {
        promises.push(this.updateTimeTrackingDraf(itemDraf));
      }

      Promise.all(promises)
        .then(async () => {
          // calculate overtime hour

          return self.composeOvertime(date[0], self.currentUser.uid);
        })
        .catch(error =>
          this.$ri.dialog
            .openErrorDialog({
              title: 'Error',
              content: error.message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            })
            .open()
        );
      await this.checkGroupUsers('hr-main/employee-time-logs');
      const arrayOfUids = this.userGroupList.map(userGroup => userGroup.uid);
      this.createNotificationByType({
        data: {
          assignees: arrayOfUids,
          project: {
            title: data.project.title || title,
            id: data.projectId || '',
            entityName: COLLECTION_EMPLOYEE_TIME_LOG,
          },
        },
        type: 'add-timelog-admin',
      });
      this.v$.$reset();
      this.clearData();
    },

    getStartTime(times) {
      if (_.isEmpty(times)) return null;
      if (times.length > 0) {
        const dateTime = times[0].startTime;
        if (dateTime) {
          if (typeof dateTime === 'string')
            return moment(dateTime).format('HH:mm');
          else return moment(dateTime.toDate()).format('HH:mm');
        }
      }
      return null;
    },

    getEndTime(times) {
      if (_.isEmpty(times)) return null;
      if (times.length > 0) {
        const dateTime = times[times.length - 1].endTime;
        if (dateTime) {
          if (typeof dateTime === 'string')
            return moment(dateTime).format('HH:mm');
          else return moment(dateTime.toDate()).format('HH:mm');
        }
      }
      return null;
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 5000,
        });
      }
      self.notificationFull.open();
    },

    restartTimer() {
      if (this.totalTimeInTimer <= 0) return;

      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: this.$t('timeTracking.restartYourTime.title'),
        content: this.$t('timeTracking.restartYourTime.content'),
        textButton: this.$t('timeTracking.button.yes'),
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            await app.stopTimer();
            app.currenttimeTracking = {
              ...app.currenttimeTracking,
              override: false,
              approvedHour: 0,
              overrideHour: 1,
            };
            app.spentTime = {
              hours: '-1',
              minutes: '-1',
            };
            if (app.isLastDrafTimeTrackingPendingForSubmit) {
              app.discardLastTimeTracking();
            }
            app.v$.$reset();
            app.startTimer();
          }
        },
      });
    },

    async startTimer() {
      const startTimer = moment(new Date(), 'MM/DD/YYYY');
      localStorage.setItem('startTimer', startTimer);

      if (this.isPendingTracking) {
        let item = {
          id: this.lastDrafTimeTracking.id,
          doc: {
            ...this.lastDrafTimeTracking,
            times: [
              ...this.lastDrafTimeTracking.times,
              {
                endTime: '',
                startTime: this.$google.firebase.firestore.Timestamp.fromDate(
                  new Date()
                ),
                isPauseTime: false,
                sum: 0,
              },
            ],
          },
        };
        this.updateTimeTrackingDraf(item);
      } else {
        let item = {
          user_id: this.currentUser.uid,
          times: [
            {
              startTime: this.$google.firebase.firestore.Timestamp.fromDate(
                new Date()
              ),
              endTime: '',
            },
          ],
        };
        try {
          const location = await this.getCurrentPosition();
          const {
            latitude,
            longitude,
            altitude,
            accuracy,
            altitudeAccuracy,
            heading,
            speed,
          } = location.coords;

          item.startLocation = {
            latitude,
            longitude,
            altitude,
            accuracy,
            altitudeAccuracy,
            heading,
            speed,
          };
          this.createTimeTrackingDraf(item).then(() => {
            this.saveUnsubmittedData();
          });
        } catch {
          this.popupRequiresLocation();
          return;
        }
      }
    },

    handleDataBeforePause() {
      if (!this.isPendingTracking) return;
      if (!this.isLastTimerRunning) return;

      // if totalTimeInTimer is 24
      let totalSecondsTimeAvailable = 0;
      if (this.totalTimeInTimer == 24 * 3600) {
        this.lastDrafTimeTracking.times.forEach(time => {
          if (time.startTime && time.endTime) {
            const startTime =
              typeof time.startTime === 'string'
                ? moment(time.startTime)
                : moment(time.startTime.toDate());
            const endTime =
              typeof time.endTime === 'string'
                ? moment(time.endTime)
                : moment(time.endTime.toDate());

            const duration = moment.duration(endTime.diff(startTime));
            const seconds = duration.asSeconds();
            totalSecondsTimeAvailable += seconds;
          }
        });
      }
      const totalOfLastTime = 24 * 3600 - totalSecondsTimeAvailable;

      const newTimes = this.lastDrafTimeTracking.times.map(r => ({ ...r }));

      const lastStartTime =
        typeof newTimes[newTimes.length - 1].startTime === 'string'
          ? moment(newTimes[newTimes.length - 1].startTime)
          : moment(newTimes[newTimes.length - 1].startTime.toDate());

      newTimes[newTimes.length - 1].endTime =
        this.totalTimeInTimer == 24 * 3600
          ? this.$google.firebase.firestore.Timestamp.fromDate(
              new Date(lastStartTime.add(totalOfLastTime, 'seconds'))
            )
          : this.$google.firebase.firestore.Timestamp.fromDate(new Date());
      const item = {
        id: this.lastDrafTimeTracking.id,
        doc: {
          ...this.lastDrafTimeTracking,
          times: newTimes,
        },
      };
      return item;
    },

    pauseTimer() {
      const item = this.handleDataBeforePause();
      if (item) {
        this.updateTimeTrackingDraf(item);
      }
    },
    handleCalendarChange(event) {
      this.currenttimeTracking.fromDate = event;
      if (
        this.currenttimeTracking.toDate[0] <
        this.currenttimeTracking.fromDate[0]
      ) {
        this.currenttimeTracking.toDate = event;
      }
      this.$f7.calendar.close();
    },
    async handleDataBeforeStop() {
      let item = {
        id: this.lastDrafTimeTracking.id,
        doc: {
          ...this.lastDrafTimeTracking,
        },
      };
      if (this.isLastTimerRunning) {
        item = this.handleDataBeforePause();
      }
      try {
        const location = await this.getCurrentPosition();
        const {
          latitude,
          longitude,
          altitude,
          accuracy,
          altitudeAccuracy,
          heading,
          speed,
        } = location.coords;

        item.doc = {
          ...item.doc,
          stopped: true,
          endLocation: {
            latitude,
            longitude,
            altitude,
            accuracy,
            altitudeAccuracy,
            heading,
            speed,
          },
        };
      } catch {
        this.popupRequiresLocation();
        return;
      }
      return item;
    },

    async stopTimer() {
      const item = await this.handleDataBeforeStop();
      if (item) {
        await this.updateTimeTrackingDraf(item);
      }
    },

    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          location => resolve(location),
          reject
        );
      });
    },

    discardLastTimeTracking() {
      let item = {
        id: this.lastDrafTimeTracking.id,
        doc: {
          ...this.lastDrafTimeTracking,
          discarded: true,
        },
      };
      this.updateTimeTrackingDraf(item);
    },
    saveUnsubmittedData() {
      let item = {
        id: this.lastDrafTimeTracking.id,
        doc: {
          ...this.lastDrafTimeTracking,
          timeLogTypeUnsubmittedService:
            this.currenttimeTracking.timeLogType || '',
          projectIdUnsubmittedService: this.currenttimeTracking.projectId || '',
          classItemUnsubmittedService: this.currenttimeTracking.classItem || '',
          serviceItemUnsubmittedService:
            this.currenttimeTracking.serviceItem || '',
          titleUnsubmittedService: this.currenttimeTracking.title || '',
        },
      };
      this.updateTimeTrackingDraf(item);
    },
    closedPopup() {
      this.closeTimerPopup();
      if (this.isClear) {
        this.clearData();
      }
      this.setTimeLogInfoAvailable({});
    },
    getValuesFromLocalStorage(keys, suffix) {
      const data = {};
      keys.forEach(key => {
        const value = localStorage.getItem(`${key}${suffix}`);
        if (value !== null) {
          data[key] = value;
        }
      });
      return data;
    },
    changeClassServiceItem(key, value) {
      this.currenttimeTracking[key] = value;
      this.saveUnsubmittedData();
    },
    changeTitle(value) {
      this.currenttimeTracking.title = value;
      this.saveUnsubmittedData();
    },
    async handlePopupOpened() {
      const currentDate = moment(new Date(), 'MM/DD/YYYY');
      const startTimer = localStorage.getItem('startTimer') || currentDate;
      this.isAfterMidnight = currentDate.isAfter(startTimer, 'day');
      this.$f7.preloader.show();

      try {
        await this.bindTimeTrackingDraf([
          {
            prop: 'user_id',
            val: this.currentUser.uid,
            op: '==',
          },
        ]);

        await this.getConstantTypeList();

        if (_.isEmpty(this.classQBList) || _.isEmpty(this.serviceItemQBList)) {
          await this.bindClassQBList();
          await this.bindServiceItemQBList();
        }

        if (_.isEmpty(this.serviceItemSettingList)) {
          await this.bindServiceItemSettingList();
        }
        const keysToGet = [
          'classItem',
          'serviceItem',
          'projectId',
          'timeLogType',
          'title',
        ];

        //get data infoUnsubmitted
        let infoUnsubmitted = {
          timeLogType:
            this.lastDrafTimeTracking.timeLogTypeUnsubmittedService || '',
          projectId:
            this.lastDrafTimeTracking.projectIdUnsubmittedService || '',
          classItem:
            this.lastDrafTimeTracking.classItemUnsubmittedService || '',
          serviceItem:
            this.lastDrafTimeTracking.serviceItemUnsubmittedService || '',
          title: this.lastDrafTimeTracking.titleUnsubmittedService || '',
        };
        if (
          Object.keys(infoUnsubmitted).every(key => infoUnsubmitted[key] === '')
        ) {
          infoUnsubmitted = {};
        }

        let timeTrackingInfo = {};
        let project = {};
        if (
          !this.lastDrafTimeTracking.submitted &&
          !_.isEmpty(infoUnsubmitted)
        ) {
          if (infoUnsubmitted.projectId) {
            project = await this.getProject(infoUnsubmitted.projectId);
          }
          timeTrackingInfo = {
            title: infoUnsubmitted.title,
            timeLogType: infoUnsubmitted.timeLogType,
            classItem: infoUnsubmitted.classItem,
            serviceItem: infoUnsubmitted.serviceItem,
            projectId: infoUnsubmitted.projectId,
            project,
          };
        } else if (!_.isEmpty(this.timeLogInfoAvailable)) {
          const businessCode = this.timeLogInfoAvailable.project
            ? this.timeLogInfoAvailable.project.businessCode
            : 'commercial';
          const suffix = `${businessCode
            .charAt(0)
            .toUpperCase()}${businessCode.slice(1)}TimeLogInfoRemember`;

          const timeLogInfoRemember = this.getValuesFromLocalStorage(
            keysToGet,
            suffix
          );
          timeTrackingInfo = {
            timeLogType: timeLogInfoRemember.timeLogType,
            classItem: timeLogInfoRemember.classItem,
            serviceItem: timeLogInfoRemember.serviceItem,
            projectId: this.timeLogInfoAvailable.project.id,
            project: this.timeLogInfoAvailable.project,
            date: this.timeLogInfoAvailable.date
              ? [new Date(moment(this.timeLogInfoAvailable.date, 'MM/DD/YYYY'))]
              : [new Date()],
          };
        } else {
          //get time log info remember with type project
          const businessCode = localStorage.getItem('jobType') || '';
          const suffix = `${businessCode
            .charAt(0)
            .toUpperCase()}${businessCode.slice(1)}TimeLogInfoRemember`;
          const timeLogInfo = this.getValuesFromLocalStorage(keysToGet, suffix);
          if (timeLogInfo.projectId) {
            project = await this.getProject(timeLogInfo.projectId);
          }
          timeTrackingInfo = {
            timeLogType: timeLogInfo.timeLogType,
            classItem: timeLogInfo.classItem,
            serviceItem: timeLogInfo.serviceItem,
            projectId: timeLogInfo.projectId,
            project,
          };
        }

        this.currenttimeTracking = {
          ...this.currenttimeTracking,
          projectId: timeTrackingInfo.projectId || null,
          project: !_.isEmpty(timeTrackingInfo.project)
            ? timeTrackingInfo.project
            : null,
          date: timeTrackingInfo.date || [new Date()],
          classItem:
            (
              this.classQBList.find(
                r => r.value === timeTrackingInfo.classItem
              ) || {}
            ).value || null,
          serviceItem:
            (
              this.serviceItemQBList.find(
                r => r.value === timeTrackingInfo.serviceItem
              ) || {}
            ).value || null,
          timeLogType: timeTrackingInfo.timeLogType || TIME_LOG_TYPE_PROJECT,
          title: timeTrackingInfo.title || '',
        };
      } catch (e) {
        console.log('[handlePopupOpened] [error]', e);
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const endDateRules = () => {
      if (
        this.currenttimeTracking.fromDate === '' ||
        this.currenttimeTracking.toDate === ''
      )
        return true;
      const startDate = moment(this.currenttimeTracking.fromDate[0]).format(
        'YYYY-MM-DD'
      );
      const endDate = moment(this.currenttimeTracking.toDate[0]).format(
        'YYYY-MM-DD'
      );
      return !moment(endDate).isBefore(startDate, 'day');
    };
    const projectRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT &&
        !this.currenttimeTracking.projectId
      )
        return false;
      return true;
    };
    const timeOffTypeRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF &&
        !this.currenttimeTracking.timeOffType
      )
        return false;
      return true;
    };
    const titleRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS &&
        !this.currenttimeTracking.title
      )
        return false;
      return true;
    };
    const spentRequiredRule = () => {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME)
        return true;
      if (!this.currenttimeTracking.override) return true;

      let condition1 = this.spentTime.hours >= 0;
      let condition2 = this.spentTime.minutes >= 0;
      let condition3 = +this.spentTime.hours + +this.spentTime.minutes > 0;

      if (!(condition1 && condition2 && condition3)) return false;
      return true;
    };
    const totalTimeInTimerRequiredRule = () => {
      if (this.currenttimeTracking.override) return true;
      if (!this.totalTimeInTimer) return false;
      return true;
    };
    const overrideHourRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
        !this.currenttimeTracking.overrideHour
      )
        return false;
      return true;
    };

    return {
      currenttimeTracking: {
        date: {
          required,
        },
        timeLogType: {
          required,
        },
        projectId: {
          projectRule,
        },
        timeOffType: {
          timeOffTypeRule,
        },
        title: {
          titleRule,
        },
        toDate: {
          endDateRules,
        },
        overrideHour: {
          overrideHourRule,
          minValue: this.currenttimeTracking.override ? minValue(0) : true,
        },
      },
      spentTime: {
        spentRequiredRule,
      },
      totalTimeInTimer: {
        totalTimeInTimerRequiredRule,
      },
    };
  },

  computed: {
    ...mapGetters({
      time_tracking_drafList: 'time-tracking/time-tracking-draf/timerList',
      showTimerPopup: 'time-tracking/time-tracking/showTimerPopup',
    }),

    ...mapGetters('time-tracking/app-constant', [
      'timeLogTypeList',
      'timeOffTypeList',
    ]),
    ...mapGetters('time-tracking/quickbooks', [
      'classQBList',
      'serviceItemQBList',
    ]),
    ...mapGetters('time-tracking/service-item-setting', [
      'serviceItemOptions',
      'serviceItemSettingList',
    ]),
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),
    ...mapGetters('time-tracking/time-tracking', ['timeLogInfoAvailable']),
    ...mapGetters('common/user', ['userGroupList']),

    maxTimeValue() {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF)
        return 8;
      else return 24;
    },
    isShowStartTime() {
      return moment(moment(this.currenttimeTracking.date[0])).isSame(
        moment(new Date()),
        'day'
      );
    },
    formattedFromDate() {
      return moment(this.currenttimeTracking.fromDate[0]).format('MM/DD/YYYY');
    },
    formattedToDate() {
      return moment(this.currenttimeTracking.toDate[0]).format('MM/DD/YYYY');
    },
    getuserIsTracking() {
      return (
        this.time_tracking_drafList.find(
          x => x.createdBy === this.currentUser.displayName
        ) || {}
      );
    },
    dateErrorMessage() {
      if (!this.v$.currenttimeTracking.date.$error) return null;
      if (this.v$.currenttimeTracking.date.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    endDateErrorMessage() {
      if (this.v$.currenttimeTracking.toDate.endDateRules.$invalid)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    },
    timeLogTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.timeLogType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    projectErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.projectId.projectRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    timeOffTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.timeOffType.timeOffTypeRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    titleErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.title.titleRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    spentTimeErrorMessage() {
      if (!this.v$.spentTime.$error) return null;
      if (this.v$.spentTime.spentRequiredRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    totalTimeInTimerErrorMessage() {
      if (!this.v$.totalTimeInTimer.$error) return null;
      if (this.v$.totalTimeInTimer.totalTimeInTimerRequiredRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    overrideHourErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return null;
      if (this.v$.currenttimeTracking.overrideHour.overrideHourRule.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currenttimeTracking.overrideHour.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_OR_EQUAL_TO_ZERO;
      return null;
    },
    infomationDetailErrorMessage() {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
        !this.timeLogTypeErrorMessage &&
        !this.dateErrorMessage
      ) {
        return null;
      }
      if (this.v$.currenttimeTracking.$error) {
        return VALIDATION_MESSAGE.COMPLETE_ALL_INFORMATION;
      }
      return null;
    },
    lastDrafTimeTracking() {
      if (
        !this.time_tracking_drafList &&
        this.time_tracking_drafList.length < 1
      )
        return null;
      return this.time_tracking_drafList[0];
    },

    isPendingTracking() {
      if (!this.lastDrafTimeTracking) return false;
      return !this.lastDrafTimeTracking.stopped;
    },

    lastTimer() {
      if (!this.lastDrafTimeTracking) return null;
      if (!this.lastDrafTimeTracking.times) return null;
      return this.lastDrafTimeTracking.times[
        this.lastDrafTimeTracking.times.length - 1
      ];
    },

    isLastTimerRunning() {
      return !(this.lastTimer || {}).endTime;
    },

    totalTimeInTimer() {
      if (!this.lastDrafTimeTracking) return 0;
      // Should be 0 if the last draf timer is submitted or discarded
      if (
        this.lastDrafTimeTracking.discarded ||
        this.lastDrafTimeTracking.submitted
      )
        return 0;
      if (!this.lastDrafTimeTracking.times) return 0;
      if (!this.lastDrafTimeTracking.times.length) return 0;
      const timersInReverse = this.lastDrafTimeTracking.times.slice().reverse();
      let totalTimeInSeconds = 0;
      let nextStartTime = moment();
      for (let index = 0; index < timersInReverse.length; index++) {
        const timer = timersInReverse[index];
        const end =
          (typeof timer.endTime === 'string'
            ? timer.endTime
            : timer.endTime.toDate()) || nextStartTime;
        const start =
          typeof timer.startTime === 'string'
            ? timer.startTime
            : timer.startTime.toDate();
        totalTimeInSeconds += moment(end).diff(moment(start), 'second');
        nextStartTime = start;
      }
      if (totalTimeInSeconds >= 24 * 3600) {
        return 24 * 3600;
      }
      return totalTimeInSeconds + this.guid - this.guid;
    },

    isLastDrafTimeTrackingPendingForSubmit() {
      if (!this.lastDrafTimeTracking) return false;
      return (
        this.lastDrafTimeTracking.stopped &&
        !this.lastDrafTimeTracking.submitted &&
        !this.lastDrafTimeTracking.discarded
      );
    },

    filteredTimeLogTypeList() {
      //if open from service/scheduling => show only time log type: project
      //else show all
      if (this.timeLogInfoAvailable && this.timeLogInfoAvailable.projectId) {
        return this.timeLogTypeList.filter(item => item.value === 'project');
      } else {
        return this.timeLogTypeList;
      }
    },
    currentDate() {
      const currentDate = this.isAfterMidnight
        ? moment().subtract(1, 'days').startOf('day')
        : moment().startOf('day');
      return toDisplayDateString(currentDate);
    },
  },

  watch: {
    totalTimeInTimer(val) {
      if (val == 24 * 3600) {
        this.stopTimer();
        this.timeElapsed24Hours = true;
      }
    },
    isShowStartTime(value) {
      if (!value) {
        this.currenttimeTracking.override = true;
      } else {
        this.currenttimeTracking.override = false;
      }
    },
  },

  mounted() {
    this.runTimer = setInterval(() => {
      this.guid++;
    }, 1000);
  },

  destroyed() {
    clearInterval(this.runTimer);
  },
};
</script>
<style lang="scss" scoped>
.icon-clock {
  padding-bottom: 2px;
}

.timer-count {
  margin-left: 5px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: var(--f7-page-bg-color);
}

.button-action {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}

.start-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.circle-btn {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 20px;
  display: grid;
  place-items: center;
  i {
    font-size: 30px;
  }
}

.warning {
  font-size: 12px;
  background-color: #f9f9cd;
}

.time-tracking-form {
  padding-bottom: 50px;
}

.time-tracking-form ::v-deep .item-title {
  width: 100%;
}

.time-tracking-form ::v-deep .accordion-list .accordion-item-content > .list {
  border-top: 1px solid var(--f7-list-item-border-color);
}
</style>
