<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <progress-bar ref="progressBar"></progress-bar>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>{{
            isAddNewSection ? 'Project Photos' : currentSection.sectionName
          }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <div
          v-if="!isAddNewSection"
          class="upload-photo"
          @click="openUploadPhoto"
        >
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <f7-icon
              size="50"
              f7="cloud_upload_fill"
            ></f7-icon>
            <span>Upload photos</span>
          </div>

          <input
            ref="imageInput"
            type="file"
            @change="onUploadImage"
            class="display-none"
            multiple
            accept=".png, .jpg, .jpeg, .webp"
          />
        </div>

        <div class="button-container margin-top">
          <div>
            <b>{{ photoIds.length }}</b> photos selected
          </div>
        </div>

        <div
          v-for="(item, index) in photosComputed"
          :key="index"
        >
          <f7-block>
            <div
              @click="
                onSelectAlbumPhoto(
                  item.data.every(photo => photoIds.includes(photo.id))
                    ? false
                    : true,
                  item.data
                )
              "
              class="display-flex align-items-center"
              style="cursor: pointer"
            >
              <f7-checkbox
                :style="
                  $device.desktop
                    ? 'transform: scale(1.5);'
                    : 'transform: scale(1.2); top: 8px'
                "
                :checked="
                  item.data.every(r => photoIds.includes(r.id)) ||
                  item.data.every(r => photoIdsSelected.includes(r.id))
                "
                :disabled="
                  item.data.every(r => photoIdsSelected.includes(r.id))
                "
                @click.native.stop
                @change.native.stop="
                  onSelectAlbumPhoto($event.target.checked, item.data)
                "
              ></f7-checkbox>
              <h3 class="bold margin-left-half">{{ item.albumName }}</h3>
            </div>
            <div class="img-grid">
              <div
                v-for="photo in item.data"
                :key="photo.id"
                @touchstart="handlePressAndHold(photo.id)"
                @touchend="handleTouchEnd"
                @mouseenter="handleMouseEnter(photo.id)"
                @mouseleave="handleSelected(null)"
              >
                <div
                  class="img-item"
                  style="border-radius: 4px"
                  @click.stop="clickPhoto(photo.id, true)"
                >
                  <img
                    class="image-show"
                    :src="photo.thumbnailUrl"
                  />
                  <div
                    v-if="
                      $device.desktop &&
                      ((selectionMode && hoveredPhotoIds.includes(photo.id)) ||
                        photoIdsSelected.includes(photo.id))
                    "
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.5);
                        "
                        :checked="
                          photoIds.includes(photo.id) ||
                          photoIdsSelected.includes(photo.id)
                        "
                        @click.native.stop
                        @change.native.stop="clickPhoto(photo.id)"
                        :disabled="photoIdsSelected.includes(photo.id)"
                        v-if="
                          photo.photoFullPath && !isVideo(photo.photoFullPath)
                        "
                      ></f7-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="!$device.desktop && selectionMode"
                    class="img-bg img-bg-select"
                  >
                    <div class="img-bg-content">
                      <f7-checkbox
                        style="
                          position: absolute;
                          top: 10%;
                          left: 10%;
                          transform: scale(1.2);
                        "
                        :checked="
                          photoIds.includes(photo.id) ||
                          photoIdsSelected.includes(photo.id)
                        "
                        @click.native.stop
                        @change.native.stop="clickPhoto(photo.id)"
                        :disabled="photoIdsSelected.includes(photo.id)"
                        v-if="
                          photo.photoFullPath && !isVideo(photo.photoFullPath)
                        "
                      ></f7-checkbox>
                    </div>
                  </div>
                  <div
                    v-else
                    class="img-bg-not-select"
                  >
                    <div
                      v-if="photo.photoFullPath && isVideo(photo.photoFullPath)"
                      class="play-button"
                    >
                      <i class="icon f7-icons size-20">play_circle_fill</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </f7-block>
        </div>
      </f7-page>
    </f7-popup>

    <photo-browser
      :photos="photoBrowserList"
      theme="dark"
      ref="pageDark"
    ></photo-browser>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { groupBy } from '@/utility/filter-tools';
import { sortLatest } from '@/utility/date-time-tool';
import PhotoBrowser from '@/components/photo/PhotoBrowser.vue';
import _ from 'lodash';
import Vue from 'vue';
import ImageTools from '../../../../utility/image-tools';
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import { uuid } from 'vue-uuid';
import { isVideo, isImage, generateTextProcessBar } from '@/utility/common';

export default {
  components: {
    PhotoBrowser,
    ProgressBar,
  },
  props: {
    showProgressBar: { type: Boolean, default: false },
    proposalSection: { type: Array, default: () => [] },
    isUploadVideo: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isPopupOpened: false,
    photoIds: [],
    sectionIndex: 0,
    photoList: [],
    isAddNewSection: false,
    photoIdsSelected: [],
    hoveredPhotoIds: [],
    selectionMode: false,
    pressHoldEvent: null,
    uuid: uuid.v4(),
    countLargeFiles: 0,
  }),
  mounted() {
    this.createTooltips();
  },
  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
      proposalTemplate: 'proposal/template/proposalTemplate',
      projectPhotoList: 'proposal/photo/projectPhotoList',
      projectPhotoAlbumList: 'proposal/album/projectPhotoAlbumList',
      albumDetail: 'proposal/album/albumDetail',
    }),
    ...mapGetters('proposal/photo', ['projectPhotoSelected']),
    currentSection() {
      return (
        (this.proposalSection || []).find(
          section => section.index === this.sectionIndex
        ) || {}
      );
    },

    sectionPhotos() {
      let list = (this.proposalSection || [])
        .filter(r => r.sectionId === this.currentSection.sectionId)
        .map(r => r.photos);
      list = [].concat.apply([], list);
      const idsUnique = [...new Set(list.map(r => r.id))];
      return idsUnique.map(r => list.find(j => j.id === r));
    },

    photosComputed() {
      const filterPhotFromPhotoList = _.cloneDeep(this.photoList || []).filter(
        item => {
          return !isVideo(item.thumbnailFullPath);
        }
      );

      let projectPhotos = filterPhotFromPhotoList;
      // group by album
      projectPhotos = groupBy(projectPhotos, i => i.albumId);

      projectPhotos = projectPhotos.map(r => ({
        ...r,
        albumName:
          r.key === 'undefined' || r.key === 'null'
            ? 'Other'
            : this.albumDetail(r.key).name,
        priority:
          r.key === 'undefined' || r.key === 'null'
            ? null
            : this.albumDetail(r.key).priority || null,
      }));
      // sort data
      const albumListDefault = (
        projectPhotos.filter(r => r.priority) || []
      ).sort((a, b) => a.priority - b.priority);
      const albumListRest = sortLatest(
        projectPhotos.filter(r => !r.priority && r.albumName !== 'Other') || []
      );

      const result = albumListDefault.concat(albumListRest);
      return result;
    },

    photoBrowserList() {
      return this.photosComputed.reduce((prev, item) => {
        prev = prev.concat(item.data);
        return prev.map(r => ({
          ...r,
          notes: r.notes || '',
          url: r.photoUrl || '',
          comments: r.comments || [],
          photoFullPath: r.photoFullPath || '',
        }));
      }, []);
    },
  },

  methods: {
    ...mapActions({
      createProjectPhoto: 'proposal/photo/createProjectPhoto',
      uploadPhoto: 'proposal/photo/uploadPhoto',
    }),
    ...mapActions('proposal/photo', [
      'deleteProjectPhoto',
      'removePhoto',
      'downloadPhoto',
    ]),

    isVideo,

    isImage,

    handleDetele() {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: `Delete 
            ${app.photoIds.length === 1 ? 'photo/video' : 'photos/videos'}
            `,
        content: `Are you sure you want to delete ?`,
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const photos = app.projectPhotoSelected(app.photoIds);
            photos.forEach(i => {
              if (i.thumbnailFullPath) app.removePhoto(i.thumbnailFullPath);
              if (i.photoFullPath) app.removePhoto(i.photoFullPath);
            });
            app.photoIds.forEach(i => {
              app.deleteProjectPhoto(i);
            });
            _sefl.app.dialog.close();
            app.$emit('cancelSelect');
          }
        },
      });
    },
    handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading photos...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const photos = this.projectPhotoSelected(this.photoIds);
      photos.forEach(i =>
        this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
      );
      // this.cancelSelection();
      this.createTooltips();
    },
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.delete-tooltip-${this.uuid}`,
          text: 'Delete photos/videos',
        },
        {
          targetEl: `.download-tooltip-${this.uuid}`,
          text: 'Download photos/videos',
        },
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button-photo',
          text: tooltip.text,
        });
        document
          .querySelector(tooltip.targetEl)
          ?.addEventListener('mouseenter', () => {
            this.$f7.tooltip.get(tooltip.targetEl)?.show();
          });
      });
    },
    openChangePopup(index) {
      this.sectionIndex = index;
      this.photoIds = this.sectionPhotos.map(r => r.id);
      if (this.currentSection.albumId) {
        this.photoList = (_.cloneDeep(this.projectPhotoList) || []).filter(
          r => {
            return r.albumId === this.currentSection.albumId;
          }
        );
      } else if (this.currentSection.albumId === null) {
        this.photoList = (_.cloneDeep(this.projectPhotoList) || []).filter(
          r => {
            return r.albumId === null || r.albumId === undefined;
          }
        );
      } else {
        this.photoList = _.cloneDeep(this.projectPhotoList) || [];
      }
      this.photoList = this.photoList.map(r => ({
        ...r,
        notes: (this.sectionPhotos.find(p => p.id === r.id) || {}).notes || '',
      }));
      let photoListIds = this.photoList.map(photo => photo.id);

      let filteredPhotoIds = this.photoIds.filter(id =>
        photoListIds.includes(id)
      );
      this.photoIds = filteredPhotoIds;
      if (this.photoIds.length > 0) {
        this.selectionMode = true;
        this.hoveredPhotoIds = _.cloneDeep(this.photoIds);
      }
      this.isPopupOpened = true;
    },

    openAddNewSection(index) {
      this.isAddNewSection = true;
      this.sectionIndex = index;
      let photosSelected = this.proposalSection
        .filter(r => r.sectionId.includes('project-photos'))
        .map(r => r.photos);
      photosSelected = [].concat.apply([], photosSelected);
      this.photoIdsSelected = [...new Set(photosSelected.map(r => r.id))];

      this.photoList = (_.cloneDeep(this.projectPhotoList) || []).map(r => ({
        ...r,
        notes: '',
      }));
      this.isPopupOpened = true;
    },

    selectAll() {
      this.createTooltips();
      this.photoIds = (this.photoList || [])
        .filter(r => !this.photoIdsSelected.includes(r.id))
        .map(r => r.id);
      this.hoveredPhotoIds = this.hoveredPhotoIds.concat(this.photoIds);
      this.selectionMode = true;
    },

    clearSelected() {
      this.photoIds = [];
    },

    saveAndClose() {
      const photos =
        this.photoList.filter(r => this.photoIds.includes(r.id)) || [];
      const self = this;
      this.$emit('onSave', {
        isAddNewSection: this.isAddNewSection,
        index: this.sectionIndex,
        photos,
        callback: () => {
          self.handlePopupClosed();
        },
      });
    },

    handlePopupClosed() {
      this.isPopupOpened = false;
      this.sectionIndex = 0;
      this.photoIds = [];
      this.photoList = [];
      this.isAddNewSection = false;
      this.photoIdsSelected = [];
    },

    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.photoBrowserList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelectPhotoChange(id);
      }
    },

    onSelectAlbumPhoto(checked, photos) {
      const photoIds = photos
        .filter(r => {
          const isVideo = r.photoFullPath && this.isVideo(r.photoFullPath);
          return !this.photoIdsSelected.includes(r.id) && !isVideo;
        })
        .map(r => r.id);

      this.photoIds = this.photoIds.filter(r => !photoIds.includes(r));
      if (checked) {
        this.photoIds = this.photoIds.concat(photoIds);
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photoIds);
      } else {
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !photoIds.includes(i)
        );
      }
      if (this.hoveredPhotoIds.length > 0) {
        this.selectionMode = true;
      } else {
        this.selectionMode = false;
      }
    },

    onSelectPhotoChange(id) {
      if (this.photoIds.includes(id)) {
        const index = this.photoIds.indexOf(id);
        if (index > -1) {
          this.photoIds.splice(index, 1);
        }
      } else {
        this.photoIds.push(id);
      }
    },

    onChangePhotoProp(prop, value, photo) {
      let index = this.photoList.findIndex(r => r.id == photo.id);
      if (index < 0) return;
      let item = { ...this.photoList[index] };
      item[prop] = value;
      Vue.set(this.photoList, index, item);
    },

    openUploadPhoto() {
      this.$refs.imageInput.click();
    },

    onStartUpload() {
      this.$refs.progressBar.show();
    },
    onUploadProgress({ photoCount, videoCount, photoTotal, videoTotal }) {
      this.$refs.progressBar.setText(
        generateTextProcessBar(
          photoTotal,
          photoCount,
          videoTotal,
          videoCount,
          this.countLargeFiles
        )
      );

      if (photoCount == photoTotal && videoCount == videoTotal) {
        this.$refs.progressBar.hide();
        this.countLargeFiles = 0;
      }
    },
    async onUploadImage(event) {
      const app = this;
      let files = app.$refs.imageInput.files;
      let photoCount = 0;
      let videoCount = 0;
      const failedVideos = [];
      let imgTool = new ImageTools(this.$f7.device);
      const imageFilesCount = Array.from(files).filter(file =>
        file.type.startsWith('image/')
      ).length;
      const videoFilesCount = Array.from(files).filter(file =>
        file.type.startsWith('video/')
      ).length;
      if (files.length > 0 && this.showProgressBar) this.onStartUpload();
      if (!this.showProgressBar) app.$f7.preloader.show();

      const uploadPromises = [];

      for (let i = 0; i < files.length; i += 1) {
        if (
          files[i].type === 'image/png' ||
          files[i].type === 'image/jpeg' ||
          files[i].type === 'image/jpg' ||
          files[i].type === 'image/webp'
        ) {
          const uploadPromise = (async () => {
            let photo = {};
            const name = files[i].name;
            const resizedFile = await imgTool.resize(files[i], {
              width: 256,
              height: 256,
            });
            if (!resizedFile.name) resizedFile.name = name;

            const thumbnailData = await app.uploadPhoto({
              file: resizedFile,
              projectId: app.proposal.projectId,
            });
            photo = {
              thumbnailUrl: thumbnailData.url,
              thumbnailFullPath: thumbnailData.fullPath,
            };

            if (app.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount,
              });
            }

            if (photo.thumbnailUrl) {
              const fullSizeFile = await imgTool.resize(files[i], {
                width: 1920,
                height: 1080,
              });
              if (!fullSizeFile.name) fullSizeFile.name = name;

              const photoData = await app.uploadPhoto({
                file: fullSizeFile,
                projectId: app.proposal.projectId,
              });
              photo = {
                ...photo,
                projectId: app.proposal.projectId,
                photoUrl: photoData.url,
                photoName: name,
                albumId: app.currentSection.albumId || null,
                photoFullPath: photoData.fullPath,
              };

              await app.createProjectPhoto(photo);
              photoCount += 1;

              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }
            }
          })().catch(() => {
            photoCount += 1;
            if (this.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount,
              });
            }
            failedVideos.push(files[i].name);
          });

          uploadPromises.push(uploadPromise);
        } else if (files[i].type.startsWith('video/') && this.isUploadVideo) {
          const uploadPromise = (async () => {
            try {
              let photo = {};
              const name = files[i].name;
              const cover = await imgTool.getVideoThumbnail(files[i]);
              const fileThumb = new File([cover], name + '.webp', {
                type: 'image/webp',
              });

              const resizedFile = await imgTool.resize(fileThumb, {
                width: 256,
                height: 256,
              });
              if (!resizedFile.name) resizedFile.name = name;

              const thumbnailData = await app.uploadPhoto({
                file: resizedFile,
                projectId: app.proposal.projectId,
              });
              photo = {
                thumbnailUrl: thumbnailData.url,
                thumbnailFullPath: thumbnailData.fullPath,
              };

              if (app.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }

              if (photo.thumbnailUrl) {
                const videoData = await app.uploadPhoto({
                  file: files[i],
                  projectId: app.proposal.projectId,
                });
                photo = {
                  ...photo,
                  projectId: app.proposal.projectId,
                  photoUrl: videoData.url,
                  photoName: name,
                  albumId: app.currentSection.albumId || null,
                  photoFullPath: videoData.fullPath,
                };

                await app.createProjectPhoto(photo);
                videoCount += 1;

                if (this.showProgressBar) {
                  app.onUploadProgress({
                    photoCount: photoCount,
                    videoCount: videoCount,
                    photoTotal: imageFilesCount,
                    videoTotal: videoFilesCount,
                  });
                }
              }
            } catch (error) {
              videoCount += 1;
              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }
              failedVideos.push(files[i].name);
            }
          })();

          uploadPromises.push(uploadPromise);
        } else {
          failedVideos.push(files[i].name);
        }
      }

      await Promise.all(uploadPromises);
      if (photoCount == imageFilesCount && videoCount == videoFilesCount) {
        event.target.value = null;
        if (!this.showProgressBar) app.$f7.preloader.hide();
      }
      if (failedVideos.length > 0) {
        app.$f7.preloader.hide();
        const failedVideosContent = `There's a problem with the files.</br>${failedVideos
          .map((file, index) => `${index + 1}. ${file}`)
          .join('<br>')}`;
        this.openWarningDialogUploadPhoto(failedVideosContent);
      }
    },
    openWarningDialogUploadPhoto(content) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Invalid file!',
        content: content,
        hideCancelButton: true,
        cssClass: 'photo-dialog',
        onClick: (_sefl, index) => {
          if (index === 0) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          }
        },
      });
    },
    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.handleSelected(id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.handleSelected(id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.photoIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.photoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
  },

  watch: {
    projectPhotoList: {
      handler(val) {
        const list = (_.cloneDeep(val) || []).map(r => ({
          ...r,
          notes: (this.photoList.find(p => p.id === r.id) || {}).notes || '',
        }));

        if (this.currentSection.albumId) {
          this.photoList = list.filter(r => {
            return r.albumId === this.currentSection.albumId;
          });
        } else if (this.currentSection.albumId === null) {
          this.photoList = list.filter(r => {
            return r.albumId === null || r.albumId === undefined;
          });
        } else {
          this.photoList = list;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-pic .card-header {
  height: 200px;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}

.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}

.img-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.6) 80%);
}

.img-checkbox {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;

  ::v-deep .icon-checkbox {
    border-color: white;
    border-radius: 0;
  }
}

.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}

.button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0rem 1rem;
}

.upload-photo {
  height: 100px;
  display: grid;
  place-items: center;
  border-width: 2px;
  border-style: dashed;
  border-color: var(--f7-color-bg-12-neutral);
  border-radius: 3px;
  margin: 20px;
  background: var(--f7-color-bg-11-neutral);
  cursor: pointer;
}

.upload-photo:hover {
  background-color: var(--f7-color-week-hover-neutral);
}

.checkbox ::v-deep i {
  border-color: white;
}

.tab-btn {
  right: var(--global-search-btn-right);

  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) - 44px
    );
  }

  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) - 12px) * 2
    );
  }
}

.play-button {
  position: absolute;
  width: 100%;
  top: 34%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
