<template>
  <f7-popup
    class="demo-popup"
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ isNew ? 'New Team' : 'Edit Team' }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="isNew ? add() : save()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <f7-list-input
          class="first-input"
          placeholder="Team Name"
          label="Team Name"
          :value="currentTeam.teamName"
          @input="currentTeam.teamName = $event.target.value.trim()"
          error-message-force
          clear-button
          :error-message="displayNameErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
      </f7-list>
      <f7-list>
        <f7-list-input
          type="textarea"
          placeholder="Description"
          label="Description"
          :value="currentTeam.description"
          @input="currentTeam.description = $event.target.value"
          error-message-force
          clear-button
          validate
          :error-message="displayDescErrorMessage"
        >
          <required-asterisk slot="label" />
        </f7-list-input>
      </f7-list>
      <f7-list>
        <f7-list-item title="Active">
          <f7-toggle
            slot="after"
            :checked="!currentTeam.disabled"
            @toggle:change="currentTeam.disabled = !$event"
          ></f7-toggle>
        </f7-list-item>
      </f7-list>
      <f7-list>
        <f7-list-item
          class="custom-item"
          link="javascript:void(0)"
          title="Add Team Member"
          @click="isShowMemberSelectPopup = true"
        >
          <required-asterisk slot="title"></required-asterisk>
          <span slot="after">{{ showTeamMember }}</span>
          <span style="color: red; font-size: 12px">{{ errMessage }}</span>
        </f7-list-item>
      </f7-list>
    </f7-page>
    <member-select-popup
      :isShowPopup="isShowMemberSelectPopup"
      :showTeamMember="showTeamMember"
      :isNew="isNew"
      @close="isShowMemberSelectPopup = false"
      @selectMember="selectMember"
      :isManager="isManager"
    ></member-select-popup>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import MemberSelectPopup from '@/plugins/time-tracking/components/popups/MemberSelectPopup.vue';
import {
  VALIDATION_MESSAGE,
  COLLECTION_TEAM_MANAGEMENT,
} from '@/utility/const';
export default {
  components: {
    MemberSelectPopup,
  },
  props: {
    isShow: Boolean,
    isNew: Boolean,
  },
  data: () => {
    return {
      isShowMemberSelectPopup: false,
      errMessage: '',
      showTeamMember: '',
      currentTeam: {
        teamName: '',
        description: '',
        disabled: false,
        userIDs: [],
        managerID: '',
      },
      isManager: false,
    };
  },
  computed: {
    ...mapGetters('common/team-management', ['team']),
    ...mapGetters('time-tracking/user', ['userList']),
    displayNameErrorMessage() {
      if (!this.v$.currentTeam.teamName.$error) return null;
      if (this.v$.currentTeam.teamName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    displayDescErrorMessage() {
      if (!this.v$.currentTeam.description.$error) return null;
      if (this.v$.currentTeam.description.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },
  methods: {
    ...mapActions('common/team-management', [
      'createTeam',
      'updateTeam',
      'getTeamListBys',
    ]),
    ...mapActions('common/administration-user', [
      'addTeamRef',
      'removeTeamRef',
    ]),
    ...mapActions('common/notification', ['createNotificationByType']),

    cancel() {
      this.v$.$reset();
      this.showTeamMember = '';
      this.$emit('close');
    },
    selectMember(userIDs, managerID) {
      this.currentTeam = {
        ...this.currentTeam,
        userIDs,
        managerID,
      };
      let nameArr = [];
      const manager = this.userList.find(i => {
        if (i.uid === managerID) {
          return true;
        }
      });
      if (manager) {
        const managerName = (manager.displayName || '') + '(Manager)';
        nameArr.push(managerName);
        this.isManager = true;
      }
      userIDs.forEach(userId => {
        if (userId !== managerID) {
          const memberName = (
            this.userList.find(i => {
              return i.uid === userId;
            }) || {}
          ).displayName;
          if (memberName) {
            nameArr.push(memberName);
          }
        }
      });
      // else {
      //   const filteredUserIDs = userIDs.filter(id => id !== managerID);

      //   this.currentTeam = {
      //     ...this.currentTeam,
      //     userIDs: filteredUserIDs,
      //     managerID: ""
      //   };
      //   filteredUserIDs.forEach(userId => {
      //     if (userId !== managerID) {
      //       const memberName = (
      //         this.userList.find(i => {
      //           return i.uid === userId;
      //         }) || {}
      //       ).displayName;
      //       if (memberName) {
      //         nameArr.push(memberName);
      //       }
      //     }
      //   });
      // }
      this.showTeamMember = nameArr.join(', ');
    },
    async existsTeam() {
      let teamByTeamName = await this.getTeamListBys([
        {
          prop: 'teamName',
          val: this.currentTeam.teamName,
          op: '==',
        },
        {
          prop: 'isDeleted',
          val: false,
          op: '==',
        },
      ]);

      if (!this.isNew) {
        teamByTeamName = teamByTeamName.filter(i => i.id !== this.team.id);
      }

      if (!_.isEmpty(teamByTeamName)) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That team has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return !_.isEmpty(teamByTeamName);
    },
    validateTeamMember() {
      if (this.currentTeam.userIDs.length < 1) {
        this.errMessage = 'This field is required';
        return true;
      } else {
        this.errMessage = '';
        return false;
      }
    },
    async add() {
      this.v$.$touch();
      const existedTeam = await this.existsTeam();
      if (this.v$.$invalid || existedTeam || this.validateTeamMember()) {
        this.validateTeamMember();
        return;
      }
      this.$f7.preloader.show();
      this.createTeam(this.currentTeam).then(async teamID => {
        if (!this.currentTeam.disabled) {
          await this.createNotificationByType({
            data: {
              assignees: this.currentTeam.userIDs,
              managerID: this.currentTeam.managerID,
              project: {
                title: this.currentTeam.teamName,
                id: teamID,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'assign-user-manager',
          });
        }
        this.currentTeam.userIDs.forEach(uid => {
          this.addTeamRef({
            id: uid,
            teamID,
          });
        });
        this.cancel();
        this.$f7.preloader.hide();
      });
    },
    async save() {
      this.v$.$touch();
      const existedTeam = await this.existsTeam();
      if (this.v$.$invalid || existedTeam || this.validateTeamMember()) {
        this.validateTeamMember();
        return;
      }
      this.$f7.preloader.show();
      let userIDsBefore = _.cloneDeep(this.team.userIDs);
      let teamNameBefore = _.cloneDeep(this.team.teamName);
      let statusBefore = _.cloneDeep(this.team.disabled);
      let managerBefore = _.cloneDeep(this.team.managerID);
      this.currentTeam = {
        ...this.currentTeam,
        userIDs: this.currentTeam.userIDs.filter(id => {
          return this.userList.some(item => item.uid === id);
        }),
      };
      let userIDsAfter = _.cloneDeep(this.currentTeam.userIDs);
      let teamNameAfter = _.cloneDeep(this.currentTeam.teamName);
      let statusAfter = _.cloneDeep(this.currentTeam.disabled);
      let managerAfter = _.cloneDeep(this.currentTeam.managerID);

      this.updateTeam({
        id: this.team.id,
        doc: this.currentTeam,
      }).then(async () => {
        let userIDsRemove = _.differenceWith(
          userIDsBefore,
          userIDsAfter,
          _.isEqual
        );
        let userIDsAdd = _.differenceWith(
          userIDsAfter,
          userIDsBefore,
          _.isEqual
        );
        userIDsAdd.forEach(id => {
          this.addTeamRef({
            id,
            teamID: this.team.id,
          });
        });
        userIDsRemove.forEach(id => {
          this.removeTeamRef({
            id,
            teamID: this.team.id,
          });
        });
        if (statusBefore !== statusAfter) {
          await this.createNotificationByType({
            data: {
              assignees: this.currentTeam.userIDs,
              disabled: this.currentTeam.disabled,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: this.currentTeam.disabled ? 'inactive-team' : 'active-team',
          });
        }
        if (teamNameBefore !== teamNameAfter) {
          await this.createNotificationByType({
            data: {
              assignees: this.currentTeam.userIDs,
              oldName: teamNameBefore,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'change-team-name',
          });
        }
        if (
          managerBefore !== managerAfter &&
          !userIDsAdd.includes(managerAfter)
        ) {
          await this.createNotificationByType({
            data: {
              assignees: [managerAfter],
              managerID: managerAfter,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'assign-user-manager',
          });
          await this.createNotificationByType({
            data: {
              assignees: [managerBefore],
              managerID: managerAfter,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'remove-manager',
          });
        }
        if (userIDsAdd.length > 0) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsAdd,
              managerID: managerAfter,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'assign-user-manager',
          });
        }
        if (userIDsRemove.length > 0) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsRemove,
              managerID: managerAfter,
              project: {
                title: this.currentTeam.teamName,
                id: this.team.id,
                type: 'team',
                entityName: COLLECTION_TEAM_MANAGEMENT,
              },
            },
            type: 'remove-user',
          });
        }
        this.cancel();
        this.$f7.preloader.hide();
      });
    },

    handlePopupOpened() {
      if (this.isNew && this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations: {
    currentTeam: {
      teamName: { required },
      description: { required },
    },
  },
  watch: {
    isShow(val) {
      if (this.isNew && val) {
        this.currentTeam = {
          teamName: '',
          description: '',
          disabled: false,
          userIDs: [],
          managerID: '',
        };
      } else if (!this.isNew && val) {
        const { teamName, description, disabled, userIDs, managerID } =
          this.team;
        this.currentTeam = {
          teamName,
          description,
          disabled,
          userIDs,
          managerID,
        };
        this.selectMember(userIDs, managerID);
      }
    },
    currentTeam(val) {
      if (val.userIDs.length > 0) {
        this.errMessage = '';
      }
    },
  },
};
</script>
<style>
.custom-item .item-after {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  display: block;
}
</style>
