<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          @click.native="handleBack"
          icon-f7="chevron_left"
        ></f7-link
      ></f7-nav-left>
      <f7-nav-title>{{
        proposal.project ? `${proposal.project.title}` : ''
      }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="exportPdf">Download PDF</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div
      v-if="proposal"
      :style="styleContent"
      ref="wrapPreviewContent"
      class="display-flex flex-direction-column align-items-center justify-content-flex-start text-color-black"
    >
      <div
        v-for="(page, index) in pdfPages"
        :key="index"
      >
        <template>
          <preview-page
            :id="`${page.pageId}_${page.index}`"
            :ref="`${page.pageId}_${page.index}`"
            :htmlValue="page.htmlValue"
          ></preview-page>
        </template>
      </div>
    </div>
    <div v-else>Not found proposal</div>
  </f7-page>
</template>

<script>
import PreviewPage from '../components/preview-components/PreviewPage.vue';
import { mapActions, mapGetters } from 'vuex';
import calcEstimateMixin from '../mixins/calc-estimate-mixin';
import jsPDF from 'jspdf';
import _ from 'lodash';
import {
  BUILDING_PRODUCT_BREAKED,
  BUILDING_PRODUCT_PAGE,
  PRODUCT_TABLE_TYPE,
  IMAGE_BREAKED,
  IMAGE_PAGE,
  IMAGE_STATUS_TYPE,
  SUMMARY_BREAKED,
  SUMMARY_ESTIMATE_PAGE,
  SUMMARY_ESTIMATE_TABLE_TYPE,
  TEXT_EDITOR_RESIZABLE_TYPE,
} from '../utility/const';
import { generateImage } from '../../../services/utils';
import { PDF_CONFIG } from '@/utility/pdf';

export default {
  mixins: [calcEstimateMixin],

  components: {
    PreviewPage,
  },

  data() {
    return {
      pages: {},
      styleContent: '',
      sortedSections: [],
      modifiedSections: [],
    };
  },

  mounted() {
    let proposalNumber = this.$f7route.params.proposalNumber;
    let proposal = this.proposalByNumber(proposalNumber);
    if (!proposal.id) {
      this.getProposalBys([
        {
          prop: 'proposalNumber',
          val: proposalNumber,
          op: '==',
        },
      ])
        .then(async docs => {
          if (docs.length > 0) {
            await this.bindProposalSectionListBys([
              {
                prop: 'proposalId',
                val: docs[0].id,
                op: '==',
              },
              {
                prop: 'isDeleted',
                val: false,
                op: '==',
              },
            ]);
            this.setProposal(docs[0]);
            this.lowResDesktopScreen();
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
    this.setProposal(proposal);
    this.lowResDesktopScreen();
  },

  watch: {
    proposal: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!_.isEmpty(val)) {
          this.getPages(val);
        }
      },
    },
    proposalSectionList: {
      handler(newList) {
        this.sortedSections = this.sortAndUpDateIndexSectionBeforeSave([
          ...newList,
        ]);
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      proposal: 'proposal/proposal/proposal',
      proposalByNumber: 'proposal/proposal/proposalByNumber',
      proposalSectionList: 'common/proposal-section/proposalSectionList',
    }),

    pdfPages() {
      let sections = [];
      (this.modifiedSections.length > 0
        ? this.modifiedSections
        : this.sortedSections
      ).forEach(section => {
        const a = this.breakSection(section);
        sections = sections.concat(a);
      });
      const pages = [];
      const headerSection = sections.find(
        section => section.sectionId === 'header-section'
      );

      const footerSection = sections.find(
        section => section.sectionId === 'footer-section'
      );

      for (let index = 0; index < sections.length; index++) {
        const section = sections[index];
        switch (section.sectionId) {
          case 'header-section':
          case 'footer-section':
            break;
          default:
            pages.push({
              htmlValue: `
                <div style="height: 100%; display: flex; flex-direction: column;">
                  <div style="flex: 1; position: relative;">
                    ${section.htmlValue}
                  </div>
                  <div style="margin-bottom:40px;">
                    ${footerSection && section.sectionId !== 'cover-page' ? footerSection.htmlValue : ''}
                  </div>
                </div>`,
              pageId: section.sectionId,
              index,
            });
        }
        if (index === 2 && headerSection && pages.length > 0) {
          pages[1].htmlValue = `
            <div style="height: 100%; display: flex; flex-direction: column;">
              <div>
                ${headerSection.htmlValue}
              </div>
              ${pages[1].htmlValue}
            </div>`;
        }
      }
      return pages;
    },
  },

  methods: {
    ...mapActions({
      setProposalField: 'proposal/proposal/setProposalField',
      setProposal: 'proposal/proposal/setProposal',
      getProposalBys: 'proposal/proposal/getProposalBys',
      bindProposalSectionListBys:
        'common/proposal-section/bindProposalSectionListBys',
    }),

    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = 1020;
        const letterFullHeight = 1320;
        const width = this.$refs.wrapPreviewContent.clientWidth;
        const height = this.$refs.wrapPreviewContent.clientHeight;
        const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${scaleRatio}); margin: ${spaceHeight}px 0`;
        } else {
          this.styleContent = '';
        }

        let divs = Array.from(
          this.$refs.wrapPreviewContent.getElementsByTagName('div')
        );
        let indexCount = 0;
        let newSortedSections = this.sortedSections.map(section => {
          if (
            section.sectionId === 'header-section' ||
            section.sectionId === 'footer-section' ||
            section.sectionId === 'cover-page'
          ) {
            return section;
          }
          let targetDivs = divs.filter(
            div =>
              div.style.textAlign === 'justify' && div.style.padding === '56px'
          );
          let pageNumber = 1;
          if (!targetDivs[indexCount]) {
            indexCount++;
            return section;
          }
          let childDivs = targetDivs[indexCount].querySelectorAll('div');
          let divSections = section.htmlValue.split('</div>');
          for (let i = 0; i < childDivs.length; i++) {
            let childDiv = childDivs[i];
            let pageHeight =
              pageNumber === 1
                ? section.index !== 1
                  ? letterFullHeight - 100
                  : letterFullHeight - 300
                : section.index !== 1
                  ? pageNumber * (letterFullHeight - 100)
                  : (pageNumber - 1) * (letterFullHeight - 100) +
                    (letterFullHeight - 300);
            if (childDiv.offsetHeight + childDiv.offsetTop > pageHeight) {
              let strChild = childDiv.outerHTML.replace('</div>', '').trim();
              let trimmedDivSections = divSections.map(section =>
                section.trim()
              );
              let count = -1;
              trimmedDivSections.map((section, index) => {
                if (
                  section.trim().replace(/\s+/g, ' ') ===
                  strChild.trim().replace(/\s+/g, ' ')
                ) {
                  count = index;
                }
              });
              if (count !== -1) {
                let list = childDiv.querySelector('ul, ol');
                if (list) {
                  let listItems = list.querySelectorAll('li');
                  let newDiv = null;
                  let newUl = null;
                  listItems.forEach(li => {
                    let liOffsetTotal = li.offsetHeight + li.offsetTop;
                    if (liOffsetTotal > pageHeight) {
                      if (!newDiv) {
                        newDiv = document.createElement('div');
                        newDiv.classList.add('break-page');
                        newDiv.style.marginTop = '15px';
                        newUl = document.createElement('ul');
                        newUl.style.listStyleType = 'disc';
                        newUl.style.fontSize = '20px';
                        newDiv.appendChild(newUl);
                        childDiv.parentNode.insertBefore(
                          newDiv,
                          childDiv.nextSibling
                        );
                        pageNumber += 1;
                      }
                      newUl.appendChild(li);
                    }
                  });

                  divSections[i] =
                    childDiv.outerHTML + newDiv.outerHTML.replace('</div>', '');
                } else {
                  if (childDiv.classList.length > 0) {
                    childDiv = childDivs[i - 1];
                    childDiv.classList.add('break-page');
                    divSections[i - 1] = childDiv.outerHTML.replace(
                      '</div>',
                      ''
                    );
                  } else {
                    childDiv.classList.add('break-page');
                    divSections[i] = childDiv.outerHTML.replace('</div>', '');
                  }
                  pageNumber += 1;
                }
              }
            }
          }
          indexCount += 1;
          return {
            ...section,
            htmlValue: divSections.join('</div>'),
          };
        });
        this.modifiedSections = newSortedSections;
      });
    },
    breakSection(section) {
      const replaced1 = section.htmlValue.replace(
        `<div style="margin-top:15px" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );

      const replaced2 = replaced1.replace(
        `<div style="margin-top:15px;" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const replaced3 = replaced2.replace(
        `<div class="break-page" style="margin-top: 15px;">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const arr = replaced3.split(
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      if (arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (i === 0) {
            arr[i] = `${item}</div></div></font>`;
          } else if (i === arr.length - 1) {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; padding: 56px;" class="">
                          <div class="">${item}`;
          } else {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; padding: 56px;" class="">
                          <div class="">${item}</div></div></font>`;
          }
        }
      }
      return arr.map(r => ({
        ...section,
        htmlValue: r,
      }));
    },

    sortAndUpDateIndexSectionBeforeSave(sections) {
      const newSections = _.cloneDeep(sections);
      newSections.sort((a, b) => a.index - b.index);

      for (let i = 0; i < newSections.length; i++) {
        newSections[i].index = i;
      }
      return newSections;
    },

    async getPages(proposal) {
      let pages = _.cloneDeep((proposal || {}).pages);

      this.pages = pages; // set initial

      if (!pages || pages.length <= 0 || !Array.isArray(pages)) return;

      let buildingProductPages = pages.filter(
        r => r.name === BUILDING_PRODUCT_PAGE
      );
      if (!buildingProductPages && buildingProductPages.length <= 0) return;

      // break product table page
      let indexProductPage = pages.findIndex(
        r => r.name === BUILDING_PRODUCT_PAGE
      );
      while (indexProductPage >= 0) {
        let page = pages[indexProductPage];
        let productTableIndex = page.components.findIndex(
          r => r.type === PRODUCT_TABLE_TYPE
        );
        let productTable = page.components[productTableIndex];
        let building = productTable.data.building;
        let products = building ? this.getProducts(building) : [];
        if (products.length) {
          //move to new page if products items more than 18 each page
          let trackingLoop = 0;
          for (let i = 0; i < products.length; i += 16) {
            let productPerPage = products.slice(i, i + 16);
            let newPage = _.cloneDeep(page);
            newPage.name = BUILDING_PRODUCT_BREAKED;
            newPage.components[productTableIndex] = {
              ...productTable,
              data: {
                products: productPerPage,
                buildingName: building.buildingName,
              },
            };
            if (
              i > 0 &&
              newPage.components.some(
                r => r.type === TEXT_EDITOR_RESIZABLE_TYPE
              )
            ) {
              // remove header page
              newPage.components = newPage.components.filter(
                r => r.type !== TEXT_EDITOR_RESIZABLE_TYPE
              );
            }
            pages.splice(
              indexProductPage + trackingLoop,
              trackingLoop == 0 ? 1 : 0,
              newPage
            );
            trackingLoop++;
          }
        } else {
          // remove blank estimate page
          pages.splice(indexProductPage, 1);
        }
        indexProductPage = pages.findIndex(
          r => r.name === BUILDING_PRODUCT_PAGE
        );
      }

      // break image table page
      let imagePageIndex = pages.findIndex(r => r.name === IMAGE_PAGE);
      while (imagePageIndex >= 0) {
        let page = pages[imagePageIndex];
        let imgIndex = page.components.findIndex(
          r => r.type === IMAGE_STATUS_TYPE
        );
        let imagePage = page.components[imgIndex];
        //move to new page if image more than 6 each page
        let trackingLoop = 0;
        let images = imagePage.data.images || [];
        if (images.length) {
          for (let i = 0; i < images.length; i += 6) {
            let imagePerPage = images.slice(i, i + 6);
            let newPage = _.cloneDeep(page);
            newPage.name = IMAGE_BREAKED;

            newPage.components[imgIndex] = {
              ...imagePage,
              data: {
                images: imagePerPage,
              },
            };
            pages.splice(
              imagePageIndex + trackingLoop,
              trackingLoop == 0 ? 1 : 0,
              newPage
            );
            trackingLoop++;
          }
        } else {
          // remove blank image page
          pages.splice(imagePageIndex, 1);
        }
        imagePageIndex = pages.findIndex(r => r.name === IMAGE_PAGE);
      }

      // remove blank summary page
      let summaryPageIndex = pages.findIndex(
        r => r.name === SUMMARY_ESTIMATE_PAGE
      );
      while (summaryPageIndex >= 0) {
        let page = pages[summaryPageIndex];
        let summaryIndex = page.components.findIndex(
          r => r.type === SUMMARY_ESTIMATE_TABLE_TYPE
        );
        let summaryTable = page.components[summaryIndex];
        if ((summaryTable.data.buildingIds || []).length < 1) {
          pages.splice(summaryPageIndex, 1);
        } else {
          let newPage = _.cloneDeep(page);
          newPage.name = SUMMARY_BREAKED;
          pages.splice(summaryPageIndex, 1, newPage);
        }
        summaryPageIndex = pages.findIndex(
          r => r.name === SUMMARY_ESTIMATE_PAGE
        );
      }
      this.pages = pages;
    },

    getProducts(building) {
      let products = [];
      (building.productData || []).forEach(element => {
        let sectionProduct = element.productList.map(r => ({
          ...r,
          category: element.category,
          tax: true,
        }));
        products = products.concat(sectionProduct);
      });

      if (!products.length) return [];
      products = this.items(this.proposal.estimate, products);
      products = [
        ...products,
        {
          isFooter: true,
          title: 'Sub Total',
          value: this.subTotal(this.proposal.estimate, products),
        },
        {
          isFooter: true,
          title: 'Tax Amount',
          value: this.taxAmount(
            this.proposal.project,
            building,
            this.proposal.estimate,
            products
          ),
        },
        {
          isFooter: true,
          title: 'Total',
          value: this.total(
            this.proposal.project,
            building,
            this.proposal.estimate,
            products
          ),
        },
      ];
      const productIdsRemoved = _.cloneDeep(
        (
          (this.proposal.productIdsRemoved || []).find(
            r => r.buildingId === building.id
          ) || {}
        ).productIds || []
      );
      products = products.filter(r => !productIdsRemoved.includes(r.id));
      return products;
    },

    async getPDF() {
      var doc = new jsPDF();
      const pages = this.$refs.htmlContent.getElementsByClassName('page-form');
      this.$f7.dialog.preloader('Downloading PDF. Please wait...');
      const images = await Promise.all(
        Array.from(pages).map(page => generateImage(page.innerHTML))
      );

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (!_.isEmpty(image)) {
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          if (i > 0) {
            doc.addPage('a4');
          }
          doc.setPage(i + 1);
          doc.addImage(image, 'PNG', 0, 0, width, height, i, 'FAST');
        }
      }
      this.$f7.dialog.close();
      doc.save(`${this.proposal.proposalNumber}-Proposal.pdf`);
    },

    async exportPdf() {
      this.$f7.dialog.preloader('Downloading PDF. Please wait...');

      const { width, height } = PDF_CONFIG.DOWNLOAD_SIZE;

      const doc = new jsPDF('p', 'pt', [width, height]); //850x1100

      try {
        const images = await Promise.all(
          this.pdfPages.map(page =>
            generateImage(
              this.$refs[`${page.pageId}_${page.index}`][0].$el.innerHTML
            )
          )
        );
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          if (!_.isEmpty(image)) {
            if (index > 0) {
              doc.addPage([width, height], 'p');
            }
            doc.setPage(index + 1);

            doc.addImage(
              image,
              'PNG',
              0,
              0,
              width,
              height,
              `page-${index}`,
              'FAST'
            );
          }
        }

        doc.save(
          `PROPOSAL - ${this.proposal.proposalNumber} - ${this.proposal.project.title}.pdf`
        );
      } catch (error) {
        this.$f7.dialog.close();
      }

      this.$f7.dialog.close();
    },
    handleBack() {
      this.$f7router.navigate(
        `/proposal/${this.$f7route.params.proposalNumber}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page-preview-content {
  width: 1020px;
  height: 1320px;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
}

.page-preview-content ::v-deep font > div {
  padding-top: 1px;
}
</style>
