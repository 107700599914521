export default {
  userList: state => state.userList,
  // userList: ({ userList }) => userList.filter(r => r.disabled == false),

  activeUsers: state => state.userList.filter(user => !user.disabled),

  userById: state => id => state.userList.find(user => user.uid === id) || {},
  userByIds:
    ({ userList }) =>
    ids => {      
      if (!ids) return '';
      return (userList.filter(r => (ids || []).includes(r.uid)) || [])
        .map(r => r.displayName)
        .join(', ');
    },
  userGroupList: state => state.userGroupList,
};
