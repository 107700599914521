<template>
  <f7-page class="project-photo-page-container">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-if="!$device.desktop"
          @click="handleBackBtn"
          icon-f7="chevron_left"
        />
      </f7-nav-left>
      <f7-nav-title>{{ `${project.title || ''}` }} </f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="selectedIds.length > 0"
          @click="cancelSelect"
        >
          Cancel
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- tabs -->
    <f7-block class="tab-block">
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          class="custom-hover"
          :active="currentTab === 'album'"
          @click.native="onTab('album')"
          >Albums</f7-button
        >
        <f7-button
          class="custom-hover"
          :active="currentTab === 'photo'"
          @click.native="onTab('photo')"
          >Photos</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div
      v-if="currentTab === 'album'"
      class="margin-top margin-left"
    >
      Selected {{ selectedIds.length }} items
    </div>
    <f7-tabs
      class="tabs-container"
      animated
    >
      <div v-show="currentTab === 'album'">
        <albums
          ref="albumTab"
          :selectionMode="selectionMode"
          :selectedAlbumIds="selectedIds"
          :hoveredAlbumIds="hoveredIds"
          :isDeleteAlbum="isDeleteAlbum"
          @clickAlbum="clickAlbum"
          @handleSelected="handleSelected"
        ></albums>
      </div>
      <div v-show="currentTab === 'photo'">
        <photos
          ref="photoTab"
          :projectPhotoListGroup="projectPhotoListGroup"
          :selectionMode="selectionMode"
          :selectedPhotoIds="selectedIds"
          :loading="loadingPhoto"
          :hasMoreItems="hasMoreItems"
          :hoveredPhotoIds="hoveredIds"
          :photoFilter="photoFilter"
          :endDateFilterErrorMessage="endDateFilterErrorMessage"
          @clickPhoto="clickPhoto"
          @clickEdit="clickEdit"
          @loadMore="loadMore"
          @handleSelected="handleSelected"
          @handleSelectAllDay="handleSelectAllDay"
          @onFilterDate="onFilterDate"
          @handleMouseEnter="createTooltips"
          @takePhoto="handleOpenTakePhotosPopup"
          @openFileInput="handleOpenFileInput"
        ></photos>
      </div>
    </f7-tabs>
    <template v-if="selectedIds.length > 0">
      <template v-if="currentTab === 'photo'">
        <delete-photo
          class="tab-btn tab-btn_1"
          :photosSelected="projectPhotoSelected(selectedIds)"
          @cancelSelect="cancelSelect"
        ></delete-photo>
        <f7-fab
          :class="`tab-btn tab-btn_3 move-tooltips-${uuid}`"
          position="right-bottom"
          slot="fixed"
          color="default"
          @click="handleMoveToAbum"
        >
          <f7-icon f7="square_arrow_left"></f7-icon>
        </f7-fab>
      </template>
      <template v-if="currentTab === 'album'">
        <delete-album
          class="tab-btn tab-btn_1"
          :selectedIds="selectedIds"
          @cancelSelect="cancelSelect"
          @handleClick="onClickDeleteAlbum"
        ></delete-album>
        <f7-fab
          v-if="selectedIds.length === 1 && !isDeleteAlbum"
          :class="`tab-btn tab-btn_3 edit-tooltips-${uuid}`"
          position="right-bottom"
          slot="fixed"
          color="default"
          @click="openEditAlbumPopup"
        >
          <f7-icon f7="pencil"></f7-icon>
        </f7-fab>
      </template>
      <f7-fab
        v-if="!isDeleteAlbum"
        :class="`tab-btn tab-btn_2 download-tooltips-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleDownload"
      >
        <f7-icon f7="cloud_download"></f7-icon>
      </f7-fab>
    </template>
    <template v-else>
      <f7-fab
        v-show="!$device.desktop"
        :class="`tab-btn tab-btn_3 add-new-tooltips-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleOpenTakePhotosPopup"
      >
        <f7-icon f7="camera"></f7-icon>
      </f7-fab>
      <f7-fab
        :class="`tab-btn tab-btn_2 upload-new-tooltips-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleOpenFileInput"
      >
        <f7-icon
          f7="plus"
          v-if="currentTab === 'photo'"
        ></f7-icon>
        <f7-icon
          f7="folder_badge_plus"
          v-else
        ></f7-icon>
      </f7-fab>
      <f7-fab
        :class="`tab-btn tab-btn_1 share-tooltips-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleShare"
      >
        <f7-icon f7="arrowshape_turn_up_right"></f7-icon>
      </f7-fab>
    </template>

    <upload-photo
      ref="uploadPhoto"
      :albumId="albumId"
      :showProgressBar="true"
    ></upload-photo>

    <photo-browser
      :photos="photoList"
      theme="dark"
      ref="pageDark"
      @clickEdit="clickEdit"
      @clickDownload="clickDownload"
    ></photo-browser>

    <add-new-album-popup
      :isShow="isShowAddAlbumPopup"
      :isEdit="isEdit"
      @open="openAddNewAlbumPopup"
      @cancelSelect="cancelSelect"
    ></add-new-album-popup>
    <edit-photo-popup ref="editPhoto"> </edit-photo-popup>
    <!-- Sheet share options -->
    <f7-sheet
      :opened="sheetShareOptionShow"
      @sheet:closed="sheetShareOptionShow = false"
      style="height: auto"
      swipe-to-close
      swipe-to-step
      backdrop
    >
      <div>
        <div
          class="display-flex padding justify-content-center align-items-center"
        >
          <div style="font-size: 18px">
            <b>Share project's photos with others</b>
          </div>
        </div>
        <div
          class="padding-horizontal padding-bottom display-flex justify-content-center align-items-center flex-direction-column"
        >
          <f7-button
            large
            fill
            class="margin-top"
            style="min-width: 300px"
            @click="shareByEmail"
          >
            <f7-icon
              f7="envelope"
              class="btn-icon"
            ></f7-icon>
            Share By Email</f7-button
          >
          <f7-button
            large
            fill
            class="margin-top"
            style="min-width: 300px"
            @click="shareBySMS"
          >
            <f7-icon
              f7="ellipses_bubble"
              class="btn-icon"
            ></f7-icon>
            Share By SMS</f7-button
          >
          <f7-button
            large
            fill
            class="margin-top"
            style="min-width: 300px"
            @click="copyLink"
          >
            <f7-icon
              f7="link"
              class="btn-icon"
            ></f7-icon
            >Copy link</f7-button
          >
        </div>
      </div>
    </f7-sheet>
    <!-- Share by Email Popup -->
    <share-by-email-popup ref="shareByEmailPopup"></share-by-email-popup>
    <!-- Share by SMS Popup -->
    <share-by-sms-popup ref="shareBySMSPopup"></share-by-sms-popup>
    <!-- Move to Album Popup -->
    <move-to-album-popup
      ref="movePhotoPopup"
      @cancelSelect="cancelSelect"
    ></move-to-album-popup>

    <take-photos-popup
      ref="takePhotosPopup"
      :albumId="albumId"
    ></take-photos-popup>
  </f7-page>
</template>
<script>
import Photos from '../components/list/Photos.vue';
import Albums from '../components/list/Albums.vue';
import UploadPhoto from '../components/upload/UploadPhoto.vue';
import AddNewAlbumPopup from '../components/popups/AddNewAlbumPopup.vue';
import EditPhotoPopup from '../components/popups/EditPhotoPopup.vue';
import ShareByEmailPopup from '../components/popups/ShareByEmailPopup.vue';
import ShareBySmsPopup from '../components/popups/ShareBySMSPopup.vue';
import MoveToAlbumPopup from '../components/popups/MoveToAlbumPopup.vue';
import PhotoBrowser from '../components/popups/PhotoBrowser.vue';
import DeletePhoto from '@/plugins/photos/components/buttons/DeletePhoto.vue';
import DeleteAlbum from '@/plugins/photos/components/buttons/DeleteAlbum.vue';
import TakePhotosPopup from '../components/popups/TakePhotosPopup.vue';

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { uuid } from 'vue-uuid';
import { TEMPLATE_TYPE, VALIDATION_MESSAGE } from '@/utility/const';
export default {
  components: {
    Photos,
    Albums,
    UploadPhoto,
    AddNewAlbumPopup,
    DeletePhoto,
    DeleteAlbum,
    EditPhotoPopup,
    PhotoBrowser,
    ShareByEmailPopup,
    ShareBySmsPopup,
    MoveToAlbumPopup,
    TakePhotosPopup,
  },

  data: () => {
    return {
      selectionMode: false,
      selectedIds: [],
      currentTab: 'album',
      isShowAddAlbumPopup: false,
      isEdit: false,
      loadingPhoto: null,
      sheetShareOptionShow: false,

      hasMoreItems: true,
      allowInfinite: true,

      currentPhotosContainHeight: 0,
      pagePhotosContainHeight: 0,
      albumId: '',
      hoveredIds: [],
      endDateFilterErrorMessage: '',
      isDeleteAlbum: false,
      uuid: uuid.v4(),
    };
  },
  methods: {
    ...mapActions('photo/project', ['getProject']),
    ...mapActions('photo/album', [
      'getProjectPhotoAlbum',
      'resetAlbum',
      'getProjectPhotoAlbumListBys',
      'getProjectPhotoAlbumListByProject',
    ]),
    ...mapActions('photo/photo', [
      'getMoreProjectPhotos',
      'getTotalPhotoByProjectId',
      'downloadPhoto',
      'resetPhoto',
      'downloadAlbum',
      'setPhotoFilterField',
      'resetPhotoFilter',
    ]),
    clickDownload(id) {
      const existedPhotoId = this.selectedIds.find(i => i === id);
      if (!existedPhotoId) {
        this.selectedIds.push(id);
      }
      this.handleDownload();
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.selectedIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredIds[lastIndex] !== null &&
          !this.selectedIds.includes(this.hoveredIds[lastIndex])
        ) {
          this.hoveredIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    handleSelectAllDay(data) {
      this.createTooltips();
      if (data.event) {
        const photosIds = (data.ids || []).filter(
          i => !this.selectedIds.includes(i)
        );
        this.selectedIds = this.selectedIds.concat(photosIds);
        this.hoveredIds = this.hoveredIds.concat(photosIds);
        this.selectionMode = true;
      } else {
        this.selectedIds = this.selectedIds.filter(i => !data.ids.includes(i));
        this.hoveredIds = this.hoveredIds.filter(i => !data.ids.includes(i));
      }
    },
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.add-new-tooltips-${this.uuid}`,
          text: `Add new ${this.currentTab === 'photo' ? 'photo/video' : this.currentTab}`,
        },
        {
          targetEl: `.upload-new-tooltips-${this.uuid}`,
          text: `Add new ${this.currentTab === 'photo' ? 'photo/video' : this.currentTab}`,
        },
        {
          targetEl: `.edit-tooltips-${this.uuid}`,
          text: `Edit ${this.currentTab}`,
        },
        {
          targetEl: `.move-tooltips-${this.uuid}`,
          text: `Move ${this.currentTab === 'photo' ? 'photo/video' : this.currentTab}`,
        },
        {
          targetEl: `.download-tooltips-${this.uuid}`,
          text: `Download ${this.currentTab === 'photo' ? 'photo/video' : this.currentTab}`,
        },
        {
          targetEl: `.share-tooltips-${this.uuid}`,
          text: 'Share project photo',
        },
      ];
      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button',
          text: tooltip.text,
        });
        document
          .querySelector(tooltip.targetEl)
          ?.addEventListener('mouseenter', () => {
            this.$f7.tooltip.get(tooltip.targetEl)?.show();
          });
      });
    },
    handleBackBtn() {
      // show loading and hide when component destroyed
      this.$f7.preloader.show();
      this.$f7router.navigate('/photo', {
        pushState: true,
        reloadAll: true,
      });
    },
    onTab(tab) {
      if (tab !== this.currentTab) this.cancelSelect();
      if (
        tab === 'photo' &&
        this.currentPhotosContainHeight === 0 &&
        this.pagePhotosContainHeight === 0
      ) {
        this.loadHeight();
      }
      if (tab === 'album') {
        this.$refs.albumTab.initData();
      }
      this.currentTab = tab;
      this.$f7router.updateCurrentUrl(
        `${this.$f7router.currentRoute.path}?currentTab=${tab}`
      );
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedIds = [];
      this.isDeleteAlbum = false;
      this.hoveredIds = [];
      this.$nextTick(() => {
        this.createTooltips();
      });
    },
    onSelect(id) {
      if (this.selectedIds.includes(id)) {
        var index = this.selectedIds.indexOf(id);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      } else {
        this.selectedIds.push(id);
      }

      if (!this.selectedIds.length) this.isDeleteAlbum = false;
    },
    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.projectPhotoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickEdit(id) {
      const photo = this.projectPhotoList.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
    },
    handleOpenTakePhotosPopup() {
      if (!this.$device.desktop) {
        this.$refs.takePhotosPopup.open();
      }
    },
    handleOpenFileInput() {
      if (this.currentTab === 'photo') {
        this.openFileInput();
      } else {
        this.openAddNewAlbumPopup(true, false);
      }
    },

    async handleDownload() {
      if (this.currentTab === 'photo') {
        this.$f7.toast
          .create({
            text: 'Downloading files...',
            closeOnClick: true,
            closeButton: false,
            closeTimeout: 3000,
          })
          .open();
        const photos = this.projectPhotoSelected(this.selectedIds);
        photos.forEach(i =>
          this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
        );
      } else {
        this.$f7.toast
          .create({
            text: 'Downloading albums...',
            closeOnClick: true,
            closeButton: false,
            closeTimeout: 3000,
          })
          .open();
        const albumsSelected = this.projectPhotoAlbumList.filter(r =>
          this.selectedIds.includes(r.id)
        );
        albumsSelected.forEach(album => {
          this.downloadAlbum({ albumId: album.id, albumName: album.name });
        });
      }
      this.cancelSelect();
    },

    openFileInput() {
      this.$refs.uploadPhoto.click();
    },
    openAddNewAlbumPopup(isShow, isEdit) {
      this.isShowAddAlbumPopup = isShow;
      this.isEdit = isEdit;
    },
    handleMoveToAbum() {
      this.$refs.movePhotoPopup.open(this.selectedIds);
    },
    handleShare() {
      this.shareProjectPhoto();
    },
    shareProjectPhoto() {
      this.sheetShareOptionShow = true;
    },
    shareByEmail() {
      this.$refs.shareByEmailPopup.open();
      this.sheetShareOptionShow = false;
    },
    shareBySMS() {
      this.$refs.shareBySMSPopup.open();
      this.sheetShareOptionShow = false;
    },
    copyLink() {
      this.$refs.shareByEmailPopup.copyLink();
      this.sheetShareOptionShow = false;
    },
    clickAlbum(id, isShowPhoto = false) {
      if (isShowPhoto) {
        this.resetPhotoFilter();
        this.$f7router.navigate(`/photo/${this.project.id}/${id}`);
      } else {
        this.onSelect(id);
      }
    },
    openEditAlbumPopup() {
      const systemAlbum = this.projectPhotoAlbumList.find(
        r =>
          r.id === this.selectedIds[0] &&
          r.templateType === TEMPLATE_TYPE.SYSTEM
      );
      if (!_.isEmpty(systemAlbum)) {
        this.$ri.dialog.openErrorDialog({
          title: `Can't rename album ${systemAlbum.name}`,
          content: "Because this is the system's default album",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        return;
      }
      this.getProjectPhotoAlbum(this.selectedIds[0]);
      this.openAddNewAlbumPopup(true, true);
    },

    photoTabInitData() {
      this.loadingPhoto = true;
      this.allowInfinite = true;
      this.hasMoreItems = true;
      const promises = [];
      promises.push(this.resetPhoto());
      promises.push(this.getTotalPhotoByProjectId(this.$f7route.params.id));
      promises.push(this.getMoreProjectPhotos(this.$f7route.params.id));
      promises.push(
        this.getProjectPhotoAlbumListByProject(this.$f7route.params.id)
      );

      Promise.all(promises)
        .then(() => {
          if (this.projectPhotoList.length === this.projectPhotosSize) {
            this.allowInfinite = false;
            this.hasMoreItems = false;
          }
          this.loadingPhoto = false;
          this.loadHeight();
        })
        .finally(() => {
          this.hasMoreItems = false;
        });
    },

    loadMore() {
      if (
        !this.allowInfinite ||
        this.projectPhotoList.length === this.projectPhotosSize
      ) {
        this.allowInfinite = false;
        this.hasMoreItems = false;
        return;
      }
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.getMoreProjectPhotos(this.$f7route.params.id)
        .then(() => {
          this.loadHeight();
          if (this.projectPhotoList.length === this.projectPhotosSize) {
            this.allowInfinite = false;
            this.hasMoreItems = false;
            return;
          }
          this.allowInfinite = true;
        })
        .finally(() => {
          this.hasMoreItems = false;
        });
    },

    loadHeight() {
      this.$nextTick(() => {
        this.currentPhotosContainHeight =
          (
            (this.$refs.photoTab && this.$refs.photoTab.$refs.imgsContainer) ||
            {}
          ).offsetHeight || 0;
        this.pagePhotosContainHeight =
          (
            (this.$refs.photoTab &&
              this.$refs.photoTab.$refs.photosPageContainer) ||
            {}
          ).offsetHeight || 0;
      });
    },

    onFilterDate({ field, value }) {
      if (
        (_.isEmpty(value) && _.isEmpty(this.photoFilter[field])) ||
        (!_.isEmpty(value) &&
          !_.isEmpty(this.photoFilter[field]) &&
          moment(value[0]).isSame(moment(this.photoFilter[field][0]), 'day')) ||
        this.currentTab !== 'photo'
      )
        return;

      this.setPhotoFilterField({ field, value });
      const { startDate, endDate } = this.photoFilter;
      let fromDate = '';
      let toDate = '';
      if (!_.isEmpty(value) && !_.isEmpty(startDate) && !_.isEmpty(endDate)) {
        if (field === 'endDate') {
          fromDate = moment(startDate[0]);
          toDate = moment(value[0]);
        } else {
          fromDate = moment(value[0]);
          toDate = moment(endDate[0]);
        }
      }
      if (fromDate && toDate && toDate.isBefore(fromDate, 'day')) {
        this.endDateFilterErrorMessage =
          VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
        return;
      } else {
        this.endDateFilterErrorMessage = '';
      }
      this.photoTabInitData();
    },
    onClickDeleteAlbum() {
      this.isDeleteAlbum = !this.isDeleteAlbum;
      this.$nextTick(() => {
        this.createTooltips();
      });
    },
  },

  computed: {
    ...mapGetters('photo/photo', [
      'projectPhotoList',
      'projectPhotoSelected',
      'projectPhotoListGroup',
      'projectPhotosSize',
      'photoFilter',
    ]),
    ...mapGetters('photo/project', ['project']),
    ...mapGetters('photo/album', ['projectPhotoAlbumList']),
    photoList() {
      return this.projectPhotoList.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
      }));
    },
  },

  watch: {
    currentPhotosContainHeight: {
      handler(val) {
        if (val && val <= this.pagePhotosContainHeight) {
          this.loadMore();
        }
      },
    },
  },

  mounted() {
    this.createTooltips();
    this.currentTab = this.$f7route.query.currentTab || 'album';
    if (this.$f7route.params.id) {
      this.getProject(this.$f7route.params.id);
      this.resetPhotoFilter();
      this.photoTabInitData();
      if (this.currentTab === 'album') {
        this.$refs.albumTab.initData();
      }
      this.getProjectPhotoAlbumListBys([
        {
          prop: 'projectId',
          val: this.$f7route.params.id,
          op: '==',
        },
        {
          prop: 'name',
          val: 'Other',
          op: '==',
        },
        {
          prop: 'templateType',
          val: TEMPLATE_TYPE.SYSTEM,
          op: '==',
        },
      ]).then(res => {
        this.albumId = res[0]?.id;
      });
    }
  },

  destroyed() {
    this.$f7.preloader.hide();
  },
};
</script>
<style lang="scss" scoped>
.project-photo-page-container ::v-deep > .page-content {
  overflow: hidden;
}
.tabs-container ::v-deep .tabs .page > .page-content {
  padding-top: 0;
}
.btn-icon {
  position: absolute;
  left: 25px;
  top: 10px;
}
.custom-hover:hover {
  background: var(--step-background-color) !important;
}
.tab-block {
  margin: 15px 5px 5px 5px;
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) + 16px
    );
  }
  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 2
    );
  }
  &_3 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 3
    );
  }
}
.custom-navbar ::v-deep .navbar-bg {
  background-color: black;
}
</style>
