<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
        <f7-link
          @click="handleBack"
          style="margin-left: 20px"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>{{
        invoiceGroup ? invoiceGroup.jobName || '' : ''
      }}</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-row>
      <!-- Invoice Group Details -->
      <f7-col
        :width="$device.desktop ? 50 : 100"
        :style="`height: ${
          $device.desktop ? 'calc(100vh - var(--f7-navbar-height))' : 'auto'
        };
          margin-top: ${
            $device.desktop && '0'
          }; overflow-x: auto; padding: 0 18px 18px 18px;
          padding-bottom: ${$device.desktop ? '0' : '85px'};`"
      >
        <div class="col-100 medium-50">
          <f7-block-title
            class="no-margin-left"
            :class="{ 'margin-top': !$device.desktop }"
            >Project Infomation</f7-block-title
          >
          <f7-list
            :inline-labels="$device.desktop"
            class="margin-top"
            :class="{ 'no-margin': !$device.desktop }"
            style="border-radius: 3px; overflow: hidden"
            no-hairlines
          >
            <!-- <f7-list-input
                disabled
                label="Job Name"
                placeholder="Job Name"
                :value="invoiceGroup ? invoiceGroup.jobName || '' : ''"
                type="text"
                error-message-force
              >
              </f7-list-input> -->
            <f7-list-input
              disabled
              label="Job Type"
              placeholder="Job Type"
              :value="
                project ? capitalizeFirstLetter(project.businessCode) : ''
              "
              type="text"
              error-message-force
            >
              <!-- <required-asterisk slot="label" /> -->
            </f7-list-input>
            <f7-list-input
              disabled
              label="Project Number"
              placeholder="Project Number"
              :value="invoiceGroup ? invoiceGroup.projectNumber || '' : ''"
              type="text"
            >
            </f7-list-input>
          </f7-list>
        </div>

        <!-- Estimate list -->
        <div class="col-100 medium-50">
          <f7-block-title
            class="no-margin-left"
            :style="{ 'margin-top': !$device.desktop && '24px' }"
            >Estimate Infomation
            <span
              class="no-data-title"
              v-show="estimateTemplateList.length === 0"
              >(No Data)</span
            >
          </f7-block-title>

          <data-table
            :headers="headersEstimate"
            :items="estimateTemplateList || []"
            :pageSize="(estimateTemplateList || []).length || 0"
            checkbox
            @selected:change="changeEstCheckbox"
            class="wrap-text-table margin-top"
            v-show="estimateTemplateList.length > 0"
          >
            <template slot="card-header">
              <f7-block-title :class="{ 'mt-1': !$device.desktop }">
                Checklist: {{ totalCheckedEstimateAmount | currencyUSD }}
              </f7-block-title>
            </template>
          </data-table>
        </div>

        <!-- Invoice list -->
        <div class="col-100 medium-50">
          <div
            style="
              align-items: center;
              display: flex;
              justify-content: space-between;
            "
          >
            <f7-block-title
              class="no-margin-left"
              :style="{ 'margin-top': !$device.desktop && '24px' }"
              >Table Invoice</f7-block-title
            >
            <f7-button
              class="display-flex justify-content-center align-items-center"
              style="width: fit-content; margin-top: 24px"
              fill
              @click="onAddNew"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Create new invoice
            </f7-button>
          </div>
          <data-table
            :headers="headersInvoice"
            :items="invoiceSortList || []"
            :pageSize="(invoiceSortList || []).length || 0"
            :rowIndexSelectedDefault="selectedRowIndex"
            isActiveRow
            @onClickRow="handleClickRow"
            class="wrap-text-table margin-top"
          >
            <template slot="card-header">
              <f7-block-title :class="{ 'mt-1': !$device.desktop }">
                Total paid: {{ totalPaidInvoiceAmount | currencyUSD }}
              </f7-block-title>
            </template>

            <template #body="{ item }">
              <template>
                <td :class="{ 'p-1': !$device.desktop }">
                  <a href="javascript:void(0)">{{ item.invoiceNumber }}</a>
                </td>
                <td>
                  <f7-chip
                    slot="after"
                    :text="getStatus(item.status).displayName"
                    :color="getStatus(item.status).color"
                  ></f7-chip>
                </td>
                <td class="text-align-right">
                  {{ totalInvoice(item) | currencyUSD }}
                </td>
                <td class="text-align-right">
                  {{ item.paidAmount | currencyUSD }}
                </td>
                <td>{{ item.invoiceDate }}</td>
                <td>{{ item.dueDate }}</td>
                <td>{{ item.paidDate }}</td>
                <td>
                  <f7-chip
                    slot="after"
                    :text="
                      item.invoiceType === 'recurring' ? 'Recurring' : 'Default'
                    "
                    :color="item.invoiceType === 'recurring' ? 'blue' : 'green'"
                  ></f7-chip>
                </td>
                <td>
                  <f7-toggle
                    v-show="
                      item.status === 'in-complete' &&
                      item.hasOwnProperty('schedule')
                    "
                    slot="after"
                    :checked="item.schedule"
                    @click.native.stop
                    @change.native.stop="
                      onMarkAsScheduled(item, $event.target.checked)
                    "
                  ></f7-toggle>
                </td>

                <td class="text-align-right">
                  <f7-link
                    v-show="
                      item.status !== 'in-canceled' &&
                      !item.invoiceType === 'recurring'
                    "
                    icon-f7="ellipsis_vertical"
                    color="red"
                    :popover-open="`.popover-actions_${item.id}`"
                    @click.stop="() => {}"
                  ></f7-link>
                  <f7-popover :class="`popover-actions_${item.id}`">
                    <f7-list class="cursor-pointer menu-actions">
                      <f7-list-item
                        link
                        popover-close
                        title="Edit"
                        v-show="
                          checkVisibleButton(item.status, 'edit') &&
                          !item.syncFromQB
                        "
                        @click.native="onEdit(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="pencil"
                        ></f7-icon>
                      </f7-list-item>

                      <f7-list-item
                        link
                        popover-close
                        title="Download PDF"
                        v-show="
                          checkVisibleButton(item.status, 'download-pdf') &&
                          !item.syncFromQB
                        "
                        @click.native="onDownloadPDF(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="arrow_down_doc"
                        ></f7-icon>
                      </f7-list-item>

                      <f7-list-item
                        link
                        popover-close
                        title="Send Email"
                        v-show="
                          checkVisibleButton(item.status, 'send-email') &&
                          !item.syncFromQB
                        "
                        @click.native="onSentEmail(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="envelope"
                        ></f7-icon>
                      </f7-list-item>

                      <f7-list-item
                        link
                        popover-close
                        title="Mark as Sent"
                        v-show="
                          checkVisibleButton(item.status, 'mark-as-sent') &&
                          !item.syncFromQB
                        "
                        @click.native="onMarkAsSentInvoice(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="checkmark"
                        ></f7-icon>
                      </f7-list-item>

                      <f7-list-item
                        link
                        popover-close
                        title="Record Payment"
                        v-show="
                          checkVisibleButton(item.status, 'payment-record') &&
                          !item.syncFromQB
                        "
                        @click.native="onRecordPayment(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="money_dollar_circle"
                        ></f7-icon>
                      </f7-list-item>

                      <f7-list-item
                        link
                        popover-close
                        title="Cancel"
                        v-show="
                          checkVisibleButton(item.status, 'cancel') &&
                          !item.syncFromQB
                        "
                        @click.native="onCancelInvoice(item)"
                      >
                        <f7-icon
                          slot="media"
                          f7="escape"
                        ></f7-icon>
                      </f7-list-item>
                    </f7-list>
                  </f7-popover>
                </td>
              </template>
            </template>
          </data-table>
        </div>

        <!-- Recurring Invoice list -->
        <div class="col-100 medium-50">
          <div
            style="
              align-items: center;
              display: flex;
              justify-content: space-between;
            "
          >
            <f7-block-title
              class="no-margin-left"
              :style="{ 'margin-top': !$device.desktop && '24px' }"
              >Table Recurring Invoice</f7-block-title
            >
            <f7-button
              class="display-flex justify-content-center align-items-center"
              style="width: fit-content; margin-top: 24px"
              fill
              @click="onAddNewRecurringInvoice"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Create new recurring invoice
            </f7-button>
          </div>
          <data-table
            :headers="headerRecurringInvoice"
            :items="recurringInvoice || []"
            :pageSize="(recurringInvoice || []).length || 0"
            :isHoverRow="false"
            class="wrap-text-table margin-top"
          >
            <template #body="{ item }">
              <template>
                <td :class="{ 'p-1': !$device.desktop }">
                  {{ item.recurringInvoiceNumber }}
                </td>
                <td>
                  <f7-chip
                    slot="after"
                    :text="getStatusRecurringInvoice(item.status).displayName"
                    :color="getStatusRecurringInvoice(item.status).color"
                  ></f7-chip>
                </td>
                <td>
                  {{ item.totalAmount | currencyUSD }}
                </td>
                <td>{{ item.startOn }}</td>
                <td>{{ item.endOn }}</td>
                <td style="text-align: center">
                  <f7-link
                    :popover-open="`.popover-actions_${item.id}`"
                    @click.stop="() => {}"
                    >View</f7-link
                  >
                  <f7-popover :class="`popover-actions_${item.id}`">
                    <f7-list class="cursor-pointer menu-actions">
                      <f7-list-item title="Invoice#"> </f7-list-item>
                      <f7-list-item
                        v-for="invoice in item.invoiceList"
                        popover-close
                        :key="invoice.id"
                      >
                        <div slot="title">{{ invoice.invoiceNumber }}</div>
                      </f7-list-item>
                    </f7-list>
                  </f7-popover>
                </td>
                <td class="text-align-right">
                  <f7-link @click.native="onEditRecurringInvoice(item)"
                    >Edit</f7-link
                  >
                </td>
              </template>
            </template>
          </data-table>
        </div>

        <f7-block-title
          class="no-margin-left"
          :style="{ 'margin-top': !$device.desktop && '24px' }"
          >Unbilled Balance</f7-block-title
        >
        <div
          :class="[
            $device.desktop ? 'summary-left' : 'summary-full',
            'padding margin-top',
          ]"
        >
          <f7-row class="margin-bottom-half align-items-center">
            <f7-col
              width="70"
              style="display: flex; align-items: center; gap: 4px"
            >
              <span>Unbilled based on Estimate</span>
              <f7-icon
                f7="info_circle_fill"
                size="14"
                class="cursor-pointer unbilled-est"
              ></f7-icon>
            </f7-col>
            <f7-col
              width="30"
              class="text-align-right"
              >{{ unBilledBasedOnEstimate | currencyUSD }}</f7-col
            >
          </f7-row>
          <f7-row class="margin-bottom-half align-items-center">
            <f7-col
              width="70"
              style="display: flex; align-items: center; gap: 4px"
            >
              <span>Unpaid Amount</span>
              <f7-icon
                f7="info_circle_fill"
                size="14"
                class="cursor-pointer unpaid-amount"
              ></f7-icon>
            </f7-col>
            <f7-col
              width="30"
              class="text-align-right"
              >{{ unpaidAmount | currencyUSD }}</f7-col
            >
          </f7-row>
        </div>
      </f7-col>

      <!-- Invoice Details -->
      <f7-col
        v-if="$device.desktop"
        width="50"
        :style="`height: ${
          $device.desktop
            ? 'calc(100vh - var(--f7-navbar-height))'
            : 'calc(100vh - var(--f7-navbar-height) - 53px)'
        };
          margin-top: ${$device.desktop ? '0' : '53px'}; overflow-x: hidden;`"
        style="display: flex; flex-direction: column; align-items: center"
        ref="invoiceDetailCol"
      >
        <div
          class="row action-bar"
          style="width: 98%"
        >
          <div style="font-weight: 600">
            {{
              (invoiceDetail && invoiceDetail.invoiceNumber
                ? invoiceDetail.invoiceNumber
                : '') || 'Click INV-xxxxxx on Table Invoices to view detail'
            }}
          </div>
          <div>
            <div
              class="invoice-button"
              v-show="
                checkVisibleButton(invoiceDetail.status, 'edit') &&
                !invoice.syncFromQB
              "
            >
              <a @click="onEdit">
                <i class="icon f7-icons">pencil</i>
                Edit
              </a>
            </div>
            <div
              class="invoice-button"
              v-show="checkVisibleButton(invoiceDetail.status, 'download-pdf')"
            >
              <a @click="onDownloadPDF">
                <i class="icon f7-icons">arrow_down_doc</i>
                Download PDF
              </a>
            </div>
            <div
              @click="onSentEmail"
              class="invoice-button"
              v-show="checkVisibleButton(invoiceDetail.status, 'send-email')"
            >
              <a>
                <i class="icon f7-icons">envelope</i>
                Send Email
              </a>
            </div>
            <div
              class="invoice-button"
              v-show="checkVisibleButton(invoiceDetail.status, 'mark-as-sent')"
            >
              <a @click="onMarkAsSentInvoice(invoice)">
                <i class="icon f7-icons">checkmark</i>
                Mark as Sent
              </a>
            </div>
            <div
              class="invoice-button"
              v-show="
                checkVisibleButton(invoiceDetail.status, 'payment-record')
              "
            >
              <a @click="onRecordPayment()">
                <i class="icon f7-icons">money_dollar_circle</i>
                Record Payment
              </a>
            </div>
            <div
              class="invoice-button"
              v-show="checkVisibleButton(invoiceDetail.status, 'cancel')"
            >
              <a @click="onCancelInvoice(invoice)">
                <i class="icon f7-icons">escape</i>
                Cancel
              </a>
            </div>
          </div>
        </div>
        <payment-record-table
          v-if="
            invoiceDetail &&
            (invoiceDetail.status === 'in-paid' ||
              invoiceDetail.status === 'in-partial-paid') &&
            paymentRecordList &&
            paymentRecordList.length > 0
          "
          :items="paymentRecordList"
          @onEdit="onEditRecordPayment"
          @onDelete="onDeleteRecordPayment"
        >
        </payment-record-table>

        <invoice-detail
          :invoiceDetail="invoiceDetail"
          :invoiceGroup="invoiceGroup || {}"
          :invoiceTemplate="invoiceTemplate"
          ref="invoiceDetail"
          class="invoice-detail"
        ></invoice-detail>
      </f7-col>
    </f7-row>

    <send-email-popup ref="sendEmailPopup"></send-email-popup>

    <invoice-detail-popup
      ref="invoiceDetailPopup"
      @onEdit="onEdit"
      @onDownloadPDF="onDownloadPDF($event)"
      @onSentEmail="onSentEmail($event)"
      @onRecordPayment="onRecordPayment($event)"
      @onEditRecordPayment="onEditRecordPayment($event)"
      @onMarkAsSent="onMarkAsSentInvoice($event)"
      @onCancelInvoice="onCancelInvoice($event)"
    ></invoice-detail-popup>

    <create-payment-record-popup
      ref="createPaymentRecordPopup"
    ></create-payment-record-popup>
  </f7-page>
</template>

<script>
import DataTable from '@/components/datatables';
import CreatePaymentRecordPopup from '../components/popups/CreatePaymentRecordPopup.vue';
import SendEmailPopup from '../components/popups/SendEmailPopup.vue';
import InvoiceDetail from '../components/details/InvoiceDetail.vue';
import PaymentRecordTable from '../components/tables/PaymentRecordTable.vue';
import { sortLatest } from '@/utility/date-time-tool';
import { firebase } from '../../../services/firebase.service';
import Menu from '../../../components/menu/Menu.vue';
import { mapActions, mapGetters } from 'vuex';
import invoiceMixins from '../mixins/invoice-mixin';
import _ from 'lodash';
import { INVOICE_STATUS_OVERDUE } from '../utility/const';
import InvoiceDetailPopup from '../components/popups/InvoiceDetailPopup.vue';
import { convertDateUTCToLocalTimeZone } from '@/utility/datetime';
import recurringInvoiceMixin from '../mixins/recurring-invoice-mixin';

export default {
  components: {
    InvoiceDetail,
    DataTable,
    SendEmailPopup,
    CreatePaymentRecordPopup,
    PaymentRecordTable,
    InvoiceDetailPopup,
    Menu,
  },

  mixins: [invoiceMixins, recurringInvoiceMixin],

  data: () => {
    return {
      popupOpened: false,
      estCheckList: [],
      estimateTemplateList: [],
      project: {},
      invoiceGroup: {},
      counterStatus: [
        'in-sent',
        'in-viewed',
        'in-paid',
        'in-partial-paid',
        'in-overdue',
      ],
    };
  },

  mounted() {
    this.createTooltip();
  },

  async created() {
    this.$f7.preloader.show();
    if (_.isEmpty(this.invoiceTemplate)) {
      await this.getTemplateList();
    }
    if (_.isEmpty(this.invoiceStatusList)) {
      await this.getConstantTypeList();
    }
    const invoiceGroupData = await this.getInvoiceGroupById(
      this.$f7route.params.invoiceGroupId
    );
    if (!invoiceGroupData) {
      this.$f7.preloader.hide();
      return;
    }
    this.invoiceGroup = invoiceGroupData;
    const project = await this.getProjectById(invoiceGroupData.projectId);
    this.project = project;
    await this.getEstimateListByProjectId(invoiceGroupData.projectId);
    await this.getInvoiceByGroupId(this.$f7route.params.invoiceGroupId);

    this.estimateTemplateList = this.estimateList.map(item => {
      return {
        ...item,
        checked: !!item.isDefault,
      };
    });
    for (const est of this.estimateTemplateList) {
      if (est.checked) {
        this.estCheckList.push(_.cloneDeep(est));
      }
    }

    await this.getRecurringInvoiceByInvoiceGroupId(this.invoiceGroup.id);

    this.$f7.preloader.hide();
    this.popupOpened = true;
    this.$nextTick(() => {
      if (this.$refs.invoiceDetailCol) {
        const width = this.$refs.invoiceDetailCol.$el.clientWidth;
        this.$refs.invoiceDetail.lowResDesktopScreen(width);
      }
    });
  },
  computed: {
    ...mapGetters('invoices/invoices', ['invoice', 'invoiceList']),
    ...mapGetters('invoices/payment-record', ['paymentRecordList']),
    // ...mapGetters("invoices/app-constant", ["invoiceStatusList"]),
    ...mapGetters('common/template', ['invoiceTemplate']),
    ...mapGetters('invoices/app-constant', ['invoiceStatusList']),
    ...mapGetters('invoices/estimate', ['estimateList']),

    // recurring
    ...mapGetters('invoices/recurring-invoices', [
      'recurringInvoiceListByInvoiceGroup',
    ]),
    ...mapGetters('invoices/app-constant', ['recurringInvoiceStatusList']),

    invoiceDetail() {
      return {
        ..._.cloneDeep(this.invoice || {}),
        invoiceDate:
          (this.invoice || {}).invoiceDate && this.invoice.invoiceDate.toDate
            ? convertDateUTCToLocalTimeZone(this.invoice.invoiceDate.toDate())
            : '',
        dueDate:
          (this.invoice || {}).dueDate && this.invoice.dueDate.toDate
            ? convertDateUTCToLocalTimeZone(this.invoice.dueDate.toDate())
            : '',
        status:
          this.getOverdueDays(this.invoice) > 0
            ? INVOICE_STATUS_OVERDUE
            : this.invoice.status,
      };
    },

    invoiceSortList() {
      let list = this.invoiceList?.map(item => ({
        ...item,
        invoiceDate: item.invoiceDate
          ? convertDateUTCToLocalTimeZone(item.invoiceDate.toDate())
          : '',
        dueDate: item.dueDate
          ? convertDateUTCToLocalTimeZone(item.dueDate.toDate())
          : '',
        paidDate: item.paidDate
          ? convertDateUTCToLocalTimeZone(item.paidDate.toDate())
          : '',
        status:
          this.getOverdueDays(item) > 0 ? INVOICE_STATUS_OVERDUE : item.status,
      }));
      return sortLatest(list);
    },

    headersEstimate() {
      return [
        {
          text: 'Estimate Number',
          value: 'estimateNumber',
          align: 'left',
        },
        {
          text: 'Name',
          value: 'estimateName',
          align: 'left',
          style: 'min-width: 300px',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'right',
          format: 'currencyUSD',
        },
      ];
    },

    headersInvoice() {
      return [
        {
          text: 'Invoice#',
          value: 'invoiceNumber',
          align: 'left',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
        },
        {
          text: 'Invoice Amount',
          value: 'invoiceAmount',
          align: 'right',
        },
        {
          text: 'Paid Amount',
          value: 'paidAmount',
          align: 'right',
        },
        {
          text: 'Invoice Date',
          value: 'invoice',
          align: 'left',
        },
        {
          text: 'Due Date',
          value: 'status',
          align: 'left',
        },
        {
          text: 'Paid Date',
          value: 'invoiceAmount',
          align: 'left',
        },
        {
          text: 'Invoice Type',
          value: 'invoiceType',
          align: 'left',
        },
        {
          text: 'Schedule email',
          value: 'invoiceAmount',
          align: 'left',
          tooltip: {
            id: 'invoice-tooltip-header',
            content: 'Auto sends at 8:00',
          },
        },

        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'action',
          width: '3%',
        },
      ];
    },

    totalCheckedEstimateAmount() {
      return this.estCheckList.reduce((acc, est) => {
        return est.checked ? acc + (est.amount || 0) : acc;
      }, 0);
    },

    totalInvoiceAmount() {
      return this.invoiceSortList.reduce((acc, invoice) => {
        return this.counterStatus.includes(invoice.status)
          ? acc + this.total(invoice)
          : acc;
      }, 0);
    },

    totalPaidInvoiceAmount() {
      return this.invoiceSortList.reduce((acc, invoice) => {
        return invoice.status === 'in-paid' ||
          invoice.status === 'in-partial-paid'
          ? acc + invoice.paidAmount
          : acc;
      }, 0);
    },

    unpaidAmount() {
      return this.totalInvoiceAmount - this.totalPaidInvoiceAmount;
    },

    unBilledBasedOnEstimate() {
      return this.totalCheckedEstimateAmount > 0
        ? this.totalCheckedEstimateAmount - this.totalInvoiceAmount
        : 0;
    },

    overdueAmount() {
      return this.invoiceList.reduce((acc, invoice) => {
        return invoice.status === 'in-overdue'
          ? acc + this.total(invoice)
          : acc;
      }, 0);
    },

    selectedRowIndex() {
      if (!this.$device.desktop) {
        return null;
      }

      if (_.isEmpty(this.invoice) || _.isEmpty(this.invoiceSortList)) {
        return 0;
      }

      return this.invoiceSortList.findIndex(
        item => item.id === this.invoice.id
      );
    },
    capitalizeFirstLetter() {
      return string => {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
      };
    },

    recurringInvoice() {
      if (!this.recurringInvoiceListByInvoiceGroup.length) return [];

      const list = this.recurringInvoiceListByInvoiceGroup.map(item => {
        return {
          ...item,
          startOn: item.startOn
            ? item.startOn._seconds
              ? this.convertDateTimeFromAlgolia(item.startOn)
              : convertDateUTCToLocalTimeZone(item.startOn.toDate())
            : '',
          endOn: item.endOn
            ? item.endOn._seconds
              ? this.convertDateTimeFromAlgolia(item.endOn)
              : convertDateUTCToLocalTimeZone(item.endOn.toDate())
            : '',
        };
      });

      return sortLatest(list);
    },

    headerRecurringInvoice() {
      return [
        {
          text: 'Invoice#',
          value: 'invoiceNumber',
          align: 'left',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
        },
        {
          text: 'Invoice Amount',
          value: 'totalAmount',
          align: 'left',
        },
        {
          text: 'Start On',
          value: 'startOn',
          align: 'left',
        },
        {
          text: 'End On',
          value: 'endOn',
          align: 'left',
        },
        {
          text: 'Child Invoice',
          value: 'childInvoice',
          align: 'left',
        },
        {
          text: 'Action',
          align: 'right',
          value: 'action',
        },
      ];
    },
  },

  methods: {
    ...mapActions('invoices/estimate', [
      'getEstimateListByProjectId',
      'updateEstimate',
    ]),
    ...mapActions('invoices/invoices', [
      'bindInvoice',
      'getInvoiceByGroupId',
      'unbindInvoice',
      'updateInvoice',
    ]),
    ...mapActions('invoices/payment-record', [
      'bindPaymentRecordListBy',
      'unbindPaymentRecordList',
    ]),
    ...mapActions('invoices/invoice-group', [
      'getInvoiceGroupById',
      'saveInvoiceGroup',
    ]),
    ...mapActions('invoices/project', ['getProjectById']),
    ...mapActions('common/template', ['getTemplateList']),
    ...mapActions('invoices/app-constant', ['getConstantTypeList']),

    ...mapActions('invoices/recurring-invoices', [
      'getRecurringInvoiceByInvoiceGroupId',
    ]),

    /**
     * populate data for invoice detail popup including invoice and estimate list
     * @param {*} invoiceGroup
     */

    handleClickRow(row) {
      // if (!row.isInvoiceDefault) return;
      this.onSelectInvoice(row);
      this.$nextTick(() => {
        if (this.$refs.invoiceDetailCol) {
          const width = this.$refs.invoiceDetailCol.$el.clientWidth;
          this.$refs.invoiceDetail.lowResDesktopScreen(width);
        }
      });
    },

    gotoPaymentReceived(check) {
      //TODO go to payment received
    },

    createTooltip() {
      this.$f7.tooltip.create({
        targetEl: '.unbilled-est',
        text: 'unbilled-est',
      });
      this.$f7.tooltip.create({
        targetEl: '.unpaid-amount',
        text: 'unpaid-amount',
      });
    },

    async changeEstCheckbox(data) {
      this.$f7.preloader.show();
      const foundedItem = _.cloneDeep(
        this.estimateTemplateList.find(item => item.id === data.id)
      );
      this.estCheckList = this.estCheckList.filter(
        item => item.id !== foundedItem.id
      );

      if (data.checked) {
        foundedItem.checked = data.checked;
        this.estCheckList.push(foundedItem);
      }

      await this.updateEstimate({
        id: data.id,
        doc: {
          isDefault: data.checked,
        },
      });

      this.$f7.preloader.hide();
    },

    onAddNew() {
      this.$f7router.navigate(
        `/invoices-group/${this.$f7route.params.invoiceGroupId}/invoice/new-invoice`
      );
    },

    openInvoiceDetailPopup(invoice) {
      this.$refs.invoiceDetailPopup.open(invoice);
    },
    async onSelectInvoice(invoice) {
      if (!this.$device.desktop) {
        this.openInvoiceDetailPopup(invoice);
        return;
      }

      if (invoice?.id) {
        this.$f7.preloader.show();
        await this.bindInvoice(invoice.id);
        await this.bindPaymentRecordListBy({
          prop: 'invoiceNumber',
          op: '==',
          val: invoice.invoiceNumber,
        });
        history.pushState(
          null,
          '',
          `/invoices-group/${this.$f7route.params.invoiceGroupId}/invoice/${invoice.invoiceNumber}`
        );
        this.$f7router.history.push(
          `/invoices-group/${this.$f7route.params.invoiceGroupId}/invoice/${invoice.invoiceNumber}`
        );
        this.$f7.preloader.hide();
      }
    },

    onEdit(invoice) {
      const currentInvoice = invoice && invoice.id ? invoice : this.invoice;
      if (!currentInvoice || !currentInvoice?.id) return;
      this.$f7router.navigate(
        `/invoices-group/${this.$f7route.params.invoiceGroupId}/invoice/edit/${currentInvoice.invoiceNumber}`
      );
    },

    getInvoiceNumberFromHistory() {
      const history = this.$f7router.history;
      const currentUrl = history[history.length - 1];
      const match = currentUrl.match(/\/invoice\/([^/]+)/);
      return match ? match[1] : null;
    },

    onEditRecurringInvoice(recurringInvoice) {
      this.$f7router.navigate(
        `/invoices-group/${this.$f7route.params.invoiceGroupId}/recurring-invoice/edit/${recurringInvoice.id}`
      );
    },
    onMarkAsSent(invoice) {
      const currentInvoice = invoice && invoice.id ? invoice : this.invoice;
      this.$ri.dialog.openInfoDialog({
        title: 'Mark As Sent',
        content: `Are you sure you want to mark as sent invoice ${currentInvoice.invoiceNumber}?`,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            this.updateInvoice({
              id: currentInvoice.id,
              doc: {
                status: 'in-sent',
              },
            })
              .then(() => {
                return this.getInvoiceByGroupId(this.invoiceGroup.id);
              })
              .then(() => {
                this.$f7.preloader.hide();
                this.$f7.toast
                  .create({
                    text: 'Invoice was marked as sent!',
                    closeOnClick: true,
                    closeButton: false,
                    closeTimeout: 5000,
                  })
                  .open();
              });
          }
        },
      });
    },
    handleBack() {
      this.$f7router.navigate('/invoices-group', {
        force: true,
      });
      this.$f7router.history = [];
    },
    async onMarkAsScheduled(invoice, isSchedule) {
      if (!invoice) return;
      this.$f7.preloader.show();

      await this.updateInvoice({
        id: invoice.id,
        doc: {
          schedule: isSchedule,
          invoiceAmount: this.total(invoice),
        },
      });

      this.$f7.preloader.hide();
    },

    onRecordPayment(invoice) {
      this.$refs.createPaymentRecordPopup.open({
        invoice: invoice || this.invoice,
      });
    },

    onEditRecordPayment(paymentRecord) {
      this.$refs.createPaymentRecordPopup.open({
        invoice: this.invoice,
        paymentRecord,
      });
    },

    onDeleteRecordPayment(paymentRecord) {
      //TODO delete record payment
    },
    getCurrentTimestamp() {
      return firebase.firestore.Timestamp.now();
    },
    async onSentEmail(invoice) {
      const currentInvoice = invoice && invoice.id ? invoice : this.invoice;
      const currentDate = convertDateUTCToLocalTimeZone(new Date());
      const invoiceDate = convertDateUTCToLocalTimeZone(
        currentInvoice.invoiceDate.toDate()
      );
      const isDifferentDay = currentDate !== invoiceDate;
      if (isDifferentDay) {
        this.$f7.dialog.preloader(
          'Changing invoice date to today. Please wait...'
        );
        const newInvoice = {
          ...currentInvoice,
          ...(isDifferentDay && {
            invoiceDate: this.getCurrentTimestamp(),
          }),
        };
        this.updateInvoice({
          id: this.invoice.id,
          doc: newInvoice,
        }).then(() => {
          setTimeout(async () => {
            await this.getInvoiceByGroupId(this.invoiceGroup.id);
            this.$f7.dialog.close();
            this.$refs.sendEmailPopup.open(_.cloneDeep(newInvoice));
          }, 1500);
        });
      } else {
        const timeConvert = new firebase.firestore.Timestamp.fromDate(
          new Date(
            convertDateUTCToLocalTimeZone(currentInvoice.invoiceDate.toDate())
          )
        );
        this.$refs.sendEmailPopup.open(
          _.cloneDeep({ ...currentInvoice, invoiceDate: timeConvert })
        );
      }
    },

    onDownloadPDF(invoice) {
      const currentInvoice = invoice && invoice.id ? invoice : this.invoice;
      const currentInvoiceGroup = this.invoiceGroup;
      this.$refs.invoiceDetail.handleDownloadPDF(
        currentInvoice,
        currentInvoiceGroup
      );
    },

    onAddNewRecurringInvoice() {
      this.$f7router.navigate(
        `/invoices-group/${this.$f7route.params.invoiceGroupId}/recurring-invoice/new-recurring-invoice`
      );
    },
    getStatusRecurringInvoice(status) {
      return (
        this.recurringInvoiceStatusList.find(x => x.value === status) || {}
      );
    },
  },

  watch: {
    totalCheckedEstimateAmount: {
      handler(totalCheckedEstimateAmount) {
        if (this.invoiceGroup?.id) {
          this.saveInvoiceGroup({
            id: this.invoiceGroup.id,
            doc: {
              unbilledAmount:
                totalCheckedEstimateAmount - this.totalInvoiceAmount,
            },
          });
        }
      },
      immediate: true,
    },

    totalInvoiceAmount: {
      handler(totalInvoiceAmount) {
        if (this.invoiceGroup?.id) {
          this.saveInvoiceGroup({
            id: this.invoiceGroup.id,
            doc: {
              totalInvoiceAmount: totalInvoiceAmount || 0,
              unbilledAmount:
                this.totalCheckedEstimateAmount - totalInvoiceAmount,
            },
          });
        }
      },
      immediate: true,
    },

    overdueAmount: {
      handler(overdueAmount) {
        if (this.invoiceGroup?.id) {
          this.saveInvoiceGroup({
            id: this.invoiceGroup.id,
            doc: {
              overdueAmount,
            },
          });
        }
      },
      immediate: true,
    },

    invoiceSortList: {
      handler(newValue) {
        if (newValue.length && this.$device.desktop) {
          if (this.$f7route.params.invoiceNumber) {
            const invoiceNumber = this.getInvoiceNumberFromHistory();
            const invoice = newValue.find(
              item => item.invoiceNumber === invoiceNumber
            );
            if (invoice) {
              this.onSelectInvoice(invoice);
            } else {
              this.onSelectInvoice(newValue[0]);
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  background: white;
  width: 50%;
  border-radius: 4px;
}

.summary-left {
  background: var(--f7-text-editor-toolbar-bg-color);
  width: 50%;
  border-radius: 4px;
}

.summary-full {
  background: var(--f7-text-editor-toolbar-bg-color);
  border-radius: 4px;
}

.page-form {
  width: 825px;
  padding: 30px 25px 30px 50px;
  margin: auto;
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 12px;
  box-sizing: border-box;
  border-color: #e5e7f2;
  background-color: white;
  box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
  border-radius: 2px;
  position: relative;
  padding-bottom: 60px;
}

.text-info {
  line-height: 2;
}

.text-align-right {
  text-align: right;
}

.bold {
  font-weight: 500;
}

.logo {
  width: 92%;

  img {
    width: 100%;
  }
}

.action-bar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 502;
  background-color: var(--f7-color-img-neutral);
  border: 1px solid var(--f7-color-border-2-neutral);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
}

.action-bar > div {
  display: flex;
  justify-content: flex-end;
}

.invoice-button {
  padding: 2px 10px;
  font-weight: 600;
  border-left: 1px solid var(--f7-color-border-2-neutral);
  box-sizing: border-box;

  a {
    color: var(--f7-color-text-3-neutral);
    display: flex;
    align-items: center;

    i {
      padding-right: 4px;
    }
  }

  a:hover {
    font-weight: 600;
    color: var(--f7-theme-color);

    i {
      font-weight: 600;
    }
  }
}

.page-content {
  overflow: hidden;
}

.card {
  margin: 0;
}

.no-data-title {
  font-size: 14px;
  color: var(--f7-color-text-4-neutral);
  font-weight: 500;
}

.p-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mt-1 {
  margin-top: 8px !important;
}

@media (max-width: 1366px) {
  .invoice-detail {
    width: 100%;
    margin-right: 6px;
  }
}
</style>
