<template>
  <div class="popup-scroll-bar">
    <!-- segmented buttons -->
    <f7-block :class="$device.desktop ? '' : 'margin-bottom'">
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'information'"
          @click.native="activeButton = 'information'"
          >Information</f7-button
        >
        <f7-button
          :active="activeButton === 'action-history'"
          @click.native="activeButton = 'action-history'"
          >Action History</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div
      v-show="activeButton === 'information'"
      style="position: relative"
    >
      <!-- Job Info -->
      <div
        v-if="!isDraft"
        class="actions-create"
        :style="`position: ${$device.desktop ? 'absolute' : 'relative'}`"
      >
        <f7-button
          small
          outline
          @click="handleCreateNewEstimate"
          >{{ !$device.desktop ? '+' : 'Create' }} Estimate</f7-button
        >
        <f7-button
          small
          outline
          @click="handleCreateNewProposal"
          >{{ !$device.desktop ? '+' : 'Create' }} Proposal</f7-button
        >
        <f7-button
          small
          outline
          popover-open=".popover-create-invoice"
          >{{ !$device.desktop ? '+' : 'Create' }} Invoice</f7-button
        >
        <f7-button
          small
          fill
          @click="startTimeLog"
          >Start Time Log</f7-button
        >
        <f7-popover class="popover-create-invoice">
          <f7-list class="cursor-pointer">
            <f7-list-item
              popover-close
              title="Default Invoice"
              @click="openCreateInvoicePopup"
            >
            </f7-list-item>
            <f7-list-item
              popover-close
              title="Recurring Invoice"
              @click="openCreateRecurringInvoicePage"
            >
            </f7-list-item>
          </f7-list>
        </f7-popover>
      </div>
      <!-- Customer Type -->
      <f7-block-header :class="$device.desktop ? '' : 'margin-top-half'"
        >Customer Type</f7-block-header
      >
      <f7-list>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="demo-radio-end"
            value="commercial"
            :checked="detail.customerType === 'commercial'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Commercial
          </div>
        </f7-list-item>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="demo-radio-end"
            value="residential"
            :checked="detail.customerType === 'residential'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Residential
          </div>
        </f7-list-item>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="demo-radio-end"
            value="multifamily"
            :checked="detail.customerType === 'multifamily'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Multifamily
          </div>
        </f7-list-item>
      </f7-list>

      <div v-if="detail.customerType === 'commercial'">
        <f7-block-title> Company Information </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <f7-list-item
            link
            @click.native="navigateToSelectContact"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Contact Name
            </div>
            <div class="item-error-message">{{ contactNameErrorMessage }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              <span v-if="contactName">{{ contactName }}</span>
              <span
                v-else
                class="list-item-title_placeholder"
                >Select contact</span
              >
            </div>
            <input-icon
              slot="media"
              icon="person"
            ></input-icon>
          </f7-list-item>
          <f7-list-item>
            <div slot="media"></div>
            <f7-button
              slot="title"
              @click="openAddNewContactPopup"
              small
              class="btn-fill-gray display-flex justify-content-center align-items-center"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Add new contact
            </f7-button>
          </f7-list-item>

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in contact.phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in contact.emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in contact.addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <!-- Company -->
        <f7-list media-list>
          <f7-list-item
            link
            @click.native="navigateToSelectCompany"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Company Name<required-asterisk />
            </div>
            <div class="item-error-message">
              {{ selectCompanyMessage || requiredErrorMessage('companyName') }}
            </div>
            <div
              class="list-item-title"
              slot="title"
            >
              <span v-if="companyName">{{ companyName }}</span>
              <span
                v-else
                class="list-item-title_placeholder"
                >Select company</span
              >
            </div>
            <input-icon
              slot="media"
              icon="globe"
            ></input-icon>
          </f7-list-item>
          <f7-list-item>
            <div slot="media"></div>
            <f7-button
              slot="title"
              @click="openAddNewCompanyPopup"
              small
              class="btn-fill-gray display-flex justify-content-center align-items-center"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Add new company
            </f7-button>
          </f7-list-item>

          <!--Company Phone -->
          <f7-list-item
            v-for="(phone, index) in detail.companyId ? company.phones : ''"
            :key="`company-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Company Address -->
          <f7-list-item
            v-for="(address, index) in detail.companyId
              ? company.addresses
              : ''"
            :key="`company-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <!-- Property -->
        <f7-list media-list>
          <f7-list-item
            :link="true"
            @click.native="navigateToSelectProperty"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Property Name<required-asterisk />
            </div>
            <div class="item-error-message">
              {{
                selectPropertyMessage || requiredErrorMessage('propertyName')
              }}
            </div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ `${propertyName}` }}
            </div>
            <input-icon
              slot="media"
              icon="building_2_fill"
            ></input-icon>
          </f7-list-item>
          <f7-list-item>
            <div slot="media"></div>
            <f7-button
              slot="title"
              @click="openAddNewPropertyPopup"
              small
              class="btn-fill-gray display-flex justify-content-center align-items-center"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Add new property
            </f7-button>
          </f7-list-item>

          <!--Property Phone -->
          <f7-list-item
            v-for="(phone, index) in detail.propertyId ? property.phones : ''"
            :key="`property-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Property Address -->
          <f7-list-item
            v-for="(address, index) in detail.propertyId
              ? property.addresses
              : ''"
            :key="`property-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>
      </div>

      <div
        v-if="
          detail.customerType === 'residential' ||
          detail.customerType === 'multifamily'
        "
      >
        <!-- Contact -->
        <f7-block-header class="display-flex justify-content-space-between">
          Contact Information
          <div v-if="detail.contactId">
            <f7-button
              small
              fill
              @click="openEditContact"
              >Edit Contact</f7-button
            >
          </div>
        </f7-block-header>
        <f7-list media-list>
          <f7-list-item
            link
            @click.native="navigateToSelectContact"
          >
            <div
              class="list-item-inner-start"
              slot="inner-start"
            >
              Contact name
            </div>

            <div
              class="list-item-title"
              slot="title"
            >
              <span v-if="contactName">{{ contactName }}</span>
              <span
                v-else
                class="list-item-title_placeholder"
                >Select contact</span
              >
            </div>
            <f7-icon
              slot="media"
              color="primary"
              f7="person"
            ></f7-icon>
          </f7-list-item>
          <f7-list-item>
            <div slot="media"></div>
            <f7-button
              slot="title"
              @click="openAddNewContactPopup"
              small
              class="btn-fill-gray display-flex justify-content-center align-items-center"
            >
              <f7-icon
                size="18"
                f7="plus"
                class="margin-right-half"
              ></f7-icon>
              Add new contact
            </f7-button>
          </f7-list-item>

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in contact.phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              f7="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in contact.emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              f7="envelope"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in contact.addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              f7="map"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <address-input-residential
          ref="addressInput"
          :addressesSuggestion="contact.addresses || []"
          :address="projectAddress"
          @input="onInputAddress($event)"
          @select="onSelectAddress($event)"
        ></address-input-residential>
      </div>

      <f7-block-header>Job Information</f7-block-header>
      <f7-list>
        <!-- Title -->
        <f7-list-input
          class="first-input"
          label="Title"
          type="text"
          placeholder="Card title"
          clear-button
          :value="detail.title"
          @blur="v => onChangeTitle(v.target.value)"
        >
          <f7-icon
            color="primary"
            f7="textbox"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Desc -->
        <f7-list-input
          label="Description"
          type="textarea"
          resizable
          placeholder="Description"
          :value="detail.description"
          @blur="v => changeCardValue('description', v.target.value)"
        >
          <f7-icon
            color="primary"
            f7="doc_text"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Due Date -->
        <f7-list-input
          label="Due Date"
          type="datepicker"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
          }"
          :value="toDateCalendar(detail.dueDate)"
          @calendar:change="
            saveDate('dueDate', $event);
            $f7.calendar.close();
          "
        >
          <f7-icon
            color="primary"
            slot="media"
            f7="timer"
          ></f7-icon>
        </f7-list-input>
      </f7-list>
      <photo-section-service
        ref="photoSectionService"
        :title="detail.title"
      ></photo-section-service>
      <f7-block-title
        class="add-schedule display-flex justify-content-space-between align-items-center"
      >
        <div>Add to Schedule</div>
        <f7-icon
          @click.native="navigateToSchedule"
          f7="calendar_badge_plus"
          size="20px"
          color="theme-color"
          class="cursor-pointer"
        ></f7-icon>
      </f7-block-title>
      <user-input
        :value="detail.assigneeIDs"
        :card="detail"
        @input="changeCardValue('assigneeIDs', $event)"
      ></user-input>

      <service-action-button
        @doAction="onDoServiceAction"
      ></service-action-button>

      <note-input
        :value="detail.noteList"
        :card="detail"
        @change="changeCardValue('noteList', $event)"
      ></note-input>

      <note-popup
        title="Archive Note"
        label="Note"
        validate
        ref="notePopup"
        @done="onSaveNote"
      ></note-popup>
    </div>

    <div v-show="activeButton === 'action-history'">
      <f7-list media-list>
        <f7-list-item
          v-for="(item, index) in history"
          :key="index"
          :title="item.nextAction"
          :after="toLocaleString(item.resolvedAt)"
          :subtitle="'Resolved by ' + item.resolvedBy"
          :text="
            item.previousAction ? 'Previous action: ' + item.previousAction : ''
          "
        ></f7-list-item>
      </f7-list>
    </div>

    <contact-list-popup
      ref="selectContact"
      :isHiddenAddNew="true"
      @onSelected="handleContactSelected"
      @onCreated="handleContactCreated"
    ></contact-list-popup>
    <company-list-popup
      ref="selectCompany"
      :contactId="contact.id"
      :isHiddenAddNew="true"
      :companyTypes="[
        COMPANY_TYPE_INSURANCE,
        COMPANY_TYPE_GENERAL_CONTRACTOR,
        COMPANY_TYPE_PROPERTY_OWNER,
        COMPANY_TYPE_PROPERTY_MANAGEMENT,
        COMPANY_TYPE_TENANT,
      ]"
      :queryFilters="`${
        contact.companyRefs && contact.companyRefs.length
          ? `contactIdRefs:${contact.id} AND`
          : ''
      } NOT companyTypes:${COMPANY_TYPE_VENDOR}`"
      @onSelected="handleCompanySelected"
      @onCreated="handleCompanyCreated"
    ></company-list-popup>
    <property-list-popup
      ref="selectProperty"
      :companyId="company.id"
      :isHiddenAddNew="true"
      :queryFilters="`${
        company.propertyRefs && company.propertyRefs.length
          ? `companyIdRefs:${company.id}`
          : ''
      }`"
      @onSelected="handlePropertySelected"
      @onCreated="handlePropertyCreated"
    ></property-list-popup>

    <edit-contact-popup ref="editContactPopup"></edit-contact-popup>
    <on-my-way-popup
      ref="onMyWayPopup"
      @done="sendOnMyWayMessage"
    ></on-my-way-popup>
    <new-invoice-popup
      ref="newInvoicePopup"
      :isEdit="isEdit"
      @setIsEdit="setIsEdit"
      :propProjectId="detail.id || ''"
    ></new-invoice-popup>
    <new-recurring-invoice-popup ref="newRecurringInvoicePopup">
    </new-recurring-invoice-popup>
  </div>
</template>

<script>
import UserInput from '../inputs/UserInput.vue';
import NoteInput from '../inputs/NoteInput.vue';
import EditContactPopup from '../popup/EditContactPopup.vue';
import OnMyWayPopup from '../popup/OnMyWayPopup.vue';

import NotePopup from '../popup/NotePopup.vue';
import ServiceActionButton from '../button/ServiceActionButton.vue';
import NewInvoicePopup from '@/plugins/invoices/components/popups/NewInvoicePopup.vue';

import InputIcon from '../icon/InputIcon.vue';

import ContactListPopup from '../../../../components/popups/ContactListPopup.vue';
import CompanyListPopup from '../../../../components/popups/CompanyListPopup.vue';
import PropertyListPopup from '../../../../components/popups/PropertyListPopup.vue';

import AddressInputResidential from '@/components/inputs/AddressInputResidential.vue';

import { useVuelidate } from '@vuelidate/core';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { toDateCalendar, toDateFirebase } from '../../../../utility/datetime';
import { firebase, auth } from '../../../../services/firebase.service';
import { getFullAddress } from '@/utility/address';
import axiosService from '../../../../services/axios.service';
import commonMixin from '../../mixin/common';
import PhotoSectionService from '../photo/PhotoSectionService.vue';
import NewRecurringInvoicePopup from '@/plugins/invoices/components/popups/NewRecurringInvoicePopup.vue';

import _ from 'lodash';
import {
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_INSURANCE,
  COMPANY_TYPE_PROPERTY_MANAGEMENT,
  COMPANY_TYPE_PROPERTY_OWNER,
  COMPANY_TYPE_VENDOR,
  COMPANY_TYPE_TENANT,
  COLLECTION_MOVE_ARCHIVE_JOB,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  components: {
    UserInput,
    NoteInput,

    EditContactPopup,
    ServiceActionButton,
    NotePopup,
    InputIcon,
    OnMyWayPopup,
    ContactListPopup,
    CompanyListPopup,
    PropertyListPopup,
    AddressInputResidential,
    PhotoSectionService,
    NewInvoicePopup,
    NewRecurringInvoicePopup,
  },
  mixins: [commonMixin],

  data() {
    return {
      isEdit: true,
      COMPANY_TYPE_GENERAL_CONTRACTOR,
      COMPANY_TYPE_INSURANCE,
      COMPANY_TYPE_PROPERTY_MANAGEMENT,
      COMPANY_TYPE_PROPERTY_OWNER,
      COMPANY_TYPE_VENDOR,
      COMPANY_TYPE_TENANT,
      COLLECTION_MOVE_ARCHIVE_JOB,
      activeButton: 'information',
      dueDate: [],
      note: '',
      action: {},
      cardStatus: '',

      selectCompanyMessage: '',
      selectPropertyMessage: '',

      newContact: false,
      newCompany: false,

      projectAddress: {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
      actionById: 'swimlane/column/action',
    }),
    ...mapGetters('swimlane/estimate', ['estimateList']),

    ...mapGetters('swimlane/client', ['contact', 'company', 'property']),
    ...mapGetters('setting/app/profile', ['getDisplayMenu']),
    ...mapGetters({
      time_tracking_drafList: 'time-tracking/time-tracking-draf/timerList',
    }),
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),

    lastDrafTimeTracking() {
      if (
        !this.time_tracking_drafList &&
        this.time_tracking_drafList.length < 1
      )
        return null;
      return this.time_tracking_drafList[0];
    },
    isNavigateToSchedule() {
      return this.getDisplayMenu.some(
        item =>
          item.routes &&
          item.routes.map(route => route.path).includes('/employee-scheduling')
      );
    },
    isExistEstimate() {
      return this.estimateList.length > 0;
    },
    isDraft() {
      return this.detail && this.detail.isDraft;
    },

    history() {
      return this.detail
        ? this.detail.actionsHistory
          ? _.cloneDeep(this.detail.actionsHistory).reverse()
          : {}
        : [];
    },

    contactName() {
      return this.getContactName(this.contact);
    },

    contactAddress() {
      return this.getContactAddress(this.contact);
    },

    propertyName() {
      return this.property && this.detail && this.detail.propertyName
        ? this.getPropertyName(this.property)
        : '';
    },

    companyName() {
      return this.company && this.detail && this.detail.companyName
        ? this.company.companyName || ''
        : '';
    },

    contactNameErrorMessage() {
      return '';
    },
    companyNameErrorMessage() {
      return '';
    },
    propertyNameErrorMessage() {
      return '';
    },
    requiredErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    ...mapMutations('time-tracking/time-tracking', ['openTimerPopup']),
    ...mapActions('time-tracking/time-tracking', ['setTimeLogInfoAvailable']),
    ...mapActions('swimlane/card', ['update']),
    ...mapActions('swimlane/client', [
      'addCompanyContactRelationShip',
      'addPropertyCompanyRelationShip',
      'getContact',
      'getCompany',
      'setCompany',
      'getProperty',
      'setProperty',
    ]),
    ...mapActions({
      bindTimeTrackingDraf: 'time-tracking/time-tracking-draf/bindTimerListBys',
    }),
    ...mapActions('common/notification', ['createNotificationByType']),
    ...mapActions('swimlane/finance', ['getInvoiceGroupByProjectId']),

    openCreateInvoicePopup() {
      this.$refs.newInvoicePopup.open();
    },

    async openCreateRecurringInvoicePage() {
      if (!_.isEmpty(this.detail)) {
        const invoiceGroup = await this.getInvoiceGroupByProjectId(this.detail);
        this.$refs.newRecurringInvoicePopup.open(
          !_.isEmpty(invoiceGroup) ? invoiceGroup[0].id : '',
          true,
          '',
          this.detail.id
        );
      }
    },

    async initData() {
      if (!_.isEmpty(this.$refs.photoSectionService)) {
        this.$refs.photoSectionService.initData(this.detail.id);
      }
      await this.bindTimeTrackingDraf([
        {
          prop: 'user_id',
          val: this.currentUser.uid,
          op: '==',
        },
      ]);
    },
    showSavedMessage() {},

    clearData() {
      this.dueDate = [];
      this.cardStatus = '';
      this.selectCompanyMessage = '';
      this.selectPropertyMessage = '';
      this.projectAddress = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      };
    },

    openEditContact() {
      this.$refs.editContactPopup.open();
    },

    saveDate(field, value) {
      if (
        (!_.isEmpty(this.detail.dueDate) &&
          this.detail.dueDate.toDate().toDateString() ===
            new Date(value[0]).toDateString()) ||
        _.isEmpty(value)
      ) {
        return;
      } else {
        this.update({
          id: this.detail.id,
          doc: {
            [field]: this.toDateFirebase(value),
          },
        }).then(() => this.showSavedMessage());
      }
    },

    changeCardValue(field, value, isShowSavedMessage = true) {
      return this.update({
        id: this.detail.id,
        doc: {
          [field]: value,
        },
      }).then(() => isShowSavedMessage && this.showSavedMessage());
    },

    onChangeTitle(value) {
      if (!value.trim()) return;
      this.changeCardValue('title', value);
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },

    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    openAddNewContactPopup() {
      this.$refs.selectContact.open(true);
    },

    setIsEdit(value) {
      this.isEdit = value;
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    async handleContactSelected(id) {
      await this.getContact(id);
      await this.changeCardValue('contactId', id);
      await this.changeJobTitle();
      this.$refs.selectCompany.autoFillCompany();
      this.newContact = false;
    },

    async handleContactCreated(id) {
      await this.getContact(id);
      await this.changeCardValue('contactId', id);
      this.handleCompanySelected('');
      this.$refs.selectCompany.autoFillCompany();
      await this.changeJobTitle();
      this.newContact = false;
    },

    async handleCompanySelected(id, isShowSavedMessage = true) {
      if (id) {
        await this.getCompany(id);
      } else {
        await this.setCompany({});
      }
      await this.changeCardValue('companyId', id, isShowSavedMessage);
      this.newCompany = false;
      this.$refs.selectProperty.autoFillProperty();
      if (this.newContact && id) {
        this.$f7.preloader.show();
        await this.addCompanyContactRelationShip({
          companyId: id,
          contactId: this.detail.contactId,
        });
        this.$f7.preloader.hide();
      }
    },

    async handleCompanyCreated(id) {
      await this.getCompany(id);
      await this.changeCardValue('companyId', id);
      this.handlePropertySelected('');
      this.newCompany = true;
      this.$f7.preloader.show();
      await this.addCompanyContactRelationShip({
        companyId: id,
        contactId: this.detail.contactId,
      });
      this.$f7.preloader.hide();
    },

    async handlePropertySelected(id, isShowSavedMessage = true) {
      if (id) {
        await this.getProperty(id);
      } else {
        await this.setProperty({});
      }
      await this.changeCardValue('propertyId', id, isShowSavedMessage);
      await this.changeJobTitle();
      if (this.newCompany && id) {
        this.$f7.preloader.show();
        await this.addPropertyCompanyRelationShip({
          propertyId: id,
          companyId: this.detail.companyId,
        });
        this.$f7.preloader.hide();
      }
    },

    async handlePropertyCreated(id) {
      await this.getProperty(id);
      await this.changeCardValue('propertyId', id);
      await this.changeJobTitle();
      this.$f7.preloader.show();
      await this.addPropertyCompanyRelationShip({
        propertyId: id,
        companyId: this.detail.companyId,
      });
      this.$f7.preloader.hide();
    },

    openAddNewCompanyPopup() {
      if (!this.detail.contactId) {
        this.selectCompanyMessage = 'Please select contact first';
        return;
      }
      this.$refs.selectCompany.open(true);
    },

    navigateToSelectCompany() {
      if (!this.detail.contactId) {
        this.selectCompanyMessage = 'Please select contact first';
        return;
      }

      this.$refs.selectCompany.open();
    },

    openAddNewPropertyPopup() {
      if (!this.detail.contactId || !this.detail.companyId) {
        this.selectPropertyMessage = 'Please select contact and company first';
        return;
      }
      this.$refs.selectProperty.open(true);
    },

    navigateToSelectProperty() {
      if (!this.detail.contactId || !this.detail.companyId) {
        this.selectPropertyMessage = 'Please select contact and company first';
        return;
      }
      this.$refs.selectProperty.open();
    },

    changeJobTitle() {
      const jobTitle = this.getJobTitle(this.detail);
      return this.changeCardValue('title', jobTitle);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    closeActionPopup(callback) {
      this.$emit('closePopup', callback);
      this.v$.$reset();
    },

    handleCreateNewEstimate() {
      if (this.invalid()) return;
      this.$f7router.navigate(
        {
          name: this.detail.contactId
            ? 'new-estimate-swimlane-service'
            : 'new-estimate-swimlane',
          params: {
            boardType: 'swimlane',
            businessCode: this.$f7route.route.meta.businessCode,
            boardId: this.detail.boardId,
            actionId: this.detail.columnId,
            projectId: this.detail.id,
            contactId: this.detail.contactId || '-',
            clientType: 'contact',
            clientId: this.detail.contactId || '-',
          },
          query: {
            activeTab: 'tab-action',
          },
        },
        { reloadAll: true }
      );

      this.closeActionPopup();
    },

    handleCreateNewProposal() {
      if (this.invalid()) return;
      this.$f7router.navigate(
        {
          name: 'new-proposal-swimlane-service',
          params: {
            boardType: 'swimlane',
            businessCode: this.$f7route.route.meta.businessCode,
            boardId: this.detail.boardId,
            actionId: this.detail.columnId,
            projectId: this.detail.id,
            contactId: this.detail.contactId || '-',
          },
          query: {
            activeTab: 'tab-action',
          },
        },
        { reloadAll: true, pushState: true }
      );

      this.closeActionPopup();
    },

    openCreateInvoicePopover() {
      this.$refs.createInvoicePopover.open();
    },

    getCreateNewInvoiceRouteName(type) {
      if (type == 'FromEstimate') {
        return this.detail.contactId
          ? 'new-invoice-from-estimate-swimlane-service'
          : 'new-invoice-from-estimate-swimlane';
      } else {
        return this.detail.contactId
          ? 'new-invoice-swimlane-service'
          : 'new-invoice-swimlane';
      }
    },

    handleCreateNewInvoice(type) {
      if (this.invalid()) return;
      const routeName = this.getCreateNewInvoiceRouteName(type);
      const self = this;
      const detail = this.detail;
      setTimeout(() => {
        this.$f7router.navigate(
          {
            name: routeName,
            params: {
              boardType: 'swimlane',
              businessCode: self.$f7route.route.meta.businessCode,
              boardId: detail.boardId,
              actionId: detail.columnId,
              projectId: detail.id,
              contactId: detail.contactId || '-',
              actionType: 'newInvoice',
            },
            query: {
              activeTab: 'tab-action',
            },
          },
          { reloadAll: true }
        );
      }, 500);
      this.closeActionPopup();
    },

    sendOnMyWayMessage(emailData) {
      const self = this;
      const {
        traveling_time,
        company_name,
        company_address,
        company_phone,
        company_website,
      } = emailData;

      this.$f7.preloader.show();
      axiosService
        .post('/notification/push-email', {
          to: self.getEmailAddress(this.contact),
          subject: {
            message: '[RooferIntel] Technician(s) is on their way!',
          },
          template: 'on-my-way',
          content: {
            traveling_time,
            company_name,
            company_address,
            company_phone,
            company_website,
          },
          attachments: [],
          scheduleSendingTime: '',
        })
        .then(() => {
          return axiosService.post('/notification/trigger-email-service', {
            sender: '',
          });
        })
        .then(() => {
          return this.updateOnMyWayHistory();
        })
        .then(() => {
          this.$f7.preloader.hide();
          self.$emit('closePopup');
        });
    },

    updateOnMyWayHistory() {
      let actionsHistory = _.cloneDeep(this.detail.actionsHistory) || [];

      actionsHistory.push({
        nextAction: 'On My Way',
        previousAction: this.actionById(this.detail.columnId).name,
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        resolvedById: auth.currentUser.uid,
      });

      return this.update({
        id: this.detail.id,
        doc: {
          actionsHistory,
        },
      });
    },

    onDoServiceAction(action) {
      if (this.invalid()) return;
      const contactObj = this.contact || {};
      switch (action.value) {
        case 'job-completed':
          this.cardStatus = 'close';
          this.action = action;
          this.$refs.notePopup.open();
          break;

        case 'lost-deal':
          this.cardStatus = 'lost-deal';
          this.action = action;
          this.$refs.notePopup.open();
          break;

        case 'cancel-job':
          this.cardStatus = 'cancel';
          this.action = action;
          this.$refs.notePopup.open();
          break;

        case 'on-my-way':
          if (this.getEmailAddress(contactObj)) {
            this.$refs.onMyWayPopup.open(contactObj);
          } else {
            this.$ri.dialog.openInfoDialog({
              title: 'On My way',
              content: 'Please input contact email first!',
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
          break;
      }
    },

    onSaveNote(note) {
      const self = this;
      let noteList = _.cloneDeep(this.detail.noteList) || [];
      this.createNotificationByType({
        data: {
          assignees: this.detail.assigneeIDs,
          project: {
            title: this.detail.title,
            id: this.detail.id,
            to: 'archive',
            type: 'job',
            entityName: COLLECTION_MOVE_ARCHIVE_JOB,
          },
        },
        type: 'move-project',
      });
      noteList.push({
        title: 'Archive Note',
        code: 'note',
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        createdById: auth.currentUser.uid,
        htmlContent: note,
      });

      const refs = [];
      refs.push(
        this.changeCardValue('archiveDate', firebase.firestore.Timestamp.now())
      );
      refs.push(this.changeCardValue('status', this.cardStatus));
      refs.push(this.changeCardValue('noteList', noteList));
      Promise.all(refs).then(() => {
        self.$emit('closePopup');
        self.$f7router.updateCurrentUrl('/dashboard/swimlane/service');
      });
    },

    getEmailAddress(contact) {
      if (contact.emails && contact.emails.length > 0) {
        let email = contact.emails.find(item => item.code === 'main');
        if (email) {
          return email.value;
        } else {
          email = contact.emails[0];
          return email.value || '';
        }
      }

      return '';
    },

    onCustomerTypeChange(event) {
      const type = event.target.value;
      const changes = {
        customerType: type,
      };

      if (type === 'residential' || type === 'multifamily') {
        changes['companyId'] = '';
        changes['companyName'] = '';
        changes['propertyId'] = '';
        changes['propertyName'] = '';
        changes['propertyAddress'] = {};
      }

      for (const field in changes) {
        this.changeCardValue(field, changes[field]);
      }
    },

    onInputAddress({ prop, value }) {
      this.projectAddress[prop] = value;
    },
    onSelectAddress(address) {
      this.changeCardValue('projectAddress', address);
    },
    startTimeLog() {
      if (this.invalid()) return;

      //get data unsubmit

      let timeLogInfoUnsubmit = {
        timeLogType:
          this.lastDrafTimeTracking.timeLogTypeUnsubmittedService || '',
        projectId: this.lastDrafTimeTracking.projectIdUnsubmittedService || '',
        classItem: this.lastDrafTimeTracking.classItemUnsubmittedService || '',
        serviceItem:
          this.lastDrafTimeTracking.serviceItemUnsubmittedService || '',
        title: this.lastDrafTimeTracking.titleUnsubmittedService || '',
      };
      //if all value "" => set timeLogInfoUnsubmit = {}
      if (
        Object.keys(timeLogInfoUnsubmit).every(
          key => timeLogInfoUnsubmit[key] === ''
        )
      ) {
        timeLogInfoUnsubmit = {};
      }

      //get isSubmitTimeLog

      //show popup submit old time log when !isSubmitTimeLog && timeLogInfoUnsubmit
      //else => don't show popup
      if (
        !this.lastDrafTimeTracking.submitted &&
        !_.isEmpty(timeLogInfoUnsubmit)
      ) {
        this.$ri.dialog.openWarningDialog({
          title: 'Submit previous timelog',
          content:
            'Before logging a new time log, please submit your previous time log.',
          textButton: 'See Timelog',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.openTimerPopup();
              this.setTimeLogInfoAvailable({ project: this.detail });
            }
          },
        });
      } else {
        this.openTimerPopup();
        this.setTimeLogInfoAvailable({ project: this.detail });
      }
    },
    getValuesFromLocalStorage(keys, suffix) {
      const data = {};
      keys.forEach(key => {
        const value = localStorage.getItem(`${key}${suffix}`);
        if (value !== null) {
          data[key] = value;
        }
      });
      return data;
    },
    navigateToSchedule() {
      if (this.isNavigateToSchedule) {
        localStorage.setItem('projectIdService', this.detail.id);
        this.$emit('closePopup');
        this.$f7router.navigate('/employee-scheduling', {
          pushState: true,
          reloadAll: true,
        });
      } else {
        window.location.href = '/permission-denied';
      }
    },
    invalid() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != '') {
          this.selectCompanyMessage = '';
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ''
        ) {
          this.selectPropertyMessage = '';
        }

        if (!_.isEmpty(val.projectAddress)) {
          this.projectAddress = _.cloneDeep(val.projectAddress);
        }
      },
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      companyName: {
        required(val) {
          if (this.detail.customerType !== 'commercial') return true;
          return !!val;
        },
      },
      propertyName: {
        required(val) {
          if (this.detail.customerType !== 'commercial') return true;
          return !!val;
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
  &_placeholder {
    color: var(--f7-color-placeholder);
  }
}

.actions-create {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
  position: absolute;
  top: -3px;
  right: 16px;
}

.popover-create-action {
  width: 250px;
}

.add-schedule {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--f7-block-strong-border-color);
  box-sizing: border-box;
  padding: 0 15px 10px 15px;
  margin: 0;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
