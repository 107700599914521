<template>
  <div>
    <data-table
      :headers="headers"
      :items="hits"
      :pageSize="hits.length"
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            placeholder="Search in projects"
            :clear-button="true"
            disable-button-text
            :value="searchText"
            @input="
              searchText = $event.target.value;
              onSearch();
            "
            @searchbar:clear="
              searchText = '';
              onSearch();
            "
            @searchbar:disable="
              searchText = '';
              onSearch();
            "
            class="search-list-detail"
          ></f7-searchbar>
        </f7-list>
      </template>
      <template #body="{ item }">
        <td>{{ item.projectNumber }}</td>
        <td>{{ item.projectName }}</td>
        <td>{{ item.companyName }}</td>
        <td>{{ item.contactName }}</td>
        <td>{{ item.contactPhoneNumber }}</td>
        <td>
          <f7-chip
            :text="onlineClass(item.projectStatus).text"
            :color="onlineClass(item.projectStatus).color"
          ></f7-chip>
        </td>
        <td>
          <div class="assignee-cell">
            <div
              v-for="(uid, index) in item.assigneeIDs"
              :key="index"
              :class="index < maxUserDisplay ? 'assignee-avatar' : ''"
            >
              <user-avatar
                v-if="index < maxUserDisplay && uid.length > 5"
                :avatarSize="24"
                :imageUrl="userById(uid) ? userById(uid).photoURL : ''"
                :name="userById(uid) ? userById(uid).displayName : ''"
                :uid="uid"
                :isArchive="true"
              ></user-avatar>
            </div>
            <div
              v-if="
                item.assigneeIDs && item.assigneeIDs.length > maxUserDisplay
              "
              class="assignee-avatar more-assignee-avatar cursor-pointer"
              @click="openAssigneeListPopup(item)"
            >
              <span>{{ `+${item.assigneeIDs.length - maxUserDisplay}` }}</span>
            </div>
          </div>
        </td>
        <td>
          {{ convertDateTimeFromAlgoliaToDate(item.dueDate) | MMDDYYYY }}
        </td>
        <td>
          {{ convertDateTimeFromAlgoliaToDate(item.archiveDate) | MMDDYYYY }}
        </td>
        <td class="label-cell">
          <long-content-block
            :seedId="`note-${item.id}`"
            :content="item.archiveNotes"
          ></long-content-block>
        </td>
        <td>
          <f7-link @click="openArchiveBoardProjectDetailPopup(item)"
            >View</f7-link
          >
        </td>
        <td class="numeric-cell">
          <template v-if="item.webContactId">
            <f7-link
              icon-f7="arrow_uturn_left_circle"
              :popover-open="`.popover-move-to-board_${item.id}`"
            ></f7-link>
            <f7-popover :class="`popover-move-to-board_${item.id}`">
              <f7-list>
                <f7-list-item
                  v-for="(action, index) in actions"
                  :key="index"
                  link
                  popover-close
                  :title="action.displayName"
                  @click="onDoAction(action.value, item)"
                ></f7-list-item>
              </f7-list>
            </f7-popover>
          </template>
          <f7-link
            v-else
            icon-f7="arrow_uturn_left_circle"
            @click.native="restore(item)"
          ></f7-link>
        </td>
      </template>
      <template slot="paging-footer">
        <div
          v-if="hits.length > 0"
          class="data-table-footer"
        >
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div class="data-table-pagination">
            <span
              v-if="page === 0"
              class="display-flex align-items-center margin-left color-gray"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="onGoToPage('first')"
              class="display-flex align-items-center margin-left"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span
              v-if="page === 0"
              class="margin-left color-gray"
              >Previous</span
            >
            <a
              v-else
              @click="onGoToPage('prev')"
              class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span
              v-else
              class="margin-left color-gray"
              >Next</span
            >

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </data-table>
    <assignee-list-popup ref="assigneeListPopup"></assignee-list-popup>
    <archive-board-project-detail-popup
      ref="archiveBoardProjectDetailPopup"
    ></archive-board-project-detail-popup>
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import LongContentBlock from '@/components/blocks/LongContentBlock.vue';
import AssigneeListPopup from '../../components/popup/AssigneeListPopup.vue';
import DataTable from '@/components/datatables';

import { mapActions, mapGetters } from 'vuex';

import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  LEAD_SOURCE_ONLINE,
  COLLECTION_RESTORE_ARCHIVE_JOB,
} from '../../../../utility/const';
import { firebase, auth } from '../../../../services/firebase.service';
import moment from 'moment';
import { comparationSort } from '../../../../utility/filter-tools';
import _ from 'lodash';
import { convertDateTimeFromAlgoliaToDate } from '../../../../utility/date-time-tool';
import ArchiveBoardProjectDetailPopup from '../../components/popup/ArchiveBoardProjectDetailPopup.vue';

export default {
  components: {
    UserAvatar,
    LongContentBlock,
    AssigneeListPopup,
    DataTable,
    ArchiveBoardProjectDetailPopup,
  },

  data() {
    return {
      actions: [
        {
          id: '1',
          displayName: 'Move to Commercial',
          value: 'create-commercial-job',
        },
        {
          id: '2',
          displayName: 'Move to Service',
          value: 'create-service-job',
        },
        {
          id: '3',
          displayName: 'Move to Residential',
          value: 'create-residential-job',
        },
      ],
      maxUserDisplay: 3,
      gridFilter: {
        status: '',
      },
      searchText: '',
    };
  },

  methods: {
    ...mapActions('dashboard/project', ['bindUserData']),
    ...mapActions('dashboard/project', [
      'getContact',
      'updateCard',
      'deleteTempleCard',
      'getActionBys',
      'getAction',
      'createProject',
      'getCardInColumn',
    ]),
    ...mapActions('dashboard/project-inactive', [
      'getFilterData',
      'searchProjectInactive',
      'goToPage',
      'setProjectInactiveNumberOfRows',
      'algoliaUpdateProjectInactiveItem',
      'algoliaDeleteProjectInactiveItem',
    ]),
    ...mapActions('dashboard/web-contact-info', ['getContactInfo']),
    ...mapActions('dashboard/client', ['updateCompany', 'updateContact']),
    ...mapActions('dashboard/swimlane', [
      'getDefaultServiceBoard',
      'getFirstColumn',
      'getSwimlaneBoard',
    ]),
    ...mapActions('common/notification', [
      'createNotificationByType',
      'getActionById',
    ]),

    convertDateTimeFromAlgoliaToDate(val) {
      return val && convertDateTimeFromAlgoliaToDate(val);
    },

    openArchiveBoardProjectDetailPopup(project) {
      this.$refs.archiveBoardProjectDetailPopup.open(project);
    },

    async getFilterDataResult() {
      await this.getFilterData({});
      const { status } = this.gridFilter;
      if (!this.statusFilterList.includes(status)) this.gridFilter.status = '';
    },

    async onSearch() {
      this.$f7.preloader.show();
      await this.handleSearch();
      this.$f7.preloader.hide();
    },

    handleSearch: _.debounce(function () {
      return this.searchProjectInactive(this.compileConditionsToSearch);
    }, 500),

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        pageName,
        ...this.compileConditionsToSearch,
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    async loadCurrentProjectInactivePage() {
      this.$f7.preloader.show();
      await this.searchProjectInactive({
        pageNumber: this.page,
        ...this.compileConditionsToSearch,
      });
      if (!this.hits.length && this.nbPages > 0) {
        await this.searchProjectInactive({
          pageNumber: this.nbPages - 1,
          ...this.compileConditionsToSearch,
        });
      }
      this.$f7.preloader.hide();
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows(value);
      this.onSearch();
    },

    async getContactNameById(id) {
      let contact = (await this.getContact(id)) || {};
      return `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
    },

    async onDoAction(action, card) {
      this.$f7.preloader.show();
      const self = this;
      let projectInfo = {};
      let showConfirmDialog = false;
      try {
        switch (action) {
          case 'create-commercial-job':
            projectInfo = await this.createCommercialCard(
              'open',
              BUSINESS_CODE_COMMERCIAL,
              card
            );
            projectInfo.businessCode = BUSINESS_CODE_COMMERCIAL;
            showConfirmDialog = true;
            break;

          case 'create-service-job':
            projectInfo = await this.createServiceCard(
              'open',
              BUSINESS_CODE_SERVICE,
              card
            );
            projectInfo.businessCode = BUSINESS_CODE_SERVICE;
            showConfirmDialog = true;
            break;

          case 'create-residential-job':
            projectInfo = await this.createCommercialCard(
              'open',
              BUSINESS_CODE_RESIDENTIAL,
              card
            );
            projectInfo.businessCode = BUSINESS_CODE_RESIDENTIAL;
            showConfirmDialog = true;
            break;

          default:
            return;
        }

        // remove this card
        await this.algoliaDeleteProjectInactiveItem(card.id);
        await this.deleteTempleCard(card.id);

        await this.loadCurrentProjectInactivePage();

        if (showConfirmDialog) {
          const app = this;
          this.$ri.dialog.openSuccessDialog({
            title: 'The job is created successfully',
            content: '',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                app.$ri.dialog.openInfoDialog({
                  title: 'Navigate to details',
                  content: 'Do you want to navigate to your job details?',
                  textButton: 'Details',
                  onClick: async (_sefl, index) => {
                    if (index === 0) {
                      _sefl.app.dialog.close();
                    } else if (index === 1) {
                      self.navigateToBoard(projectInfo);
                    }
                  },
                });
              }
            },
          });
        }
      } catch (error) {
        console.warn(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    async navigateToBoard({
      businessCode,
      boardNumber,
      actionNumber,
      actionId,
      cardNumber,
    }) {
      switch (businessCode) {
        case BUSINESS_CODE_SERVICE:
          return this.$f7router.navigate(
            `/dashboard/swimlane/service/${boardNumber}/card/${cardNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );

        case BUSINESS_CODE_RESIDENTIAL:
          return this.$f7router.navigate(
            `/dashboard/swimlane/residential/${cardNumber}/action/${actionNumber}`,
            {
              reloadAll: true,
              pushState: true,
            }
          );

        case BUSINESS_CODE_COMMERCIAL:
          if (!_.isEmpty(actionId)) {
            const action = await this.getActionById(actionId);
            return this.$f7router.navigate(
              `/dashboard/swimlane/commercial/${action.boards[0]}/project/${cardNumber}/action/${actionNumber}`,
              {
                reloadAll: true,
                pushState: true,
              }
            );
          } else {
            return this.$f7router.navigate(
              `/dashboard/swimlane/commercial/sales/project/${cardNumber}/action/${actionNumber}`,
              {
                reloadAll: true,
                pushState: true,
              }
            );
          }
      }
    },

    async createCommercialCard(status, businessCode, card) {
      const project = {};
      let actions = [];
      if (businessCode === BUSINESS_CODE_COMMERCIAL) {
        actions =
          (await this.getActionBys([{ prop: 'code', op: '==', val: 110 }])) ||
          [];
        project.contactedDate = firebase.firestore.Timestamp.now();
      } else if (businessCode === BUSINESS_CODE_RESIDENTIAL) {
        actions =
          (await this.getActionBys([{ prop: 'code', op: '==', val: 800 }])) ||
          [];
        project.inspectDate = firebase.firestore.Timestamp.now();
        project.inspectTime = moment().format('HH:mm');
      }

      const contactInfo = await this.getContactInfo(card.webContactId);

      if (!actions[0] || !contactInfo) {
        return;
      }

      const action = actions[0];
      const contactId = card.contactId;
      const companyId = card.companyId;

      const actionsHistory = [];
      actionsHistory.push({
        nextAction: 'Job Created',
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: '',
      });

      project.commentAndNote = [
        {
          code: 'note',
          title: 'Client Note',
          htmlContent: contactInfo.message,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        },
      ];

      project.actionsHistory = actionsHistory;
      project.status = status;
      project.actions = [action.id];
      project.dueDate = firebase.firestore.Timestamp.now();
      project.leadSource = LEAD_SOURCE_ONLINE;
      project.title = await this.getContactNameById(contactId);
      project.contactId = contactId;
      project.businessCode = businessCode;
      if (project.businessCode === BUSINESS_CODE_COMMERCIAL) {
        project.companyId = companyId;
      }

      const { docNumber } = await this.createProject({
        tenantId: this.user.tenantId,
        project,
      });

      return {
        boardNumber: null,
        actionNumber: action.code,
        cardNumber: docNumber,
      };
    },

    async createServiceCard(status, businessCode, card) {
      const project = {};
      const defaultServiceBoard =
        await this.getDefaultServiceBoard(businessCode);
      const contactInfo = await this.getContactInfo(card.webContactId);
      if (!defaultServiceBoard || !contactInfo) {
        return;
      }
      const firstColumn = await this.getFirstColumn(defaultServiceBoard.id);

      const cardsInColumn = await this.getCardInColumn(firstColumn.id);
      const minPriorityInColumn =
        Math.min(...[...cardsInColumn.map(r => r.priority), 0]) || 0;

      const actionsHistory = [
        {
          nextAction: 'Job Created',
          previousAction: '',
          resolvedAt: firebase.firestore.Timestamp.now(),
          resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
          resolvedById: auth.currentUser.uid,
        },
      ];

      const contactId = card.contactId;
      let companyId = '';
      if (card.companyId) {
        companyId = card.companyId;
        this.updateCompany({
          id: companyId,
          doc: { contactRefs: [{ id: contactId, optionalRole: '' }] },
        });
        this.updateContact({
          id: contactId,
          doc: { companyRefs: [{ fromDate: '', toDate: '', id: companyId }] },
        });
      }

      project.title = await this.getContactNameById(contactId);
      project.description = contactInfo.message;
      project.priority = minPriorityInColumn - 1000;
      project.columnId = firstColumn.id;
      project.boardId = defaultServiceBoard.id;
      project.dueDate = firebase.firestore.Timestamp.now();
      project.contactId = contactId;
      project.companyId = companyId;
      project.status = status;
      project.actionsHistory = actionsHistory;
      project.businessCode = businessCode;
      project.customerType = BUSINESS_CODE_COMMERCIAL;

      const { docNumber } = await this.createProject({
        tenantId: this.user.tenantId,
        project,
      });

      return {
        boardNumber: defaultServiceBoard.boardNumber,
        actionNumber: null,
        cardNumber: docNumber,
      };
    },

    async restore(card) {
      const self = this;
      this.$ri.dialog.openInfoDialog({
        title: 'Restore project',
        content: 'Do you want to restore this project?',
        textButton: 'Restore',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.updateCard({
              id: card.id,
              doc: { status: 'open' },
            })
              .then(async () => {
                self.$f7.preloader.show();
                await this.createNotificationByType({
                  data: {
                    assignees: card.assigneeIDs,
                    project: {
                      title: card.projectName,
                      id: card.id,
                      entityName: COLLECTION_RESTORE_ARCHIVE_JOB,
                    },
                  },
                  type: 'restore-project',
                });
                await this.algoliaUpdateProjectInactiveItem(card.id);
                await this.loadCurrentProjectInactivePage();
                self.$f7.preloader.hide();
              })
              .then(() => {
                this.$ri.dialog.openInfoDialog({
                  title: 'Navigate to details',
                  content: 'Do you want to navigate to your job details?',
                  textButton: 'Yes',
                  onClick: async (_sefl, index) => {
                    if (index === 0) {
                      _sefl.app.dialog.close();
                    } else if (index === 1) {
                      // show load and destroy will hide
                      self.$f7.preloader.show();
                      let board = null;
                      let action = null;
                      if (card.projectBusinessCode === BUSINESS_CODE_SERVICE) {
                        board = await this.getSwimlaneBoard(card.boardId);
                      } else if (
                        card.projectBusinessCode === BUSINESS_CODE_COMMERCIAL ||
                        card.projectBusinessCode === BUSINESS_CODE_RESIDENTIAL
                      ) {
                        action = await this.getAction(card.actions[0]);
                      }
                      self.navigateToBoard({
                        businessCode: card.projectBusinessCode,
                        boardNumber: board && board.boardNumber,
                        actionNumber: action && action.code,
                        actionId: action && action.id,
                        cardNumber: card.projectNumber,
                      });
                    }
                  },
                });
              });
          }
        },
      });
    },

    openAssigneeListPopup(card) {
      this.$refs.assigneeListPopup.open(card);
    },
  },

  computed: {
    ...mapGetters('dashboard/project', ['userById']),
    ...mapGetters('dashboard/project-inactive', [
      'hitsPerPage',
      'hits',
      'nbHits',
      'nbPages',
      'page',
      'statusFilterList',
    ]),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('common/app-constant', ['tenantId']),

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },

    headers() {
      let self = this;
      return [
        {
          text: 'Project#',
          value: 'projectNumber',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Project Information',
          value: 'projectName',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Company Name',
          value: 'companyName',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Contact Name',
          value: 'contactName',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Phone Number',
          value: 'contactPhoneNumber',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'left',
          filter: {
            type: 'select',
            value: self.gridFilter.status,
            selectSource: [
              { value: '', text: 'All statuses' },
              ...this.statusFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.title,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.status = value;
              this.onSearch();
            },
          },
        },

        {
          text: 'Assignees',
          value: 'assigneeIDs',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Due Date',
          value: 'dueDate',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Date of Archive',
          value: 'archiveDate',
          sortable: false,
          align: 'left',
        },

        {
          text: 'Note',
          value: 'archiveNotes',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Details',
          value: 'details',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ];
    },

    statusFilterOptions() {
      return this.statusList.filter(r =>
        (this.statusFilterList || []).includes(r.id)
      );
    },

    statusList() {
      return [
        { id: 'close', title: 'Close' },
        { id: 'cancel', title: 'Cancel' },
        { id: 'lost-deal', title: 'Lost Deal' },
        { id: 'put-a-lien', title: 'Put a Lien' },
      ];
    },

    onlineClass() {
      return status => {
        switch (status) {
          case 'close':
            return { color: 'red', text: 'Close' };

          case 'lost-deal':
            return { color: 'deeppurple', text: 'Lost Deal' };

          case 'put-a-lien':
            return { color: 'yellow', text: 'Put a Lien' };

          default:
            return { color: 'gray', text: 'Cancel' };
        }
      };
    },

    compileConditionsToSearch() {
      // filter
      const filters = [];
      const { status } = this.gridFilter;

      if (status) filters.push(`projectStatus:${status}`);

      return {
        query: this.searchText,
        filters: filters.join(' AND '),
      };
    },
  },

  created() {
    this.onSearch();
    this.getFilterDataResult();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-detail.searchbar input').focus();
      }
    });
  },

  destroyed() {
    this.$f7.preloader.hide();
  },
};
</script>

<style lang="scss" scoped>
.assignee-avatar {
  margin-left: 3px;
}
.more-assignee-avatar {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 12px;
}

.assignee-cell {
  display: flex;
}
</style>
