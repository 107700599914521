<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="closePopup"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Job</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="save">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- Customer Type -->
      <f7-block-title>Customer Type</f7-block-title>
      <f7-list>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="customer-type-add-popup"
            value="commercial"
            :checked="job.customerType === 'commercial'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Commercial
          </div>
        </f7-list-item>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="customer-type-add-popup"
            value="residential"
            :checked="job.customerType === 'residential'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Residential
          </div>
        </f7-list-item>
        <f7-list-item>
          <f7-radio
            slot="content-start"
            name="customer-type-add-popup"
            value="multifamily"
            :checked="job.customerType === 'multifamily'"
            @change="onCustomerTypeChange"
          >
          </f7-radio>
          <div
            slot="after-title"
            class="margin-left"
          >
            Multifamily
          </div>
        </f7-list-item>
      </f7-list>

      <div v-if="job.customerType === 'commercial'">
        <f7-block-title> Contact Information </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <contact-name-input
            ref="selectContact"
            media-list
            :displayCard="job"
            :errorMessage="requiredErrorMessage('contactId')"
            :contactName="contactName"
            :companyId="company.id"
            :queryFilters="`${
              company.contactRefs &&
              company.contactRefs.length &&
              job.customerType === 'commercial'
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handleContactSelected"
            @onDeleteContact="onDeleteContact($event)"
            @onCreated="handleContactCreated"
            :autoFocus="true"
            :tabIndex="1"
          />

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in contact.phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in contact.emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in contact.addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <!-- Company -->
        <f7-list media-list>
          <company-name-input
            ref="selectCompany"
            media-list
            :displayCard="job"
            :errorMessage="
              selectCompanyMessage || requiredErrorMessage('companyId')
            "
            :companyName="companyName"
            :contactId="contact.id"
            :companyTypes="[
              COMPANY_TYPE_INSURANCE,
              COMPANY_TYPE_GENERAL_CONTRACTOR,
              COMPANY_TYPE_PROPERTY_OWNER,
              COMPANY_TYPE_PROPERTY_MANAGEMENT,
              COMPANY_TYPE_TENANT,
            ]"
            :queryFilters="`${
              contact.companyRefs && contact.companyRefs.length
                ? `contactIdRefs:${contact.id} AND`
                : ''
            } NOT companyTypes:${COMPANY_TYPE_VENDOR}`"
            @onSelected="handleCompanySelected"
            @onDeleteCompany="onDeleteCompany($event)"
            @onCreated="handleCompanyCreated"
            :tabIndex="2"
          />

          <!--Company Phone -->
          <f7-list-item
            v-for="(phone, index) in company.phones"
            :key="`company-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Company Address -->
          <f7-list-item
            v-for="(address, index) in company.addresses"
            :key="`company-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <!-- Property -->
        <f7-list media-list>
          <property-name-input
            ref="selectProperty"
            media-list
            :displayCard="job"
            :errorMessage="
              selectPropertyMessage || requiredErrorMessage('propertyId')
            "
            :propertyName="propertyName"
            :companyId="company.id"
            :queryFilters="`${
              company.propertyRefs && company.propertyRefs.length
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handlePropertySelected"
            @onDeleteProperty="onDeleteProperty"
            @onCreated="handlePropertyCreated"
            :tabIndex="3"
          />

          <!--Property Phone -->
          <f7-list-item
            v-for="(phone, index) in property.phones"
            :key="`property-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Property Address -->
          <f7-list-item
            v-for="(address, index) in property.addresses"
            :key="`property-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>
      </div>

      <div
        v-if="
          job.customerType === 'residential' ||
          job.customerType === 'multifamily'
        "
      >
        <!-- Contact -->
        <f7-block-title class="display-flex justify-content-space-between">
          Contact Information
          <div v-if="job.contactId">
            <f7-button
              small
              fill
              @click="openEditContact"
              >Edit Contact</f7-button
            >
          </div>
        </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <contact-name-input
            ref="selectContact"
            media-list
            :displayCard="job"
            :errorMessage="requiredErrorMessage('contactId')"
            :contactName="contactName"
            :companyId="company.id"
            :queryFilters="`${
              company.contactRefs &&
              company.contactRefs.length &&
              job.customerType === 'commercial'
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handleContactSelected"
            @onDeleteContact="onDeleteContact($event)"
            @onCreated="handleContactCreated"
            :autoFocus="true"
          />

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in contact.phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in contact.emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in contact.addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <address-input-residential
          ref="addressInput"
          :addressesSuggestion="contact.addresses || []"
          @input="onInputAddress($event)"
          @select="onSelectAddress($event)"
        ></address-input-residential>
      </div>

      <!-- job info -->
      <f7-block-title>Job Information</f7-block-title>
      <f7-list>
        <!-- Title -->
        <f7-list-input
          label="Title"
          type="text"
          placeholder="Card title"
          clear-button
          :value="job.title"
          @input="changeCardValue('title', $event.target.value)"
          error-message-force
          :error-message="requiredErrorMessage('title')"
        >
          <required-asterisk slot="label"></required-asterisk>
          <f7-icon
            color="primary"
            material="title"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Desc -->
        <f7-list-input
          label="Description"
          type="textarea"
          placeholder="Description"
          :value="job.description"
          @input="v => changeCardValue('description', v.target.value)"
        >
          <f7-icon
            color="primary"
            material="description"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Due Date -->
        <f7-list-input
          label="Due Date"
          type="datepicker"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
          }"
          :value="job.dueDate"
          placeholder="Enter due date"
          @calendar:change="
            changeCardValue('dueDate', $event);
            $f7.calendar.close();
          "
        >
          <f7-icon
            color="primary"
            slot="media"
            f7="timer"
          ></f7-icon>
        </f7-list-input>
      </f7-list>

      <user-input
        :value="job.assigneeIDs"
        @input="changeCardValue('assigneeIDs', $event)"
        :isNew="true"
      ></user-input>

      <!-- Note -->
      <note-editor
        :value="job.noteList && job.noteList[0] && job.noteList[0].htmlContent"
        @onChange="saveNote($event)"
        :styleProp="`margin-top: 10px`"
      ></note-editor>
    </f7-page>

    <edit-contact-popup ref="editContactPopup"></edit-contact-popup>
  </f7-popup>
</template>

<script>
import UserInput from '../inputs/UserInput.vue';
import InputIcon from '../icon/InputIcon.vue';
import AddressInputResidential from '@/components/inputs/AddressInputResidential.vue';

import { mapActions, mapGetters } from 'vuex';
import { getFullAddress } from '@/utility/address';
import { toDateFirebase } from '../../../../utility/datetime';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dashboardService from '../../../../services/dashboard.service';
import EditContactPopup from './EditContactPopup.vue';
import { firebase, auth } from '@/services/firebase.service';
import commonMixin from '../../mixin/common';
import {
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_INSURANCE,
  COMPANY_TYPE_PROPERTY_MANAGEMENT,
  COMPANY_TYPE_PROPERTY_OWNER,
  COMPANY_TYPE_VENDOR,
  COMPANY_TYPE_TENANT,
  COLLECTION_OPRATION_CARD,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import ContactNameInput from '@/components/inputs/ContactNameInput.vue';
import CompanyNameInput from '@/components/inputs/CompanyNameInput.vue';
import PropertyNameInput from '@/components/inputs/PropertyNameInput.vue';
import NoteEditor from '@/plugins/dashboard/components/note/NoteEditor.vue';

export default {
  components: {
    UserInput,
    EditContactPopup,
    InputIcon,
    AddressInputResidential,
    ContactNameInput,
    CompanyNameInput,
    PropertyNameInput,
    NoteEditor,
  },
  mixins: [commonMixin],

  data() {
    return {
      COMPANY_TYPE_GENERAL_CONTRACTOR,
      COMPANY_TYPE_INSURANCE,
      COMPANY_TYPE_PROPERTY_MANAGEMENT,
      COMPANY_TYPE_PROPERTY_OWNER,
      COMPANY_TYPE_VENDOR,
      COMPANY_TYPE_TENANT,

      popupOpened: false,
      job: {},

      selectCompanyMessage: '',
      selectPropertyMessage: '',
      newContact: false,
      newCompany: false,
      newProperty: false,
      firstSelectedField: '',
    };
  },

  computed: {
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('swimlane/client', ['contact', 'company', 'property']),

    contactName() {
      return this.getContactName(this.contact);
    },

    contactAddress() {
      return this.getContactAddress(this.contact);
    },

    propertyName() {
      return this.getPropertyName(this.property);
    },

    companyName() {
      return this.company ? this.company.companyName : '';
    },

    propertyAddress() {
      const mainAddress = (this.property.addresses || []).find(
        r => r.code === 'main'
      );
      const firstAddress =
        this.property.addresses && this.property.addresses[0];
      const address = mainAddress || firstAddress || {};
      return address;
    },

    requiredErrorMessage() {
      return prop => {
        if (!this.v$.job[prop].$error) return null;
        if (this.v$.job[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    /**
     * Check for this company has property or not
     */
    hasPropertyRefs() {
      return this.company.propertyRefs && this.company.propertyRefs.length > 0;
    },
  },

  methods: {
    ...mapActions({
      update: 'swimlane/card/update',
    }),
    ...mapActions('swimlane/client', [
      'addCompanyContactRelationShip',
      'addPropertyCompanyRelationShip',
      'getContact',
      'getCompany',
      'setCompany',
      'getProperty',
      'setProperty',
      'setContact',
      'unbindContact',
      'unbindCompany',
      'unbindProperty',
    ]),
    ...mapActions('common/notification', ['createNotificationByType']),

    saveNote(event) {
      this.changeCardValue('noteList', event);
    },

    open(data) {
      this.popupOpened = true;
      this.job = {
        ...{
          title: '',
          description: '',
          dueDate: [],
          contactId: null,
          companyId: null,
          propertyId: null,
          assigneeIDs: [],
          noteList: [],
          customerType: 'commercial',
          projectAddress: {
            address: '',
            city: '',
            state: '',
            zipcode: '',
            country: '',
          },
        },
        ...data,
      };
    },

    handlePopupOpened() {
      this.focusContact();
    },

    async closePopup() {
      this.job = {
        title: '',
        description: '',
        dueDate: [],
        contactId: null,
        companyId: null,
        propertyId: null,
        assigneeIDs: [],
        noteList: [],
        customerType: 'commercial',
        projectAddress: {
          address: '',
          city: '',
          state: '',
          zipcode: '',
          country: '',
        },
      };

      this.selectCompanyMessage = '';
      this.selectPropertyMessage = '';

      await this.unbindContact();
      await this.unbindCompany();
      await this.unbindProperty();
      this.v$.$reset();
      this.$nextTick(() => {
        if (this.$refs.addressInput) {
          this.$refs.addressInput.clearData();
        }
        if (this.$refs.selectContact) {
          this.$refs.selectContact.close();
        }
      });

      this.$refs.addressInput &&
        this.$refs.addressInput.v$ &&
        this.$refs.addressInput.v$.$reset();

      this.popupOpened = false;
      this.$f7router.updateCurrentUrl(
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}`
      );
    },

    openEditContact() {
      this.$refs.editContactPopup.open();
    },

    navigateToSelectCompany() {
      // this.setCurrentCardValues(this.displayCard);
      this.$refs.selectCompany.open();
    },

    navigateToSelectProperty() {
      this.$refs.selectProperty.open();
    },

    async onDeleteContact(event) {
      event && event.stopPropagation();
      await this.setContact({});
      this.changeCardValue('contactId', '');

      if (this.firstSelectedField === 'contact') {
        await this.onDeleteCompany();
        await this.onDeleteProperty();

        this.onDeleteProperty();
        this.firstSelectedField = '';
      }
      this.$refs.selectContact.setQueryFilters('');
    },
    async onDeleteCompany(event) {
      event && event.stopPropagation();

      await this.setCompany({});
      this.changeCardValue('companyId', '');
      if (this.firstSelectedField === 'company') {
        await this.onDeleteContact('');
        await this.onDeleteProperty('');

        this.onDeleteProperty();
        this.firstSelectedField = '';
      }
      this.$refs.selectCompany.setQueryFilters('');
    },
    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    async handleContactSelected(id) {
      this.$f7.preloader.show();
      if (id) {
        await this.getContact(id);
      } else {
        await this.setContact({});
      }
      this.changeCardValue('contactId', id);

      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'contact';
      }
      // this.changeProjectTitle();
      if (!this.newCompany && this.job.customerType === 'commercial') {
        this.$refs.selectCompany.autoFillCompany();
      }
      this.changeJobTitle();
      this.newContact = false;
      this.$f7.preloader.hide();
    },

    async handleContactCreated(id) {
      this.$f7.preloader.show();
      await this.getContact(id);
      this.changeCardValue('contactId', id);
      this.changeJobTitle();
      this.newContact = true;
      if (this.job.companyId) {
        await this.addCompanyContactRelationShip({
          companyId: this.job.companyId,
          contactId: id,
        });
      } else {
        this.$refs.selectCompany.autoFillCompany();
      }
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'contact';
      }
      this.$f7.preloader.hide();
    },

    async handleCompanySelected(id) {
      this.$f7.preloader.show();
      if (id) {
        await this.getCompany(id);
      } else {
        await this.setCompany({});
      }
      this.changeCardValue('companyId', id);
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'company';
      }
      if (this.newProperty) {
        await this.addPropertyCompanyRelationShip({
          propertyId: this.job.propertyId,
          companyId: id,
        });
      } else {
        this.$refs.selectProperty.autoFillProperty();
      }
      this.newCompany = false;

      if (_.isEmpty(this.contact)) {
        this.$refs.selectContact.autoFillContact();
      }
      if (this.newContact && id) {
        await this.addCompanyContactRelationShip({
          companyId: id,
          contactId: this.job.contactId,
        });
      }
      this.$f7.preloader.hide();
    },

    async handleCompanyCreated(id) {
      this.$f7.preloader.show();
      await this.getCompany(id);
      this.changeCardValue('companyId', id);
      // this.handlePropertySelected('');
      if (this.job.propertyId) {
        await this.addPropertyCompanyRelationShip({
          propertyId: this.job.propertyId,
          companyId: id,
        });
      }
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'company';
      }
      this.$refs.selectContact.autoFillContact();
      this.newCompany = true;
      await this.addCompanyContactRelationShip({
        companyId: id,
        contactId: this.job.contactId,
      });
      this.$f7.preloader.hide();
    },

    async handlePropertySelected(id) {
      this.$f7.preloader.show();
      this.newProperty = false;

      if (id) {
        await this.getProperty(id);
      } else {
        await this.setProperty({});
      }
      this.changeCardValue('propertyId', id);
      this.changeJobTitle();
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'property';
      }
      //check if first select property, handle reverse query logic
      if (!this.job.companyId) {
        this.getCompanyAndContactByProperty();
      }

      if (this.newCompany && id) {
        await this.addPropertyCompanyRelationShip({
          propertyId: id,
          companyId: this.job.companyId,
        });
      }
      this.$f7.preloader.hide();
    },

    async handlePropertyCreated(id) {
      this.$f7.preloader.show();
      this.newProperty = true;
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'property';
      }
      await this.getProperty(id);
      this.changeCardValue('propertyId', id);
      if (this.job.companyId) {
        await this.addPropertyCompanyRelationShip({
          propertyId: id,
          companyId: this.job.companyId,
        });
      }
      this.changeJobTitle();
      this.$f7.preloader.hide();
    },

    changeJobTitle() {
      const jobTitle = this.getJobTitle(this.job);
      this.changeCardValue('title', jobTitle);
    },

    changeCardValue(field, value) {
      if (field === 'noteList') {
        const noteList = [
          {
            title: 'Note',
            code: 'note',
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email,
            createdById: auth.currentUser.uid,
            htmlContent: value,
          },
        ];
        this.job[field] = noteList;
      } else {
        this.job[field] = value;
      }
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    invalid() {
      this.v$.$touch();
      if (
        ['residential', 'multifamily'].includes(this.job.customerType) &&
        !_.isEmpty(this.$refs.addressInput)
      ) {
        this.$refs.addressInput.v$.$touch();
        if (this.$refs.addressInput.v$.$invalid) {
          return true;
        }
      }
      return this.v$.$invalid;
    },

    save() {
      if (this.invalid()) return;

      this.$f7.preloader.show();
      let data = {
        ...this.job,
        dueDate: toDateFirebase(this.job.dueDate),
        contactName: this.contactName,
      };
      if (data.customerType === 'commercial') {
        data.companyName = this.companyName;
        data.propertyName = this.propertyName;
        data.propertyAddress = this.propertyAddress;
      }
      dashboardService.addCardDoc(this.user.tenantId, data).then(async res => {
        await this.createNotificationByType({
          data: {
            assignees: res.assigneeIDs,
            project: {
              title: res.title,
              id: res.id,
              type: 'project',
              entityName: COLLECTION_OPRATION_CARD,
            },
          },
          type: 'assign-user',
        });
        this.closePopup();
        this.$f7.preloader.hide();
        this.$emit('openCard', {
          id: res.id,
          cardNumber: res.docNumber,
        });
      });
    },

    onCustomerTypeChange(event) {
      this.changeCardValue('customerType', event.target.value);
    },

    onInputAddress({ prop, value }) {
      this.job.projectAddress[prop] = value;
      this.changeJobTitle();
    },
    onSelectAddress(address) {
      this.job.projectAddress = address;
      this.changeJobTitle();
    },

    async getCompanyAndContactByProperty() {
      // check if has company then: get first company, catch: return
      if (this.property.companyIdRefs?.length) {
        const companyId = this.property.companyIdRefs[0];
        await this.getCompany(companyId);
        this.job.companyId = companyId;

        // if company has contact, get firt contact, else return
        if (this.company.contactIdRefs?.length && !this.job.contactId) {
          const contactId = this.company.contactIdRefs[0];
          await this.getContact(contactId);
          this.job.contactId = contactId;
        }
      }
    },

    async onDeleteProperty(event) {
      event && event.stopPropagation();
      await this.setProperty({});
      this.job.propertyId = '';

      if (this.firstSelectedField === 'property') {
        await this.onDeleteContact('');
        await this.onDeleteCompany('');
        this.firstSelectedField = '';
      }
      this.$refs.selectProperty.setQueryFilters('');
    },

    focusContact() {
      setTimeout(() => {
        this.$refs.selectContact.focusInput();
      }, 0);
    },
  },

  watch: {
    job: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != '') {
          this.selectCompanyMessage = '';
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ''
        ) {
          this.selectPropertyMessage = '';
        }
      },
    },

    'job.customerType': {
      handler(val, oldVal) {
        if (oldVal) {
          this.focusContact();
        }
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      job: {
        title: {
          required,
        },
        contactId: {
          required,
        },
        companyId: {
          required(val) {
            if (this.job.customerType !== 'commercial') return true;
            return !!val;
          },
        },
        propertyId: {
          required(val) {
            if (this.job.customerType !== 'commercial') return true;
            return !!val;
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-title {
  flex: 1;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  line-height: 26px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
