<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ popupTitle }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="send()">Send </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list
        media-list
        style="z-index: 999; width: calc(100% - 6px)"
      >
        <email-auto-complete-input
          :options="listEmails"
          :value="composer.to"
          @change="
            value => {
              composer.to = value;
              v$.composer.to.$touch();
            }
          "
          title="To"
          placeholder="Enter email"
          isRequired
        >
          <f7-icon
            slot="icon"
            f7="envelope"
          ></f7-icon>
          <span slot="error-message">{{ toEmailErrorMessage }}</span>
        </email-auto-complete-input>
        <!-- cc -->
        <email-auto-complete-input
          :options="listEmails"
          :value="composer.cc"
          @change="
            value => {
              composer.cc = value;
              v$.composer.cc.$touch();
            }
          "
          title="Cc"
          placeholder="Enter email"
          class="email-data"
        >
          <f7-icon
            slot="icon"
            f7="square_on_square"
          ></f7-icon>
          <span slot="error-message">{{ ccEmailErrorMessage }}</span>
        </email-auto-complete-input>
        <!-- subject -->
        <div class="email-input subject">
          <div class="email-input-icon">
            <f7-icon f7="square_favorites_alt"></f7-icon>
          </div>
          <div class="email-input-content">
            <div class="email-input-lable">Subject<required-asterisk /></div>
            <div class="email-input-wrap">
              <f7-input
                type="textarea"
                placeholder="Enter subject"
                resizable
                clear-button
                @blur="v$.composer.subject.$touch()"
                @input="composer.subject = $event.target.value"
                :value="composer.subject"
              />
            </div>
            <div class="email-error-message">{{ subjectErrorMessage }}</div>
          </div>
        </div>
      </f7-list>
      <!-- Text area -->
      <f7-block-header class="display-flex justify-content-space-between">
        <div>Message<required-asterisk /></div>
        <div class="error-message">{{ messageErrorMessage }}</div>
      </f7-block-header>
      <f7-text-editor
        style="height: 500px; z-index: 10"
        ref="emailContent"
        :value="composer.message"
        placeholder="Enter message..."
        @texteditor:change="onChangeMessage"
        @texteditor:blur="v$.composer.message.$touch()"
        :buttons="buttons"
        :custom-buttons="customButtons"
      ></f7-text-editor>

      <f7-block-title v-show="attachments && attachments.length > 0"
        >Select attachments</f7-block-title
      >
      <f7-list>
        <f7-list-item
          v-for="(file, index) in attachments || []"
          :key="index"
          checkbox
          :title="file.fileName"
          :value="file.fileName"
          :checked="attachmentSelected.some(r => r.fileName === file.fileName)"
          @change="checkFile"
        ></f7-list-item>
      </f7-list>
      <attachment-input
        :projectId="projectId"
        attachment-type="email-attachment"
        add-button-title="Add an Attachment"
        :value="newAttachmentFiles"
        @input="newAttachmentFiles = $event"
      ></attachment-input>
    </f7-page>
    <select-color-popup ref="selectColor" />
  </f7-popup>
</template>

<script>
import EmailAutoCompleteInput from '@/components/inputs/EmailAutoCompleteInput';
import AttachmentInput from '@/components/inputs/AttachmentInput.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { mapActions } from 'vuex';
import axiosService from '@/services/axios.service';
import { firebase, auth } from '@/services/firebase.service';
import { buttonsForTextFormatBar, VALIDATION_MESSAGE } from '@/utility/const';
import SelectColorPopup from '@/plugins/proposal/components/popup/SelectColorPopup.vue';
import { customButtonForTextFormatBar } from '@/utility/common';

export default {
  components: {
    EmailAutoCompleteInput,
    AttachmentInput,
    SelectColorPopup,
  },

  props: {
    popupTitle: { type: String, default: 'Send Email' },
    templateName: String,
    projectId: { type: String, default: '' },
    attachments: { type: Array, default: () => [] },
    listEmails: { type: Array, default: () => [] },
  },

  data() {
    return {
      popupOpened: false,
      composer: {
        attachments: [],
        message: '',
        subject: '',
        from: '',
        to: '',
        cc: '',
        threadId: false,
      },
      attachmentSelected: [],
      newAttachmentFiles: [],
      callback: () => {},

      currentRange: null,
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar,
    };
  },

  computed: {
    toEmailErrorMessage() {
      if (!this.v$.composer.to.$error) return null;
      if (this.v$.composer.to.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.composer.to.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    ccEmailErrorMessage() {
      if (!this.v$.composer.cc.$error) return null;
      if (this.v$.composer.cc.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    subjectErrorMessage() {
      if (!this.v$.composer.subject.$error) return null;
      if (this.v$.composer.subject.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    messageErrorMessage() {
      if (!this.v$.composer.message.$error) return null;
      if (this.v$.composer.message.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  methods: {
    ...mapActions('common/email-notification', ['createEmailNotification']),

    onChangeMessage(value) {
      if (value === '<br>') {
        this.composer.message = '';
        this.$refs.emailContent.f7TextEditor.setValue('');
      } else {
        this.composer.message = value;
      }
    },
    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: 'Cancel',
              color: 'gray',
            },
            {
              text: 'OK',
              cssClass: 'primary',
            },
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.emailContent;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById('insert-link');
                const inputValue = inputElement.value;
                document.execCommand('createLink', true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          },
        })
        .open();
    },

    async send() {
      if (!this.validate()) return;

      let email = {
        to: this.composer.to,
        from: this.composer.from,
        cc: this.composer.cc,
        subject: this.composer.subject,
        template: this.templateName || '',
        htmlContent: this.composer.message,
        attachments: this.getAttachment().map(r => ({
          fileName: r.fileName,
          path: r.url,
        })),
        scheduleSendingTime: firebase.firestore.Timestamp.now(),
        isSent: false,
        numberOfAttempt: 0,
      };

      await this.sendMail(email);
    },

    async sendMail(email) {
      try {
        this.$f7.preloader.show();
        const data = await this.createEmailNotification(email);
        if (data) {
          await axiosService.post('/notification/trigger-email-service', {
            sender: this.composer.from,
          });
          const convertValueHtml = this.composer.message.replace(
            /<a href="(https?:\/\/[^"]+)"/g,
            (match, url) =>
              `<a href="#" onclick="window.open('${url}', '_blank')" `
          );
          const receiverList = [];
          this.composer.to
            .trim()
            .split(',')
            .forEach(receiver => {
              if (receiver.trim() !== '') {
                receiverList.push({
                  id: data,
                  receiver,
                  cc: this.composer.cc,
                  subject: this.composer.subject,
                  template: this.templateName || '',
                  content: convertValueHtml,
                  attachments: this.getAttachment(),
                  createdAt: firebase.firestore.Timestamp.now(),
                  createdBy:
                    auth.currentUser.displayName || auth.currentUser.email,
                });
              }
            });
          await this.callback(receiverList);
          this.showToastMessage('Email Sent!');
          this.$f7.preloader.hide();
          this.cancel();
          this.$emit('closePopup');
        }
      } catch (error) {
        this.$f7.preloader.hide();
        throw new Error(error.message);
      }
    },

    getAttachment() {
      return this.composer.attachments.concat(
        this.attachmentSelected,
        this.newAttachmentFiles
      );
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },

    async open({ composer, callback }) {
      this.composer = composer;
      this.callback = callback;
      this.popupOpened = true;
    },

    cancel() {
      this.popupOpened = false;
      this.composer = {
        attachments: [],
        message: '',
        subject: '',
        from: '',
        to: '',
        cc: '',
        threadId: false,
      };
      this.attachmentSelected = [];
      this.newAttachmentFiles = [];
      this.callback = () => {};
      this.v$.$reset();
    },

    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return true;
    },

    async checkFile(event) {
      const fileName = event.target.value;

      const file = this.attachments.find(item => item.fileName === fileName);

      if (file && event.target.checked) {
        this.attachmentSelected.push(file);
      } else if (file && !event.target.checked) {
        // remove
        const index = this.attachmentSelected.findIndex(
          item => item.fileName === file.fileName
        );
        if (index >= 0) {
          this.attachmentSelected.splice(index, 1);
        }
      }
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      composer: {
        subject: {
          required,
        },
        message: {
          required,
        },
        to: {
          required,
          commonEmailRule: email,
        },
        cc: {
          commonEmailRule: email,
        },
      },
    };
  },
};
</script>
<style land="scss" scoped>
.email-input {
  background-color: var(--f7-text-editor-bg-color);
}
::v-deep .text-editor-toolbar {
  z-index: inherit;
}
.error-message {
  font-size: 12px;
  color: var(--f7-theme-color);
}
</style>
