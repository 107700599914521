<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancelNewUser"
    @popup:opened="handlePopupOpened"
  >
    <f7-view>
      <f7-page>
        <!-- <form> -->
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>{{ isNew ? 'New User' : 'Edit User' }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="isNew ? add() : save()">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <f7-block-title class="searchbar-found">Components</f7-block-title> -->

        <f7-list class="justify-content-center align-items-center display-flex">
          <upload-avatar
            :textUpload="isNew ? `Upload` : `Edit`"
            :avatarSize="70"
            :imageUrl="photoURL"
            :name="displayName"
            @upload="upload"
            :isEdit="true"
          />
        </f7-list>

        <f7-block-title v-if="isNew">Login method</f7-block-title>
        <f7-list v-if="isNew">
          <f7-list-item
            radio
            radio-icon="start"
            title="Email"
            :name="`login-method-radio-${id}`"
            :checked="customClaims.loginMethod === LOGIN_METHOD_EMAIL"
            @change="onChangeLoginMethod"
            :value="LOGIN_METHOD_EMAIL"
          ></f7-list-item>
          <f7-list-item
            radio
            radio-icon="start"
            title="Phone Number"
            :name="`login-method-radio-${id}`"
            :checked="customClaims.loginMethod === LOGIN_METHOD_EMAIL_BY_PHONE"
            @change="onChangeLoginMethod"
            :value="LOGIN_METHOD_EMAIL_BY_PHONE"
          ></f7-list-item>
        </f7-list>

        <f7-list
          inline-labels
          class="components-list searchbar-found"
          @submit.prevent="add"
        >
          <!-- displayName -->
          <f7-list-input
            class="first-input"
            label="Full Name"
            placeholder="John Doe"
            :value="displayName"
            @input="updateField('displayName', $event.target.value.trim())"
            error-message-force
            clear-button
            :error-message="newNameErrorMessage"
          >
            <required-asterisk slot="label" />
          </f7-list-input>

          <!-- email -->
          <f7-list-input
            :disabled="!isNew && !isPhoneLogin"
            label="Email"
            placeholder="john.doe@email.com"
            :value="customClaims.displayEmail || email"
            @input="
              email = $event.target.value.trim();
              updateCustomClaimsField(
                'displayEmail',
                $event.target.value.trim()
              );
            "
            error-message-force
            :clear-button="isPhoneLogin"
            :error-message="newEmailErrorMessage"
          >
            <required-asterisk
              slot="label"
              v-show="!isPhoneLogin"
            ></required-asterisk>
          </f7-list-input>

          <f7-list-input
            label="Password"
            v-show="isNew"
            :value="password"
            @input="updateField('password', $event.target.value.trim())"
            error-message-force
            clear-button
            :error-message="newPasswordErrorMessage"
          >
            <required-asterisk slot="label" />
          </f7-list-input>
          <!-- phoneNumber -->
          <f7-list-input
            :disabled="!isNew && isPhoneLogin"
            label="Phone"
            :value="phoneNumber"
            @input="updateField('phoneNumber', $event.target.value.trim())"
            error-message-force
            :clear-button="!isPhoneLogin"
            :error-message="newPhoneErrorMessage"
            v-mask="'(+1) ###-###-####'"
          >
            <required-asterisk slot="label" />
          </f7-list-input>
          <!-- Pay Type -->
          <f7-list-input
            type="select"
            label="Pay Type"
            :value="customClaims.payType"
            @change="customClaims.payType = $event.target.value"
            error-message-force
            :error-message="payTypeErrorMessage"
          >
            <required-asterisk slot="label" />
            <option
              value=""
              disabled
              hidden
            >
              Select pay type
            </option>
            <option
              v-for="item in payTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.displayName }}
            </option>
          </f7-list-input>
        </f7-list>

        <f7-list inline-labels>
          <f7-list-item title="Active">
            <f7-toggle
              slot="after"
              :checked="!disabled"
              @toggle:change="disabled = !$event"
            ></f7-toggle>
          </f7-list-item>
        </f7-list>
        <f7-list inline-labels>
          <f7-list-item title="Receive Online Lead Email, SMS, Notification">
            <f7-toggle
              slot="after"
              :checked="customClaims.allowReceiveNotification"
              @toggle:change="customClaims.allowReceiveNotification = $event"
            ></f7-toggle>
          </f7-list-item>
        </f7-list>
        <f7-list>
          <f7-list-item
            link
            @click.native="openPopupGroupSelect('menu')"
          >
            <div slot="title">
              Groups<required-asterisk></required-asterisk>
            </div>
            <div
              slot="after-title"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 33%;
              "
            >
              {{ menuList }}
            </div>
            <div class="error-message">
              {{ newGroupsErrorMessage }}
            </div>
          </f7-list-item>
        </f7-list>

        <f7-list inline-labels>
          <f7-list-input
            label="Set Default Screen"
            type="select"
            defaultValue=""
            :value="customClaims.homeUrl"
            @change="customClaims.homeUrl = $event.target.value"
          >
            <option
              value=""
              disabled
              hidden
            >
              Select default screen
            </option>
            <option
              v-for="(menu, index) in menus"
              :key="index"
              :hidden="isHidden(menu)"
              :value="menu.path"
            >
              {{ menu.displayName }}
            </option>
          </f7-list-input>
        </f7-list>
        <f7-list inline-labels>
          <f7-list-input
            label="QB Linked User"
            type="select"
            placeholder="QB Linked User"
            :value="(customClaims.qbUser || {}).Id"
            @change="changeQBUser($event.target.value)"
          >
            <option
              v-for="(user, index) in qbUsers"
              :key="index"
              :value="user.Id"
            >
              {{ user.DisplayName }}
            </option>
          </f7-list-input>
        </f7-list>
        <group-select
          ref="groupSelect"
          :selected="groupIDs"
          :menus="groups"
          @select="handleSelected"
        ></group-select>
        <!-- <f7-row class="actions-container justify-content-flex-end">
          <f7-button class="margin-right-half" @click="onQBUserList" fill>
            Get List of QB Employee
          </f7-button>
        </f7-row> -->
        <!-- </form> -->
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import GroupSelect from '@/components/selects/GroupSelect.vue';
import UploadAvatar from '@/components/uploads/UploadAvatar.vue';

import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, minLength, email } from '@vuelidate/validators';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import Vue from 'vue';
import { COLLECTION_GROUP_ADMIN } from '@/utility/const';
import { isImage } from '@/utility/common';

import axiosService from '@/services/axios.service';
import quickbooksService from '@/services/quickbooks.service';
import {
  LOGIN_METHOD_EMAIL,
  LOGIN_METHOD_EMAIL_BY_PHONE,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  directives: { mask },
  components: {
    GroupSelect,
    UploadAvatar,
  },
  props: {
    id: String,
    // isNew: { type: Boolean, default: false }
    // popupOpened: { type: Boolean, default: false }
  },

  data() {
    return {
      uid: '',
      customClaims: {
        role: 'user',
        payType: '',
        groupIDs: [],
        qbUser: {},
        homeUrl: '',
        allowReceiveNotification: false,
        loginMethod: LOGIN_METHOD_EMAIL,
        displayEmail: '',
      },
      displayName: '',
      disabled: false,
      password: '',
      email: '',
      phoneNumber: '',
      photoURL: '',

      qbUserList: [],
      groupIDs: [],
      popupOpened: false,
      isNew: false,
      LOGIN_METHOD_EMAIL,
      LOGIN_METHOD_EMAIL_BY_PHONE,
    };
  },

  watch: {
    user: {
      deep: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          this.resetDisplayData();
          this.uid = val.uid;
          this.phoneNumber = this.formatPhone(val.phoneNumber);
          this.displayName = val.displayName;
          this.email = val.email;
          this.customClaims = _.cloneDeep(val.customClaims);
          this.photoURL = val.photoURL;

          this.disabled = val.disabled;
        }
      },
    },

    // popupOpened(val) {
    //   this.resetDisplayData();
    //   if (!_.isEmpty(this.user) && !this.isNew) {
    //     this.uid = this.user.uid;
    //     this.phoneNumber = this.formatPhone(this.user.phoneNumber);
    //     this.displayName = this.user.displayName;
    //     this.email = this.user.email;
    //     this.customClaims = {
    //       ...this.user.customClaims,
    //       payType: this.user.customClaims.payType,
    //       homeUrl: this.user.customClaims.homeUrl,
    //       allowReceiveNotification: this.user.customClaims
    //         .allowReceiveNotification
    //     };

    //     this.photoURL = this.user.photoURL;

    //     this.disabled = this.user.disabled;
    //   }

    //   if (val === true && _.isEmpty(this.setting)) {
    //     this.bindSetting(this.tenantId);
    //   }
    // },

    menuOptions: {
      handler(val) {
        const isExistedMenu = val.find(
          r => r.path === this.customClaims.homeUrl
        );
        if (!isExistedMenu) this.customClaims.homeUrl = '';
      },
      immediate: true,
      deep: true,
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const checkPassword = password => {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return passwordRegex.test(password);
    };

    return {
      displayName: {
        required,
      },
      password: this.isNew
        ? {
            required,
            commonPasswordRule: password => checkPassword(password),
          }
        : {},
      phoneNumber: {
        required,
        minLength: minLength(17),
      },
      customClaims: {
        payType: {
          required,
        },
        displayEmail:
          this.customClaims.loginMethod === LOGIN_METHOD_EMAIL
            ? {
                required,
                commonEmailRule: email,
              }
            : { commonEmailRule: email },
      },
      groupIDs: {
        required,
      },
    };
  },

  computed: {
    ...mapGetters('administration/user-list-page/user', [
      'user',
      'users',
      'qbUsers',
    ]),
    ...mapGetters('administration/user-list-page/group', ['groups']),
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),
    ...mapGetters('setting/app/system', ['setting', 'currentSubscription']),
    ...mapGetters('administration/app-constant', ['roleList', 'payTypeList']),
    ...mapGetters('administration/plan', ['planList']),
    ...mapGetters('administration/user-list-page/menu', ['menus']),
    ...mapGetters('common/administration-user', ['userForGroup']),

    menuOptions() {
      let groupByIds = _.cloneDeep(this.groups);
      if (_.isEmpty(this.userForGroup.groupIDs)) return _.cloneDeep(this.menus);
      else {
        groupByIds = groupByIds.filter(r =>
          (this.userForGroup.groupIDs || []).includes(r.id)
        );
      }
      let menuIds = [];
      groupByIds.forEach(r => {
        menuIds = menuIds.concat(r.menuIDs);
      });
      menuIds = [...new Set(menuIds)];
      const menus = this.menus.filter(r => menuIds.includes(r.id));
      return menus;
    },
    menuList() {
      if (!this.groupIDs.length) return '';
      return (
        this.groups.filter(r => (this.groupIDs || []).includes(r.id)) || []
      )
        .map(r => r.displayName)
        .join(', ');
    },
    tenantId() {
      return this.currentUser.tenantId;
    },

    numberOfUsers() {
      return (this.users.filter(r => !r.disabled) || []).length;
    },

    currentPlan() {
      return (
        this.planList.find(r => r.id === this.currentSubscription.size) || {}
      );
    },

    newNameErrorMessage() {
      if (!this.v$.displayName.$error) return null;
      if (this.v$.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    newEmailErrorMessage() {
      if (this.customClaims.loginMethod === LOGIN_METHOD_EMAIL) {
        if (!this.v$.customClaims.displayEmail.$error) return null;
        if (this.v$.customClaims.displayEmail.required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        if (this.v$.customClaims.displayEmail.commonEmailRule.$invalid)
          return VALIDATION_MESSAGE.INVALID_EMAIL;
      } else {
        if (!this.v$.customClaims.displayEmail.$error) return null;
        if (this.v$.customClaims.displayEmail.commonEmailRule.$invalid)
          return VALIDATION_MESSAGE.INVALID_EMAIL;
      }
      return null;
    },
    newPasswordErrorMessage() {
      if (!this.v$.password.$error) return null;
      if (this.v$.password.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.password.commonPasswordRule.$invalid)
        return VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH_TWO;
      return null;
    },
    newPhoneErrorMessage() {
      if (!this.v$.phoneNumber.$error) return null;
      if (this.v$.phoneNumber.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.phoneNumber.minLength.$invalid)
        return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
      // if (this.v$.phoneNumber.commonPhoneRule.$invalid) return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
      return null;
    },
    payTypeErrorMessage() {
      if (!this.v$.customClaims.payType.$error) return null;
      if (this.v$.customClaims.payType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    newGroupsErrorMessage() {
      if (!this.v$.groupIDs.$error) return null;
      if (this.v$.groupIDs.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    isPhoneLogin() {
      if (this.customClaims) {
        return this.customClaims.loginMethod === LOGIN_METHOD_EMAIL_BY_PHONE;
      }

      return false;
    },
  },

  methods: {
    ...mapActions('administration/user-list-page/user', [
      'updateUser',
      'addUser',
      'deleteUser',
      'getUserList',
      'getQBUserList',
      'removeAvatar',
      'uploadAvatar',
    ]),
    ...mapActions('administration/user-list-page/group', [
      'modifyRelationShip',
    ]),
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('common/notification', ['createNotificationByType']),
    ...mapActions('common/administration-user', {
      addAdminUser: 'createUser',
      updateAdminUser: 'updateUser',
    }),

    isHidden(menu) {
      return !this.menuOptions.some(r => r.id == menu.id);
    },
    handleSelected(value) {
      this.groupIDs = value;
    },
    openPopupGroupSelect() {
      this.$refs.groupSelect.open('menu');
    },
    selectGroups(groupIds) {
      this.groupIDs = groupIds;
      if (!_.isEmpty(groupIds)) {
        const group = this.groups.filter(r => groupIds.includes(r.id)) || [];

        if (
          group.some(r => r.displayName === 'Workers' && r.isDefault) &&
          this.menuOptions.some(r => r.path === '/my-time-log?openTimer=1') &&
          this.isNew
        ) {
          this.customClaims.homeUrl = '/my-time-log?openTimer=1';
        } else if (
          !group.some(r => r.displayName === 'Workers' && r.isDefault) &&
          this.menuOptions.some(r => r.path === '/my-scheduling') &&
          this.isNew
        ) {
          this.customClaims.homeUrl = '/my-scheduling';
        }
      } else {
        this.customClaims.homeUrl = '';
      }
    },
    isImage,
    upload(fileData) {
      this.$f7.preloader.show();
      if (this.customClaims && this.customClaims.avatarRef) {
        // for the exist user, upload and then save data
        this.removeAvatar(this.customClaims.avatarRef);
      }
      const fileType = fileData.info && fileData.info.name;

      if (!this.isImage(fileType)) {
        this.$f7.preloader.hide();
        this.openWarningDialogUploadPhoto();
        return; //check file image
      }

      this.uploadAvatar(fileData).then(fileInfo => {
        this.customClaims.avatarRef = fileInfo.fullPath;
        this.photoURL = fileInfo.url;
        if (this.uid) {
          this.updateUser({
            uid: this.uid,
            user: { photoURL: fileInfo.url, customClaims: this.customClaims },
          }).then(() => {
            this.$f7.preloader.hide();
          });
        } else {
          this.$f7.preloader.hide();
        }
      });
    },
    openWarningDialogUploadPhoto(message = 'Please upload again.') {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Invalid file!',
        content: message,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },
    updateField(fieldName, value) {
      Vue.set(this, fieldName, value);
      // this[fieldName] = value;
      this.v$[fieldName].$touch();
    },

    updateCustomClaimsField(fieldName, value) {
      Vue.set(this.customClaims, fieldName, value);
      this.v$.customClaims[fieldName].$touch();
    },

    onChangeLoginMethod(event) {
      // console.log("onChangeLoginMethod", event.target.value);
      this.customClaims.loginMethod = event.target.value;
    },

    cancelNewUser() {
      // console.log("reset");
      this.v$.$reset();
      // this.$emit("close");
      this.popupOpened = false;
      this.resetDisplayData();
    },

    existsUser() {
      let foundEmail = false;
      let foundPhone = false;

      if (this.customClaims.loginMethod === LOGIN_METHOD_EMAIL) {
        foundEmail = this.users.find(user => {
          return user.email === this.customClaims.displayEmail;
        });
      } else if (
        this.customClaims.loginMethod === LOGIN_METHOD_EMAIL_BY_PHONE
      ) {
        const realPhoneNumber = this.phoneNumber.replace(/\(|\)|-| /g, '');
        foundPhone = this.users.find(user => {
          return user.phoneNumber === realPhoneNumber;
        });
      }

      if (foundEmail || foundPhone) {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That user has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }

      return foundEmail || foundPhone;
    },

    getCompanyName(tenantId) {
      let arr = (tenantId || '').split('-');
      arr.pop();
      arr = arr.map(item => _.capitalize(item || ''));
      return arr.join(' ');
    },

    sendMail(uid) {
      const companyName = this.getCompanyName(this.currentUser.tenantId);

      let receiverInfo = this.userInfo(uid);
      let receiver = `${receiverInfo.displayName || ''} <${
        receiverInfo.email
      }>`;

      const id_token = localStorage.getItem('id_token');

      return axiosService
        .post('/notification/push-email', {
          to: receiver,
          subject: {
            message: 'Account Invitation',
          },
          template: 'account-invitation',
          content: {
            user_name: receiverInfo.displayName || '',
            supervisor_name: this.currentUser.displayName || '',
            company_name: companyName,
            accept_link: `${window.location.origin}/account?mode=acceptInvitation&idToken=${id_token}&uid=${uid}&tenantId=${this.currentUser.tenantId}`,
          },
          attachments: [],
          scheduleSendingTime: '',
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post('/notification/trigger-email-service', {
            sender: '',
          });
        })
        .catch(error => {
          console.error('[Send Email]', error);
          throw new Error(error.message);
        });
    },

    add() {
      this.v$.$touch();
      if (this.v$.$invalid || this.existsUser()) {
        return;
      }

      const realPhoneNumber = this.phoneNumber.replace(/\(|\)|-| /g, '');
      const newUser = {
        phoneNumber: realPhoneNumber,
        displayName: this.displayName,
        // email: this.email,
        customClaims: _.cloneDeep(this.customClaims),
        photoURL: this.photoURL,
        password: this.password,
        disabled: this.disabled,
        tenantId: localStorage.getItem('login_tenant'),
        companyName: this.setting.companyName || '',
      };

      if (this.customClaims.loginMethod === LOGIN_METHOD_EMAIL_BY_PHONE) {
        newUser.emailVerified = true;
        newUser.email = `info${realPhoneNumber}@rooferintel.com`;
      } else {
        newUser.email = this.customClaims.displayEmail;
      }

      this.$f7.preloader.show();

      return (
        this.addUser(
          newUser
          //   {
          //   phoneNumber: this.phoneNumber.replace(/\(|\)|-| /g, ""),
          //   displayName: this.displayName,
          //   email: this.email,
          //   customClaims: _.cloneDeep(this.customClaims),
          //   photoURL: this.photoURL,
          //   password: this.password,
          //   disabled: this.disabled,
          //   tenantId: localStorage.getItem("login_tenant"),
          //   companyName: this.setting.companyName || ""
          // }
        )
          .then(async uid => {
            const ref = [];
            await this.addAdminUser({
              id: uid,
              groupIDs: this.groupIDs,
              teamIDs: [],
            });
            for (const groupID of this.groupIDs) {
              ref.push(
                this.modifyRelationShip({
                  id: groupID,
                  action: 'add',
                  doc: {
                    id: uid,
                    key: 'userIDs',
                  },
                })
              );
            }

            return Promise.all([uid, ...ref]);
          })
          .then(data => {
            return this.getUserList().then(() => {
              return data[0];
            });
          })
          // .then(uid => {
          //   return this.sendMail(uid).then(() => uid);
          // })
          .then(uid => {
            // this.$emit("close");
            if (this.photoURL) {
              this.updateUser({
                uid: uid,
                user: {
                  photoURL: this.photoURL,
                  customClaims: this.customClaims,
                },
              });
            }
            this.popupOpened = false;
            this.$f7router.navigate(`/administration/users/${uid}`, {
              pushState: true,
            });
          })
          .catch(error => {
            var notificationClickToClose = this.$f7.notification.create({
              icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
              title: 'Create new user failure',
              text: error.message,
              closeOnClick: true,
              closeButton: true,
              closeTimeout: 10000,
            });
            notificationClickToClose.open();
          })
          .finally(() => {
            this.$f7.preloader.hide();
          })
      );
    },
    createNotification(userIDs, group, type) {
      this.createNotificationByType({
        data: {
          assignees: userIDs,
          project: {
            title: group.displayName,
            id: group.id,
            type: 'group',
            entityName: COLLECTION_GROUP_ADMIN,
          },
        },
        type: type,
      });
    },
    save() {
      const uid = this.uid;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      if (!this.disabled) {
        if (
          this.currentPlan.limitUsers !== null &&
          this.numberOfUsers + 1 > this.currentPlan.limitUsers
        ) {
          this.showUpgradeSubscriptionMessage();
          return;
        }
      }

      this.$f7.preloader.show();
      let before = this.userForGroup.groupIDs;
      let after = this.groupIDs;
      let remove = _.differenceWith(before, after, _.isEqual);
      let add = _.differenceWith(after, before, _.isEqual);

      const userData = {
        phoneNumber: this.phoneNumber.replace(/\(|\)|-| /g, ''),
        displayName: this.displayName,
        customClaims: _.cloneDeep(this.customClaims),
        photoURL: this.photoURL,
        disabled: this.disabled,
      };
      if (this.email) {
        userData.email = this.email;
      }

      this.updateUser({
        uid: uid,
        user: userData,
      })
        .then(async () => {
          // remove|add user - group ref
          await this.updateAdminUser({
            id: uid,
            doc: {
              groupIDs: after,
            },
          });

          for (const groupID of add) {
            const group = this.groups.find(group => group.id === groupID);
            if (group) {
              this.createNotification([uid], group, 'assign-user');
            }
            this.modifyRelationShip({
              id: groupID,
              action: 'add',
              doc: {
                id: uid,
                key: 'userIDs',
              },
            });
          }
          for (const groupID of remove) {
            const group = this.groups.find(group => group.id === groupID);
            if (group) {
              this.createNotification([uid], group, 'remove-user');
            }
            this.modifyRelationShip({
              id: groupID,
              action: 'remove',
              doc: {
                id: uid,
                key: 'userIDs',
              },
            });
          }

          this.getUserList().then(() => {
            this.popupOpened = false;
            // this.$emit("close");
            // this.$f7.preloader.hide();
          });
        })
        .catch(error => {
          var notificationClickToClose = this.$f7.notification.create({
            icon: "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
            title: 'Update failure',
            text: error.message,
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 10000,
          });
          notificationClickToClose.open();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    showUpgradeSubscriptionMessage() {
      this.$ri.dialog.openWarningDialog({
        title: 'Upgrade your subscription',
        content:
          'You cannot activate this account with current plan. Please go to subscription to upgrade to higher plan',
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.popupOpened = false;
            this.$f7router.navigate(`/administration/subscription`, {
              pushState: true,
              reloadAll: true,
            });
          }
        },
      });
    },

    mergerSelect(array) {
      if (array && array.length > 0) {
        return (array || []).map(item => {
          return item.id ? item.id : item;
        });
      }
    },

    formatPhone(val) {
      let numberFormat = (val || '').slice(2);
      if (numberFormat) {
        return (
          '(+1) ' +
          numberFormat.substring(0, 3) +
          '-' +
          numberFormat.substring(3, 6) +
          '-' +
          numberFormat.substring(6)
        );
      }
      return '';
    },

    resetDisplayData() {
      this.uid = '';
      this.groupIDs = [];
      this.customClaims = {
        role: 'user',
        payType: '',
        groupIDs: [],
        qbUser: {},
        homeUrl: '',
        allowReceiveNotification: false,
        loginMethod: LOGIN_METHOD_EMAIL,
        displayEmail: '',
      };
      this.displayName = '';
      this.disabled = false;
      this.password = '';
      this.email = '';
      this.phoneNumber = '';
      this.photoURL = '';
    },

    changeQBUser(id) {
      let user = this.qbUserList.find(item => item.Id === id) || {};
      let customClaims = _.cloneDeep(this.customClaims);
      customClaims.qbUser = user;
      this.customClaims = customClaims;
    },

    showErrorMessage(messages) {
      this.$ri.dialog.openSuccessDialog({
        title: 'Connect to Quickbooks Online',
        content: messages,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            window.location.href = '/administration/users';
          }
        },
      });
    },

    loginQB() {
      this.$ri.dialog.openInfoDialog({
        title: 'Connect to Quickbooks Online',
        content:
          'You need to set up a connection between RooferIntel and Quickbook. It will take about 5 minutes. Do you want to proceed now?',
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            quickbooksService.getAuthUri('qb-contact/export').then(uri => {
              window.location.href = uri;
            });
          }
        },
      });
    },

    handlePopupOpened() {
      // console.log("handlePopupOpened");

      this.resetDisplayData();

      if (!_.isEmpty(this.user) && !this.isNew) {
        // console.log("bind user");
        this.uid = this.user.uid;
        this.phoneNumber = this.formatPhone(this.user.phoneNumber);
        this.displayName = this.user.displayName;
        this.email = this.user.email;
        this.customClaims = {
          ...this.user.customClaims,
          payType: this.user.customClaims.payType,
          homeUrl: this.user.customClaims.homeUrl,
          allowReceiveNotification:
            this.user.customClaims.allowReceiveNotification,
        };
        this.groupIDs = this.userForGroup.groupIDs;
        this.photoURL = this.user.photoURL;
        this.disabled = this.user.disabled;
      }

      if (_.isEmpty(this.setting)) {
        this.bindSetting(this.tenantId);
      }

      if (this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }

      this.$f7.preloader.hide();
    },

    openPopup({ isNew }) {
      this.$f7.preloader.show();
      this.isNew = isNew;
      this.popupOpened = true;
    },
  },
};
</script>
<style scoped lang="scss">
.error-message {
  font-size: 12px;
  color: var(--f7-theme-color);
}
</style>
