<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Team Management</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="isShowTeamDetailPopup = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-searchbar
      v-if="isFirstLoading === false && firstData.length > 0"
      ref="searchList"
      disable-button-text
      placeholder="Search Team List"
      :clear-button="true"
      class="search-list"
      :value="searchText"
      @input="
        setSearchText($event.target.value);
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:clear="
        setSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setSearchText('');
        onSearch();
      "
      @blur="
        if (!searchText.trim()) {
          setSearchText('');
        }
      "
    ></f7-searchbar>
    <div
      v-show="isFirstLoading === false && firstData.length > 0"
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list class="no-margin">
        <f7-list-group
          v-for="item in dataTeamList"
          :key="item.key"
          media-list
        >
          <f7-list-item
            :title="item.key"
            group-title
          ></f7-list-item>
          <f7-list-item
            v-for="item in item.data"
            :key="item.id"
            :title="item.teamName"
            :subtitle="`Number of members: ${item.totalUserIDs}`"
            reloadDetail
            :link="`/team-management/${item.id}`"
            :text-color="item.id === (team || {}).id ? 'primary' : 'default'"
          >
            <div slot="text">
              <div>Manager: {{ item.managerName }}</div>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(item.disabled).text"
              :color="onlineClass(item.disabled).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>

    <!-- not access -->
    <template v-if="firstData.length < 1 && isFirstLoading === false">
      <f7-block strong>
        <p>Only Managers and Owners can see these team list</p>
      </f7-block>
    </template>
    <team-detail-popup
      :isShow="isShowTeamDetailPopup"
      :isNew="true"
      @close="isShowTeamDetailPopup = false"
    ></team-detail-popup>
  </f7-page>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { groupBy } from '@/utility/filter-tools';
import TeamDetailPopup from '@/plugins/time-tracking/components/popups/TeamDetailPopup.vue';
import { auth } from '../../../services/firebase.service';
import Menu from '../../../components/menu/Menu.vue';
import _ from 'lodash';

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
export default {
  components: {
    TeamDetailPopup,
    Menu,
  },
  data() {
    return {
      firstData: [],
      isFirstLoading: false,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,

      isShowTeamDetailPopup: false,
    };
  },

  methods: {
    ...mapActions('common/team-management', [
      'searchTeam',
      'loadMoreTeam',
      'resetSearch',
      'unbindTeam',
      'setSearchText',
      'setQueryFilters',
    ]),
    ...mapActions('time-tracking/user', ['getUserList', 'getUserNotCommit']),
    onlineClass(disabled) {
      return disabled
        ? { color: '', text: 'Inactive' }
        : { color: 'green', text: 'Active' };
    },

    afterLoadTeamList() {
      this.firstData = _.cloneDeep(this.hits);
      this.isFirstLoading = false;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$refs.searchList &&
            this.$el.querySelector('.search-list.searchbar input').focus();
        }
      });
    },

    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function () {
      const self = this;
      return self
        .searchTeam({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
          if (this.isFirstLoading) this.afterLoadTeamList();
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreTeam({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
  },

  computed: {
    ...mapGetters('common/team-management', [
      'team',
      'hits',
      'nbPages',
      'page',
      'searchText',
    ]),
    ...mapGetters('time-tracking/user', ['userList']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),

    dataTeamList() {
      const userIds = this.userList.map(r => r.uid);
      const teamList = this.hits.map(team => ({
        ...team,
        totalUserIDs: team.userIDs.filter(r => userIds.includes(r)).length || 0,
      }));

      let dataGroup = groupBy(teamList, item =>
        ((item.teamName || '').charAt(0) || '').toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data,
      }));
    },
  },

  created() {
    this.$f7.preloader.show();
    this.isFirstLoading = true;

    Promise.all([
      this.getUserList(),
      this.getUserNotCommit(auth.currentUser.uid),
    ])
      .then(res => {
        const user = res[1];
        if (
          this.isAdminGroup ||
          (user.customClaims && user.customClaims.role === 'owner')
        ) {
          this.onSearch();
        } else {
          this.setQueryFilters(`managerID:${auth.currentUser.uid}`);
          this.onSearch();
        }
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  beforeDestroy() {
    this.resetSearch();
    this.unbindTeam();
  },
};
</script>
