<template>
  <f7-popup
    :opened="isShow"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Service Job</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block-title>Customer Type</f7-block-title>
      <f7-list>
        <f7-list-item
          radio
          radio-icon="start"
          title="Commercial"
          name="demo-radio-end"
          :checked="object.customerType === 'commercial'"
          value="commercial"
          @change="onCustomerTypeChange"
        ></f7-list-item>
        <f7-list-item
          radio
          radio-icon="start"
          title="Residential"
          name="demo-radio-end"
          :checked="object.customerType === 'residential'"
          value="residential"
          @change="onCustomerTypeChange"
        ></f7-list-item>
        <f7-list-item
          radio
          radio-icon="start"
          title="Multi Family"
          name="demo-radio-end"
          :checked="object.customerType === 'multifamily'"
          value="multifamily"
          @change="onCustomerTypeChange"
        ></f7-list-item>
      </f7-list>
      <!-- Contact -->
      <div v-if="object.customerType === 'commercial'">
        <f7-block-title>Contact Information </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <contact-name-input
            media-list
            :displayCard="object"
            :errorMessage="requiredErrorMessage('contactId')"
            :contactName="contactName"
            ref="selectContact"
            :companyId="company.id"
            :queryFilters="`${
              company.contactRefs &&
              company.contactRefs.length &&
              object.customerType === 'commercial'
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handleSelectedContact"
            @onDeleteContact="onDeleteContact($event)"
            :autoFocus="true"
            :tabIndex="1"
            :isAddNew="false"
          ></contact-name-input>

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in (contact || {}).phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in (contact || {}).emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in (contact || {}).addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>
        <f7-list media-list>
          <!-- Company  -->
          <company-name-input
            media-list
            :displayCard="object"
            :errorMessage="
              selectCompanyMessage || requiredErrorMessage('companyId')
            "
            :companyName="companyName"
            ref="selectCompany"
            @onSelected="handleSelectedCompany"
            :contactId="contact.id"
            :queryFilters="`${
              contact.companyRefs &&
              contact.companyRefs.length &&
              object.customerType === 'commercial'
                ? `contactIdRefs:${contact.id} AND`
                : ''
            } NOT companyTypes:${COMPANY_TYPE_VENDOR}`"
            @onDeleteCompany="onDeleteCompany($event)"
            :tabIndex="2"
            :isAddNew="false"
          />

          <!--Company Phone -->
          <f7-list-item
            v-for="(phone, index) in (company || {}).phones"
            :key="`company-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Company Address -->
          <f7-list-item
            v-for="(address, index) in (company || {}).addresses"
            :key="`company-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>
        <f7-list media-list>
          <!-- property -->
          <property-name-input
            media-list
            :displayCard="object"
            :errorMessage="
              selectPropertyMessage || requiredErrorMessage('propertyId')
            "
            :propertyName="propertyName"
            ref="selectProperty"
            @onSelected="handleSelectedProperty"
            :companyId="object.companyId"
            :queryFilters="`${
              company.propertyRefs && company.propertyRefs.length
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onDeleteProperty="onDeleteProperty($event)"
            :tabIndex="3"
            :isAddNew="false"
          />

          <!--Property Phone -->
          <f7-list-item
            v-for="(phone, index) in (property || {}).phones"
            :key="`property-phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!--Property Address -->
          <f7-list-item
            v-for="(address, index) in (property || {}).addresses"
            :key="`property-address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>
            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>
      </div>
      <div v-if="object.customerType === 'residential'">
        <!-- Contact -->
        <f7-block-title class="display-flex justify-content-space-between">
          Contact Information
        </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <contact-name-input
            media-list
            :displayCard="object"
            :errorMessage="requiredErrorMessage('contactId')"
            :contactName="contactName"
            ref="selectContact"
            :companyId="company.id"
            :queryFilters="`${
              company.contactRefs &&
              company.contactRefs.length &&
              object.customerType === 'commercial'
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handleSelectedContact"
            @onDeleteContact="onDeleteContact($event)"
            :autoFocus="true"
            :tabIndex="1"
            :isAddNew="false"
          ></contact-name-input>

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in (contact || {}).phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in (contact || {}).emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in (contact || {}).addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <address-input-residential
          ref="addressInput"
          :address="object.projectAddress"
          :addressesSuggestion="contact.addresses || []"
          @input="onInputAddress($event)"
          @select="onSelectAddress($event)"
        ></address-input-residential>
      </div>
      <div v-if="object.customerType === 'multifamily'">
        <!-- Contact -->
        <f7-block-title class="display-flex justify-content-space-between">
          Contact Information
        </f7-block-title>
        <f7-list media-list>
          <!-- Contact -->
          <contact-name-input
            media-list
            :displayCard="object"
            :errorMessage="requiredErrorMessage('contactId')"
            :contactName="contactName"
            ref="selectContact"
            :companyId="company.id"
            :queryFilters="`${
              company.contactRefs &&
              company.contactRefs.length &&
              object.customerType === 'commercial'
                ? `companyIdRefs:${company.id}`
                : ''
            }`"
            @onSelected="handleSelectedContact"
            @onDeleteContact="onDeleteContact($event)"
            :autoFocus="true"
            :tabIndex="1"
            :isAddNew="false"
          ></contact-name-input>

          <!-- Phone -->
          <f7-list-item
            v-for="(phone, index) in (contact || {}).phones"
            :key="`phone-${index}`"
          >
            <div slot="header">{{ phone.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ phone.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="phone"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'tel:' + phone.value"
                icon-f7="phone_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Email -->
          <f7-list-item
            v-for="(email, index) in (contact || {}).emails"
            :key="`email-${index}`"
          >
            <div slot="header">{{ email.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ email.value }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="email"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="'mailto:' + email.value"
                icon-f7="envelope_circle_fill"
              ></f7-link>
            </div>
          </f7-list-item>

          <!-- Address -->
          <f7-list-item
            v-for="(address, index) in (contact || {}).addresses"
            :key="`address-${index}`"
          >
            <div slot="header">{{ address.code }}</div>
            <div
              class="list-item-title"
              slot="title"
            >
              {{ getFullAddress(address) }}
            </div>
            <f7-icon
              slot="media"
              color="primary"
              material="map_fill"
            ></f7-icon>

            <div slot="after-title">
              <f7-link
                class="external icon-link"
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"
                icon-f7="placemark_fill"
              ></f7-link>
            </div>
          </f7-list-item>
        </f7-list>

        <address-input-residential
          ref="addressInput"
          :address="object.projectAddress"
          :addressesSuggestion="contact.addresses || []"
          @input="onInputAddress($event)"
          @select="onSelectAddress($event)"
        ></address-input-residential>
      </div>
      <!-- Job Info -->
      <f7-block-title>Job Information</f7-block-title>
      <f7-list>
        <!-- Title -->
        <f7-list-input
          class="first-input"
          label="Title"
          type="text"
          placeholder="Card title"
          clear-button
          :value="object.title"
          @input="changeCardValue('title', $event.target.value)"
          error-message-force
          :error-message="requiredErrorMessage('title')"
          :tabindex="4"
        >
          <required-asterisk slot="label"></required-asterisk>
          <f7-icon
            color="primary"
            material="title"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Desc -->
        <f7-list-input
          label="Description"
          type="textarea"
          placeholder="Description"
          :value="object.description"
          @input="$event => changeCardValue('description', $event.target.value)"
          :tabindex="5"
        >
          <f7-icon
            color="primary"
            material="description"
            slot="media"
          ></f7-icon>
        </f7-list-input>

        <!-- Due Date -->
        <f7-list-input
          label="Due Date"
          type="datepicker"
          placeholder="MM/DD/YYYY"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
            },
          }"
          :value="object.dueDate"
          @calendar:change="
            changeCardValue('dueDate', $event);
            $f7.calendar.close();
          "
          :tabindex="6"
        >
          <f7-icon
            color="primary"
            slot="media"
            f7="timer"
          ></f7-icon>
        </f7-list-input>
      </f7-list>

      <f7-block-title>Notes</f7-block-title>
      <f7-list media-list>
        <f7-list-input
          placeholder="Enter note..."
          type="texteditor"
          resizable
          clear-button
          :value="
            object.noteList &&
            object.noteList[0] &&
            object.noteList[0].htmlContent
          "
          @texteditor:change="value => changeCardValue('noteList', value)"
        >
          <input-icon
            slot="media"
            icon="doc_text"
          ></input-icon>
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { firebase, auth } from '../../../../services/firebase.service';
import InputIcon from '../../../../components/icons/InputIcon.vue';

import AddressInputResidential from '@/components/inputs/AddressInputResidential.vue';
import { mapGetters, mapActions } from 'vuex';
import { toDateFirebase } from '../../../../utility/datetime';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import commonMixin from '../../mixins/common';
import { getFullAddress } from '@/utility/address';
import {
  BUSINESS_CODE_SERVICE,
  COMPANY_TYPE_VENDOR,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import ContactNameInput from '@/components/inputs/ContactNameInput.vue';
import CompanyNameInput from '@/components/inputs/CompanyNameInput.vue';
import PropertyNameInput from '@/components/inputs/PropertyNameInput.vue';

export default {
  props: {
    isShow: Boolean,
  },

  components: {
    InputIcon,
    AddressInputResidential,
    ContactNameInput,
    CompanyNameInput,
    PropertyNameInput,
  },
  mixins: [commonMixin],

  data: () => {
    return {
      object: {
        contactId: null,
        companyId: null,
        propertyId: null,
        title: '',
        description: '',
        dueDate: [],
        noteList: [],
        customerType: 'commercial',
        projectAddress: {
          address: '',
          city: '',
          state: '',
          zipcode: '',
          country: '',
        },
      },
      firstSelectedField: '',

      selectCompanyMessage: '',
      selectPropertyMessage: '',
      COMPANY_TYPE_VENDOR,
    };
  },

  computed: {
    ...mapGetters('scheduling/client', ['contact', 'company', 'property']),

    contactName() {
      return this.getContactName(this.contact);
    },

    companyName() {
      return this.company ? this.company.companyName || '' : '';
    },

    propertyName() {
      return this.getPropertyName(this.property);
    },
    propertyAddress() {
      const mainAddress = (this.property.addresses || []).find(
        r => r.code === 'main'
      );
      const firstAddress =
        this.property.addresses && this.property.addresses[0];
      const address = mainAddress || firstAddress || {};
      return address;
    },
    requiredErrorMessage() {
      return prop => {
        if (!this.v$.object[prop].$error) return null;
        if (this.v$.object[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    /**
     * Check for this company has property or not
     */
    hasPropertyRefs() {
      return this.company.propertyRefs && this.company.propertyRefs.length > 0;
    },
  },

  methods: {
    ...mapActions('scheduling/client', [
      'getContact',
      'getCompany',
      'getProperty',
      'setContact',
      'setCompany',
      'setProperty',
    ]),
    ...mapActions({
      getBoardBys: 'scheduling/board/getBys',
      getColumnBys: 'scheduling/column/getBys',
    }),
    ...mapActions('scheduling/project', ['createProject', 'getCardInColumn']),

    cancel() {
      this.object = {
        contactId: null,
        companyId: null,
        propertyId: null,
        title: '',
        description: '',
        dueDate: [],
        noteList: [],
        customerType: 'commercial',
        projectAddress: {
          address: '',
          city: '',
          state: '',
          zipcode: '',
          country: '',
        },
      };
      this.selectCompanyMessage = '';
      this.selectPropertyMessage = '';
      this.$nextTick(() => {
        if (this.$refs.addressInput) {
          this.$refs.addressInput.clearData();
        }
      });

      this.$refs.addressInput &&
        this.$refs.addressInput.v$ &&
        this.$refs.addressInput.v$.$reset();

      this.v$.$reset();
      this.setContact({});
      this.setCompany({});
      this.setProperty({});
      this.$emit('close');
    },
    navigateToSelectCompany() {
      this.$refs.selectCompany.open();
    },

    navigateToSelectProperty() {
      this.$refs.selectProperty.open();
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    async handleSelectedContact(contactId) {
      if (contactId) {
        await this.getContact(contactId);
      } else {
        await this.setContact({});
      }
      this.changeCardValue('contactId', contactId);
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'contact';
      }
      // this.changeProjectTitle();
      this.$refs.selectCompany.autoFillCompany();
    },

    async handleSelectedCompany(companyId) {
      this.changeCardValue('companyId', companyId);
      if (!companyId) await this.setCompany({});
      else await this.getCompany(companyId);
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'company';
      }
      if (this.newProperty) {
        await this.addPropertyCompanyRelationShip({
          propertyId: this.object.propertyId,
          companyId: id,
        });
      } else {
        this.$refs.selectProperty.autoFillProperty();
      }

      if (_.isEmpty(this.contact)) {
        this.$refs.selectContact.autoFillContact();
      }
      if (this.newContact === true && id && this.card.contactId) {
        await this.addCompanyContactRelationShip({
          companyId: id,
          contactId: this.card.contactId,
        });
      }
    },

    async handleSelectedProperty(propertyId) {
      this.changeCardValue('propertyId', propertyId);
      if (!propertyId) await this.setProperty({});
      else await this.getProperty(propertyId);
      if (!this.object.companyId) {
        this.getCompanyAndContactByProperty();
      }
      this.changeJobTitle();
      if (this.firstSelectedField === '') {
        this.firstSelectedField = 'property';
      }
    },

    async changeJobTitle() {
      const jobTitle = await this.getJobTitle(this.object);
      this.changeCardValue('title', jobTitle);
    },

    async onDeleteContact(event) {
      event && event.stopPropagation();
      await this.setContact({});
      this.changeCardValue('contactId', '');

      if (this.firstSelectedField === 'contact') {
        await this.onDeleteCompany();
        await this.onDeleteProperty();

        this.onDeleteProperty();
        this.firstSelectedField = '';
      }
      this.$refs.selectContact.setQueryFilters('');
    },
    async onDeleteCompany(event) {
      event && event.stopPropagation();

      await this.setCompany({});
      this.changeCardValue('companyId', '');
      if (this.firstSelectedField === 'company') {
        await this.onDeleteContact('');
        await this.onDeleteProperty('');

        this.onDeleteProperty();
        this.firstSelectedField = '';
      }
      this.$refs.selectCompany.setQueryFilters('');
    },
    changeCardValue(field, value) {
      if (field === 'noteList') {
        const noteList = [
          {
            title: 'Note',
            code: 'note',
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email,
            createdById: auth.currentUser.uid,
            htmlContent: value,
          },
        ];
        this.object[field] = noteList;
      } else {
        this.object[field] = value;
      }
    },

    async add() {
      this.v$.$touch();
      if (this.invalid()) return;

      this.$f7.preloader.show();
      let actionsHistory = [];
      actionsHistory.push({
        nextAction: 'Job Created',
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: '',
      });
      let boardId = await this.getDefaultBoard();
      let columnId = await this.getDefaultColunm(boardId);
      const cardsInColumn = await this.getCardInColumn(columnId);
      const minPriorityInColumn =
        Math.min(...[...cardsInColumn.map(r => r.priority), 0]) || 0;

      let data = {
        ...this.object,
        dueDate: toDateFirebase(this.object.dueDate),
        boardId,
        columnId,
        businessCode: BUSINESS_CODE_SERVICE,
        status: 'open',
        isDraft: false,
        actionsHistory,
        contactName: this.contactName,
        priority: minPriorityInColumn - 1000,
      };

      if (data.customerType === 'commercial') {
        data.companyName = this.companyName;
        data.propertyName = this.propertyName;
        data.propertyAddress = this.propertyAddress;
      }

      this.createProject(data).then(dataPrj => {
        this.cancel();
        this.$emit('onCreated', dataPrj.id);
        this.$f7.preloader.hide();
      });
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.first-input input').focus();
      }
    },
    onCustomerTypeChange(event) {
      this.changeCardValue('customerType', event.target.value);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },
    async getDefaultBoard() {
      let docs = await this.getBoardBys([
        {
          prop: 'businessCode',
          op: '==',
          val: BUSINESS_CODE_SERVICE,
        },
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
        {
          prop: 'default',
          op: '==',
          val: true,
        },
      ]);
      if (docs.length > 0) {
        return docs[0].id;
      }
    },

    async getDefaultColunm(boardId) {
      let docs = await this.getColumnBys([
        {
          prop: 'boardId',
          op: '==',
          val: boardId,
        },
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
      ]);
      if (docs.length > 0) {
        docs.sort((a, b) => a.priority - b.priority);
        return docs[0].id;
      }
    },
    invalid() {
      this.v$.$touch();
      if (
        ['residential', 'multifamily'].includes(this.object.customerType) &&
        !_.isEmpty(this.$refs.addressInput)
      ) {
        this.$refs.addressInput.v$.$touch();
        if (this.$refs.addressInput.v$.$invalid) {
          return true;
        }
      }
      return this.v$.$invalid;
    },

    onInputAddress({ prop, value }) {
      this.object.projectAddress[prop] = value;
      this.changeJobTitle();
    },

    onSelectAddress(address) {
      this.changeCardValue('projectAddress', address);
      this.changeJobTitle();
    },

    async getCompanyAndContactByProperty() {
      // check if has company then: get first company, catch: return
      if (this.property.companyIdRefs?.length) {
        const companyId = this.property.companyIdRefs[0];
        await this.getCompany(companyId);
        this.object.companyId = companyId;

        // if company has contact, get firt contact, else return
        if (this.company.contactIdRefs?.length && !this.object.contactId) {
          const contactId = this.company.contactIdRefs[0];
          await this.getContact(contactId);
          this.object.contactId = contactId;
        }
      }
    },

    async onDeleteProperty(event) {
      event && event.stopPropagation();

      await this.setProperty({});
      this.object.propertyId = '';
      this.object.title = '';

      if (this.firstSelectedField === 'property') {
        await this.onDeleteContact('');
        await this.onDeleteCompany('');
        this.firstSelectedField = '';
      }
      this.$refs.selectProperty.setQueryFilters('');
    },
  },

  watch: {
    object: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != '') {
          this.selectCompanyMessage = '';
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ''
        ) {
          this.selectPropertyMessage = '';
        }
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      object: {
        title: {
          required,
        },
        contactId: {
          required,
        },
        companyId: {
          required(val) {
            if (this.object.customerType !== 'commercial') return true;
            return !!val;
          },
        },
        propertyId: {
          required(val) {
            if (this.object.customerType !== 'commercial') return true;
            return !!val;
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-title {
  flex: 1;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  line-height: 26px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
