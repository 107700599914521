import appConstant from './app-constant';
import priceList from './price-list';
import purchaseOrder from './purchase-order';
import activity from './activity';
import project from './project';
import vendor from './vendor';
import estimate from './estimate';
import productItem from './product-item';
import property from './property';
import common from './common';
import group from './group';
import _ from 'lodash';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      'app-constant': appConstant(tenant),
      'purchase-order': purchaseOrder(tenant),
      'purchase-order-grid-page': {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          'purchase-order': _.cloneDeep(purchaseOrder(tenant)),
        },
      },
      'purchase-order-details-page': {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          'price-list': _.cloneDeep(priceList(tenant)),
          'purchase-order': _.cloneDeep(purchaseOrder(tenant)),
          activity: _.cloneDeep(activity(tenant)),
          project: _.cloneDeep(project(tenant)),
          vendor: _.cloneDeep(vendor(tenant)),
          estimate: _.cloneDeep(estimate(tenant)),
          'product-item': _.cloneDeep(productItem(tenant)),
          property: _.cloneDeep(property(tenant)),
          group: group(tenant),
        },
      },
      'purchase-order-list-page': {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          'purchase-order': _.cloneDeep(purchaseOrder(tenant)),
        },
      },
    },
  };
};
