<template>
  <f7-fab
    :class="`delete-tooltip-${uuid}`"
    position="right-bottom"
    slot="fixed"
    color="default"
    @click="handleDetele"
  >
    <f7-icon f7="trash"></f7-icon>
  </f7-fab>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';

export default {
  data: () => ({
    uuid: uuid.v4(),
  }),
  mounted() {
    this.createTooltip();
  },
  props: {
    selectedIds: Array,
  },
  methods: {
    ...mapActions('swimlane/photo', ['deleteProjectPhoto', 'removePhoto']),
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: `.delete-tooltip-${this.uuid}`,
        cssClass: 'tooltip-fab-button-photo',
        text: 'Delete photo/video',
      });
    },
    handleDetele() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: `Delete 
            ${app.selectedIds.length === 1 ? 'photo/video' : 'photos/videos'}
            `,
        content: `Are you sure you want to delete ?`,
        textButton: 'Delete',
        hideCancelButton: false,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const photos = app.projectPhotoSelected(app.selectedIds);
            photos.forEach(i => {
              if (i.thumbnailFullPath == i.photoFullPath) {
                app.removePhoto(i.photoFullPath);
              } else {
                if (i.thumbnailFullPath) app.removePhoto(i.thumbnailFullPath);
                if (i.photoFullPath) app.removePhoto(i.photoFullPath);
              }
            });
            app.selectedIds.forEach(i => {
              app.deleteProjectPhoto(i);
            });
            _sefl.app.dialog.close();
            app.$emit('cancelSelect');
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters('swimlane/photo', ['projectPhotoSelected']),
  },
};
</script>
