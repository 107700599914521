export default {
  objectList: [],
  objectSubscribe: null,
  viewport: {
    startDate: null,
    endDate: null,
  },
  showTimerPopup: false,
  detailUid: null,
  teamFilter: [],
  statusFilter: [],
  timeLogTypeFilter: [],
  projectFilter: null,
  isMyTimeLog: false,
  currentWeek: [],
  timeLogInfoAvailable: {},
  monthYearFilter: {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  },
};
