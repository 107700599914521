import axiosService from './axios.service';

const pushNotification = async result => {
  try {
    return await axiosService.post('/notification/push-notification', {
      to: result.to,
      content: result.message,
    });
  } catch (error) {
     
    console.error('[Send Notification]', error);
    throw new Error(error.message);
  }
};

const getAuthUri = async ({tenantId, redirectPath}) => {
  try {
    const result = await axiosService.get(`/notification/generate-uri?redirectPath=${redirectPath}&tenantId=${tenantId}`);
    if(result.data.success && result.data.code === 100){
      return result.data.data;
    }
    return null;
  } catch (error) {
    throw new Error(error.message);
  }
}

const setRefreshToken = async ({tenantId, code, redirectPath}) => {
  try {
    return await axiosService.post(`/notification/set-code`, {tenantId, code, redirectPath});
  } catch (error) {
    throw new Error(error.message);
  }
}

const checkRefreshToken = async ({tenantId}) => {
  try {
    return await axiosService.get(`/notification/check-info?tenantId=${tenantId}`);
  } catch (error) {
    throw new Error(error.message);
  }
}

const revokeRefreshToken = async ({tenantId}) => {
  try {
    return await axiosService.get(`/notification/revoke?tenantId=${tenantId}`);
  } catch (error) { 
    throw new Error(error.message);
  }
}

export default {
  pushNotification,
  getAuthUri,
  setRefreshToken,
  checkRefreshToken,
  revokeRefreshToken
};
