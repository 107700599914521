<template>
  <div
    class="no-padding-top page-content infinite-scroll-content"
    style="overflow: auto"
    @infinite="loadMore"
  >
    <div>
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in hits"
            :title="item.proposalNumber"
            :key="item.id"
            :reload-detail="true"
            swipeout
            link
            :text-color="
              item.proposalNumber === proposalNumber ? 'primary' : 'default'
            "
            @click.native="checkBeforeNavigation(item)"
          >
            <f7-swipeout-actions right>
              <f7-swipeout-button
                :close="true"
                color="blue"
                @click.stop="copyProposal(item.id)"
              >
                <span>Copy</span>
              </f7-swipeout-button>
              <f7-swipeout-button
                v-if="
                  item.status !== 'signed' &&
                  item.status !== 'sent' &&
                  item.status !== 'viewed'
                "
                :close="true"
                color="red"
                @click.stop="openConfirmDelete(item.id)"
                >Delete {{ item.signatureAt }}</f7-swipeout-button
              >
            </f7-swipeout-actions>
            <div slot="footer">
              <span>Created on: </span>
              <timeago
                v-if="item.createdAt"
                :datetime="convertDateTime(item.createdAt)"
                :autoUpdate="1"
                :converterOptions="{ includeSeconds: true }"
              />
            </div>
            <div slot="text">
              <div class="item-text-slot">
                Project: {{ item.projectName || '' }}
              </div>
              <template
                v-if="
                  item.businessCode === BUSINESS_CODE_COMMERCIAL ||
                  (item.businessCode === BUSINESS_CODE_SERVICE &&
                    item.customerType === BUSINESS_CODE_COMMERCIAL)
                "
              >
                <span class="item-text-slot">
                  Property:
                  {{ item.propertyName || '' }}
                </span>
              </template>
              <template
                v-else-if="
                  item.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                  (item.businessCode === BUSINESS_CODE_SERVICE &&
                    item.customerType === BUSINESS_CODE_RESIDENTIAL)
                "
              >
                <span class="item-text-slot">
                  Contact:
                  {{ item.contactName || '' }}
                </span>
              </template>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(item.status).text"
              :color="onlineClass(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  COLLECTION_PROPOSAL,
} from '../../../../utility/const';
import { clearSelectionHightLight } from '@/utility/common';

export default {
  data: () => {
    return {
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      itemCopyproposal: null,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
      selectedStatuses: [],
    };
  },

  methods: {
    ...mapActions('proposal/proposal', [
      'bindFinancingProposalDefaultList',
      'searchProposal',
      'loadMoreProposal',
      'resetSearch',
      'createNewProposal',
      'getProposalById',
      'deleteProposal',
      'setProposal',
      'updateProposal',
      'setSelectedStatusFilter',
    ]),
    ...mapActions('proposal/template', [
      'bindProposalTemplateListBy',
      'getProposalTemplateById',
    ]),
    ...mapActions('proposal/history', ['createHistory']),
    ...mapActions({
      getSetting: 'setting/app/system/getSetting',
    }),
    ...mapActions('common/notification', ['createNotificationByType']),

    dateToValue(a) {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    },
    convertDateTime(createdAt) {
      return moment(new Date(this.dateToValue(createdAt))).toDate();
    },
    init() {
      const refs = [];
      if (!this.hasMoreItems) {
        this.$f7.preloader.show();
      }

      if (_.isEmpty(this.proposalTemplateList)) {
        refs.push(
          this.bindProposalTemplateListBy({
            prop: 'status',
            val: 'active',
            op: '==',
          })
        );
      }
      if (_.isEmpty(this.financingProposalDefaultList)) {
        refs.push(this.bindFinancingProposalDefaultList());
      }
      refs.push(this.onSearch());
      Promise.all(refs).then(() => {
        if (!this.hasMoreItems) {
          this.$f7.preloader.hide();
        }
      });
    },
    onSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchProposal({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    },
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreProposal({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    async openConfirmDelete(proposalID) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete Proposal',
        content: 'Are you sure you want to to delete the Proposal?',
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            app.deleteProposal(proposalID).then(async () => {
              const proposal = await this.getProposalById(proposalID);
              await app.createNotificationByType({
                data: {
                  assignees: [proposal.createdById],
                  project: {
                    title: proposal.proposalNumber,
                    id: proposal.id,
                    entityName: COLLECTION_PROPOSAL,
                  },
                },
                type: 'delete-proposal',
              });
              app.setProposal({});
              app.$f7router.updateCurrentUrl('/proposal');
              app.$f7.preloader.hide();
            });
          }
        },
      });
    },

    onlineClass(status) {
      return status === 'sent'
        ? { color: 'green', text: 'Sent' }
        : status === 'withdraw'
          ? { color: '', text: 'Withdrawn' }
          : status === 'viewed'
            ? { color: 'pink', text: 'Viewed' }
            : status === 'signed'
              ? { color: 'blue', text: 'Signed' }
              : { color: 'yellow', text: 'Draft' };
    },
    templateOnlineClass(status) {
      return status === 'active'
        ? { color: 'green', text: 'Active' }
        : { color: '', text: 'Inactive' };
    },

    toDetail(item) {
      if (!_.isEmpty(item)) {
        return `/proposal/${item.proposalNumber}`;
      } else {
        return '#';
      }
    },
    async getSettingDoc() {
      const response = await this.getSetting(this.currentUser.tenantId);
      return { success: true, data: response };
    },
    async copyProposal(proposalId) {
      // get proposal by id
      const proposal = await this.getProposalById(proposalId);
      this.$ri.dialog.openInfoDialog({
        title: 'Copy proposal',
        content: 'Are you sure you want to copy the proposal?',
        textButton: 'Confirm',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            let doc = _.cloneDeep(proposal);
            doc = {
              ...doc,
              copyFromId: doc.id,
              status: 'draft',
              id: null,
            };
            if (doc.ownerSigner || doc.companyRepresentativeSigner) {
              const proposalTemplate = await this.getProposalTemplateById(
                doc.proposalTemplateId
              );
              const indexOfSignatureSection = doc.sections.findIndex(
                r => r.sectionId === 'signature-section'
              );
              // get org signature section in template
              const signatureTemp =
                proposalTemplate &&
                proposalTemplate.sections.find(
                  section => section.sectionId === 'signature-section'
                );
              const responseGetSetting = await this.getSettingDoc();
              signatureTemp.htmlValue = signatureTemp.htmlValue.replace(
                '<span style="background-color: #f05034;">[Roofing Company Name]</span>',
                responseGetSetting.data.companyName || ''
              );
              if (indexOfSignatureSection > -1) {
                doc.sections.splice(indexOfSignatureSection, 1, {
                  ...signatureTemp,
                  index: indexOfSignatureSection,
                });
              }
            }
            doc.updatedAt && delete doc.updatedAt;
            doc.updatedBy && delete doc.updatedBy;
            doc.signatureImageUrl && delete doc.signatureImageUrl;
            doc.signatureImageFullPath && delete doc.signatureImageFullPath;
            doc.companyRepresentativeSigner &&
              delete doc.companyRepresentativeSigner;
            doc.ownerSigner && delete doc.ownerSigner;
            doc.fullNameOfSigner && delete doc.fullNameOfSigner;
            const proposalCreated = await this.createNewProposal(doc);

            // save history
            this.createHistory({
              proposalId: proposalCreated.id,
              note: 'Created',
            });

            this.$f7router.navigate(`/proposal/${proposalCreated.docNumber}`, {
              pushState: true,
            });
            this.$f7.preloader.hide();
          }
        },
      });
    },

    async checkBeforeNavigation(item) {
      clearSelectionHightLight();
      // console.log('checkBeforeNavigation IN');

      if(typeof this.saveFunc === 'function') {
        this.$f7.preloader.show();
        await this.saveFunc(null, false)
        this.$f7.preloader.hide();
        // console.log('checkBeforeNavigation save DONE');
      }

      let link = '#';
      if (!_.isEmpty(item)) {
        link = `/proposal/${item.proposalNumber}`;
      }
      this.$f7router.navigate(link, { animate: false, pushState: true });
      // console.log('checkBeforeNavigation OUT');
    },

    filterByStatus(checked, status) {
      // update checked status for statusFilter
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    },
    removeFilter() {
      this.selectedStatuses = [];
      this.setSelectedStatusFilter([]);
      this.onSearch();
    },
  },

  computed: {
    ...mapGetters('proposal/proposal', [
      'proposalNumber',
      'financingProposalDefaultList',
      'searchText',
      'hits',
      'nbPages',
      'page',
      'selectedStatusFilter',
      'saveFunc',
    ]),
    ...mapGetters('proposal/template', ['proposalTemplateList']),
    ...mapGetters('setting/app/profile', ['currentUser']),
  },

  beforeDestroy() {
    const currentPath = (
      (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
      {}
    ).path;
    if (currentPath !== '/proposal') {
      this.removeFilter();
      this.resetSearch();
    }
  },
};
</script>

<style scoped>
.item-text-slot {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
