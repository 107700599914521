<template>
  <div>
    <f7-list>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>
      <invoice-section
        ref="invoiceSection"
        @closePopup="closeActionPopup"
      ></invoice-section>
    </f7-list>

    <send-email-input
      title="Retainage Emails"
      email-type="retainage"
      :projectId="displayCard.id"
      :value="displayCard.retainageEmails"
      default-subject="Invoice"
      :default-email-address="defaultEmailAddess"
      attachment-title="Invoice Attachment"
      @input="setAttachment('retainageEmails', $event)"
    ></send-email-input>

    <!-- attachment -->
    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :isSaveButton="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Retainge Note',
          note,
          callback: () => {
            note = '';
          },
        })
      "
    ></note-editor>

    <date-popup
      ref="dueDatePopup"
      v-model="newDueDate"
      title="Enter Due Date"
      label="Due Date"
      validate
      not-allow-past
      @done="resolveWithDueDate"
    ></date-popup>

    <f7-popup
      :opened="popupPaymentOpened"
      @popup:closed="popupPaymentOpened = false"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="popupPaymentOpened = false">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Received Payment</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="resolvePayment">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- Payment date -->
        <f7-list>
          <f7-list-input
            label="Check Number"
            placeholder="Enter Check #"
            type="text"
            resizable
            clear-button
            :value="displayCard.payment ? displayCard.payment.checkNumber : ''"
            @input="
              displayCard.payment.checkNumber = $event.target.value.trim()
            "
          >
            <input-icon
              slot="media"
              icon="number"
            ></input-icon>
          </f7-list-input>
          <f7-list-input
            type="datepicker"
            :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
            }"
            label="Payment Day"
            placeholder="Enter Payment Day"
            readonly
            :value="paymentDate"
            @calendar:change="paymentDate = $event"
          >
            <input-icon
              slot="media"
              icon="calendar_today"
            ></input-icon>
          </f7-list-input>

          <f7-list-input
            label="Payment Amount"
            placeholder="Enter Payment Amount"
            type="text"
            resizable
            clear-button
            :value="
              displayCard.payment ? displayCard.payment.paymentAmount : ''
            "
            @input="
              displayCard.payment.paymentAmount = $event.target.value.trim()
            "
          >
            <input-icon
              slot="media"
              icon="money_dollar"
            ></input-icon>
          </f7-list-input>
        </f7-list>

        <attachment-input
          :projectId="displayCard.id"
          title="Attachments"
          attachment-type="attachment"
          add-button-title="Add an Attachment"
          :value="displayCard.attachmentFiles"
          @input="setAttachment('attachmentFiles', $event)"
        ></attachment-input>

        <f7-list>
          <f7-list-input
            label="Archive Note"
            type="textarea"
            resizable
            clear-button
            :value="archiveNote"
            @input="archiveNote = $event.target.value.trim()"
          >
            <input-icon
              slot="media"
              icon="doc_text"
            ></input-icon
          ></f7-list-input>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import SendEmailInput from '../input/SendEmailInput.vue';
import InputIcon from '../icon/InputIcon.vue';
import InvoiceSection from '../invoice/InvoiceSection.vue';
import {
  PAYMENT_STATUS_PUT_A_LIEN,
  PAYMENT_STATUS_RECEIVED_PAYMENT,
} from '../../../../utility/const';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';
import { mapState, mapActions } from 'vuex';

import _ from 'lodash';
import methodsMixins from '../../mixin/methods';
import computedsMixins from '../../mixin/computeds';
import moment from 'moment';
import NoteEditor from '../note/NoteEditor.vue';

export default {
  components: {
    UserInput,
    AttachmentInput,
    DatePopup,
    SendEmailInput,
    InputIcon,
    InvoiceSection,
    NoteEditor,
  },
  mixins: [methodsMixins, computedsMixins],

  data: () => {
    return {
      note: '',
      archiveNote: '',
      currentResponse: {},

      displayCard: {
        payment: {},
      },
      dueDate: [],
      paymentDate: [],

      popupPaymentOpened: false,
      newDueDate: [],
      paymentStatus: '',
    };
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.retainage = this.displayCard.retainage || false;
        if (!_.isEmpty(this.$refs.invoiceSection)) {
          this.$refs.invoiceSection.initData();
        }
        this.displayCard.payment = this.displayCard.payment || {};

        this.paymentDate = toDateCalendar(
          this.displayCard.payment.paymentDate || []
        );
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'save-and-close':
          this.save();
          this.$emit('doClosePopup', 'closeOnly');
          break;

        case 'payment-received':
          this.currentResponse = response;
          this.paymentStatus = PAYMENT_STATUS_RECEIVED_PAYMENT;
          this.popupPaymentOpened = true;
          // this.resolve();
          break;

        case 'move-to-lien':
          this.currentResponse = response;
          this.paymentStatus = PAYMENT_STATUS_PUT_A_LIEN;
          this.$refs.dueDatePopup.open();
          break;
      }
    },

    resolvePayment() {
      this.popupPaymentOpened = false;
      this.$f7router.updateCurrentUrl(
        '/dashboard/swimlane/commercial/post-construction'
      );
      setTimeout(() => {
        this.resolve(() => {});
      }, 1000);
    },

    resolveWithDueDate() {
      this.dueDate = this.newDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve(callback) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup', callback);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Retainage Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }

      if (this.archiveNote) {
        commentAndNote.push({
          code: 'note',
          title: 'Archive Note',
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        this.displayCard.archiveDate = firebase.firestore.Timestamp.now();
      }

      this.displayCard.paymentStatus = this.paymentStatus;

      this.displayCard.commentAndNote = commentAndNote;

      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      if (!_.isEmpty(this.paymentDate)) {
        this.displayCard.payment.paymentDate = toDateFirebase(this.paymentDate);
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            'day'
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },

    closeActionPopup(callback) {
      this.$emit('doClosePopup', callback);
    },
  },
};
</script>

<style></style>
