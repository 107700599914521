<template>
  <f7-page v-if="detail">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Category</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-list
      inset
      media-list
    >
      <f7-list-input
        label="Name"
        type="text"
        placeholder="Category name"
        :value="detail.name"
        @change="changeFieldValue('name', $event.target.value)"
        error-message-force
        :error-message="nameErrorMessage"
      >
        <required-asterisk slot="label" />
        <f7-icon
          color="primary"
          slot="media"
          f7="briefcase"
        />
      </f7-list-input>
      <f7-list-item
        ref="categoryDetailRoofTypesPopup"
        smart-select
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search',
          on: {
            closed: v => {
              const vals = v.getValue();
              if (!vals || !vals.length) return;
              changeFieldValue('roofTypes', v.getValue());
            },
            opened: handlePopupOpened,
          },
          formatValueText: function () {
            return null;
          },
          appendSearchbarNotFound: 'No Data',
          cssClass: 'multi-select-popup',
        }"
        header="Roof types"
        :title="roofTypesDisplay(detail.roofTypes)"
      >
        <f7-icon
          color="primary"
          slot="media"
          f7="square_on_circle"
        />
        <select multiple>
          <option
            v-for="item in roofTypeList"
            :key="item.id"
            :value="item.value"
            :selected="(detail.roofTypes || []).includes(item.value)"
          >
            {{ item.displayName }}
          </option>
        </select>
      </f7-list-item>
    </f7-list>
    <sub-category-list
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
    />
  </f7-page>

  <f7-page v-else>
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found category</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The category could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import SubCategoryList from '../components/list/SubCategoryList.vue';

export default {
  data() {
    return {
      searchText: '',
      categoryId: '',
      popupAddNew: false,
      nameErrorMessage: '',
    };
  },
  methods: {
    ...mapMutations({
      setDetailId: 'product/category-page/category/setDetailId',
    }),

    ...mapActions({
      update: 'product/category-page/category/update',
    }),

    changeFieldValue(field, value) {
      if (field === 'name') {
        if (!value) {
          this.nameErrorMessage = 'This field is required';
          return;
        } else {
          this.nameErrorMessage = '';
        }
      }
      this.update({
        id: this.detail.id,
        doc: {
          [field]: value,
        },
      });
    },
    roofTypesDisplay(roofTypes) {
      return this.roofTypeList
        .filter(t => (roofTypes || []).includes(t.value))
        .map(t => t.displayName)
        .join(', ');
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.categoryDetailRoofTypesPopup.f7SmartSelect.searchbar.$el[0]
          .querySelector('.searchbar input')
          .focus();
      }
    },
    handleBack() {
      this.$f7router.back('/category', { force: true });
    },
  },
  components: {
    SubCategoryList,
  },
  computed: {
    ...mapGetters({
      detail: 'product/category-page/category/detail',
      roofTypeList: 'product/roof-type/roofTypeList',
    }),
  },
  created() {
    this.setDetailId(this.$f7route.params.categoryId);
  },
};
</script>

<style scoped>
.flex-1 {
  flex: 1;
}
.add-button {
  min-width: 150px;
}
</style>
<style>
.multi-select-popup .searchbar-not-found {
  padding: 8px;
  text-align: center;
}
</style>
