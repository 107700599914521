<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-if="!$f7.device.ipad"
          @click="backToGrid"
          icon-f7="chevron_left"
          >Purchase Orders</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>{{ purchaseOrder.taskNumber || '' }}</f7-nav-title>
    </f7-navbar>

    <purchase-order-detail />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import poMixin from '../mixins/purchase-order-mixin';
import PurchaseOrderDetail from '../components/details/PurchaseOrderDetail.vue';
import _ from 'lodash';

export default {
  components: {
    PurchaseOrderDetail,
  },

  computed: {
    ...mapGetters('purchase-order/purchase-order', ['purchaseOrder']),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('purchase-order/purchase-order-details-page/common', [
      'destroyFlag',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'actionList',
    ]),
    ...mapGetters('purchase-order/purchase-order-details-page/vendor', [
      'vendorList',
    ]),
    ...mapGetters('purchase-order/app-constant', ['roofTypeList']),

    ...mapGetters('common/app-constant', ['tenantId']),
  },

  mixins: [poMixin],

  created() {
    this.$f7.preloader.show();
    this.setDestroyFlag(false);
    const seft = this;
    this.bindPurchaseOrder({
      poNumber: this.$f7route.params.purchaseOrderNumber,
    })
      .then(() => {
        const promises = [];
        if (_.isEmpty(this.purchaseOrder)) {
          return;
        }
        if (_.isEmpty(this.setting)) {
          promises.push(this.bindSetting(this.tenantId));
        }
        if (_.isEmpty(this.vendorList)) {
          promises.push(this.bindVendorList());
        }
        if (_.isEmpty(this.roofTypeList)) {
          promises.push(this.bindConstantTypeList());
        }
        if (_.isEmpty(seft.actionList)) {
          promises.push(this.getActionList());
        }
        promises.push(this.getProject(this.purchaseOrder.projectId));
        if (this.purchaseOrder.priceListId) {
          promises.push(this.bindPriceListItem(this.purchaseOrder.priceListId));
        }
        promises.push(
          this.bindProductItemListBys([
            {
              prop: 'vendorId',
              val: this.purchaseOrder.vendorId,
              op: '==',
            },
            {
              prop: 'status',
              val: 'pi-active',
              op: '==',
            },
            {
              prop: 'isDeleted',
              val: false,
              op: '==',
            },
          ])
        );

        return Promise.all(promises).then(() => {
          return this.bindPurchaseOrderResponse(this.getActionId());
        });
      })
      .finally(() => {
        this.$f7.preloader.hide();
        this.setDestroyFlag(true);
      });
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.unbindWorkOrderTask();
      this.resetActionList();
      this.unbindPriceListItem();
      this.unbindProductItemList();
      this.unbindVendorList();
    }
  },

  methods: {
    ...mapActions('purchase-order/purchase-order', [
      'bindPurchaseOrderResponse',
      'bindPurchaseOrder',
      'unbindWorkOrderTask',
    ]),
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('purchase-order/purchase-order-details-page/project', [
      'getProject',
      'getActionList',
      'resetActionList',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/product-item', [
      'bindProductItemListBys',
      'unbindProductItemList',
    ]),
    ...mapActions('purchase-order/app-constant', ['bindConstantTypeList']),
    ...mapActions('purchase-order/purchase-order-details-page/common', [
      'setDestroyFlag',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/vendor', [
      'bindVendorList',
      'unbindVendorList',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/price-list', [
      'bindPriceListItem',
      'unbindPriceListItem',
    ]),

    backToGrid() {
      this.$f7router.navigate('/purchase-order-grid', {
        reloadAll: true,
      });
    },
  },
};
</script>
