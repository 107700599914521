<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupAddNewProductItem"
    @popup:closed="closedPopup"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Product Item</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="addProductItem">Add</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :headers="headers"
        :items="productItemListComputed"
        :pageSize="productItemListComputed.length"
        :checkbox="true"
        @selected:change="checkBoxChange"
        @callBackChangePage="createColorPhotoTooltip"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              placeholder="Search by SKU, Product Item, Manufacturer, Color Name, Price"
              :clear-button="true"
              disable-button-text
              @input="searchtext = $event.target.value"
              @searchbar:disable="searchtext = ''"
              ref="searchProductItemPopup"
            ></f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td>{{ item.sku }}</td>
          <td>{{ item.productItem }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ item.categoryName }}</td>
          <td>{{ item.subCategoryName }}</td>
          <td>
            <ul class="technical-data">
              <li
                v-for="(attach, index) in item.technicalData"
                :key="index"
                :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
              >
                <a
                  class="display-block"
                  @click.stop="openLink(item, attach)"
                >
                  {{ attach.name }}
                </a>
              </li>
            </ul>
          </td>
          <td>
            <f7-link
              v-for="(color, index) in item.colorPhotos || []"
              :key="index"
              @click.native.stop="clickPhoto(color)"
              :id="`${color.id}-${item.id}`"
            >
              {{
                `${color.colorName}${
                  index + 1 === (item.colorPhotos || []).length ? '' : ',&nbsp;'
                }`
              }}
            </f7-link>
          </td>
          <td>{{ item.packaging }}</td>
          <td>{{ item.unitPack }}</td>
          <td>
            {{
              item.unitSize && item.uom && item.packaging
                ? `${item.unitSize} ${item.uom}/${item.packaging}`
                : ''
            }}
          </td>
          <td>{{ item.uom }}</td>
          <td>{{ item.price | currencyUSD }}</td>
        </template>
      </data-table>
      <f7-photo-browser
        :photos="productPhotos"
        theme="dark"
        :ref="`detail_pageDark`"
        type="popup"
      ></f7-photo-browser>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from '@/components/datatables';
import { applyFilter, comparationSort } from '@/utility/filter-tools';
import _ from 'lodash';
import { createMediaArray, isImage, isVideo, openAttackLink } from '@/utility/common';

export default {
  components: {
    DataTable,
  },

  props: {
    popupAddNewProductItem: Boolean,
    productItemList: Array,
    existingProductItems: Array,
  },

  data: () => ({
    searchtext: '',
    productPhotos: [],
    selectedProducts: [],
    gridFilter: {
      categoryId: '',
      subCategoryId: '',
    },
  }),

  computed: {
    headers() {
      const self = this;
      return [
        {
          text: 'SKU',
          value: 'sku',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Product Item',
          value: 'productItem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Manufacturer',
          align: 'left',
          sortable: false,
          value: 'manufacturer',
        },
        {
          text: 'Product Category',
          value: 'productCategory',
          sortable: false,
          align: 'left',
          filter: {
            type: 'select',
            value: self.gridFilter.categoryId,
            selectSource: [
              { value: '', text: 'All categories' },
              ...this.categoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.categoryId = value;
            },
          },
        },
        {
          text: 'Sub Category',
          value: 'subCategory',
          sortable: false,
          align: 'left',
          filter: {
            type: 'select',
            value: self.gridFilter.subCategoryId,
            selectSource: [
              { value: '', text: 'All sub categories' },
              ...this.subCategoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name,
                }))
                .sort((a, b) => comparationSort(a.text, b.text)),
            ],
            onChange: value => {
              self.gridFilter.subCategoryId = value;
            },
          },
        },
        {
          text: 'Technical Data',
          value: 'technicalData',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Color',
          value: 'color',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Packaging',
          value: 'packaging',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Unit Packs',
          value: 'unitPack',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Unit Size',
          value: 'unitSize',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Price',
          value: 'price',
          sortable: false,
          align: 'left',
        },
      ];
    },
    categoriesFilterOptions() {
      const uniqueCategories = new Map();
      this.productItemList?.forEach(item => {
        if (item.categoryId && item.categoryName) {
          uniqueCategories.set(item.categoryId, {
            id: item.categoryId,
            name: item.categoryName
          });
        }
      });
      return Array.from(uniqueCategories.values());
    },
    subCategoriesFilterOptions() {
      const uniqueSubCategories = new Map();
      this.productItemList?.forEach(item => {
        if (item.subCategoryId && item.subCategoryName) {
          uniqueSubCategories.set(item.subCategoryId, {
            id: item.subCategoryId,
            name: item.subCategoryName
          });
        }
      });
      return Array.from(uniqueSubCategories.values());
    },
    /**
     * productItemListComputed
     */
    productItemListComputed() {
      // First apply category filters if they exist
      let productsByFilters = this.productItemList;
      if (this.gridFilter.categoryId) {
        productsByFilters = productsByFilters.filter(
          item => item.categoryId === this.gridFilter.categoryId
        );
      }
      if (this.gridFilter.subCategoryId) {
        productsByFilters = productsByFilters.filter(
          item => item.subCategoryId === this.gridFilter.subCategoryId
        );
      }

      // Then apply search text filter
      const filteredProducts = applyFilter(
        productsByFilters,
        this.searchtext,
        [
          'productItem',
          'sku',
          'manufacturer',
          'price',
          i => (i.colorPhotos || []).map(c => c.colorName).join(', ')
        ]
      );
      return filteredProducts.map(product => ({
        ...product,
        checked: this.selectedProducts.some(p => p.id === product.id),
      }));
    },
  },

  methods: {
    closedPopup() {
      this.productPhotos = [];
      this.selectedProducts = [];
      this.gridFilter = {
        categoryId: '',
        subCategoryId: '',
      };
      this.searchtext = '';
      this.$emit('close')
    },
    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },
    /**
     * addProductItem
     */
    addProductItem() {
      this.$emit('onAddProductItems', this.selectedProducts);
    },

    /**
     * showNotification
     */
    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },

    handlePopupOpened() {
      this.selectedProducts = _.cloneDeep(this.existingProductItems);
      if (this.$device.desktop) {
        this.$refs.searchProductItemPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    createColorPhotoTooltip() {
      this.$nextTick(() => {
        this.productItemListComputed.forEach(product => {
          (product.colorPhotos || []).forEach(color => {
            const el = this.$f7.tooltip.get(`#${color.id}-${product.id}`);
            if (el) {
              this.$f7.tooltip.destroy(`#${color.id}-${product.id}`);
            }
            const photo = (color.photos || [])[0];
            const tooltipContent = photo
              ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
              : `<span>No image !</span>`;
            this.$f7.tooltip.create({
              targetEl: `#${color.id}-${product.id}`,
              cssClass: photo ? 'tooltip-image-preview' : '',
              text: tooltipContent,
            });
          });
        });

        this.productItemListComputed.forEach(attachMent => {
          (attachMent.technicalData || []).forEach(item => {
            const el1 = this.$f7.tooltip.get(
              `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
            if (el1) {
              this.$f7.tooltip.destroy(
                `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
              );
            }
            const tooltipContent = isImage(item.url)
              ? `<img class="tooltip-image-show" src="${item?.url}" />`
              : isVideo(item.url)
                ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
                : `<span>${item?.name}</span>`;
            this.$f7.tooltip.create({
              targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
              cssClass:
                isImage(item.url) || isVideo(item.url)
                  ? 'tooltip-image-preview'
                  : '',
              text: tooltipContent,
            });
          });
        });
      });
    },

    checkBoxChange(data) {
      const foundedItem = this.selectedProducts.find(
        item => item.id === data.id
      );
      // remove unchecked item
      if (foundedItem && data.checked === false) {
        this.selectedProducts = this.selectedProducts.filter(
          item => item.id !== foundedItem.id
        );
      }
      // add checked item
      else if (!foundedItem && data.checked === true) {
        const productToAdd = this.productItemList.find(
          item => item.id === data.id
        );
        if (productToAdd) {
          this.selectedProducts.push({ ...productToAdd, checked: true });
        }
      }

      // foundedItem && data.checked === true => do notthing
    },
  },
  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>
