export default {
  proposalList: state => state.proposalList,

  proposal: state => {
    return state.proposal;
  },

  proposalNew: state => {
    return state.proposalNew;
  },

  proposalByNumber: state => proposalNumber => {
    return (
      state.proposalList.find(r => r.proposalNumber === proposalNumber) || {}
    );
  },
  proposalById:
    ({ proposalList }) =>
    proposalId => {
      return proposalList.find(r => r.id === proposalId) || {};
    },
  proposalNumber: state =>
    state.proposal ? state.proposal.proposalNumber : '',
  photos: state => {
    return state.proposal ? state.proposal.photos || [] : [];
  },
  photosOfBuilding: state => {
    return state.proposal ? state.proposal.photosOfBuilding || [] : [];
  },
  imagesToDelete: state => state.imagesToDelete || [],

  financingProposalDefaultList: state => {
    return state.financingProposalDefaultList;
  },
  financingProposalList: state => {
    return state.financingProposalList;
  },
  proposalType: state => state.proposalType,
  // Fulltext search
  order: state => state.order,
  searchText: state => {
    return state.searchText;
  },

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  paramsBack: state => state.paramsBack,
  queryBack: state => state.queryBack,
  isChangeProposalTypeAndNavigate: state =>
    state.isChangeProposalTypeAndNavigate,
  selectedStatusFilter: state => state.selectedStatusFilter,
  queryFilters: state => {
    return state.selectedStatusFilter.map(s => `status:${s}`).join(' OR ');
  },

  saveFunc: state => state.saveFunc,
};
